import React, { useEffect, useState } from 'react';
import { Col, Icon, Layout, message, Row, Typography } from 'antd';
import { get } from 'lodash';
import Search from 'antd/lib/input/Search';

import { handleErr } from 'utils';
import CustomerService from 'api/customer';
import PdAntdSwitch from 'components/pd-antd-switch';
import PdButton from 'components/pd-button';
import PdMobileTable from 'components/pd-mobile-table';
import PdModal from 'components/pd-modal';

import './style.scss';

const { Title } = Typography;

export function CustomerSetup({ history }) {
  const [cursor, setCursor] = useState(1);
  const [customerList, setCustomerList] = useState([]);
  const [query, setQuery] = useState('');
  const [showModal, setShowModal] = useState(false);

  async function handleDelete() {
    try {
      await CustomerService.deleteCustomer(showModal.id);
      message.success('Customer successfully deleted');
      fetchCustomerList({ cursor: cursor, q: query });
    } catch (error) {
      message.error(
        get(error, 'response.message', 'Failed to Submit Form Data')
      );
    } finally {
      setShowModal(false); // Using setShowModal.bind(this, false); won't affect anything.
    }
  }

  function handleEdit(customerData) {
    history.push({ pathname: '/customer-setup/edit', data: customerData });
  }

  function handleAddCustomer() {
    history.push({
      pathname: '/customer-setup/create',
    });
  }

  async function handleChangeStatus(id, status) {
    try {
      if (status === 'active') {
        await CustomerService.deactivateCustomer(id);
      } else {
        await CustomerService.activateCustomer(id);
      }

      await fetchCustomerList({ q: query });
    } catch (error) {
      handleErr(error);
    }
  }

  const modalContent = () => (
    <Row justify="center" type="flex">
      <Col xs={{ span: 11 }}>
        <PdButton
          block
          ghost
          type="primary"
          onClick={setShowModal.bind(this, false)}>
          Cancel
        </PdButton>
      </Col>
      <Col xs={{ span: 11, offset: 2 }}>
        <PdButton block onClick={handleDelete}>
          Delete
        </PdButton>
      </Col>
    </Row>
  );

  const ColumnAction = (rowData = {}) => {
    const { id, status } = rowData;
    return (
      <Row justify="center" type="flex">
        {/** Inserting data on setShowModal for give customer ID to delete modal */}
        <Col xs={{ span: 8 }} onClick={setShowModal.bind(this, rowData)}>
          <Icon type="delete" theme="twoTone" twoToneColor="green" />
        </Col>
        <Col xs={{ span: 9 }} onClick={handleEdit.bind(this, rowData)}>
          <Icon type="edit" theme="twoTone" twoToneColor="green" />
        </Col>
        <Col>
          <PdAntdSwitch
            checked={status === 'active'}
            onChange={() => handleChangeStatus(id, status)}
          />
        </Col>
      </Row>
    );
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Customer',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
      width: '25%',
    },
    {
      title: '',
      width: '25%',
      render: ColumnAction,
    },
  ];

  const modalConfig = {
    children: modalContent(),
    modalClassName: 'pd-modal-mobile',
    modalTitle: 'Are you sure to delete this customer ?',
    onCancel: setShowModal.bind(this, false),
    visible: true,
  };

  useEffect(() => {
    fetchCustomerList({ q: query });
  }, [cursor, query]);

  async function fetchCustomerList({ q = '' }) {
    const { data } = await CustomerService.getCustomerList({
      q,
    });

    const customersData = get(data, 'data', []);
    customersData.forEach((customer) => {
      customer['key'] = customer.id;
    });
    setCustomerList(customersData);
  }

  function handleSearchCustomerName(e) {
    setQuery(get(e, 'target.value', ''));
    setCursor(1);
  }

  return (
    <Layout className="pd-mobile-customer-setup">
      {/* Section that display chart */}
      <Row xs={24} justify="center" type="flex">
        <Col className="pd-margin-top-md pd-margin-bottom-sm" xs={{ span: 24 }}>
          <Title className="pd-align-center" level={3}>
            Customer Setup
          </Title>
        </Col>
        <Col xs={{ span: 13 }}>
          <Search
            placeholder="Search by customer name"
            onChange={handleSearchCustomerName}
          />
        </Col>
        <Col xs={{ span: 8, offset: 1 }}>
          <PdButton block onClick={handleAddCustomer}>
            Add New
          </PdButton>
        </Col>
        <Col xs={{ span: 24 }} className="pd-margin-top-lg">
          <PdMobileTable
            dataSource={customerList}
            columns={columns}
            pagination={{ current: cursor, onChange: setCursor, pageSize: 5 }}
          />
        </Col>
      </Row>
      {showModal && <PdModal {...modalConfig} />}
    </Layout>
  );
}

export default { CustomerSetup };
