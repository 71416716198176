import api from './index';

export default {
  getRevenue({ start_date = '', end_date = '' }) {
    const params = {
      start_date,
      end_date,
    };
    return api.get(`/order_invoices/revenue`, { params });
  },
};
