import api from './index';
import axios from 'axios';

export default {
  getUploadFileUrl(params) {
    return api.get('/uploads/sign_images', { params });
  },
  uploadImage(file, url) {
    return axios({
      method: 'put',
      url,
      data: file,
    });
  },
};
