import api from './index';

export default {
  getServiceList({ limit = 25000, cursor = 1, q = '', status = '' }) {
    return api.get('/services', {
      params: {
        limit,
        cursor,
        q,
        ...(status ? { status } : {}),
      },
    });
  },
  getServiceDetail(id = 1) {
    return api.get(`/services/${id}`);
  },
  getServiceReport() {
    return api.get('/services/service_report');
  },
  createService(service = {}) {
    return api.post('/services', service);
  },
  createBulkService(services = []) {
    return api.post('/services/bulk_create', services);
  },
  updateService({ id = '', service = {} }) {
    return api.put(`/services/${id}`, service);
  },
  deactiveService(id = '') {
    return api.delete(`/services/${id}`);
  },
  reactiveService(id = '') {
    return api.put(`/services/${id}/reactivate`);
  },
  deleteService(id = '') {
    return api.delete(`/services/${id}/delete`);
  },
};
