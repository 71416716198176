import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import AuthenticationApi from 'api/authentication';
import AdminAPI from 'api/admin';
import { handleErr } from 'utils';
import { PageSpinner } from 'components/page-spinner';
import { UNLIMTED_PLAN_TERM } from 'utils/constants';

export const AuthenticationContext = createContext();

function AuthenticationContextProvider({ children }) {
  const [authenticated, setAuthenticated] = useState(false);
  const [authenticatedUserProfile, setAuthenticatedUserProfile] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  async function login(payload) {
    const { data } = await AuthenticationApi.login(payload);
    const {
      access_token: accessToken = null,
      admin: userProfile = {},
      company = {},
    } = data?.data;
    const {
      email = '',
      full_name = '',
      icon_setting = [],
      role = {},
    } = userProfile;
    const { permission = [] } = role;

    if (accessToken && userProfile) {
      localStorage.setItem('cms_token', accessToken);
      localStorage.setItem('current_admin', email);
      localStorage.setItem('admin_name', full_name);
      localStorage.setItem('admin_icon_setting', icon_setting);
      localStorage.setItem('admin_permission', permission);

      setAuthenticated(true);
      setAuthenticatedUserProfile((prev) => ({
        ...prev,
        ...userProfile,
        isUnlimitedPlan: company?.subscription_type === 'unlimited',
      }));
    }

    window.location.reload();
  }

  async function fetchCurrentAdmin() {
    try {
      setIsLoading(true);
      const { data } = await AdminAPI.getPersonalAdmin();
      const { data: currentUser = {} } = data;
      const subscriptionType =
        currentUser.company?.subscription_type ?? 'basic';
      setAuthenticatedUserProfile((prev) => ({
        ...prev,
        name: currentUser.full_name,
        isUnlimitedPlan: UNLIMTED_PLAN_TERM.includes(
          subscriptionType.toLowerCase()
        ),
      }));
    } catch (error) {
      handleErr(error);
    } finally {
      setIsLoading((prev) => !prev);
    }
  }

  useEffect(() => {
    if (localStorage.getItem('cms_token')) {
      fetchCurrentAdmin();
    }
  }, []);

  if (isLoading) {
    return <PageSpinner />;
  }

  return (
    <AuthenticationContext.Provider
      value={{
        authenticated,
        authenticatedUserProfile,
        login,
      }}>
      {children}
    </AuthenticationContext.Provider>
  );
}

AuthenticationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object,
};

export default AuthenticationContextProvider;
