import React, { useRef } from 'react';
import { CSVReader } from 'react-papaparse';
import { message } from 'antd';
import { identity } from 'lodash';

import PdButton from 'components/pd-button';

import CustomerService from 'api/customer';
import ServiceAPI from 'api/service';

import { handleErr } from 'utils';

import './style.scss';

/**
 *
 * @param {() => {}} afterUpload - callback when file is uploaded
 * @param {Array} requiredFieldName - array of fieldnames that must be filled before hit BE
 * @param {String} type - to indicate which BE API that must be hit to update data
 */

export function PdCsvUploader({
  afterUpload = identity,
  requiredFieldName = [],
  type = '',
}) {
  const csvButtonRef = useRef();

  async function handleOnFileLoad(file) {
    const arrayOfData = file.map(({ data }) => data);
    let fieldName = '';
    let row = '';

    // iteration for validation
    for (let i = 0; i < arrayOfData.length; i++) {
      const data = arrayOfData[i] ?? {};
      for (const key in data) {
        if (!data[key] && requiredFieldName.includes(key)) {
          fieldName = key;
          row = i + 1;
          break;
        } else {
          row = '';
        }
      }
      if (row) break;
    }

    if (row) {
      message.error(`Field ${fieldName} in row ${row} is empty!`);
    } else {
      // hit BE here
      const customers = arrayOfData.map(
        ({
          billing_address = '',
          city = '',
          customer_name = '',
          email = '',
          fax_number = '',
          phone_number = '',
          postal_code = '',
          regular_service = '',
          state_code = '',
        }) => {
          const addressAttr = {
            address: billing_address,
            city,
            postal_code,
            state_code,
          };
          return {
            name: customer_name,
            email: email,
            phone_number,
            fax_number,
            regular_service,
            billing_address_attributes: addressAttr,
            property_addresses_attributes: [addressAttr],
          };
        }
      );

      const services = arrayOfData.map(
        ({ description = '', price = '', service_name = '' }) => ({
          description,
          price,
          title: service_name,
        })
      );
      try {
        switch (type) {
          case 'customer-setup':
            await CustomerService.createBulkCustomer({ customers });
            break;
          case 'service':
            await ServiceAPI.createBulkService({ services });
            break;
          default:
            break;
        }

        message.success('Successfully upload csv data');
        afterUpload();
      } catch (error) {
        handleErr(error);
      }
    }

    csvButtonRef.current.removeFile();
  }
  return (
    <div className="pd-csv-uploader">
      <CSVReader
        config={{ header: true }}
        noDrag
        noProgressBar
        onFileLoad={handleOnFileLoad}
        ref={csvButtonRef}>
        <PdButton block>Upload .csv</PdButton>
      </CSVReader>
    </div>
  );
}
