import React, { useEffect, useState } from 'react';
import { Col, Layout, Row } from 'antd';
import Search from 'antd/lib/input/Search';
import { get } from 'lodash';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdAntdTable from 'components/pd-antd-table';
import { PdDownloadCSV } from 'components/pd-download-csv';

import CustomerService from 'api/customer';

import './style.scss';

export function ReportAllCustomer({ history }) {
  const [customerList, setCustomerList] = useState([]);
  const [cursor, setCursor] = useState(1);
  const [query, setQuery] = useState('');

  const columns = [
    {
      title: 'Customer',
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Phone',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    {
      title: 'Billing Address',
      dataIndex: 'billing_address.address',
      key: 'billing_address',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: '',
      key: 'id',
      render: ColumnAction,
      width: '15%',
    },
  ];

  function handleCustomerDetail(rowData) {
    history.push({
      pathname: '/all-customer/detail',
      id: get(rowData, 'id', ''),
    });
  }

  function handleSearchCustomerName(e) {
    setQuery(get(e, 'target.value', ''));
    setCursor(1);
  }

  async function fetchCustomerList({ cursor = 1, q = '' }) {
    const { data } = await CustomerService.getCustomerList({
      cursor: cursor,
      q: q,
      status: 'active',
    });
    // append key for each data
    const customersData = get(data, 'data', []);
    customersData.forEach((customer) => {
      customer['key'] = customer.id;
    });
    setCustomerList(customersData);
  }

  useEffect(() => {
    fetchCustomerList({ cursor: cursor, q: query });
  }, [cursor, query]);

  function ColumnAction(rowData) {
    return (
      <Row>
        <Col lg={24}>
          <PdButton
            block
            type="primary"
            onClick={handleCustomerDetail.bind(this, rowData)}>
            Detail
          </PdButton>
        </Col>
      </Row>
    );
  }

  return (
    <Layout className="pd-cms-customer-setup">
      <DashboardContainer>
        <Row align="middle" type="flex">
          <Col lg={10}>
            <Search
              placeholder="Search by Customer Name"
              onChange={handleSearchCustomerName}
            />
          </Col>
          <Col lg={{ span: 3, offset: 11 }}>
            <PdDownloadCSV
              fetchData={CustomerService.getAllCustomerReport}
              filename="customer-report.csv"
            />
          </Col>
        </Row>
      </DashboardContainer>
      <DashboardContainer>
        <Row align="middle">
          <Col lg={24}>
            <PdAntdTable
              dataSource={customerList}
              columns={columns}
              pagination={{ pageSize: 6 }}
            />
          </Col>
        </Row>
      </DashboardContainer>
    </Layout>
  );
}

export default { ReportAllCustomer };
