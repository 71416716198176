import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Layout, Row } from 'antd';
import { get } from 'lodash';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdForm from 'components/pd-form';

const FORM_FIELD = {
  SERVICE_NAME: 'service_name',
  PRICE: 'price',
  DESCRIPTION: 'description',
};

const { TextArea } = Input;

function TermAndPolicyCreateEdit({ form, history }) {
  const [fieldLabel, setFieldLabel] = useState('');

  const { getFieldDecorator } = form;

  const listLabel = {
    terms: 'Terms And Services',
    policy: 'Privacy Policy',
  };

  useEffect(() => {
    const fieldLabel = get(history, 'location.pathname');
    const paths = fieldLabel.split('/');
    setFieldLabel(listLabel[paths[paths.length - 1]]);
  }, [history, listLabel]);

  function handleSubmit() {}

  return (
    <Layout className="pd-cms-form-service">
      <DashboardContainer>
        <PdForm onSubmit={handleSubmit}>
          <Form.Item label={fieldLabel}>
            {getFieldDecorator(FORM_FIELD.DESCRIPTION)(
              <TextArea rows={16} placeholder={`Enter ${fieldLabel}`} />
            )}
          </Form.Item>

          <Form.Item className="btn-container">
            <Row align="middle" justify="center" type="flex">
              <Col lg={8}>
                <PdButton block>Submit</PdButton>
              </Col>
            </Row>
          </Form.Item>
        </PdForm>
      </DashboardContainer>
    </Layout>
  );
}

export const TermAndPolicyPageForm = Form.create({ name: 'term-and-policy' })(
  TermAndPolicyCreateEdit
);
