import React from 'react';
import PropTypes from 'prop-types';

import UserContext from './user';
import AuthenticationContext from './authentication';

function MainContext({ children }) {
  return (
    <AuthenticationContext>
      <UserContext>{children}</UserContext>
    </AuthenticationContext>
  );
}

MainContext.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainContext;
