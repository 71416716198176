import React, { useState, useEffect } from 'react';
import { Col, Icon, Layout, Row, Typography } from 'antd';
import Search from 'antd/lib/input/Search';

import PdMobileTable from 'components/pd-mobile-table';
import { PageSpinner } from 'components/page-spinner';

import { handleErr } from 'utils';

import CustomerAPI from 'api/customer';

import './style.scss';

const { Title } = Typography;

const itemPerPage = 5;

export function CustomerBalance({ history }) {
  const [cursor, setCursor] = useState(1);
  const [customerCount, setCustomerCount] = useState(0);
  const [customerList, setCustomerList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState('');

  const columns = [
    {
      title: 'Customer',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
    },
    {
      title: 'Outstanding Balance',
      dataIndex: 'current_balance',
      key: 'current_balance',
      width: '25%',
    },
    {
      title: '',
      key: 'id',
      render: ColumnAction,
      align: 'center',
      width: '20%',
    },
  ];

  function ColumnAction(rowData = {}) {
    return (
      <Row justify="center" type="flex">
        <Col xs={24} onClick={handleDetailCustomer.bind(this, rowData)}>
          <Icon type="search" />
        </Col>
      </Row>
    );
  }

  function handleDetailCustomer(rowData) {
    const { id = '', name = '' } = rowData;
    history.push({
      pathname: `/customer-balance/detail`,
      id,
      name,
    });
  }

  function handleSearch(ev) {
    setQuery(ev.target.value || '');
  }

  async function getCustomerData({ q = '', cursor = 1, limit = 100 }) {
    setIsLoading(true);
    try {
      const { data: response } = await CustomerAPI.getCustomerList({
        q,
        cursor,
        limit,
      });
      const { data = [], metadata = {} } = response ?? {};

      setCustomerList(data);
      setCustomerCount(metadata?.total_items);
    } catch (err) {
      handleErr(err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getCustomerData({ q: query, cursor, limit: itemPerPage });
    // eslint-disable-next-line
  }, [query, cursor]);

  return (
    <Layout className="pd-mobile-customer-balance">
      {/* Section that display chart */}
      <Row xs={24} justify="center" type="flex">
        <Col className="pd-margin-top-md pd-margin-bottom-sm" xs={{ span: 24 }}>
          <Title className="pd-align-center" level={3}>
            Customer Balance
          </Title>
        </Col>
        <Col span={22}>
          <Search
            placeholder="Search by Customer Name"
            onChange={handleSearch}
          />
        </Col>
        <Col xs={{ span: 24 }} className="pd-margin-top-lg">
          {isLoading && <PageSpinner />}
          <PdMobileTable
            dataSource={customerList}
            rowKeys="id"
            columns={columns}
            pagination={{
              onChange: setCursor,
              pageSize: itemPerPage,
              total: customerCount,
            }}
          />
        </Col>
      </Row>
    </Layout>
  );
}

export default { CustomerBalance };
