import api from './index';
import { omitBy } from 'lodash';
import { isNil } from 'utils';

export default {
  getCustomerList({ limit = 25000, cursor = 1, q = '' } = {}) {
    const params = {
      cursor,
      q,
      limit,
    };
    return api.get('/customers', {
      params: omitBy(params, isNil),
    });
  },
  getAllCustomerBalanceReport() {
    return api.get('/customers/customer_balance_report');
  },
  getAllCustomerReport() {
    return api.get('/customers/customer_report');
  },
  getCustomerBalance({ id = undefined, limit = 50, period = null }) {
    let params = { limit };

    if (period) {
      params = { ...params, period };
    }

    return api.get(`/customers/${id}/balances`, {
      params,
    });
  },
  getCustomerOrderForPayment(id) {
    return api.get(`/customers/${id}/index_order`);
  },
  createCustomer(customer = {}) {
    return api.post('/customers', customer);
  },
  createBulkCustomer(customers = []) {
    return api.post('/customers/bulk_create', customers);
  },
  createCustomerPayment(id, payload) {
    return api.post(`/customers/${id}/payment`, payload);
  },
  getCustomerDetail(id) {
    return api.get(`/customers/${id}`);
  },
  updateCustomer({ id = '', customer = {} }) {
    return api.put(`/customers/${id}`, customer);
  },
  deleteCustomer(id) {
    return api.delete(`/customers/${id}/delete`);
  },
  activateCustomer(id) {
    return api.put(`/customers/${id}/reactivate`);
  },
  deactivateCustomer(id) {
    return api.delete(`/customers/${id}`);
  },
};
