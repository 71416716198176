/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Col, Row, Select } from 'antd';
import { get, identity } from 'lodash';
import moment from 'moment';

import PdButton from 'components/pd-button';
import PdModal from 'components/pd-modal';
import PdInputNumber from 'components/pd-input-number';
import PdDatePicker from 'components/pd-datepicker';

import CustomerApi from 'api/customer';

import { handleErr } from 'utils';
import {
  numberOnlyFormat,
  priceFormatter,
  priceParser,
} from 'utils/inputNumberUtils';

import './style.scss';

const { Option } = Select;

/**
 *
 * @param {() => {}} onCancel = callback when close icon modal is clicked
 * @param {() => {}} onHandleSubmit = callback when submit button modal is clicked
 * @param {Boolean} visible = false(hide), true(show)
 * @returns
 */
function PaymentModal({
  onCancel = identity,
  onHandleSubmit = identity,
  visible = false,
}) {
  const [formField, setFormField] = useState({});
  const [maxAmount, setMaxAmount] = useState(0);
  const [typeOptions, setTypeOptions] = useState([]);

  const location = useLocation();
  const { id = '' } = location;

  const dateNow = moment(Date.now());

  function handleOnChangeDate(val) {
    setFormField((prev) => ({ ...prev, payment_date: val }));
  }

  function handleOnChangeType(val) {
    // set amount value based on type
    const chosenType =
      typeOptions.find(({ value = '' }) => value === val) ?? {};
    const { amount = 0 } = chosenType;
    handleOnChangeAmount(+amount);
    setMaxAmount(+amount);

    setFormField((prev) => ({ ...prev, order_invoice_id: val }));
  }

  function handleOnChangeAmount(val) {
    const amount = typeof val === 'number' ? val : 0;
    setFormField((prev) => ({ ...prev, amount }));
  }

  function handleTwoFormatter(value) {
    return priceFormatter(numberOnlyFormat(value));
  }

  function handleSubmit() {
    const { payment_date = Date.now() } = formField;
    const formattedDate = moment(payment_date).format('YYYY/MM/DD');
    const payload = { ...formField, payment_date: formattedDate };
    onHandleSubmit({ payment: payload });
  }

  async function fetchOrderPayment() {
    try {
      const { data } = await CustomerApi.getCustomerOrderForPayment(id);
      const paymentList = get(data, 'data', []);
      const typeOptionList = paymentList.map(
        ({ id = '', invoice_number = '', payment_remaining = 0 }) => ({
          title: invoice_number,
          value: id,
          amount: payment_remaining,
        })
      );
      setTypeOptions(typeOptionList);
    } catch (error) {
      handleErr(error);
    }
  }

  useEffect(() => {
    fetchOrderPayment();
    // eslint-disable-next-line
  }, []);

  return (
    <PdModal
      className="pd-modal-payment"
      modalTitle="Payment"
      onCancel={onCancel}
      visible={visible}>
      <Row
        align="middle"
        className="payment-container"
        justify="center"
        type="flex">
        <Col lg={10} className="payment-label">
          Transaction Date
        </Col>
        <Col lg={10}>
          <PdDatePicker
            format="MM/DD/YYYY"
            defaultValue={dateNow}
            onChange={handleOnChangeDate}
          />
        </Col>
      </Row>
      <Row
        align="middle"
        className="payment-container"
        justify="center"
        type="flex">
        <Col lg={10} className="payment-label">
          Work Orders Type
        </Col>
        <Col lg={10}>
          <Select
            className="w-full"
            onChange={handleOnChangeType}
            placeholder="Select type">
            {typeOptions.map(({ title = '', value = '' }, idx) => (
              <Option key={idx} value={value}>
                {title}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row
        align="middle"
        className="payment-container"
        justify="center"
        type="flex">
        <Col lg={10} className="payment-label">
          Amount
        </Col>
        <Col lg={10}>
          <PdInputNumber
            defaultValue={0}
            formatter={handleTwoFormatter}
            min={1}
            max={maxAmount}
            onChange={handleOnChangeAmount}
            parser={priceParser}
            value={get(formField, 'amount', 0)}
          />
        </Col>
      </Row>
      <Row
        align="middle"
        className="payment-container"
        justify="center"
        type="flex">
        <Col lg={10}>
          <PdButton block onClick={handleSubmit}>
            Submit
          </PdButton>
        </Col>
      </Row>
    </PdModal>
  );
}

export default PaymentModal;

/* eslint-disable no-unused-vars */
