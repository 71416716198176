import React from 'react';
import { Col, Row, Typography } from 'antd';

import PdModal from 'components/pd-modal';

import { numberWithCommas } from 'utils/numberFormater';

import './style.scss';
import { capitalizeFirstLetter, DATE_FORMAT } from 'utils';
import moment from 'moment';

const { Text } = Typography;

function DetailModal({ invoiceData: detailData = {}, ...props }) {
  const {
    invoice_number = '',
    payment_status = '',
    total_price = 0,
    order_services = [],
  } = detailData;

  function parseData() {
    return order_services.map((orderService) => {
      const {
        price = 0,
        quantity = 0,
        service_date = moment().format(DATE_FORMAT),
        service = {},
      } = orderService;
      return [
        {
          title: 'Service Date',
          content: service_date,
        },
        {
          title: 'Service',
          content: service.title,
        },
        {
          title: 'Quantity',
          content: quantity,
        },
        {
          title: '$ per Unit',
          content: price,
        },
        {
          title: 'Amount',
          content: price * quantity,
        },
      ];
    });
  }

  const modalConfig = {
    modalTitle: 'Detail Invoice',
    modalClassName: 'pd-modal-mobile',
    width: 900,
  };

  return (
    <PdModal className="pd-modal-detail" {...modalConfig} {...props}>
      <Text className="detail-container-label">
        Work Order No : {invoice_number}
      </Text>
      <Row>
        <Text className="detail-container-label">
          Payment Status : {capitalizeFirstLetter(payment_status)}
        </Text>
      </Row>
      <Row
        align="middle"
        className="detail-container"
        justify="center"
        type="flex">
        <Col xs={24} className="detail-container-invoice">
          {/* Map Section */}
          <Row>
            {parseData().map((array, idx) => (
              <Col xs={24} className="detail-container-content" key={idx}>
                {array.map(({ title, content }, idx) => (
                  <Row key={idx}>
                    <Col xs={12}>
                      <Text strong>{title}</Text>
                    </Col>
                    <Col xs={12} className="pd-margin-bottom-md">
                      <Text>{content}</Text>
                    </Col>
                  </Row>
                ))}
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row type="flex" justify="end">
        <Col className="detail-container-label">Total Amount :</Col>
        <Col
          xs={8}
          className="detail-container-label pd-align-right pd-padding-right-md">
          {`$${numberWithCommas(total_price)}`}
        </Col>
      </Row>
    </PdModal>
  );
}

export default DetailModal;
