import React, { useState, useEffect } from 'react';
import { Form, Layout, Select, Tabs } from 'antd';
import moment from 'moment';

import DashboardContainer from 'components/dashboard-container';
import PdCalendar from 'components/pd-calendar';
import PdForm from 'components/pd-form';
import PdTabs from 'components/pd-tabs';
import { CalendarWorkOrderDetail } from './work-order-detail';

import CustomerApi from 'api/customer';
import OrderServiceApi from 'api/order-service';

import { handleErr } from 'utils';

import './style.scss';

const { Option } = Select;
const { TabPane } = Tabs;

export function Calendar() {
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(undefined);
  const [showWorkOrder, setShowWorkOrder] = useState(false);
  const [view, setView] = useState('dayGridWeek');
  const [workOrderId, setWorkOrderId] = useState('');

  const horizontalLayout = {
    labelAlign: 'left',
    labelCol: { span: 3 },
    wrapperCol: { span: 5 },
  };

  function handleChange(key) {
    setView(key);
  }

  function handleSelectCustomer(customerId) {
    setSelectedCustomer(customerId);
    fetchCalendarEvents(customerId);
  }

  function handleToWorkOrderDetail(id) {
    setWorkOrderId(id);
    setShowWorkOrder(true);
  }

  function handleBackFromDetail() {
    setShowWorkOrder(false);
    fetchCalendarEvents(selectedCustomer);
  }

  function handleChangeDate(range_date = {}) {
    fetchCalendarEvents({ ...range_date, customer_id: selectedCustomer });
  }

  async function fetchCalendarEvents({
    start_date = moment().day(0).format('YYYY-MM-DD'),
    end_date = moment().day(6).format('YYYY-MM-DD'),
    customer_id = 0,
  }) {
    try {
      const { data: fetchData = {} } = await OrderServiceApi.getOrderService({
        customer_id,
        start_date,
        end_date,
      });
      const { data = [] } = fetchData;
      const listOfEvents = [];
      data.map((calendarEvent) => {
        const { date = '', services = [] } = calendarEvent;
        services.map(({ service = {}, work_order = {} }) => {
          const {
            address = {},
            customer = {},
            id: workOrderId = '',
          } = work_order;
          const { address: addressName } = address;
          const { name = '' } = customer;
          const { service: serviceChild = {}, info_description = '' } = service;
          const { title = '' } = serviceChild;
          listOfEvents.push({
            address: addressName,
            customer: name,
            description: info_description,
            id: workOrderId,
            start: date,
            title,
          });
        });
      });
      setCalendarEvents(listOfEvents);
    } catch (error) {
      handleErr(error);
    }
  }

  async function fetchCustomerList() {
    try {
      const { data: fetchData = {} } = await CustomerApi.getCustomerList({
        status: 'active',
      });
      const { data = [] } = fetchData;
      setCustomerList(data);
    } catch (error) {
      handleErr(error);
    }
  }

  useEffect(() => {
    fetchCustomerList();
  }, []);

  return (
    <Layout className="pd-cms-calendar">
      <DashboardContainer>
        {showWorkOrder ? (
          <CalendarWorkOrderDetail
            onClickBack={handleBackFromDetail}
            orderId={workOrderId}
          />
        ) : (
          <>
            <PdForm>
              <Form.Item label="Customer Name" {...horizontalLayout}>
                <Select
                  className="form-item-block"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={handleSelectCustomer}
                  optionFilterProp="children"
                  placeholder="Enter Customer Name"
                  showSearch
                  value={selectedCustomer}>
                  {customerList.map(({ name = '', id = '' }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </PdForm>
            <PdTabs defaultActiveKey={view} centered onChange={handleChange}>
              <TabPane tab="Week" key="dayGridWeek" />
              <TabPane tab="Month" key="dayGridMonth" />
            </PdTabs>
            <PdCalendar
              events={calendarEvents}
              onClickOrder={handleToWorkOrderDetail}
              view={view}
              onChangeDate={handleChangeDate}
            />
          </>
        )}
      </DashboardContainer>
    </Layout>
  );
}

export default { Calendar };
