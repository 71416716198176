import api from 'api';

export default {
  getStates() {
    return api.get('/state_provinces');
  },
  getStateDetail(code) {
    return api.get(`/state_provinces/${code}`);
  },
};
