import React, { useState, useEffect, useContext } from 'react';
import {
  Checkbox,
  Col,
  DatePicker,
  Input,
  Layout,
  message,
  Row,
  Icon,
  Select,
} from 'antd';
import { get } from 'lodash';
import moment from 'moment';

import DashboardContainer from 'components/dashboard-container';
import PdAntdTable from 'components/pd-antd-table';
import PdButton from 'components/pd-button';
import PdPreviewEmail from 'components/pd-preview-email';
import { AuthenticationContext } from 'contexts/authentication';

import BillingInvoiceApi from 'api/billing-invoice';

import './style.scss';
import { INVOICE_STATUS } from './mockData';

import { BASIC_PLAN_WARN_COPYWRITE, handleErr } from 'utils';
import PdModalOTI from './modal-OTI';

const { MonthPicker } = DatePicker;
const { Search } = Input;
const { Option } = Select;

export function Invoice({ history }) {
  const [billingInvoices, setBillingInvoices] = useState([]);
  const [cursor, setCursor] = useState(1);
  const [isPreviewEmail, setIsPreviewEmail] = useState(false);
  const [meta, setMeta] = useState({});
  const [pdfUrl, setPdfUrl] = useState('');
  const [period, setPeriod] = useState('');
  const [previewId, setPreviewId] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [sendBulkMailData, setSendBulkMailData] = useState([]);
  const [showModalOTI, setShowModalOTI] = useState(false);
  const [status, setStatus] = useState('');

  const { authenticatedUserProfile } = useContext(AuthenticationContext);
  const { isUnlimitedPlan = false } = authenticatedUserProfile;

  const noSendBulkMailData = sendBulkMailData.length === 0;

  const columns = [
    {
      title: '',
      key: 'key',
      render: ColumnCheckbox,
    },
    {
      title: 'Invoice Number',
      dataIndex: 'id',
      key: 'id',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Customer Name',
      dataIndex: 'customer.name',
      key: 'customer.name',
      sorter: (a, b) => a.customer.name.localeCompare(b.customer.name),
    },
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'period',
      sorter: (a, b) => a.period.localeCompare(b.period),
      render: (value) => moment(value).format('MM/YYYY'),
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      width: '10%',
    },
    {
      title: 'Sent Mail',
      dataIndex: 'status',
      key: 'status',
      render: ColumnStatus,
    },
    {
      title: '',
      key: 'id',
      render: ColumnAction,
    },
  ];

  function ColumnStatus(status) {
    if (status === 'sent') {
      return (
        <Icon type="check-circle" className="icon-check pd-margin-left-md" />
      );
    }
    return;
  }

  function ColumnCheckbox(data = {}) {
    return <Checkbox onChange={handleOnChangeCheckbox.bind(this, data)} />;
  }

  function ColumnAction({ id = '' }) {
    return (
      <Row type="flex" justify="end" align="middle" gutter={16}>
        <Col lg={{ span: 10 }}>
          <PdButton
            block
            type="primary"
            onClick={openPreviewModal.bind(this, id)}
            disabled={!isUnlimitedPlan}
            tooltipTitle={!isUnlimitedPlan && BASIC_PLAN_WARN_COPYWRITE}>
            Send Mail
          </PdButton>
        </Col>
        <Col lg={{ span: 10 }}>
          <PdButton
            block
            type="primary"
            onClick={handleInvoiceDetail.bind(this, id)}>
            Detail
          </PdButton>
        </Col>
      </Row>
    );
  }

  async function handleSendBulkMail() {
    //  write code here
    try {
      sendBulkMailData.map(async ({ id = '' }) => {
        await BillingInvoiceApi.sendMail(id);
      });
      message.success(
        'Successfully send bulk email to the all checked customer'
      );
    } catch (error) {
      handleErr(error);
    }
  }

  async function openPreviewModal(id) {
    try {
      await BillingInvoiceApi.resyncDetail(id);
      const { data } = await BillingInvoiceApi.getBillingInvoiceDetail(id);

      setPdfUrl(data?.data?.pdf_url);
      setIsPreviewEmail(true);
      setPreviewId(id);
    } catch (error) {
      handleErr(error);
    }
  }

  async function handleSendMail(id) {
    try {
      await BillingInvoiceApi.sendMail(id);
      setIsPreviewEmail(false);
      fetchBillingInvoice({
        cursor,
        q: searchQuery,
        period,
        status,
      });
      message.success('Successfully send email to the customer');
    } catch (error) {
      handleErr(error);
    }
  }

  function handleInvoiceDetail(id) {
    const invoiceList = billingInvoices
      .map(({ id }) => id)
      .sort((a, b) => b - a);
    history.push({ pathname: '/invoice/detail', id, invoiceList });
  }

  function handleSearchCustomerName(e) {
    setSearchQuery(get(e, 'target.value', ''));
    setCursor(1);
  }

  function handleChangePeriod(date) {
    setCursor(1);
    setPeriod(date.format('YYYY-MM-01'));
  }

  function handleSelectStatus(value) {
    setStatus(value === 'all' ? '' : value);
  }

  function changeSendBulkMailData(e, data) {
    const isChecked = get(e, ['target', 'checked'], false);
    const { id: colId = '' } = data;
    setSendBulkMailData((prev) => {
      const tempData = [...prev];
      const findResult = tempData.filter(({ id }) => id !== colId);
      return isChecked ? [...prev, data] : findResult;
    });
  }

  function handleOnChangeCheckbox(data, e) {
    changeSendBulkMailData(e, data);
  }

  async function handleSubmitOTI(values) {
    try {
      await BillingInvoiceApi.createOneTimeService(values);
      fetchBillingInvoice({
        cursor,
        q: searchQuery,
        period,
        status,
      });
      setShowModalOTI(false);
      message.success('Succes create invoice');
    } catch (error) {
      handleErr(error);
    }
  }

  async function fetchBillingInvoice({
    cursor = 1,
    limit = 25000,
    q = '',
    period = '',
    status = '',
  }) {
    try {
      const { data } = await BillingInvoiceApi.getBillingInvoiceList({
        cursor,
        limit,
        q,
        period,
        status,
      });
      const billingInvoiceListTemp = get(data, 'data', []);
      const billingInvoiceList = billingInvoiceListTemp.map((invoice) => {
        const { period = Date.now() } = invoice;
        return { ...invoice, period: moment(period).format('MM/DD/YYYY') };
      });
      setBillingInvoices(billingInvoiceList);

      const { metadata = {} } = data;
      setMeta(metadata);
    } catch (error) {
      handleErr(error);
    }
  }

  useEffect(() => {
    fetchBillingInvoice({
      cursor,
      q: searchQuery,
      period,
      status,
    });
  }, [cursor, searchQuery, period, status]);

  return (
    <Layout className="pd-cms-invoice">
      {/* Section that display chart */}
      <DashboardContainer>
        <Row align="middle" type="flex" justify="space-between">
          <Col lg={18}>
            <Row align="middle" type="flex" gutter={16}>
              <Col lg={10}>
                <Search
                  placeholder="Search Invoice Number/Customer Name"
                  onChange={handleSearchCustomerName}
                />
              </Col>
              <Col lg={4}>
                <MonthPicker
                  format="YYYY/MM"
                  onChange={handleChangePeriod}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col lg={4}>
                <Select
                  value={status === '' ? 'all' : status}
                  placeholder="Status"
                  className="w-full"
                  onSelect={handleSelectStatus}>
                  {INVOICE_STATUS.map(({ key, text }) => (
                    <Option key={key} value={key}>
                      {text}
                    </Option>
                  ))}
                </Select>
              </Col>
              {status === '' && (
                <Col lg={4}>
                  <PdButton type="link" onClick={() => setStatus('created')}>
                    Sent : {meta?.total_sent} / {meta?.total_items}
                  </PdButton>
                </Col>
              )}
            </Row>
          </Col>
          <Col lg={6}>
            <Row gutter={16}>
              <Col lg={12}>
                <PdButton block onClick={() => setShowModalOTI(true)}>
                  Create Invoice
                </PdButton>
              </Col>
              <Col lg={12}>
                <PdButton
                  block
                  onClick={handleSendBulkMail}
                  disabled={noSendBulkMailData}>
                  Send Bulk Mail
                </PdButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardContainer>
      <DashboardContainer>
        <Row align="middle">
          <Col lg={24}>
            <PdAntdTable
              dataSource={billingInvoices}
              rowKeys="id"
              columns={columns}
            />
          </Col>
        </Row>
      </DashboardContainer>

      {/* modal preview email */}
      <PdPreviewEmail
        visible={isPreviewEmail}
        onCancel={() => setIsPreviewEmail(false)}
        isDesktop
        previewUrl={pdfUrl}
        onSendEmail={() => handleSendMail(previewId)}
      />

      {/* modal create one time service */}
      <PdModalOTI
        visible={showModalOTI}
        onCancel={() => setShowModalOTI(false)}
        onSubmit={handleSubmitOTI}
      />
    </Layout>
  );
}

export default { Invoice };
