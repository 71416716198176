import React, { useState } from 'react';
import { Col, Row, Input } from 'antd';
import { identity } from 'lodash';

import PdButton from 'components/pd-button';
import PdModal from 'components/pd-modal';

import './style.scss';

function AddCustModal({
  onCancel = identity,
  onSubmit = identity,
  visible = false,
}) {
  const [payload, setPayload] = useState({
    name: '',
    email: '',
    phone_number: '',
  });

  function handleChange(ev, field) {
    const { value = '' } = ev.target;
    setPayload((prev) => ({
      ...prev,
      [field]: value,
    }));
  }

  return (
    <PdModal
      className="pd-modal-add-cust"
      modalTitle="Add Customer"
      onCancel={onCancel}
      visible={visible}>
      <Row
        align="middle"
        className="add-cust-container"
        justify="center"
        type="flex">
        <Col lg={8} className="add-cust-label">
          Customer Name
        </Col>
        <Col lg={12}>
          <Input
            placeholder="Enter customer name"
            onChange={(ev) => handleChange(ev, 'name')}
            value={payload.name}
          />
        </Col>
      </Row>
      <Row
        align="middle"
        className="add-cust-container"
        justify="center"
        type="flex">
        <Col lg={8} className="add-cust-label">
          Email
        </Col>
        <Col lg={12}>
          <Input
            placeholder="Enter customer email"
            onChange={(ev) => handleChange(ev, 'email')}
            type="email"
            value={payload.email}
          />
        </Col>
      </Row>
      <Row
        align="middle"
        className="add-cust-container"
        justify="center"
        type="flex">
        <Col lg={8} className="add-cust-label">
          Phone Number
        </Col>
        <Col lg={12}>
          <Input
            placeholder="Enter customer phone number"
            onChange={(ev) => handleChange(ev, 'phone_number')}
            value={payload.phone_number}
          />
        </Col>
      </Row>
      <Row
        align="middle"
        className="add-cust-container"
        justify="center"
        type="flex">
        <Col lg={10}>
          <PdButton block onClick={() => onSubmit(payload)}>
            Create New Customer
          </PdButton>
        </Col>
      </Row>
    </PdModal>
  );
}

export default AddCustModal;
