import React, { useState, useEffect, useContext } from 'react';
import {
  Checkbox,
  Col,
  DatePicker,
  Icon,
  Input,
  Layout,
  message,
  Row,
  Typography,
  Select,
} from 'antd';
import { get } from 'lodash';
import moment from 'moment';

import PdButton from 'components/pd-button';
import PdMobileTable from 'components/pd-mobile-table';
import PdModal from 'components/pd-modal';
import PdPreviewEmail from 'components/pd-preview-email';
import { handleErr } from 'utils';
import { AuthenticationContext } from 'contexts/authentication';

import BillingInvoiceApi from 'api/billing-invoice';

import { INVOICE_STATUS } from './mockData';
import PdModalOTI from './modal-OTI';
import './style.scss';

const { MonthPicker } = DatePicker;
const { Search } = Input;
const { Title, Text } = Typography;
const { Option } = Select;

export function Invoice({ history }) {
  const [billingInvoices, setBillingInvoices] = useState([]);
  const [isPreviewEmail, setIsPreviewEmail] = useState(false);
  const [meta, setMeta] = useState({});
  const [pdfUrl, setPdfUrl] = useState('');
  const [period, setPeriod] = useState('');
  const [previewId, setPreviewId] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [sendBulkMailData, setSendBulkMailData] = useState([]);
  const [showModalOTI, setShowModalOTI] = useState(false);
  const [status, setStatus] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [invoiceMessage, setinvoiceMessage] = useState('');

  const { authenticatedUserProfile } = useContext(AuthenticationContext);
  const { isUnlimitedPlan = false } = authenticatedUserProfile;

  const noSendBulkMailData = sendBulkMailData.length === 0;

  const columns = [
    {
      title: '',
      key: 'key',
      width: '5%',
      render: ColumnCheckbox,
    },
    {
      title: 'No.',
      dataIndex: 'id',
      key: 'id',
      defaultSortOrder: 'descend',
      width: '15%',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Cust.',
      dataIndex: 'customer.name',
      key: 'customer.name',
      width: '25%',
      sorter: (a, b) => a.customer.name.localeCompare(b.customer.name),
    },
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'period',
      width: '25%',
      sorter: (a, b) => a.period.localeCompare(b.period),
      render: (value) => moment(value).format('MM/YYYY'),
    },
    {
      title: 'Sent Mail',
      dataIndex: 'status',
      key: 'status',
      render: ColumnStatus,
    },
    {
      title: '',
      key: 'action',
      width: '5%',
      render: ColumnAction,
    },
  ];

  function ColumnStatus(status) {
    if (status === 'sent') {
      return <Icon type="check-circle" className="icon-check " />;
    }
    return;
  }

  function ColumnCheckbox(data = {}) {
    return <Checkbox onChange={handleOnChangeCheckbox.bind(this, data)} />;
  }

  function ColumnAction(rowData) {
    const { id = '', message = '' } = rowData;
    return (
      <Row>
        {isUnlimitedPlan && (
          <Col onClick={() => openPreviewModal(id)}>
            <Icon type="mail" />
          </Col>
        )}
        <Col onClick={() => handleInvoiceDetail(id)}>
          <Icon type="search" />
        </Col>
        <Col onClick={() => handleShowMessage(message)}>
          <Icon type="message" />
        </Col>
      </Row>
    );
  }

  function handleShowMessage(message) {
    setShowMessage(true);
    setinvoiceMessage(message || 'No message');
  }

  async function handleSendBulkMail() {
    //  write code here
    try {
      sendBulkMailData.map(async ({ id = '' }) => {
        await BillingInvoiceApi.sendMail(id);
      });
      message.success(
        'Successfully send bulk email to the all checked customer'
      );
    } catch (error) {
      handleErr(error);
    }
  }

  async function openPreviewModal(id) {
    try {
      await BillingInvoiceApi.resyncDetail(id);
      const { data } = await BillingInvoiceApi.getBillingInvoiceDetail(id);

      setPdfUrl(data?.data?.pdf_url);
      setIsPreviewEmail(true);
      setPreviewId(id);
    } catch (error) {
      handleErr(error);
    }
  }

  async function handleSendMail(id) {
    try {
      await BillingInvoiceApi.sendMail(id);
      message.success('Successfully send email to the customer');
    } catch (error) {
      handleErr(error);
    }
  }

  function handleInvoiceDetail(id) {
    const invoiceList = billingInvoices
      .map(({ id }) => id)
      .sort((a, b) => b - a);
    history.push({ pathname: '/invoice/detail', id, invoiceList });
  }

  function handleSearchCustomerName(e) {
    setSearchQuery(get(e, 'target.value', ''));
  }

  function handleSearchByMonth(date) {
    setPeriod(date.format('YYYY-MM-01'));
  }

  function handleSelectStatus(status) {
    setStatus(status === 'all' ? '' : status);
  }

  function changeSendBulkMailData(e, data) {
    const isChecked = get(e, ['target', 'checked'], false);
    const { id: colId = '' } = data;
    setSendBulkMailData((prev) => {
      const tempData = [...prev];
      const findResult = tempData.filter(({ id }) => id !== colId);
      return isChecked ? [...prev, data] : findResult;
    });
  }

  function handleOnChangeCheckbox(data, e) {
    changeSendBulkMailData(e, data);
  }

  async function fetchBillingInvoice({
    cursor = 1,
    limit = 25000,
    q = '',
    period = '',
    status = '',
  }) {
    try {
      const { data } = await BillingInvoiceApi.getBillingInvoiceList({
        cursor,
        limit,
        q,
        period,
        status,
      });
      const billingInvoiceListTemp = get(data, 'data', []);
      const billingInvoiceList = billingInvoiceListTemp.map((invoice) => {
        const { period = Date.now() } = invoice;
        return { ...invoice, period: moment(period).format('MMM/YYYY') };
      });
      setBillingInvoices(billingInvoiceList);

      const { metadata = {} } = data;
      setMeta(metadata);
    } catch (error) {
      handleErr(error);
    }
  }

  async function handleSubmitOTI(values) {
    try {
      await BillingInvoiceApi.createOneTimeService(values);
      fetchBillingInvoice({
        q: searchQuery,
        period,
        status,
      });
      setShowModalOTI(false);
      message.success('Succes create invoice');
    } catch (error) {
      handleErr(error);
    }
  }

  useEffect(() => {
    fetchBillingInvoice({
      q: searchQuery,
      period,
      status,
    });
    // eslint-disable-next-line
  }, [searchQuery, period, status]);

  return (
    <Layout className="pd-mobile-invoice">
      <Row>
        <Col className="pd-margin-top-md pd-margin-bottom-sm">
          <Title className="pd-align-center" level={3}>
            Invoices
          </Title>
        </Col>
      </Row>
      <Row
        type="flex"
        justify="center"
        gutter={10}
        className="pd-margin-bottom-sm">
        <Col xs={22}>
          <Search
            placeholder="Search Invoice Number/Customer Name"
            onChange={handleSearchCustomerName}
          />
        </Col>
      </Row>
      <Row type="flex" justify="center" gutter={10}>
        <Col xs={11}>
          <MonthPicker format="YYYY/MM" onChange={handleSearchByMonth} />
        </Col>
        <Col xs={11}>
          <Select
            value={status === '' ? 'all' : status}
            placeholder="Status"
            className="w-full"
            onSelect={handleSelectStatus}>
            {INVOICE_STATUS.map(({ key, text }) => (
              <Option key={key} value={key}>
                {text}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row
        type="flex"
        justify="center"
        gutter={10}
        className="pd-margin-top-md pd-margin-bottom-md">
        <Col xs={6}>
          {status === '' && (
            <PdButton type="link" onClick={() => setStatus('created')}>
              Sent : {meta?.total_sent} / {meta?.total_items}
            </PdButton>
          )}
        </Col>
        <Col xs={8}>
          <PdButton block onClick={() => setShowModalOTI(true)}>
            Create Invoice
          </PdButton>
        </Col>
        <Col xs={8}>
          <PdButton
            block
            onClick={handleSendBulkMail}
            disabled={noSendBulkMailData}>
            Send Bulk Mail
          </PdButton>
        </Col>
      </Row>

      <PdMobileTable
        dataSource={billingInvoices}
        rowKey="id"
        columns={columns}
        pagination={{
          pageSize: 5,
          total: meta?.total_items,
        }}
      />

      {/* modal preview email */}
      <PdPreviewEmail
        visible={isPreviewEmail}
        onCancel={() => setIsPreviewEmail(false)}
        previewUrl={pdfUrl}
        onSendEmail={() => handleSendMail(previewId)}
      />

      {/* modal create one time service */}
      <PdModalOTI
        visible={showModalOTI}
        onCancel={() => setShowModalOTI(false)}
        onSubmit={handleSubmitOTI}
      />

      {/* modal invoice message */}
      <PdModal
        modalTitle="Invoice Message"
        visible={showMessage}
        onCancel={() => setShowMessage(false)}>
        <Text>{invoiceMessage}</Text>
      </PdModal>
    </Layout>
  );
}

export default { Invoice };
