import React from 'react';
import { Row, Col, Checkbox } from 'antd';
import { identity } from 'lodash-es';

import './style.scss';

export default function PdCheckbox(props) {
  const {
    data = [],
    value = [],
    onChange = identity,
    isDisabled = false,
    fullWidth = false,
  } = props;

  return (
    <Checkbox.Group onChange={onChange} value={value} style={{ width: '100%' }}>
      <Row className="pd-margin-top-sm">
        {data.map((item, idx) => (
          <Col key={idx} lg={fullWidth ? 24 : 4} xs={fullWidth ? 24 : 6}>
            <Checkbox
              value={item.toLowerCase()}
              disabled={isDisabled}
              key={idx}
              style={{ marginLeft: 0 }}>
              {fullWidth ? item : item[0]}
            </Checkbox>
          </Col>
        ))}
      </Row>
    </Checkbox.Group>
  );
}
