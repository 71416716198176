import React, { useState, useEffect } from 'react';
import {
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  InputNumber,
  Layout,
  message,
  Row,
  Select,
  Modal,
} from 'antd';
import { get } from 'lodash';
import moment from 'moment';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdTable, { PdTableBodyRow, PdTableHeader } from 'components/pd-table';
import PdForm from 'components/pd-form';

import {
  appendPercentage,
  numberOnlyFormat,
  parsePercentage,
  priceFormatter,
  priceParser,
} from 'utils/inputNumberUtils';

import ServiceAPI from 'api/service';
import CustomerAPI from 'api/customer';
import ProposalAPI from 'api/proposal';

import AddCustModal from './add-cust-modal';
import './style.scss';
import { handleErr } from 'utils';

const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;

const FORM_FIELD = {
  ORDERED_AT: 'ordered_at',
  BODY_PROPOSAL: 'body_proposal',
  DISCOUNT: 'discount',
};

export function ProposalCreate({ form, history }) {
  const [customerList, setCustomerList] = useState([]);
  const [customer, setCustomer] = useState({
    address_id: undefined,
    email: 'Autofill',
    customer_id: undefined,
  });
  const [discount, setDiscount] = useState(0);
  const [metaField, setMetaField] = useState(1);
  const [serviceField, setServiceField] = useState({ 0: {} });
  const [serviceList, setServiceList] = useState([]);
  const [showAddCustModal, setShowAddCustModal] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [anyChange, setAnyChange] = useState(false);

  const customersData = customerList ?? {};

  const { getFieldDecorator } = form;

  const horizontalLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 20, offset: 1 },
  };

  const quarterHorizontalLayout = {
    labelCol: { span: 3, offset: 16 },
    wrapperCol: { span: 4, offset: 1 },
  };

  const tableHeaders = [
    { title: 'Service', width: 4 },
    { title: 'Service Date', width: 4 },
    { title: 'Quantity', width: 4 },
    { title: '$ per Unit', width: 4 },
    { title: 'Amount', width: 4 },
    { title: 'Meetings', width: 4 },
  ];

  function handleBackProposal() {
    if (anyChange) {
      confirm({
        title: 'Are you sure?',
        content: 'Your changes will be lost',
        cancelButtonProps: { ghost: true },
        onOk: () => {
          history.goBack();
        },
      });
    } else {
      history.goBack();
    }
  }

  function handleAddNewService() {
    setServiceField((prevState) => ({
      ...prevState,
      [metaField]: {},
    }));
    setMetaField((metaField) => metaField + 1);
  }

  async function handleChangeCustomer({ key: customerID }) {
    !anyChange && setAnyChange(true);
    try {
      const data = customerList.find(({ id }) => id === Number(customerID));
      const { email = '', id = 0, billing_address = {} } = data;

      return setCustomer({
        address_id: billing_address.id,
        customer_id: id,
        email,
      });
    } catch (err) {
      message.error(
        get(err, 'response.message', 'Failed to get customer detail')
      );
    }
  }

  function handleChangeDiscount(value) {
    setDiscount(value);
  }

  function handleChangeService(value, key) {
    !anyChange && setAnyChange(true);
    const { price } = serviceList.find(({ id }) => id === value);
    setServiceField((prev) => ({
      ...prev,
      [key]: {
        service_id: value,
        price,
      },
    }));
  }

  function handleChangeDate(e, serviceFieldIdx) {
    const { _d = '' } = e ?? {};
    return setServiceField((prevState) => {
      const serviceObject = { ...prevState[serviceFieldIdx], service_date: _d };
      return { ...prevState, [serviceFieldIdx]: serviceObject };
    });
  }

  function handleChangeQuantity(e, serviceFieldIdx) {
    !anyChange && setAnyChange(true);
    const { qty = 0 } = e ?? {};
    return setServiceField((prevState) => {
      const serviceObject = { ...prevState[serviceFieldIdx], quantity: qty };
      return { ...prevState, [serviceFieldIdx]: serviceObject };
    });
  }

  function handleChangeMeetings(value, serviceFieldIdx) {
    !anyChange && setAnyChange(true);
    return setServiceField((prevState) => {
      const serviceObject = { ...prevState[serviceFieldIdx], meetings: value };
      return { ...prevState, [serviceFieldIdx]: serviceObject };
    });
  }

  function handleChangePrice(e, serviceFieldIdx) {
    !anyChange && setAnyChange(true);
    const { price = 0 } = e ?? {};
    return setServiceField((prevState) => {
      const serviceObject = {
        ...prevState[serviceFieldIdx],
        price: Number(price),
      };
      return { ...prevState, [serviceFieldIdx]: serviceObject };
    });
  }

  function handleAmount(idx) {
    const { price = 0, quantity = 0 } = serviceField[idx];
    return price * quantity;
  }

  function handleTotalAmount() {
    let totalQty = Object.values(serviceField).reduce(
      (acc, { price = 0, quantity = 0 }) => acc + Number(price) * quantity,
      0
    );

    const calc = totalQty - totalQty * (discount / 100);
    setTotalAmount(calc);
  }

  function handleTwoFormatter(type, value) {
    switch (type) {
      case 'currency':
        return priceFormatter(numberOnlyFormat(value));
      case 'percentage':
        return appendPercentage(numberOnlyFormat(value));
    }
  }

  function handleDeleteRow(idx) {
    setServiceField((prevState) => {
      let filtered = {};

      for (let key in prevState) {
        if (key !== String(idx)) {
          filtered[key] = { ...prevState[key] };
        }
      }

      return filtered;
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        const order_services_attributes = Object.values(serviceField).map(
          (order) => {
            if (!order.service_date) {
              return {
                ...order,
                service_date: moment(),
              };
            }
            return order;
          }
        );

        const payload = {
          customer_id: customer.customer_id,
          ordered_at: get(values, 'ordered_at._d', ''),
          discount,
          body_proposal: get(values, 'body_proposal', ''),
          order_services_attributes,
        };

        try {
          await ProposalAPI.createProposal({
            order: payload,
          });
          history.push({ pathname: '/proposal' });
        } catch (err) {
          message.error(
            get(err, 'response.message', 'Failed to create proposal')
          );
        }
      }
    });
  }

  async function handleAddCust(payload) {
    try {
      await CustomerAPI.createCustomer({
        customer: payload,
      });
      setShowAddCustModal(false);
      fetchCustomerList();
    } catch (error) {
      handleErr(error);
    }
  }

  function handleSearch(input, option) {
    const { children: text } = option?.props;
    return text.toLowerCase().includes(input.toLowerCase());
  }

  async function fetchCustomerList() {
    try {
      const { data } = await CustomerAPI.getCustomerList({ q: '' });

      const customerData = get(data, 'data', []);

      setCustomerList(customerData);
    } catch (err) {
      message.error(
        get(err, 'response.message', 'Failed to get customer list')
      );
    }
  }

  async function fetchServiceList() {
    try {
      const { data } = await ServiceAPI.getServiceList({ q: '' });

      const serviceData = get(data, 'data', []);

      setServiceList(serviceData);
    } catch (err) {
      get(err, 'response.message', 'Failed to get service list');
    }
  }

  useEffect(() => {
    fetchCustomerList();
    fetchServiceList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleTotalAmount();
    // eslint-disable-next-line
  }, [serviceField, discount]);

  return (
    <Layout className="pd-cms-proposal-detail">
      <DashboardContainer>
        <PdForm
          onSubmit={handleSubmit}
          onChange={() => !anyChange && setAnyChange(true)}>
          <Form.Item label="Proposal Number" {...horizontalLayout}>
            <Input placeholder="Auto generate" disabled />
          </Form.Item>

          <Form.Item label="Customer Name" {...horizontalLayout}>
            <Row type="flex" align="middle" justify="space-between">
              <Col span={21}>
                <Select
                  className="form-item-block"
                  showSearch
                  placeholder="Enter Customer Name"
                  onSelect={(val, customerID) =>
                    handleChangeCustomer(customerID)
                  }>
                  {customersData.map((customer) => {
                    const { name = '', id = 1 } = customer ?? {};
                    return (
                      <Option key={id} value={name}>
                        {name}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
              <PdButton onClick={() => setShowAddCustModal(true)}>
                Add Customer
              </PdButton>
            </Row>
          </Form.Item>

          <Form.Item label="Email" {...horizontalLayout}>
            <Input value={customer.email} disabled />
          </Form.Item>

          <Form.Item label="Proposal Date" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.ORDERED_AT, {
              initialValue: moment(),
            })(
              <DatePicker
                placeholder="Select Proposal Date"
                format="MM/DD/YYYY"
              />
            )}
          </Form.Item>

          <Form.Item label="Description" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.BODY_PROPOSAL)(
              <TextArea rows={5}></TextArea>
            )}
          </Form.Item>

          <PdTable className="pd-table-body-work-orders">
            <PdTableHeader listHeader={tableHeaders} />

            {Object.keys(serviceField).map((key) => (
              <PdTableBodyRow className="pd-table-body-work-orders" key={key}>
                <Col lg={4}>
                  <Form.Item>
                    <Select
                      showSearch
                      placeholder="Service Name"
                      onSelect={(value) => handleChangeService(value, key)}
                      filterOption={handleSearch}>
                      {serviceList.map(({ id = '', title = '' }) => (
                        <Option key={id} value={id}>
                          {title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col lg={4}>
                  <Form.Item>
                    <DatePicker
                      defaultValue={moment()}
                      format="MM/DD/YYYY"
                      onChange={(e) => handleChangeDate(e, key)}
                    />
                  </Form.Item>
                </Col>
                <Col lg={4}>
                  <Form.Item>
                    <InputNumber
                      min={1}
                      placeholder={0}
                      onChange={(e) => handleChangeQuantity({ qty: e }, key)}
                      formatter={numberOnlyFormat}
                    />
                  </Form.Item>
                </Col>
                <Col lg={4}>
                  <Form.Item>
                    <InputNumber
                      onChange={(e) => handleChangePrice({ price: e }, key)}
                      value={serviceField[key].price ?? 0}
                      formatter={handleTwoFormatter.bind(this, 'currency')}
                      parser={priceParser}
                    />
                  </Form.Item>
                </Col>
                <Col lg={4}>
                  <Form.Item>
                    <InputNumber
                      disabled
                      value={handleAmount(key)}
                      formatter={priceFormatter}
                      parser={priceParser}
                    />
                  </Form.Item>
                </Col>
                <Col lg={3}>
                  <Form.Item>
                    <InputNumber
                      min={1}
                      placeholder={1}
                      onChange={(value) => handleChangeMeetings(value, key)}
                      formatter={numberOnlyFormat}
                    />
                  </Form.Item>
                </Col>
                {Object.keys(serviceField).length > 1 && (
                  <Col lg={1}>
                    <Form.Item>
                      <Icon
                        type="delete"
                        theme="twoTone"
                        twoToneColor="red"
                        onClick={handleDeleteRow.bind(this, key)}
                      />
                    </Form.Item>
                  </Col>
                )}
              </PdTableBodyRow>
            ))}
          </PdTable>

          <Form.Item>
            <PdButton type="link" icon="plus" onClick={handleAddNewService}>
              Add More Service
            </PdButton>
          </Form.Item>

          <Form.Item label="Discount" {...quarterHorizontalLayout}>
            <InputNumber
              formatter={handleTwoFormatter.bind(this, 'percentage')}
              parser={parsePercentage}
              onChange={handleChangeDiscount}
            />
          </Form.Item>

          <Form.Item label="Total Amount" {...quarterHorizontalLayout}>
            <Input prefix="$" value={totalAmount} disabled />
          </Form.Item>

          <Row
            align="middle"
            justify="center"
            type="flex"
            className="submit-container">
            <Col lg={6}>
              <PdButton block ghost type="primary" onClick={handleBackProposal}>
                Back
              </PdButton>
            </Col>
            <Col lg={{ span: 6, offset: 1 }}>
              <PdButton block htmlType="submit">
                Submit
              </PdButton>
            </Col>
          </Row>
        </PdForm>
      </DashboardContainer>
      {showAddCustModal && (
        <AddCustModal
          visible={showAddCustModal}
          onCancel={() => setShowAddCustModal(false)}
          onSubmit={handleAddCust}
        />
      )}
    </Layout>
  );
}

export const ProposalCreatePageForm = Form.create({ name: 'proposal-edit' })(
  ProposalCreate
);
