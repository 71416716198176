import api from './index';
import { omitBy } from 'lodash';
import { isNil } from 'utils';

export default {
  createProposal(order = {}) {
    return api.post('/order_proposals', order);
  },
  updateProposal(id, payload) {
    return api.put(`/order_proposals/${id}`, payload);
  },
  getProposalDetail(id) {
    return api.get(`/order_proposals/${id}`);
  },
  getProposalList({
    cursor = 1,
    limit = 25000,
    q = '',
    status = '',
    archive = false,
  }) {
    const params = {
      cursor,
      limit,
      q,
      status,
      archive,
    };
    return api.get('/order_proposals', {
      params: omitBy(params, isNil),
    });
  },
  getProposalPDF(id) {
    return api.post(`/order_proposals/${id}/print_pdf`);
  },
  approveProposal(id) {
    return api.post(`/order_proposals/${id}/approve`);
  },
  rejectProposal(id) {
    return api.post(`/order_proposals/${id}/reject`);
  },
  sendMail(id, emails = []) {
    return api.post(`/order_proposals/${id}/send_email`, { emails });
  },
  getPropertyAddress(id) {
    return api.get(`/order_proposals/customers/${id}/addresses`);
  },
  archiveProposal(id) {
    return api.post(`/order_proposals/${id}/archive`);
  },
  deleteProposal(id) {
    return api.delete(`/order_proposals/${id}`);
  },
};
