import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  InputNumber,
  Layout,
  message,
  Row,
  Select,
  Typography,
  Modal,
} from 'antd';
import { get, has, set } from 'lodash';
import moment from 'moment';

import StateService from 'api/state';
import CustomerService from 'api/customer';
import InvoiceApi from 'api/invoice';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdForm from 'components/pd-form';
import PdTable, { PdTableBodyRow, PdTableHeader } from 'components/pd-table';
import { PdMobileRecurringFormModal } from 'components/pd-mobile-recurring-form-modal';
import PdCheckbox from 'components/pd-checkbox';

import { FORM_RECURRING_DAY_OPTIONS } from 'utils';
import { priceFormatter, priceParser } from 'utils/inputNumberUtils';
import { oneNumberDecimalFormat } from 'utils/numberFormater';
import { CUSTOMER_EMAIL_COPY_WRITE } from 'utils/constants';

import './style.scss';

const { Text, Title } = Typography;
const { TextArea } = Input;
const { confirm } = Modal;

const FORM_FIELD = {
  ID: 'id',
  NAME: 'name',
  EMAIL: 'email',
  PHONE: 'phone_number',
  FAX: 'fax_number',
  REGULAR_SERVICE: 'regular_service',
  STATE_CODE: 'state_code',
  ADDRESS: 'address',
  CITY: 'city',
  POSTAL_CODE: 'postal_code',
  START_DATE: 'start_date',
  NOTES: 'notes',
  SECONDARY_EMAIL: 'secondary_email',
};

const tableHeaders = [
  { title: 'Service', width: 8 },
  { title: '$ per Unit', width: 8 },
  { title: 'Amount', width: 8 },
];

const { Option } = Select;

function CustomerEdit({ form, history, location }) {
  const [listState, setListState] = useState([]);
  const [listAddress, setListAddress] = useState({ 0: {} });
  const [billingAddress, setBillingAddress] = useState({});
  const [isBillingPropertyEqual, setIsBillingPropertyEqual] = useState(true);
  const [customerData, setCustomerData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [addressList, setAddressList] = useState([]);
  // recurringAddId for generate id when click Add Recurring
  const [recurringAddId, setRecurringAddId] = useState(0);
  const [recurringContent, setRecurringContent] = useState([]);
  const [recurringEditTempData, setRecurringEditTempData] = useState({});
  const [anyChange, setAnyChange] = useState(false);

  const { getFieldDecorator } = form;

  const customerId = get(location, 'data.id', '');

  const horizontalLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  const halfHorizontalLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  useEffect(() => {
    if (!has(location, 'data.id')) {
      history.push({ pathname: '/customer-setup' });
      return;
    }
    fetchStatesData();
    fetchUserData(customerId);
    fetchAddresses(customerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchStatesData() {
    const { data } = await StateService.getStates();
    setListState(get(data, 'data', []));
  }

  async function fetchUserData(id) {
    try {
      const { data } = await CustomerService.getCustomerDetail(id);
      setCustomerData(get(data, 'data', {}));
      setBillingAddress(get(data, 'data.billing_address', {}));

      const propertyAddresses = get(data, 'data.property_addresses', []);
      propertyAddresses.forEach((address, idx) => {
        setListAddress((prevState) => ({
          ...prevState,
          [idx]: address,
        }));
      });

      // set recurring content
      const recurringTemp = get(data, ['data', 'order_recurring_invoices'], []);

      const orderRecurringInvoices = recurringTemp.map((recurring) => {
        const rawStartDate = get(recurring, 'start_date', Date.now());
        return {
          ...recurring,
          start_date: moment(rawStartDate).format('MM/DD/YYYY'),
        };
      });
      setRecurringContent(orderRecurringInvoices);
    } catch (err) {
      message.error(
        get(err, 'response.message', 'Failed to get customer data')
      );
    }
  }

  async function fetchAddresses(id) {
    const { data } = await InvoiceApi.getCustomerAddressList(id);
    const list = get(data, 'data', []);
    setAddressList(list);
  }

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        const listPropertyAddress = [];
        let updatedListAddress = listAddress;
        Object.keys(updatedListAddress).forEach((key) => {
          set(
            updatedListAddress[key],
            'state_code',
            get(updatedListAddress[key], 'state.code', '')
          );
          listPropertyAddress.push(updatedListAddress[key]);
        });

        const payload = {
          name: get(values, 'name', ''),
          email: get(values, 'email', ''),
          phone_number: get(values, 'phone_number', ''),
          fax_number: get(values, 'fax_number', ''),
          regular_service: get(values, 'regular_service', 0),
          start_date: values?.start_date?._d ?? moment()._d,
          notes: values?.notes ?? '',
          billing_address_attributes: {
            ...billingAddress,
            state_code: billingAddress.state.code,
          },
          property_addresses_attributes: listPropertyAddress,
          secondary_email: values?.secondary_email,
        };

        const order_recurring_invoices_attributes = recurringContent.map(
          (content) => {
            if (content._destroy) return content;
            const {
              address = {},
              days = [],
              discount = 0,
              frequency = 0,
              order_services = [],
              start_date = '',
            } = content;
            const { id: address_id = '' } = address;

            // adjust order_services_attributes
            const order_services_attributes = order_services.map(
              ({
                id = '',
                price = 0,
                quantity = 0,
                service = {},
                meetings = 1,
              }) => {
                const { id: service_id = '' } = service;
                const result = {
                  price,
                  quantity,
                  service_id,
                  meetings,
                };
                return id ? { ...result, id } : result;
              }
            );

            const parsedRecurringServices = {
              address_id,
              days,
              discount,
              frequency,
              order_services_attributes,
              start_date: moment(start_date).format('YYYY/MM/DD'),
            };

            if (typeof content.id === 'string') {
              return parsedRecurringServices;
            }

            return {
              id: content.id,
              ...parsedRecurringServices,
            };
          }
        );

        try {
          await CustomerService.updateCustomer({
            id: get(location, 'data.id', ''),
            customer: {
              ...payload,
              order_recurring_invoices_attributes,
            },
          });
          message.success('Customer has been updated');

          if (location?.function) {
            location.function();
            return;
          }

          history.push({ pathname: '/customer-setup' });
        } catch (err) {
          message.error(
            get(err, 'response.message', 'Failed to Submit Form Data')
          );
        }
      }
    });
  }

  function handleCancel() {
    if (anyChange) {
      confirm({
        title: 'Are you sure?',
        content: 'Your changes will be lost',
        cancelButtonProps: { ghost: true },
        onOk: () => {
          history.goBack();
        },
      });
    } else {
      history.goBack();
    }
  }

  function handleAddNewAddress() {
    setListAddress((prevState) => ({
      ...prevState,
      [Object.keys(listAddress).length]: {},
    }));
  }

  function handleChangeState(idx, optionObject) {
    const selectedAddress = JSON.parse(optionObject.key);
    const addressObject = listAddress[idx];
    set(addressObject, 'state', selectedAddress);

    setListAddress((prevState) => ({
      ...prevState,
      [idx]: addressObject,
    }));
  }

  function handleChangeAddress({ idx, key, value }) {
    let selectedAddress = listAddress[idx];
    selectedAddress[key] = value;

    setListAddress((prevState) => ({
      ...prevState,
      [idx]: selectedAddress,
    }));
  }

  function handleChangeBillingAddress({ key, value }) {
    setBillingAddress((prevState) => ({
      ...prevState,
      [key]: value,
    }));

    if (isBillingPropertyEqual) {
      handleChangeAddress({ idx: 0, key, value });
    }
  }

  function handleChangeBillingState(optionObject) {
    const selectedAddress = JSON.parse(optionObject.key);
    setBillingAddress((prevState) => ({
      ...prevState,
      ['state']: selectedAddress,
      ['country']: get(selectedAddress, 'country', ''),
    }));

    if (isBillingPropertyEqual) {
      handleChangeState(0, optionObject);
    }
  }

  function handleChangeAddressCondition(ev) {
    setIsBillingPropertyEqual(ev.target.checked);

    if (ev.target.checked) {
      setListAddress((prev) => {
        return {
          ...prev,
          0: billingAddress,
        };
      });
    }
  }

  function handleShowRecurringModal() {
    setRecurringEditTempData([]);
    setShowModal(true);
  }

  function handleModalSubmit(modalData) {
    const id = `add-data${recurringAddId}`;
    setRecurringContent((prev) => [...prev, { ...modalData, id }]);
    setRecurringAddId((prev) => prev + 1);
  }

  function handleDeleteRecurring(deleteId) {
    setRecurringContent((prev) =>
      prev.map((content) => {
        if (content.id !== deleteId) return content;
        else return { id: deleteId, _destroy: true };
      })
    );
  }

  function handleEditRecurring(editId) {
    const result = recurringContent.find(({ id }) => id === editId);
    setRecurringEditTempData({ ...result, isEdit: true });
    setShowModal(true);
  }

  function handleChangeRecurringEditContent(id, editedData) {
    setRecurringContent((prev) =>
      prev.map((content) => {
        if (content.id !== id) return content;
        else return editedData;
      })
    );
  }

  return (
    <Layout className="pd-mobile-form-customer">
      <DashboardContainer>
        <Col xs={24}>
          <Title
            className="pd-align-center pd-margin-top-xs pd-margin-bottom-md"
            level={3}>
            Edit Customer
          </Title>
        </Col>
        <PdForm
          onSubmit={handleSubmit}
          onChange={() => !anyChange && setAnyChange(true)}>
          <Form.Item label="Customer ID" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.ID, {
              initialValue: get(customerData, 'id', ''),
            })(<Input placeholder="Autofill" disabled />)}
          </Form.Item>
          <Form.Item label="Customer Name" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.NAME, {
              initialValue: get(customerData, 'name', ''),
              rules: [
                {
                  required: true,
                  message: 'Please input Customer Name!',
                },
              ],
            })(<Input placeholder="Enter Customer Name" />)}
          </Form.Item>

          <Form.Item label="Billing Address" {...horizontalLayout}>
            <Input
              value={get(billingAddress, 'address', '')}
              placeholder="Enter Customer Billing Address"
              onChange={(e) =>
                handleChangeBillingAddress({
                  key: FORM_FIELD.ADDRESS,
                  value: get(e, 'target.value', ''),
                })
              }
            />
          </Form.Item>
          <Form.Item label="City" {...horizontalLayout}>
            <Input
              value={get(billingAddress, FORM_FIELD.CITY, '')}
              placeholder="Enter Customer City"
              onChange={(e) =>
                handleChangeBillingAddress({
                  key: FORM_FIELD.CITY,
                  value: get(e, 'target.value', ''),
                })
              }
            />
          </Form.Item>
          <Row>
            <Col xs={12}>
              <Form.Item label="State / Province" {...halfHorizontalLayout}>
                <Select
                  value={get(billingAddress, 'state.name', '')}
                  showSearch
                  placeholder="Choose State / Province"
                  onSelect={(value, optionObject) =>
                    handleChangeBillingState(optionObject)
                  }>
                  {listState?.map((state) => (
                    <Option key={JSON.stringify(state)} value={state.name}>
                      {get(state, 'name', '')}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 11, offset: 1 }}>
              <Form.Item label="Country" {...halfHorizontalLayout}>
                <Input
                  value={get(billingAddress, 'country', '')}
                  placeholder="Autofill"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label="Postal Code" {...halfHorizontalLayout}>
                <Input
                  value={get(billingAddress, 'postal_code', '')}
                  placeholder="Enter Postal Code"
                  onChange={(e) =>
                    handleChangeBillingAddress({
                      key: FORM_FIELD.POSTAL_CODE,
                      value: get(e, 'target.value', ''),
                    })
                  }
                />
                <Checkbox
                  defaultChecked={isBillingPropertyEqual}
                  onChange={handleChangeAddressCondition}>
                  Property Address same with Billing Address
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          {/** Property Address Section */}

          {Object.keys(listAddress).map((key, idx) => (
            <>
              <Form.Item label="Property Address" {...horizontalLayout}>
                <Input
                  value={get(listAddress[key], FORM_FIELD.ADDRESS, '')}
                  placeholder="Enter Customer Property Address"
                  onChange={(e) =>
                    handleChangeAddress({
                      idx,
                      key: FORM_FIELD.ADDRESS,
                      value: get(e, 'target.value', ''),
                    })
                  }
                  disabled={idx === 0 && isBillingPropertyEqual}
                />
              </Form.Item>

              <Form.Item label="City" {...horizontalLayout}>
                <Input
                  value={get(listAddress[key], FORM_FIELD.CITY, '')}
                  placeholder="Enter Property City"
                  onChange={(e) =>
                    handleChangeAddress({
                      idx,
                      key: FORM_FIELD.CITY,
                      value: get(e, 'target.value', ''),
                    })
                  }
                  disabled={idx === 0 && isBillingPropertyEqual}
                />
              </Form.Item>

              <Row>
                <Col xs={12}>
                  <Form.Item label="State / Province" {...halfHorizontalLayout}>
                    <Select
                      value={get(listAddress[key], 'state.name', '')}
                      showSearch
                      placeholder="Choose State / Province"
                      onSelect={(value, optionObject) =>
                        handleChangeState(idx, optionObject)
                      }
                      disabled={idx === 0 && isBillingPropertyEqual}>
                      {listState?.map((state) => (
                        <Option key={JSON.stringify(state)} value={state.name}>
                          {get(state, 'name', '')}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 11, offset: 1 }} onClick={handleAddNewAddress}>
                  <Form.Item label="Country" {...halfHorizontalLayout}>
                    <Input
                      placeholder="Autofill"
                      disabled
                      value={get(listAddress[key], 'state.country', '')}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item label="Postal Code" {...halfHorizontalLayout}>
                    <Input
                      value={get(listAddress[key], 'postal_code', '')}
                      placeholder="Enter Postal Code"
                      onChange={(e) =>
                        handleChangeAddress({
                          idx,
                          key: FORM_FIELD.POSTAL_CODE,
                          value: get(e, 'target.value', ''),
                        })
                      }
                      disabled={idx === 0 && isBillingPropertyEqual}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          ))}

          {/** Property Address Section/ */}

          <Form.Item className="add-property-container">
            <Row>
              <Col xs={{ span: 24 }} onClick={handleAddNewAddress}>
                <Icon className="pd-margin-right-sm" type="plus-circle" />
                Add Another Property
              </Col>
            </Row>
          </Form.Item>

          <Form.Item label="Phone Number" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.PHONE, {
              initialValue: get(customerData, 'phone_number', ''),
              rules: [
                {
                  required: true,
                  message: 'Please input Customer Phone!',
                },
              ],
            })(<Input placeholder="Enter Phone Number" />)}
          </Form.Item>
          <Form.Item label="Fax" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.FAX, {
              initialValue: get(customerData, 'fax_number', ''),
            })(<Input placeholder="Enter Fax" />)}
          </Form.Item>
          <Form.Item label="Email Address" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.EMAIL, {
              initialValue: get(customerData, 'email', ''),
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid Email Address!',
                },
                {
                  required: true,
                  message: 'Please input Customer Email Address!',
                },
              ],
            })(
              <>
                <Input placeholder="Enter Customer Email Address" />
                <Text>{CUSTOMER_EMAIL_COPY_WRITE}</Text>
              </>
            )}
          </Form.Item>
          <Form.Item label="Secondary Email" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.SECONDARY_EMAIL, {
              initialValue: get(customerData, 'secondary_email', ''),
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid Email Address!',
                },
              ],
            })(<Input placeholder="Enter Customer Secondary Email Address" />)}
          </Form.Item>
          <Form.Item label="Regular Service" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.REGULAR_SERVICE, {
              initialValue: get(customerData, 'regular_service', 0),
            })(
              <InputNumber
                defaultValue={0}
                formatter={priceFormatter}
                parser={priceParser}
              />
            )}
          </Form.Item>
          <Form.Item label="Start Date" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.START_DATE, {
              initialValue: moment(customerData?.start_date) ?? '',
            })(
              <DatePicker
                format="MM/DD/YYYY"
                placeholder="Select Start Date"
                style={{ width: '100%' }}
              />
            )}
          </Form.Item>
          <Form.Item label="Notes" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.NOTES, {
              initialValue: get(customerData, 'notes', ''),
            })(<TextArea />)}
          </Form.Item>

          {false && (
            <Row
              type="flex"
              justify="center"
              align="middle"
              className="recurring-container">
              <Col lg={22}>
                <Col type="flex" justify="center" align="middle">
                  <Row lg={19}>
                    <Title level={4}>Recurring Services</Title>
                  </Row>
                  <Row lg={5}>
                    <PdButton block onClick={handleShowRecurringModal}>
                      Add Recurring Service
                    </PdButton>
                  </Row>
                </Col>
              </Col>

              {/* Content */}
              {recurringContent
                .filter((content) => !content?._destroy)
                .map(
                  (
                    {
                      address = {},
                      days = [],
                      discount = '',
                      frequency = 0,
                      id = '',
                      order_services = [],
                      start_date = '',
                      total_price = '',
                    },
                    idx
                  ) => (
                    <div
                      className="recurring-content pd-margin-top-md"
                      key={idx}>
                      <Row
                        align="middle"
                        className="btn-delete-float-right"
                        justify="end"
                        type="flex">
                        <Col>
                          <PdButton
                            block
                            className="w-full pd-button"
                            onClick={() => handleDeleteRecurring(id)}>
                            Delete
                          </PdButton>
                        </Col>
                      </Row>

                      <Row className="content">
                        <Text>
                          <span className="content-label">Start Date</span> :{' '}
                          {start_date}
                        </Text>
                      </Row>

                      <Row className="content">
                        <Text>
                          <span className="content-label">
                            Week gaps between Work Order
                          </span>{' '}
                          : {frequency}
                        </Text>
                      </Row>

                      <Row className="content">
                        <Text>
                          <span className="content-label">Address</span> :{' '}
                          {get(address, 'address', '')}
                        </Text>
                      </Row>

                      <Row className="content pd-margin-bottom-md">
                        <Col className="content-label" lg={5}>
                          Days :
                        </Col>
                        <Col lg={{ span: 17, offset: 2 }}>
                          <PdCheckbox
                            data={FORM_RECURRING_DAY_OPTIONS}
                            value={days}
                            isDisabled
                          />
                        </Col>
                      </Row>

                      {/* Table Section */}
                      <PdTable className="detail-container">
                        <PdTableHeader
                          listHeader={tableHeaders}
                          align="center"
                        />
                        {order_services.map(
                          (
                            {
                              price = 0,
                              quantity = 0,
                              service = {},
                              meetings = 1,
                            },
                            idx
                          ) => (
                            <PdTableBodyRow key={idx} align="middle">
                              <Col xs={8} align="center">
                                <Row>
                                  <Text>{service.title || ''}</Text>
                                </Row>
                                <Text
                                  style={{
                                    display: 'block',
                                  }}>{`(Qty: ${quantity})`}</Text>
                                <Text>{`(Meetings: ${meetings})`}</Text>
                              </Col>
                              <Col xs={8} align="center">
                                <Text>$ {price}</Text>
                              </Col>
                              <Col xs={8} align="center">
                                <Text>$ {price * quantity}</Text>
                              </Col>
                            </PdTableBodyRow>
                          )
                        )}
                      </PdTable>

                      <Row
                        align="middle"
                        className="content pd-margin-top-md"
                        justify="end"
                        type="flex">
                        <Col className="content-label">Discount</Col>
                        <Col xs={{ span: 8, offset: 1 }} align="right">
                          <Text>{discount} %</Text>
                        </Col>
                      </Row>

                      <Row
                        align="middle"
                        className="content"
                        justify="end"
                        type="flex">
                        <Col className="content-label">Total Amount</Col>
                        <Col xs={{ span: 8, offset: 1 }} align="right">
                          <Text>$ {oneNumberDecimalFormat(total_price)}</Text>
                        </Col>
                      </Row>

                      <Row
                        align="middle"
                        className="pd-margin-top-md"
                        justify="center"
                        type="flex">
                        <Col lg={10}>
                          <PdButton
                            block
                            onClick={() => handleEditRecurring(id)}>
                            Edit
                          </PdButton>
                        </Col>
                      </Row>
                    </div>
                  )
                )}
            </Row>
          )}

          <Form.Item className="btn-container">
            <Row justify="center" type="flex">
              <Col xs={8}>
                <PdButton block ghost type="primary" onClick={handleCancel}>
                  Cancel
                </PdButton>
              </Col>
              <Col xs={{ span: 8, offset: 2 }}>
                <PdButton block onClick={handleSubmit}>
                  Edit
                </PdButton>
              </Col>
            </Row>
          </Form.Item>
        </PdForm>
      </DashboardContainer>

      {/* Modal Recurring */}
      {showModal && (
        <PdMobileRecurringFormModal
          addressList={addressList}
          data={recurringEditTempData}
          onCancel={() => setShowModal(false)}
          onChangeRecurringEditContent={handleChangeRecurringEditContent}
          onSubmit={handleModalSubmit}
          visible={showModal}
        />
      )}
    </Layout>
  );
}

export const CustomerEditPageForm = Form.create({ name: 'customer-setup' })(
  CustomerEdit
);
