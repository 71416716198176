import React, { useEffect, useState, useRef } from 'react';
import { Redirect } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { Col, Layout, message, Row, Typography } from 'antd';
import { get } from 'lodash';

import CustomerService from 'api/customer';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';

import { numberWithCommas } from 'utils/numberFormater';

import './style.scss';

const { Text, Title } = Typography;

export function ReportCustomerDetail({ history, location }) {
  const [customerData, setCustomerData] = useState({});
  const {
    email = '-',
    fax_number = '-',
    id = '-',
    name = '-',
    phone_number = '-',
    postal_code = '-',
    property_addresses = [],
    regular_service = 0,
  } = customerData ?? {};
  const {
    address = '-',
    city = '-',
    state = '-',
  } = customerData?.billing_address ?? {};
  const { id: locationID = '' } = location ?? {}; // FOR USE EFFECT
  const componentRef = useRef();
  const handlePDFPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const ComponentToPrint = (props) => (
    <div {...props}>
      <Layout
        className="pd-cms-customer-detail pd-component-to-print-content"
        ref={componentRef}>
        <DashboardContainer>
          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              Customer ID
            </Col>
            <Col lg={10}> {id}</Col>
          </Row>
          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              Customer Name
            </Col>
            <Col lg={10}>{name}</Col>
          </Row>

          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              Billing Address
            </Col>
            <Col lg={10}>{address}</Col>
          </Row>
          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              City
            </Col>
            <Col lg={10}>{city}</Col>
          </Row>
          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              State / Province
            </Col>
            <Col lg={7}>{get(state, 'name', '-')}</Col>
            <Col lg={5} className="detail-container-label">
              Country
            </Col>
            <Col lg={7}>{get(state, 'country', '-')}</Col>
          </Row>
          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              Postal Code
            </Col>
            <Col lg={10}>{postal_code}</Col>
          </Row>

          {property_addresses.map((propertyData) => (
            <>
              <Row align="middle" type="flex" className="detail-container">
                <Col className="detail-container-label" lg={5}>
                  Property Address
                </Col>
                <Col lg={10}>{get(propertyData, 'address', '-')}</Col>
              </Row>
              <Row align="middle" type="flex" className="detail-container">
                <Col className="detail-container-label" lg={5}>
                  City
                </Col>
                <Col lg={10}>{get(propertyData, 'city', '-')}</Col>
              </Row>
              <Row align="middle" type="flex" className="detail-container">
                <Col className="detail-container-label" lg={5}>
                  State / Province
                </Col>
                <Col lg={7}>{get(propertyData, 'state.name', '')}</Col>
                <Col lg={5} className="detail-container-label">
                  Country
                </Col>
                <Col lg={7}>{get(propertyData, 'state.country', '-')}</Col>
              </Row>
              <Row align="middle" type="flex" className="detail-container">
                <Col className="detail-container-label" lg={5}>
                  Postal Code
                </Col>
                <Col lg={10}>{get(propertyData, 'postal_code', '-')}</Col>
              </Row>
            </>
          ))}

          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              Phone number
            </Col>
            <Col lg={10}>{phone_number}</Col>
          </Row>
          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              Fax
            </Col>
            <Col lg={10}>{fax_number}</Col>
          </Row>
          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              Email Address
            </Col>
            <Col lg={10}>{email}</Col>
          </Row>
          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              Regular Service
            </Col>
            <Col lg={10}>{`$${numberWithCommas(regular_service)}`}</Col>
          </Row>
        </DashboardContainer>
      </Layout>
    </div>
  );

  function handleBack() {
    history.push('/all-customer');
  }

  async function fetchCustomerData(id) {
    try {
      const { data } = await CustomerService.getCustomerDetail(id);
      setCustomerData(get(data, 'data', []));
    } catch (err) {
      message.error(
        get(err, 'response.message', 'Failed to get customer data')
      );
    }
  }

  useEffect(() => {
    fetchCustomerData(locationID);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!locationID) {
    return <Redirect to="/all-customer" />;
  }

  return (
    <div className="pd-mobile-detail-customer">
      {/* Print Content */}
      <ComponentToPrint className="pd-component-to-print" />
      {/* Main Content */}
      <Layout>
        <Row xs={24} justify="center" type="flex">
          <Col className="pd-margin-top-md" xs={24}>
            <Title className="pd-align-center" level={3}>
              Detail Customer
            </Title>
          </Col>
        </Row>
        <DashboardContainer>
          <Row xs={24}>
            <Row>
              <Col xs={12}>
                <Text strong>Customer ID</Text>
              </Col>
              <Col xs={12} className="pd-margin-bottom-lg">
                <Text>{id}</Text>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Text strong>Customer Name</Text>
              </Col>
              <Col xs={12} className="pd-margin-bottom-lg">
                <Text>{name}</Text>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Text strong>Billing Address</Text>
              </Col>
              <Col xs={12} className="pd-margin-bottom-lg">
                <Text>{address}</Text>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Text strong>City</Text>
              </Col>
              <Col xs={12} className="pd-margin-bottom-lg">
                <Text>{city}</Text>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Text strong>State / Province</Text>
              </Col>
              <Col xs={12} className="pd-margin-bottom-lg">
                <Text>{state.name}</Text>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Text strong>Country</Text>
              </Col>
              <Col xs={12} className="pd-margin-bottom-lg">
                <Text>{state.country}</Text>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Text strong>Postal Code</Text>
              </Col>
              <Col xs={12} className="pd-margin-bottom-lg">
                <Text>{postal_code}</Text>
              </Col>
            </Row>

            {/** FOR PROPERTY ADDRESSES SECTION */}

            {property_addresses.map((propertyAddress, idx) => (
              <React.Fragment key={idx}>
                <Row>
                  <Col xs={12}>
                    <Text strong>Property Address</Text>
                  </Col>
                  <Col xs={12} className="pd-margin-bottom-lg">
                    <Text>{get(propertyAddress, 'address', '-')}</Text>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Text strong>City</Text>
                  </Col>
                  <Col xs={12} className="pd-margin-bottom-lg">
                    <Text>{get(propertyAddress, 'city', '-')}</Text>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Text strong>State / Province</Text>
                  </Col>
                  <Col xs={12} className="pd-margin-bottom-lg">
                    <Text>{get(propertyAddress, 'state.name', '-')}</Text>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Text strong>Country</Text>
                  </Col>
                  <Col xs={12} className="pd-margin-bottom-lg">
                    <Text>{get(propertyAddress, 'state.country', '-')}</Text>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Text strong>Postal Code</Text>
                  </Col>
                  <Col xs={12} className="pd-margin-bottom-lg">
                    <Text>{get(propertyAddress, 'postal_code', '-')}</Text>
                  </Col>
                </Row>
              </React.Fragment>
            ))}

            {/** FOR PROPERTY ADDRESSES SECTION/ */}

            <Row>
              <Col xs={12}>
                <Text strong>Phone number</Text>
              </Col>
              <Col xs={12} className="pd-margin-bottom-lg">
                <Text>{phone_number}</Text>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Text strong>Fax number</Text>
              </Col>
              <Col xs={12} className="pd-margin-bottom-lg">
                <Text>{fax_number}</Text>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Text strong>Email</Text>
              </Col>
              <Col xs={12} className="pd-margin-bottom-lg">
                <Text>{email}</Text>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Text strong>Regular Service</Text>
              </Col>
              <Col xs={12} className="pd-margin-bottom-lg">
                <Text>{`$${numberWithCommas(regular_service)}`}</Text>
              </Col>
            </Row>
          </Row>
          <Row justify="center" type="flex">
            <Col xs={8}>
              <PdButton block ghost onClick={handleBack}>
                Back
              </PdButton>
            </Col>
            <Col xs={{ span: 8, offset: 2 }}>
              <PdButton block onClick={handlePDFPrint}>
                PDF Print
              </PdButton>
            </Col>
          </Row>
        </DashboardContainer>
      </Layout>
    </div>
  );
}

export default { ReportCustomerDetail };
