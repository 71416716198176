// TODO: Handle download .csv

import React, { useEffect, useState } from 'react';
import { Col, Icon, Input, Layout, message, Row, Typography } from 'antd';
import { get } from 'lodash';
import moment from 'moment';

import { PdDownloadCSV } from 'components/pd-download-csv';
import PdMobileTable from 'components/pd-mobile-table';

import { numberDate, numberWithCommas } from 'utils/numberFormater';

import InvoiceAPI from 'api/invoice';

import './style.scss';

const { Search } = Input;
const { Title } = Typography;

export function InvoiceInformation({ history }) {
  const [customerList, setCustomerList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const columns = [
    {
      title: 'Work Order No.',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Customer',
      dataIndex: 'customer.name',
      key: 'customer.name',
      align: 'center',
      sorter: (a, b) => a.customer.name.localeCompare(b.customer.name),
    },
    {
      title: 'Work Order Date',
      dataIndex: 'customer.last_invoice_date',
      key: 'customer.last_invoice_date',
      render: ColumnDate,
      align: 'center',
      sorter: (a, b) =>
        moment(a.ordered_at).valueOf() - moment(b.ordered_at).valueOf(),
    },
    {
      title: 'Amount',
      dataIndex: 'total_price',
      key: 'total_price',
      render: ColumnAmount,
      align: 'center',
    },
    {
      title: '',
      key: 'key',
      render: ColumnAction,
    },
  ];

  const dataSource = customerList || [];

  function ColumnAmount(price) {
    return `$ ${numberWithCommas(price)}`;
  }

  function ColumnAction(rowData = {}) {
    return (
      <Row justify="center" type="flex">
        <Col xs={24} onClick={handleInvoiceDetail.bind(this, rowData)}>
          <Icon type="search" />
        </Col>
      </Row>
    );
  }

  function ColumnDate(date) {
    return <>{`${numberDate(date)}`}</>;
  }

  async function fetchCustomerList({ limit = 6, cursor = 1, q = '' }) {
    try {
      const { data } = await InvoiceAPI.getAllInvoiceInfo({
        limit,
        cursor,
        q,
      });

      const customerData = get(data, 'data', []);

      setCustomerList(customerData);
    } catch (err) {
      message.error(
        get(err, 'response.message', 'Failed to get customer data')
      );
    }
  }

  function fetchDownloadCsvData() {
    const customerListData = customerList.map((customerEl) => {
      const { customer = {}, address = {} } = customerEl;
      const { name = '', billing_address = {} } = customer;
      const { address: billingAddressTitle = '' } = billing_address;
      const { address: addressTitle = '' } = address;
      return {
        ...customer,
        name,
        address: addressTitle,
        billing_address: billingAddressTitle,
      };
    });
    return { data: { data: customerListData } };
  }

  function handleInvoiceDetail({ id } = 0) {
    history.push({
      pathname: `/work-orders-information/detail`,
      id,
    });
  }

  function handleSearchCustomer(e) {
    setSearchQuery(get(e, 'target.value', ''));
  }

  useEffect(() => {
    fetchCustomerList({
      limit: 1000,
      cursor: 1,
      q: searchQuery,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <Layout className="pd-mobile-orders">
      {/* Section that display chart */}
      <Row xs={24} justify="center" type="flex">
        <Col className="pd-margin-top-md pd-margin-bottom-sm" xs={24}>
          <Title className="pd-align-center" level={3}>
            Work Orders Information
          </Title>
        </Col>
        <Col xs={13}>
          <Search
            placeholder="Search by customer name"
            onChange={handleSearchCustomer}
          />
        </Col>
        <Col xs={{ span: 8, offset: 1 }}>
          <PdDownloadCSV
            fetchData={fetchDownloadCsvData}
            filename="work-orders-information.csv"
          />
        </Col>
        <Col xs={24} className="pd-margin-top-lg">
          <PdMobileTable
            dataSource={dataSource}
            rowKey="id"
            columns={columns}
            pagination={{ pageSize: 5 }}
          />
        </Col>
      </Row>
    </Layout>
  );
}

export default { InvoiceInformation };
