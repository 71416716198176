import React from 'react';
import { Col, Row } from 'antd';

import PdModal from 'components/pd-modal';
import PdAntdTable from 'components/pd-antd-table';

import { capitalizeFirstLetter } from 'utils';
import { numberWithCommas } from 'utils/numberFormater';
import './style.scss';

function DetailModal({ ...props }) {
  const { invoiceData } = props;
  const {
    invoice_number = '',
    payment_status = '',
    total_price = 0,
    order_services = [],
  } = invoiceData;

  const dataSource = order_services ?? {};

  const columns = [
    {
      title: 'Service Date',
      dataIndex: 'service_date',
      key: 'service_date',
    },
    {
      title: 'Service',
      dataIndex: 'service.title',
      key: 'service.title',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: '$ per unit',
      dataIndex: 'price',
      key: 'price',
      render: ColumnAmount,
    },
    {
      title: 'Amount',
      dataIndex: ['price', 'quantity'],
      key: 'price',
      render: (_, row) => ColumnTotalBalance(row['price'], row['quantity']),
    },
  ];

  function ColumnAmount(text) {
    return `$ ${numberWithCommas(text)}`;
  }

  function ColumnTotalBalance(price, quantity) {
    return `$ ${numberWithCommas(Number(price) * Number(quantity))}`;
  }

  return (
    <PdModal
      className="pd-modal-detail"
      modalTitle="Detail Work Order"
      width={900}
      {...props}>
      <Row
        align="middle"
        className="detail-container"
        justify="center"
        type="flex">
        <Col lg={23} className="detail-container-label">
          Work Order No: {invoice_number}
        </Col>
        <Col lg={23} className="detail-container-label">
          Payment Status: {capitalizeFirstLetter(payment_status)}
        </Col>
        <Col lg={23} className="detail-container-table">
          <PdAntdTable
            dataSource={dataSource}
            columns={columns}
            pagination={false}
          />
        </Col>
        <Col
          lg={{ span: 4, offset: 23 - 7 }}
          className="detail-container-label">
          Total Amount:
        </Col>
        <Col lg={{ span: 3 }} className="detail-container-label">
          {`$${numberWithCommas(total_price)}`}
        </Col>
      </Row>
    </PdModal>
  );
}

export default DetailModal;
