import React, { useState } from 'react';
import { Col, Icon, Row } from 'antd';
import { useLocation } from 'react-router-dom';

import Logo from 'components/logo';
import MobileSidebar from 'components/mobile-sidebar';

import './style.scss';
import { PATH_PAGE } from 'utils/constants';

export function MobileHeader() {
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);
  const location = useLocation();

  function handleOnClick() {
    setShowMobileSidebar(!showMobileSidebar);
  }
  return (
    <header className="pd-mobile-header">
      {showMobileSidebar && <MobileSidebar closeSidebar={handleOnClick} />}
      <Row type="flex" align="middle">
        <Col xs={2} onClick={handleOnClick}>
          <Icon type="menu" />
        </Col>
        <Col xs={19} onClick={handleOnClick}>
          <h3 className="pd-header-title">
            {PATH_PAGE[location?.pathname] || '-'}
          </h3>
        </Col>
        <Col xs={3} className="logo-container">
          <Logo size="mobile" />
        </Col>
      </Row>
    </header>
  );
}

export default MobileHeader;
