import React, { useState, useEffect } from 'react';
import { get, identity } from 'lodash';
import moment from 'moment';

import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
} from 'antd';

import PdButton from 'components/pd-button';
import PdForm from 'components/pd-form';
import PdModal from 'components/pd-modal';
import PdTable, { PdTableBodyRow, PdTableHeader } from 'components/pd-table';

import ServiceApi from 'api/service';

import { FORM_RECURRING_DAY_OPTIONS } from 'utils';
import {
  appendPercentage,
  numberOnlyFormat,
  parsePercentage,
  priceFormatter,
  priceParser,
} from 'utils/inputNumberUtils';
import { oneNumberDecimalFormat } from 'utils/numberFormater';

import './style.scss';
const { Group } = Checkbox;
const { Option } = Select;
const { Text } = Typography;

let globalIndex = 0;

/**
 *
 * @param {Array} addressList - list address for select option
 * @param {Object} data - data for edit autofill
 * @param {Object} form - form antd
 * @param {() => {}} onCancel - callback to close modal
 * @param {() => {}} onChangeRecurringEditContent - callback to edit Recurring List special for edit in edit form
 * @param {() => {}} onSubmit - callback to add data to Recurring List in edit form
 * @param {Boolean} visible - show modal identifier
 * @returns Modal for create or edit recurring list in customer setup and all customer edit form
 */

function RecurringCreateEdit({
  addressList = [],
  data = {},
  form,
  onCancel = identity,
  onChangeRecurringEditContent = identity,
  onSubmit = identity,
  visible = false,
}) {
  const [servicesList, setServicesList] = useState([]);
  const [checkedValue, setCheckedValue] = useState(get(data, 'days', []));
  // for tracking what row is categorized as edit
  const [tableRowIds, setTableRowIds] = useState([]);
  const [noteFieldOpenList, setNoteFieldOpenList] = useState([]);

  const { getFieldDecorator, getFieldValue, setFieldsValue, validateFields } =
    form;

  const tableHeaders = [
    { title: 'Service', width: 5 },
    { title: 'Quantity', width: 5 },
    { title: '$ per Unit', width: 5 },
    { title: 'Amount', width: 5 },
    { title: 'Meetings', width: 4 },
  ];

  const totalAmount = () => {
    const amountList = getFieldValue('amount') || [];
    let rawTotal = 0;
    if (amountList[0] || amountList[0] === 0)
      rawTotal = amountList.reduce(
        (prevValue, currentValue) => prevValue + currentValue
      );

    const discount = getFieldValue('discount') || 0;
    rawTotal *= (100 - +discount) / 100;

    return oneNumberDecimalFormat(rawTotal);
  };

  const countAmount = (key) => {
    const quantity = getFieldValue(`quantity[${key}]`);
    const price = getFieldValue(`price[${key}]`);
    return quantity && price ? quantity * price : 0;
  };

  /* TABLE SECTION MULTI FORM SETUP (globalIndex declaration is included) */

  getFieldDecorator('keys', { initialValue: [] });
  const keys = getFieldValue('keys');
  const tableFormItems = keys.map((key) => (
    <PdTableBodyRow key={key}>
      <Col lg={5}>
        {getFieldDecorator(`service[${key}]`, {
          rules: [
            {
              message: 'Please select the service',
              required: true,
            },
          ],
          initialValue: get(
            data,
            ['order_services', `${key}`, 'service', 'id'],
            ''
          ),
        })(
          <Select
            className="w-full"
            onSelect={handleOnSelect.bind(this, key)}
            placeholder="Service Name"
            showSearch>
            {servicesList.map(({ id, title }, idx) => (
              <Option key={idx} value={id}>
                {title}
              </Option>
            ))}
          </Select>
        )}
      </Col>
      <Col lg={5}>
        {getFieldDecorator(`quantity[${key}]`, {
          initialValue: get(data, ['order_services', `${key}`, 'quantity'], 0),
        })(
          <InputNumber
            min={0}
            formatter={numberOnlyFormat}
            onChange={handleOnChangeQuantity.bind(this, key)}
          />
        )}
      </Col>
      <Col lg={5}>
        {getFieldDecorator(`price[${key}]`, {
          initialValue: get(data, ['order_services', `${key}`, 'price'], 0),
        })(
          <InputNumber
            formatter={handleTwoFormatter.bind(this, 'currency')}
            onChange={handleOnChangePrice.bind(this, key)}
            parser={priceParser}
          />
        )}
      </Col>
      <Col lg={5}>
        {getFieldDecorator(`amount[${key}]`, {
          initialValue: countAmount(key),
        })(
          <InputNumber
            disabled
            formatter={priceFormatter}
            parser={priceParser}
          />
        )}
      </Col>
      <Col lg={3}>
        {getFieldDecorator(`meetings[${key}]`, {
          initialValue: get(data, ['order_services', `${key}`, 'meetings'], 1),
        })(
          <InputNumber
            min={1}
            formatter={numberOnlyFormat}
            onChange={handleOnChangeQuantity.bind(this, key)}
          />
        )}
      </Col>
      <Col lg={1} align="right">
        <Icon
          theme="twoTone"
          twoToneColor="green"
          type="profile"
          onClick={handleOpenNotes.bind(this, key)}
        />
      </Col>
      {noteFieldOpenList.includes(key) && (
        <Col lg={24}>
          {getFieldDecorator(`info_description[${key}]`, {
            initialValue: get(
              data,
              ['order_services', `${key}`, 'info_description'],
              ''
            ),
          })(
            <Input
              placeholder="Add notes"
              onChange={handleChangeNotes.bind(this, key)}
            />
          )}
        </Col>
      )}
    </PdTableBodyRow>
  ));

  /* END OF SETUP */

  function handleTwoFormatter(type, value) {
    switch (type) {
      case 'currency':
        return priceFormatter(numberOnlyFormat(value));
      case 'percentage':
        return appendPercentage(numberOnlyFormat(value));
    }
  }

  function handleAddTableRow() {
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(globalIndex++);
    setFieldsValue({
      keys: nextKeys,
    });
  }

  function handleOnSelect(key, value) {
    const selectedService = servicesList.find(({ id }) => id === value);
    const servicePrice = get(selectedService, 'price', 0);
    setFieldsValue({
      [`price[${key}]`]: servicePrice,
    });

    // calculate Amount based on quantity and price field
    const quantity = getFieldValue(`quantity[${key}]`);
    const price = getFieldValue(`price[${key}]`);
    const amount = quantity && price ? quantity * price : 0;
    setFieldsValue({
      [`amount[${key}]`]: amount,
    });
  }

  function handleOnChangeCheckbox(checkedValue) {
    setCheckedValue(checkedValue);
  }

  function handleOnChangePrice(key, value) {
    const quantity = getFieldValue(`quantity[${key}]`);
    const amount = quantity ? value * quantity : 0;
    setFieldsValue({
      [`amount[${key}]`]: amount,
    });
  }

  function handleOnChangeQuantity(key, value) {
    const price = getFieldValue(`price[${key}]`);
    const amount = price ? value * price : 0;
    setFieldsValue({
      [`amount[${key}]`]: amount,
    });
  }

  function handleChangeNotes(key, value) {
    setFieldsValue({
      [`info_description[${key}]`]: value,
    });
  }

  function handleOpenNotes(key) {
    setNoteFieldOpenList((prev) => {
      if (prev.includes(key)) {
        return prev.filter((item) => item !== key);
      }
      return [...prev, key];
    });
  }

  function handleModalOnCancel() {
    // reset table section
    globalIndex = 0;
    setFieldsValue({
      keys: [],
    });
    onCancel();
  }

  async function handleSubmit(event) {
    event.preventDefault();
    validateFields(async (err, values) => {
      if (!err) {
        // adjust for table multiform
        const {
          keys = [],
          service = [],
          quantity = [],
          price = [],
          meetings = [],
          info_description = [],
        } = values;
        const startDate = moment(get(values, 'start_date', Date.now())).format(
          'YYYY/MM/DD'
        );
        const order_services = keys.map((key) => {
          const selectedService = servicesList.find(
            ({ id }) => id === service[key]
          );
          const title = get(selectedService, 'title', '');
          return {
            id: tableRowIds[key],
            price: price[key],
            quantity: quantity[key],
            service: { id: service[key], title },
            meetings: meetings[key],
            info_description: info_description[key],
          };
        });

        // make payload to send it to edit form
        const modalData = {
          ...values,
          days: checkedValue,
          id: get(data, 'id', ''),
          order_services,
          start_date: startDate,
          total_price: totalAmount(),
        };

        // reset table section
        globalIndex = 0;
        setFieldsValue({
          keys: [],
        });

        // call function to make changes
        if (get(data, 'isEdit', '') === 0 || get(data, 'isEdit', '')) {
          const editId = get(data, 'id', '');
          onChangeRecurringEditContent(editId, modalData);
        } else {
          onSubmit(modalData);
        }

        onCancel();
      }
    });
  }

  async function fetchServices() {
    const { data } = await ServiceApi.getServiceList({ limit: 10 });
    const list = get(data, 'data', []);
    setServicesList(list);
  }

  function generateStartDateInitialValue() {
    const startDate = get(data, 'start_date', '');
    if (startDate) return moment(startDate);
    return '';
  }

  useEffect(() => {
    if (get(data, 'order_services', '')) {
      const servicesAttribbutes = get(data, 'order_services', []);
      const orderServiceIds = [];
      servicesAttribbutes.forEach(({ id, info_description }, idx) => {
        handleAddTableRow();
        orderServiceIds.push(id);
        if (info_description) {
          setNoteFieldOpenList((prev) => [...prev, idx]);
        }
      });
      setTableRowIds(orderServiceIds);
    } else {
      // add 1 tableBodyRow Input for defaultValue
      handleAddTableRow();
    }
    fetchServices();
    // eslint-disable-next-line
  }, []);

  return (
    <PdModal
      modalTitle="Recurring Services"
      onCancel={handleModalOnCancel}
      visible={visible}
      width={'60vw'}>
      <PdForm onSubmit={handleSubmit} className="pd-recurring-form">
        <Form.Item>
          <Row className="form-item">
            <Col className="form-item-label" lg={5}>
              Start Date
            </Col>
            <Col lg={{ span: 9, offset: 2 }}>
              {getFieldDecorator('start_date', {
                rules: [
                  {
                    message: 'Please input the start date',
                    required: true,
                  },
                ],
                initialValue: generateStartDateInitialValue(),
              })(<DatePicker className="w-full" format="MM/DD/YYYY" />)}
            </Col>
          </Row>

          <Row className="form-item">
            <Col className="form-item-label" lg={5}>
              Address
            </Col>
            <Col lg={{ span: 17, offset: 2 }}>
              {getFieldDecorator('address', {
                rules: [
                  {
                    message: 'Please input the address',
                    required: true,
                  },
                ],
                initialValue: get(data, 'address', ''),
              })(
                <Select>
                  {addressList.map(({ id, address }, idx) => (
                    <Option key={idx} value={{ id, address }}>
                      {address}
                    </Option>
                  ))}
                </Select>
              )}
            </Col>
          </Row>

          <Row className="form-item pd-margin-bottom-md">
            <Col className="form-item-label" lg={5}>
              Days
            </Col>
            <Col lg={{ span: 17, offset: 2 }}>
              <Row className="pd-margin-top-sm">
                <Group
                  onChange={handleOnChangeCheckbox}
                  defaultValue={checkedValue}>
                  <Col lg={24}>
                    {FORM_RECURRING_DAY_OPTIONS.map((day, idx) => (
                      <Checkbox key={idx} value={day.toLowerCase()}>
                        {day[0]}
                      </Checkbox>
                    ))}
                  </Col>
                </Group>
              </Row>
            </Col>
          </Row>

          {/* Table Section */}
          <PdTable className="detail-container">
            <PdTableHeader listHeader={tableHeaders} />
            {tableFormItems}
            <PdTableBodyRow>
              <Col>
                <PdButton onClick={handleAddTableRow} type="link" icon="plus">
                  Add More Service
                </PdButton>
              </Col>
            </PdTableBodyRow>
          </PdTable>

          <Row
            className="form-item pd-margin-top-md"
            align="middle"
            justify="end"
            type="flex">
            <Col className="form-item-label" lg={6}>
              Discount
            </Col>
            <Col lg={{ span: 4, offset: 1 }}>
              {getFieldDecorator('discount', {
                initialValue: get(data, 'discount', 0),
              })(
                <InputNumber
                  formatter={handleTwoFormatter.bind(this, 'percentage')}
                  parser={parsePercentage}
                />
              )}
            </Col>
          </Row>

          <Row className="form-item" align="middle" justify="end" type="flex">
            <Col className="form-item-label" lg={6}>
              Total Amount
            </Col>
            <Col lg={{ span: 4, offset: 1 }}>
              <Text>$ {totalAmount()}</Text>
            </Col>
          </Row>

          <Row
            className="pd-margin-top-md"
            align="middle"
            justify="center"
            type="flex">
            <Col lg={8}>
              <PdButton block htmlType="submit">
                Submit
              </PdButton>
            </Col>
          </Row>
        </Form.Item>
      </PdForm>
    </PdModal>
  );
}

export const PdRecurringFormModal = Form.create({
  name: 'recurring-create-edit',
})(RecurringCreateEdit);
