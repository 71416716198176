import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';

import { Layout } from 'antd';

import { StoreProvider } from 'store';

import MainContext from './contexts';

// Web Content
import { Admin as DashboardAdmin } from 'pages/dashboard/admin';
import { AdminPageForm } from 'pages/dashboard/admin/create-edit';
import { AllCustomer as DashboardAllCustomer } from 'pages/dashboard/all-customer';
import { AllCustomerDetail } from 'pages/dashboard/all-customer/detail';
import { Calendar as DashboardCalendar } from 'pages/dashboard/calendar';
import { CustomerBalance as DashboardCustomerBalance } from 'pages/dashboard/customer-balance';
import { CustomerBalanceDetail } from 'pages/dashboard/customer-balance/detail';
import { CustomerSetup as DashboardCustomerSetup } from 'pages/dashboard/customer-setup';
import { CustomerCreatePageForm } from 'pages/dashboard/customer-setup/create';
import { CustomerEditPageForm } from 'pages/dashboard/customer-setup/edit';
import { Home as DashboardHome } from 'pages/dashboard/home';
import { Invoice as DashboardInvoice } from 'pages/dashboard/invoice';
import { InvoiceDetail as DashboardInvoiceDetail } from 'pages/dashboard/invoice/detail';
import { Role as DashboardRole } from 'pages/dashboard/role';
import { RolePageForm } from 'pages/dashboard/role/create-edit';
import { Service as DashboardService } from 'pages/dashboard/service';
import { ServicePageForm } from 'pages/dashboard/service/create-edit';
import { TermPolicy as DashboardTermPolicy } from 'pages/dashboard/term-policy';
import { TermAndPolicyPageForm } from 'pages/dashboard/term-policy/create-edit';
import { WorkOrders as DashboardWorkOrders } from 'pages/dashboard/orders';
import { WorkOrdersDetail as DashboardWorkOrdersDetail } from 'pages/dashboard/orders/detail';
import { WorkOrdersCreateForm as DashboardWorkOrdersCreateForm } from 'pages/dashboard/orders/create';
import { ReportAllCustomer as DashboardReportAllCustomer } from 'pages/dashboard/report-all-customer';
import { ReportCustomerDetail } from 'pages/dashboard/report-all-customer/detail';
import { ReportCustomerPageForm } from 'pages/dashboard/report-all-customer/edit';
import { InvoiceInformation as DashboardWorkInvoice } from 'pages/dashboard/invoice-information';
import { InvoiceInformationDetail as DashboardWorkInvoiceDetail } from 'pages/dashboard/invoice-information/detail';
import { InvoiceInformationEditPageForm as DashboardInvoiceEditPageForm } from 'pages/dashboard/invoice-information/edit';
import { Proposal as DashboardProposal } from 'pages/dashboard/proposal';
import { ProposalCreatePageForm as DashboardProposalCreatePageForm } from 'pages/dashboard/proposal/create';
import { ProposalDetail as DashboardProposalDetail } from 'pages/dashboard/proposal/detail';
import { ProposalEditPageForm as DashboardProposalEditPageForm } from 'pages/dashboard/proposal/edit';
import { SettingsPageForm } from 'pages/dashboard/settings';

// Mobile Content
import { Admin as MobileDashboardAdmin } from 'pages/mobile/dashboard/admin';
import { AdminPageForm as MobileAdminPageForm } from 'pages/mobile/dashboard/admin/create-edit';
import { AllCustomerBalance as MobileDashboardAllCustomerBalance } from 'pages/mobile/dashboard/all-customer-balance';
import { AllCustomerBalanceDetail as MobileDashboardAllCustomerBalanceDetail } from 'pages/mobile/dashboard/all-customer-balance/detail';
import { Calendar as DashboardMobileCalendar } from 'pages/mobile/dashboard/calendar';
import { CustomerBalance as MobileDashboardCustomerBalance } from 'pages/mobile/dashboard/customer-balance';
import { CustomerBalanceDetail as MobileCustomerBalanceDetail } from 'pages/mobile/dashboard/customer-balance/detail';
import { CustomerCreatePageForm as MobileCustomerCreatePageForm } from 'pages/mobile/dashboard/customer-setup/create';
import { CustomerEditPageForm as MobileCustomerEditPageForm } from 'pages/mobile/dashboard/customer-setup/edit';
import { CustomerSetup as MobileDashboardCustomerSetup } from 'pages/mobile/dashboard/customer-setup';
import { Home as MobileDashboardHome } from 'pages/mobile/dashboard/home';
import { InvoiceInformation as MobileDashboardWorkInvoice } from 'pages/mobile/dashboard/invoice-information';
import { InvoiceInformationDetail as MobileDashboardWorkInvoiceDetail } from 'pages/mobile/dashboard/invoice-information/detail';
import { OrderDetail as MobileOrderDetail } from 'pages/mobile/dashboard/orders/detail';
import { OrderPageForm as MobileOrderPageForm } from 'pages/mobile/dashboard/orders/create-edit';
import { Orders as MobileDashboardOrders } from 'pages/mobile/dashboard/orders';
import { Proposal as MobileDashboardProposal } from 'pages/mobile/dashboard/proposal';
import { ProposalCreateForm as MobileProposalCreateForm } from 'pages/mobile/dashboard/proposal/create';
import { ProposalEditForm as MobileProposalEditForm } from 'pages/mobile/dashboard/proposal/edit';
import { ProposalDetail as MobileProposalDetail } from 'pages/mobile/dashboard/proposal/detail';
import { ReportAllCustomer as MobileDashboardReportAllCustomer } from 'pages/mobile/dashboard/report-all-customer';
import { ReportCustomerDetail as MobileDashboardReportCustomerDetail } from 'pages/mobile/dashboard/report-all-customer/detail';
import { ReportCustomerPageForm as MobileDashboardReportCustomerPageForm } from 'pages/mobile/dashboard/report-all-customer/edit';
import { Role as MobileDashboardRole } from 'pages/mobile/dashboard/role';
import { RolePageForm as MobileRolePageForm } from 'pages/mobile/dashboard/role/create-edit';
import { Service as MobileDashboardService } from 'pages/mobile/dashboard/service';
import { ServicePageForm as MobileServicePageForm } from 'pages/mobile/dashboard/service/create-edit';
import { SettingsPageForm as MobileSettingsPageForm } from 'pages/mobile/dashboard/settings';
import { TermPolicy as MobileTermPolicy } from 'pages/mobile/dashboard/term-policy';
import { Invoice as MobileDashboardInvoice } from 'pages/mobile/dashboard/invoice';
import { InvoiceDetail as MobileDashboardInvoiceDetail } from 'pages/mobile/dashboard/invoice/detail';

// ContentManagement
import { ForgotPasswordForm } from 'pages/forgot-password';
import { LoginPageForm } from 'pages/login';
import { Sidebar } from 'components/sidebar';
import { SignupPageForm } from 'pages/signup';

import AuthenticatedGuardRoute from 'components/guard/authenticated';
import NotAuthenticatedGuardRoute from 'components/guard/notAuthenticated';
import { Header } from 'components/header';
import { Footer } from 'components/footer';

// MobileManagement
import MobileHeader from 'components/mobile-header';

import 'antd/dist/antd.css';
import 'App.scss';

const { Content } = Layout;

function DashboardWrapper() {
  return (
    <>
      <BrowserView>
        <Switch>
          <NotAuthenticatedGuardRoute
            exact
            path="/login"
            component={LoginPageForm}
          />
          <NotAuthenticatedGuardRoute
            exact
            path="/signup"
            component={SignupPageForm}
          />
          <NotAuthenticatedGuardRoute
            exact
            path="/forgot-password"
            component={ForgotPasswordForm}
          />
          <Layout className="pd-cms">
            <Sidebar />
            <Layout className="pd-cms-layout">
              <Header />
              <Content>
                <AuthenticatedGuardRoute
                  path="/calendar"
                  component={DashboardCalendar}
                />
                <AuthenticatedGuardRoute
                  path="/setting"
                  component={SettingsPageForm}
                />
                <AuthenticatedGuardRoute
                  path="/proposal"
                  component={DashboardProposal}
                />
                <AuthenticatedGuardRoute
                  path="/proposal/detail"
                  component={DashboardProposalDetail}
                />
                <AuthenticatedGuardRoute
                  path="/proposal/create"
                  component={DashboardProposalCreatePageForm}
                />
                <AuthenticatedGuardRoute
                  path="/proposal/edit"
                  component={DashboardProposalEditPageForm}
                />
                <AuthenticatedGuardRoute
                  path="/work-orders-information"
                  component={DashboardWorkInvoice}
                />
                <AuthenticatedGuardRoute
                  path="/work-orders-information/detail"
                  component={DashboardWorkInvoiceDetail}
                />
                <AuthenticatedGuardRoute
                  path="/invoice"
                  component={DashboardInvoice}
                />
                <AuthenticatedGuardRoute
                  path="/invoice/detail"
                  component={DashboardInvoiceDetail}
                />
                <AuthenticatedGuardRoute
                  path="/invoice/edit"
                  component={DashboardInvoiceEditPageForm}
                />
                <AuthenticatedGuardRoute
                  path="/work-orders"
                  component={DashboardWorkOrders}
                />
                <AuthenticatedGuardRoute
                  path="/work-orders/detail"
                  component={DashboardWorkOrdersDetail}
                />
                <AuthenticatedGuardRoute
                  path="/work-orders/create"
                  component={DashboardWorkOrdersCreateForm}
                />
                <AuthenticatedGuardRoute
                  path="/all-customer"
                  component={DashboardReportAllCustomer}
                />
                <AuthenticatedGuardRoute
                  path="/all-customer/detail"
                  component={ReportCustomerDetail}
                />
                <AuthenticatedGuardRoute
                  path="/all-customer/edit"
                  component={ReportCustomerPageForm}
                />
                <AuthenticatedGuardRoute
                  path="/all-customer-balance"
                  component={DashboardAllCustomer}
                />
                <AuthenticatedGuardRoute
                  path="/all-customer-balance/detail"
                  component={AllCustomerDetail}
                />
                <AuthenticatedGuardRoute
                  path="/customer-balance"
                  component={DashboardCustomerBalance}
                />
                <AuthenticatedGuardRoute
                  path="/customer-balance/detail"
                  component={CustomerBalanceDetail}
                />
                <AuthenticatedGuardRoute
                  path="/customer-setup"
                  component={DashboardCustomerSetup}
                />
                <AuthenticatedGuardRoute
                  path="/customer-setup/create"
                  component={CustomerCreatePageForm}
                />
                <AuthenticatedGuardRoute
                  path="/customer-setup/edit"
                  component={CustomerEditPageForm}
                />
                <AuthenticatedGuardRoute
                  path="/terms-and-policy"
                  component={DashboardTermPolicy}
                />
                <AuthenticatedGuardRoute
                  path="/terms-and-policy/:page"
                  component={TermAndPolicyPageForm}
                />
                <AuthenticatedGuardRoute
                  path="/service"
                  component={DashboardService}
                />
                <AuthenticatedGuardRoute
                  path="/service/:page"
                  component={ServicePageForm}
                />
                <AuthenticatedGuardRoute
                  path="/role"
                  component={DashboardRole}
                />
                <AuthenticatedGuardRoute
                  path="/role/:page"
                  component={RolePageForm}
                />
                <AuthenticatedGuardRoute
                  path="/admin"
                  component={DashboardAdmin}
                />
                <AuthenticatedGuardRoute
                  path="/admin/:page"
                  component={AdminPageForm}
                />
                <AuthenticatedGuardRoute path="/" component={DashboardHome} />
              </Content>
              <Footer />
            </Layout>
          </Layout>
        </Switch>
      </BrowserView>

      <MobileView>
        <Switch>
          <NotAuthenticatedGuardRoute
            exact
            path="/login"
            component={LoginPageForm}
          />
          <NotAuthenticatedGuardRoute
            exact
            path="/forgot-password"
            component={ForgotPasswordForm}
          />
          <Layout className="pd-mobile">
            <Layout className="pd-mobile-layout">
              <MobileHeader />
              <Content>
                <AuthenticatedGuardRoute
                  path="/setting"
                  component={MobileSettingsPageForm}
                />
                <AuthenticatedGuardRoute
                  path="/admin"
                  component={MobileDashboardAdmin}
                />
                <AuthenticatedGuardRoute
                  path="/admin/:page"
                  component={MobileAdminPageForm}
                />
                <AuthenticatedGuardRoute
                  path="/role"
                  component={MobileDashboardRole}
                />
                <AuthenticatedGuardRoute
                  path="/role/:page"
                  component={MobileRolePageForm}
                />
                <AuthenticatedGuardRoute
                  path="/service"
                  component={MobileDashboardService}
                />
                <AuthenticatedGuardRoute
                  path="/service/:page"
                  component={MobileServicePageForm}
                />
                <AuthenticatedGuardRoute
                  path="/customer-setup"
                  component={MobileDashboardCustomerSetup}
                />
                <AuthenticatedGuardRoute
                  path="/customer-setup/create"
                  component={MobileCustomerCreatePageForm}
                />
                <AuthenticatedGuardRoute
                  path="/customer-setup/edit"
                  component={MobileCustomerEditPageForm}
                />
                <AuthenticatedGuardRoute
                  path="/customer-balance"
                  component={MobileDashboardCustomerBalance}
                />
                <AuthenticatedGuardRoute
                  path="/customer-balance/detail"
                  component={MobileCustomerBalanceDetail}
                />
                <AuthenticatedGuardRoute
                  path="/all-customer"
                  component={MobileDashboardReportAllCustomer}
                />
                <AuthenticatedGuardRoute
                  path="/all-customer/detail"
                  component={MobileDashboardReportCustomerDetail}
                />
                <AuthenticatedGuardRoute
                  path="/all-customer/edit"
                  component={MobileDashboardReportCustomerPageForm}
                />
                <AuthenticatedGuardRoute
                  path="/all-customer-balance"
                  component={MobileDashboardAllCustomerBalance}
                />
                <AuthenticatedGuardRoute
                  path="/all-customer-balance/detail"
                  component={MobileDashboardAllCustomerBalanceDetail}
                />
                <AuthenticatedGuardRoute
                  path="/work-orders"
                  component={MobileDashboardOrders}
                />
                <AuthenticatedGuardRoute
                  path="/work-orders/detail"
                  component={MobileOrderDetail}
                />
                <AuthenticatedGuardRoute
                  path="/work-orders/create"
                  component={MobileOrderPageForm}
                />
                <AuthenticatedGuardRoute
                  path="/work-orders-information"
                  component={MobileDashboardWorkInvoice}
                />
                <AuthenticatedGuardRoute
                  path="/work-orders-information/detail"
                  component={MobileDashboardWorkInvoiceDetail}
                />
                <AuthenticatedGuardRoute
                  path="/invoice"
                  component={MobileDashboardInvoice}
                />
                <AuthenticatedGuardRoute
                  path="/invoice/detail"
                  component={MobileDashboardInvoiceDetail}
                />
                <AuthenticatedGuardRoute
                  path="/proposal"
                  component={MobileDashboardProposal}
                />
                <AuthenticatedGuardRoute
                  path="/proposal/create"
                  component={MobileProposalCreateForm}
                />
                <AuthenticatedGuardRoute
                  path="/proposal/edit"
                  component={MobileProposalEditForm}
                />
                <AuthenticatedGuardRoute
                  path="/proposal/detail"
                  component={MobileProposalDetail}
                />
                <AuthenticatedGuardRoute
                  path="/terms-and-policy"
                  component={MobileTermPolicy}
                />
                <AuthenticatedGuardRoute
                  path="/calendar"
                  component={DashboardMobileCalendar}
                />
                <AuthenticatedGuardRoute
                  path="/"
                  component={MobileDashboardHome}
                />
              </Content>
              <Footer />
            </Layout>
          </Layout>
        </Switch>
        {/* mobile route since the layout different better to recreate the page */}
      </MobileView>
    </>
  );
}

function App() {
  return (
    <Router>
      <StoreProvider>
        <MainContext>
          <DashboardWrapper />
        </MainContext>
      </StoreProvider>
    </Router>
  );
}

export default App;
