import React, { useState, useEffect } from 'react';
import { Form, Input, Row, Select, DatePicker } from 'antd';
import { identity } from 'lodash';
import moment from 'moment';

import CustomerAPI from 'api/customer';
import PdModal from 'components/pd-modal';
import PdButton from 'components/pd-button';
import { handleErr } from 'utils';

const { Option } = Select;
const { TextArea } = Input;
const { MonthPicker } = DatePicker;

export default function PdModalOTI({
  visible = false,
  onCancel = identity,
  onSubmit = identity,
}) {
  const [customerList, setCustomerList] = useState([]);
  const [payload, setPayload] = useState({
    customer_id: '',
    period: moment().format('YYYY-MM-01'),
    message: '',
  });

  function handleChangeField(type) {
    return function (value) {
      setPayload((prev) => {
        return {
          ...prev,
          [type]: type === 'period' ? value.format('YYYY-MM-01') : value,
        };
      });
    };
  }

  function handleSubmit() {
    onSubmit(payload);
  }

  async function fetchCustomerList() {
    try {
      const { data = {} } = await CustomerAPI.getCustomerList();
      setCustomerList(data?.data);
    } catch (error) {
      handleErr(error);
    }
  }

  useEffect(() => {
    fetchCustomerList();
  }, []);

  return (
    <PdModal
      modalTitle="Create One Time Invoice"
      visible={visible}
      onCancel={onCancel}>
      <Form className="pd-one-time-service">
        <Form.Item
          colon={false}
          label="Customer Name"
          labelAlign="left"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 10 }}>
          <Select
            value={payload.customer_id}
            placeholder="Customer"
            onSelect={handleChangeField('customer_id')}
            style={{ width: '100%' }}>
            {customerList.map(({ id = '', name = '' }) => (
              <Option key={id} value={id}>
                {name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          colon={false}
          label="Period"
          labelAlign="left"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 10 }}>
          <MonthPicker
            onChange={handleChangeField('period')}
            disabledDate={(current) =>
              current && current > moment().endOf('day')
            }
            placeholder="Select Month"
            defaultValue={moment()}
          />
        </Form.Item>
        <Form.Item
          colon={false}
          label="Messages"
          labelAlign="left"
          labelCol={{ span: 6 }}
          name="message"
          wrapperCol={{ span: 18 }}>
          <TextArea rows={3} onChange={handleChangeField('message')} />
        </Form.Item>
        <Row type="flex" justify="center">
          <PdButton onClick={handleSubmit}>Submit</PdButton>
        </Row>
      </Form>
    </PdModal>
  );
}
