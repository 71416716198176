// TODO: Implement Accept, Reject, Send

import React, { useEffect, useState, useContext } from 'react';
import { Col, Layout, message, Row, Select, Typography, Modal } from 'antd';
import { get } from 'lodash';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdTable, { PdTableBodyRow, PdTableHeader } from 'components/pd-table';
import PdModal from 'components/pd-modal';
import PdPreviewEmail from 'components/pd-preview-email';

import { handleErr, BASIC_PLAN_WARN_COPYWRITE } from 'utils';
import { numberDate, numberWithCommas } from 'utils/numberFormater';
import { AuthenticationContext } from 'contexts/authentication';

import ProposalAPI from 'api/proposal';

import './style.scss';

const { Option } = Select;
const { Text } = Typography;
const { confirm } = Modal;

export function ProposalDetail({ history, location }) {
  const [selectedProperty, setSelectedProperty] = useState('');
  const [propertyAddress, setPropertyAddress] = useState([]);
  const [proposalData, setProposalData] = useState({});
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const [isPreviewEmail, setIsPreviewEmail] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');

  const { authenticatedUserProfile } = useContext(AuthenticationContext);
  const { isUnlimitedPlan = false } = authenticatedUserProfile;

  const { id: proposalDataId = '' } = location ?? {};
  const {
    body_proposal = '',
    customer = {},
    discount = 0,
    id = undefined,
    order_services = [],
    ordered_at = '',
    status = '',
    total_price = 0,
  } = proposalData ?? {};
  const { name = '', email = '' } = customer ?? {};

  const tableHeaders = [
    { title: 'Service', width: 4 },
    { title: 'Service Date', width: 4 },
    { title: 'Quantity', width: 4 },
    { title: '$ per Unit', width: 4 },
    { title: 'Amount', width: 4 },
    { title: 'Meetings', width: 4 },
  ];

  const statusList = [
    { value: 'sent', text: 'Sent' },
    { value: 'approved', text: 'Approved' },
    { value: 'rejected', text: 'Rejected' },
  ];

  const PdStatusDropdown = () => (
    <Select onChange={handleOnChangeStatus} defaultValue={status}>
      {statusList.map(({ value, text }, idx) => (
        <Option key={idx} value={value}>
          {text}
        </Option>
      ))}
    </Select>
  );

  async function handleOnChangeStatus(statusValue) {
    try {
      await ProposalAPI.updateProposal(id, { order: { status: statusValue } });
      fetchProposalData(id);
    } catch (error) {
      handleErr(error);
    }
  }

  function handleEditInvoice(id = undefined) {
    history.push({
      pathname: '/proposal/edit',
      id,
    });
  }

  async function fetchProposalData(id) {
    try {
      const { data: proposalData } = await ProposalAPI.getProposalDetail(id);
      const { data = {} } = proposalData ?? {};

      setProposalData(data);
      getPDFUrl({ isComeFromFetch: true });
    } catch (err) {
      message.error(get(err, 'response.message', 'Failed to get invoice data'));
    }
  }

  async function getPDFUrl({ isComeFromFetch = false } = {}) {
    try {
      if (!isComeFromFetch && status !== 'rejected')
        fetchProposalData(proposalDataId);
      const { data } = await ProposalAPI.getProposalPDF(proposalDataId);
      setPdfUrl(data?.pdf_link);
    } catch (err) {
      handleErr(err);
    }
  }

  async function fetchPropertyAddress(id) {
    try {
      const { data = {} } = await ProposalAPI.getPropertyAddress(id);
      const { data: dataProperty = [] } = data;

      setPropertyAddress(dataProperty);
    } catch (error) {
      handleErr(error);
    }
  }

  function handleBackProposal() {
    history.push({
      pathname: '/proposal',
    });
  }

  async function handleSendMail(emails = []) {
    try {
      await ProposalAPI.sendMail(id, emails);
      fetchProposalData(proposalDataId);
      setIsPreviewEmail(false);
      message.success('Successfully send email to the customer');
    } catch (error) {
      handleErr(error);
    }
  }

  async function handlePDFPrint() {
    try {
      if (pdfUrl === '') {
        await getPDFUrl();
      }

      window.open(pdfUrl, '_blank');
    } catch (err) {
      message.error(
        err?.response?.message || 'Failed to get PDF print of the proposal'
      );
    }
  }

  async function handleReject() {
    try {
      await handleOnChangeStatus('rejected');
      handleBackProposal();
    } catch (err) {
      handleErr(err);
    }
  }

  async function handleApprove() {
    try {
      if (!customer?.billing_address?.id) {
        message.info(
          'Please complete your customer data before generate work order',
          5
        );
        history.push({
          pathname: '/customer-setup/edit',
          data: customer,
          function: () => {
            history.push({
              pathname: '/proposal/detail',
              id,
            });
          },
        });
        return;
      }

      if (!proposalData?.address?.id) {
        await fetchPropertyAddress(customer.id);
        setShowPropertyModal(true);
        return;
      }

      await ProposalAPI.approveProposal(id);
      history.push('/work-orders');
    } catch (error) {
      handleErr(error);
    }
  }

  async function handleUpdatePropertyAddress() {
    try {
      await ProposalAPI.updateProposal(id, {
        order: { address_id: selectedProperty },
      });
      fetchProposalData(id);
      setShowPropertyModal(false);
    } catch (error) {
      handleErr(error);
    }
  }

  function handleDeleteProposal(id) {
    confirm({
      title: 'Are you sure you want to delete this proposal?',
      content: "You can't undo this action",
      cancelButtonProps: { ghost: true },
      onOk: async () => {
        try {
          await ProposalAPI.deleteProposal(id);
          history.goBack();
        } catch (err) {
          message.error(err?.response?.message || 'Failed to delete proposals');
        }
      },
    });
  }

  function PdTableBodyRowService(props) {
    const { title, service_date, quantity, price, meetings } = props;
    return (
      <PdTableBodyRow>
        <Col lg={4}>{title}</Col>
        <Col lg={4}>{numberDate(service_date)}</Col>
        <Col lg={4}>{quantity}</Col>
        <Col lg={4}>{`$ ${numberWithCommas(price)}`}</Col>
        <Col lg={4}>{`$ ${numberWithCommas(price * quantity)}`}</Col>
        <Col lg={4}>{meetings}</Col>
      </PdTableBodyRow>
    );
  }

  useEffect(() => {
    !proposalDataId
      ? history.push('/proposal')
      : fetchProposalData(proposalDataId);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout className="pd-cms-proposal-detail">
      <DashboardContainer>
        <Row>
          <Col lg={19}>
            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" lg={6}>
                Proposal No.
              </Col>
              <Col lg={15}>: {id}</Col>
            </Row>

            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" lg={6}>
                Customer Name
              </Col>
              <Col lg={15}>: {name}</Col>
            </Row>
            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" lg={6}>
                Email
              </Col>
              <Col lg={15}>: {email}</Col>
            </Row>
            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" lg={6}>
                Proposal Date
              </Col>
              <Col lg={15}>: {numberDate(ordered_at)}</Col>
            </Row>
            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" lg={6}>
                Description
              </Col>
              <Col lg={15}>: {body_proposal}</Col>
            </Row>
            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" lg={6}>
                Status
              </Col>
              <Col lg={3}>
                {status === 'created' ? (
                  status.charAt(0).toUpperCase() + status.slice(1)
                ) : (
                  <PdStatusDropdown />
                )}
              </Col>
            </Row>
          </Col>
          <Col lg={5}>
            <Row className="action-section">
              <Col>
                <PdButton
                  block
                  ghost
                  type="primary"
                  onClick={handleEditInvoice.bind(this, id)}>
                  Edit
                </PdButton>
              </Col>
              <Col>
                <PdButton
                  block
                  ghost
                  type="danger"
                  onClick={() => handleDeleteProposal(id)}>
                  Delete
                </PdButton>
              </Col>
              {status === 'sent' && (
                <>
                  <Col>
                    <PdButton block onClick={handleApprove}>
                      Generate Work Order
                    </PdButton>
                  </Col>
                  <Col>
                    <PdButton block onClick={handleReject}>
                      Reject
                    </PdButton>
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
        {/* Table Section */}
        <PdTable className="detail-container">
          <PdTableHeader listHeader={tableHeaders} />
          {order_services.map((orderService) => {
            const {
              id = '-',
              service_date = '-',
              quantity = 0,
              service = {},
              meetings = 1,
              price = 0,
            } = orderService ?? {};
            const { title = '-' } = service ?? {};
            return (
              <PdTableBodyRowService
                key={id}
                service_date={service_date}
                quantity={quantity}
                title={title}
                price={price}
                meetings={meetings}
              />
            );
          })}
        </PdTable>

        <Row
          align="middle"
          justify="end"
          type="flex"
          className="detail-container">
          <Col className="detail-container-label" lg={3}>
            Discount
          </Col>
          <Col lg={{ span: 4, offset: 1 }}>{`${numberWithCommas(
            discount
          )} %`}</Col>
        </Row>
        <Row
          align="middle"
          justify="end"
          type="flex"
          className="detail-container">
          <Col className="detail-container-label" lg={3}>
            Total Amount
          </Col>
          <Col lg={{ span: 4, offset: 1 }}>{`$ ${numberWithCommas(
            total_price
          )}`}</Col>
        </Row>
        <Row align="middle" justify="center" type="flex">
          <Col lg={{ span: 4 }}>
            <PdButton block ghost type="primary" onClick={handleBackProposal}>
              Back
            </PdButton>
          </Col>
          <Col lg={{ span: 7, offset: 1 }}>
            <PdButton
              block
              onClick={() => setIsPreviewEmail(true)}
              disabled={!isUnlimitedPlan}
              tooltipTitle={!isUnlimitedPlan && BASIC_PLAN_WARN_COPYWRITE}>
              Send Mail to Customer
            </PdButton>
          </Col>
          <Col lg={{ span: 4, offset: 1 }}>
            <PdButton block onClick={handlePDFPrint}>
              PDF Print
            </PdButton>
          </Col>
        </Row>
      </DashboardContainer>

      <PdModal
        modalTitle="Property Address"
        onCancel={() => setShowPropertyModal(false)}
        visible={showPropertyModal}>
        <Row type="flex" justify="center" align="middle">
          <Col span={8}>
            <Text>Address</Text>
          </Col>
          <Col span={15}>
            <Select
              onChange={setSelectedProperty}
              value={selectedProperty}
              style={{ width: '100%' }}>
              {propertyAddress.map(({ id, address }) => (
                <Option key={id} value={id}>
                  {address}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row type="flex" justify="center" className="pd-margin-top-md">
          <Col span={6}>
            <PdButton
              block
              type="primary"
              onClick={handleUpdatePropertyAddress}>
              Submit
            </PdButton>
          </Col>
        </Row>
      </PdModal>

      {/* modal preview email */}
      <PdPreviewEmail
        visible={isPreviewEmail}
        onCancel={() => setIsPreviewEmail(false)}
        isDesktop
        previewUrl={pdfUrl}
        onSendEmail={handleSendMail}
        currentRecipient={email}
      />
    </Layout>
  );
}

export default { ProposalDetail };
