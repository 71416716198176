import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, Input, Layout, message, Row, Typography } from 'antd';

import { get, startCase } from 'lodash';

import SettingApi from 'api/settings';

import DashboardContainer from 'components/dashboard-container';
import PdColorPicker from 'components/pd-color-picker';
import PdButton from 'components/pd-button';
import PdForm from 'components/pd-form';
import PdUploader from 'components/pd-uploader';

import { UserContext } from 'contexts/user';

import { ADMIN_PERMISSION, FORM_FIELD_SETTING } from 'utils';

import './style.scss';

const { Title, Text } = Typography;

function SettingsForm({ form }) {
  const { setUserSettings } = useContext(UserContext);

  const [backgroundColor, setBackgroundColor] = useState('');
  const [buttonColor, setButtonColor] = useState('');
  const [editEmailField, setEditEmailField] = useState([]);
  const [settingsValue, setSettingsValue] = useState({});
  const [titleFontColor, setTitleFontColor] = useState('');

  const { getFieldDecorator, validateFields, setFieldsValue, getFieldValue } =
    form;
  const {
    address = '',
    company_logo = '',
    company_name = '',
    second_line_address = '',
    phone_number,
  } = settingsValue;

  function handleChangeBackgroundColor({ hex = '' }) {
    setBackgroundColor(hex);
  }

  function handleChangeTitleFontColor({ hex = '' }) {
    setTitleFontColor(hex);
  }

  function handleChangeButtonColor({ hex = '' }) {
    setButtonColor(hex);
  }

  function handleEmailEditForm(field) {
    setEditEmailField((prev) => [...prev, field]);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (!err) {
        let isAnyEmailChanged = false;
        Object.keys(values).forEach((key) => {
          if (key.includes('email') && values[key] !== settingsValue[key]) {
            isAnyEmailChanged = true;
          }
        });

        const payload = {
          setting_page: {
            ...values,
            background_color: backgroundColor,
            button_color: buttonColor,
            title_font_color: titleFontColor,
          },
        };
        try {
          await SettingApi.updateSettingsValue(payload);
          const { setting_page = {} } = payload;
          setUserSettings(setting_page);
          document.documentElement.style.setProperty(
            '--backgroundTheme',
            backgroundColor
          );
          document.documentElement.style.setProperty(
            '--buttonTheme',
            buttonColor
          );
          document.documentElement.style.setProperty(
            '--titleTheme',
            titleFontColor
          );
          fetchSettings();
          message.success('Successfully update settings');

          if (isAnyEmailChanged) {
            message.success(
              'Please look for a verification email in your inbox in the next 24 hours.'
            );
          }
        } catch (error) {
          message.error(
            get(error, 'response.message', 'Failed to update settings')
          );
        }
      }
    });
  }

  async function fetchSettings() {
    const { data } = await SettingApi.getSettingsValue();
    const settings = get(data, 'data', {});
    const {
      background_color = '',
      button_color = '',
      title_font_color = '',
    } = settings;
    setSettingsValue(settings);
    setBackgroundColor(background_color);
    setButtonColor(button_color);
    setTitleFontColor(title_font_color);

    const emptyEmail = Object.keys(settings).filter(
      (setting) => setting.includes('email') && !setting
    );
    setEditEmailField([...emptyEmail]);
  }

  function handleAfterUpload(imgUrl) {
    setFieldsValue({
      [FORM_FIELD_SETTING.LOGO]: imgUrl,
    });
  }

  useEffect(() => {
    if (ADMIN_PERMISSION.includes('setting')) {
      fetchSettings();
    }
  }, []);

  return (
    <Layout className="pd-mobile-form-settings">
      <DashboardContainer>
        <Col xs={24}>
          <Title
            className="pd-align-center pd-margin-top-xs pd-margin-bottom-md"
            level={3}>
            Settings
          </Title>
        </Col>
        <PdForm onSubmit={handleSubmit}>
          <Form.Item label="Company Name">
            {getFieldDecorator(FORM_FIELD_SETTING.COMPANY_NAME, {
              initialValue: company_name,
              rules: [
                {
                  required: true,
                  message: 'Please input Company Name!',
                },
              ],
            })(<Input placeholder="Enter Company Name" />)}
          </Form.Item>
          <Form.Item label="First Line Address">
            {getFieldDecorator(FORM_FIELD_SETTING.FIRST_LINE_ADDRESS, {
              initialValue: address,
              rules: [
                {
                  required: true,
                  message: 'Please input Company Address!',
                },
              ],
            })(<Input placeholder="Enter Company Address" />)}
          </Form.Item>
          <Form.Item label="Second Line Address">
            {getFieldDecorator(FORM_FIELD_SETTING.SECOND_LINE_ADDRESS, {
              initialValue: second_line_address,
            })(<Input placeholder="Enter Company Address" />)}
          </Form.Item>
          <Form.Item label="Phone Number">
            {getFieldDecorator(FORM_FIELD_SETTING.PHONE_NUMBER, {
              initialValue: phone_number,
            })(<Input placeholder="Enter Phone Number" />)}
          </Form.Item>
          {Object.keys(FORM_FIELD_SETTING)
            .filter((key) => key.toLowerCase().includes('email'))
            .map((key) => {
              const emailField = key.includes('COMPANY')
                ? 'email'
                : FORM_FIELD_SETTING[key].toLowerCase();
              const isEdit = editEmailField.includes(emailField);
              return (
                <Row key={key}>
                  <Col span={24}>
                    <Form.Item
                      label={startCase(key)}
                      extra={
                        isEdit &&
                        'Email addresses are case sensitive. sender@EXAMPLE.com is seen as different email from  sender@example.com'
                      }>
                      {isEdit ? (
                        getFieldDecorator(FORM_FIELD_SETTING[key], {
                          initialValue: settingsValue[emailField],
                          rules: [
                            {
                              type: 'email',
                              message: 'The input is not valid E-mail!',
                            },
                          ],
                        })(<Input placeholder="Enter Email" />)
                      ) : (
                        <Row type="flex" justify="space-between" align="middle">
                          <Text>{settingsValue[emailField]}</Text>
                          <PdButton
                            onClick={() => handleEmailEditForm(emailField)}>
                            Edit
                          </PdButton>
                        </Row>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              );
            })}

          {/** IMAGE UPLOAD SECTION */}
          <Row>
            <Form.Item label="Upload Logo">
              {getFieldDecorator(FORM_FIELD_SETTING.LOGO, {
                initialValue: company_logo,
                rules: [
                  {
                    required: true,
                    message: 'Please input Company Logo!',
                  },
                ],
              })(
                <PdUploader
                  alt="company-logo"
                  afterUpload={handleAfterUpload}
                  imgUrl={getFieldValue(FORM_FIELD_SETTING.LOGO)}
                />
              )}
            </Form.Item>
          </Row>
          {/** IMAGE UPLOAD SECTION/ */}

          {/** COLOR PICKER SECTION */}
          <Row>
            <PdColorPicker
              formLabel="Background Color"
              value={backgroundColor}
              onColorChange={handleChangeBackgroundColor}
            />
            <PdColorPicker
              formLabel="Title Font Color"
              value={titleFontColor}
              onColorChange={handleChangeTitleFontColor}
            />
            <PdColorPicker
              formLabel="Button Color"
              value={buttonColor}
              onColorChange={handleChangeButtonColor}
            />
          </Row>
          {/** COLOR PICKER SECTION/ */}

          <Form.Item className="btn-container">
            <Row justify="center" type="flex">
              <PdButton block onClick={handleSubmit}>
                Submit
              </PdButton>
            </Row>
          </Form.Item>
        </PdForm>
      </DashboardContainer>
    </Layout>
  );
}

export const SettingsPageForm = Form.create({ name: 'settings' })(SettingsForm);
