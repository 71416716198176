import React, { useEffect, useState } from 'react';
import { Col, Icon, Layout, Row, Typography } from 'antd';
import Search from 'antd/lib/input/Search';
import { get } from 'lodash';
import moment from 'moment';

import CustomerAPI from 'api/customer';

import { PdDownloadCSV } from 'components/pd-download-csv';
import PdMobileTable from 'components/pd-mobile-table';

import { numberWithCommas } from 'utils/numberFormater';

import './style.scss';

const { Title } = Typography;

export function AllCustomerBalance({ history }) {
  const [dataSource, setDataSource] = useState([]);
  const [query, setQuery] = useState('');

  const columns = [
    {
      title: 'Last Invoice Date',
      dataIndex: 'last_invoice_date',
      key: 'last_invoice_date',
      defaultSortOrder: 'descend',
      sorter: (a, b) =>
        moment(a.last_invoice_date).valueOf() -
        moment(b.last_invoice_date).valueOf(),
      width: '35%',
    },
    {
      title: 'Customer',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: '30%',
    },
    {
      title: 'Outstanding Balance',
      dataIndex: 'current_balance',
      key: 'current_balance',
      render: ColumnAmount,
      width: '35%',
    },
    {
      title: '',
      key: 'id',
      render: ColumnAction,
      width: '5%',
    },
  ];

  function ColumnAmount(text) {
    return `$ ${numberWithCommas(text)}`;
  }

  function ColumnAction(rowData = {}) {
    const { id, name } = rowData || {};

    return (
      <Row justify="center" type="flex">
        <Col xs={24} onClick={handleDetailCustomer.bind(this, id, name)}>
          <Icon type="search" />
        </Col>
      </Row>
    );
  }

  function handleDetailCustomer(id, name) {
    history.push({
      pathname: '/all-customer-balance/detail',
      data: { id: id, name },
    });
  }

  function handleSearchCustomerName(e) {
    setQuery(e?.target?.value || '');
  }

  async function fetchDataSource(q) {
    const { data } = await CustomerAPI.getCustomerList({ q });
    const customerListTemp = get(data, 'data', []);
    const customerList = customerListTemp.map((customer) => {
      const { last_invoice_date = '' } = customer;
      const formattedDate = moment(last_invoice_date).format('MM/DD/YYYY');
      return last_invoice_date
        ? { ...customer, last_invoice_date: formattedDate }
        : { ...customer };
    });

    setDataSource(customerList);
  }

  useEffect(() => {
    fetchDataSource(query);
    // eslint-disable-next-line
  }, [query]);

  return (
    <Layout className="pd-mobile-all-customer-balance">
      {/* Section that display chart */}
      <Row xs={24} justify="center" type="flex">
        <Col className="pd-margin-top-md pd-margin-bottom-sm" xs={24}>
          <Title className="pd-align-center" level={3}>
            All Customer Balance
          </Title>
        </Col>
        <Col xs={13}>
          <Search
            placeholder="Search by Customer Name"
            onChange={handleSearchCustomerName}
          />
        </Col>
        <Col xs={{ span: 8, offset: 1 }}>
          <PdDownloadCSV
            fetchData={CustomerAPI.getAllCustomerBalanceReport}
            filename="customer-balance-report.csv"
          />
        </Col>
        <Col xs={24} className="pd-margin-top-lg">
          <PdMobileTable
            dataSource={dataSource}
            columns={columns}
            pagination={{ pageSize: 6 }}
          />
        </Col>
      </Row>
    </Layout>
  );
}

export default { AllCustomerBalance };
