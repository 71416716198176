import React from 'react';
import { Table } from 'antd';

import './style.scss';

function PdMobileTable(props) {
  return <Table className="pd-mobile-table" {...props} />;
}

export default PdMobileTable;
