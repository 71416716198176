import React, { useState, useEffect } from 'react';
import {
  Col,
  Layout,
  Row,
  Typography,
  message,
  Select,
  DatePicker,
  InputNumber,
  Icon,
  Divider,
  Input,
  Modal,
} from 'antd';
import moment from 'moment';

import PdButton from 'components/pd-button';
import PdAntdSwitch from 'components/pd-antd-switch';
import PdModal from 'components/pd-modal';

import InvoiceAPI from 'api/invoice';
import ServiceAPI from 'api/service';

import { capitalizeFirstLetter, handleErr, properCaseConverter } from 'utils';

import './style.scss';
import { FIELDS } from './fields';

const { Text, Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;

export function OrderDetail({ history, location }) {
  const [invoiceData, setInvoiceData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [services, setServices] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [notesFormField, setNotesFormField] = useState({});
  const [anyChange, setAnyChange] = useState(false);

  function handleBackInvoice() {
    if (isEdit && anyChange) {
      confirm({
        title: 'Are you sure?',
        content: 'Your changes will be lost',
        cancelButtonProps: { ghost: true },
        onOk: () => {
          history.goBack();
        },
      });
    } else {
      history.goBack();
    }
  }

  function handleEdit() {
    setIsEdit(true);
  }

  async function handleChangeProgress(rowId, checked) {
    try {
      const order_services_attributes = { id: rowId, finish: checked };
      const payload = { order: { order_services_attributes } };
      await InvoiceAPI.updateServiceProgress(location.id, payload);
      fetchInvoiceData(location.id);
    } catch (error) {
      message.error(error.response.message || 'Update service progress failed');
    }
  }

  function handleChangeService(
    value = 0,
    field = '',
    idx = 0,
    recurringIdx = 0
  ) {
    !anyChange && setAnyChange(true);
    setInvoiceData((prev) => {
      return {
        ...prev,
        order_services: prev.order_services.map((order, orderIdx) => {
          if (orderIdx === idx) {
            const { price } = services.find(
              ({ id }) =>
                id === (field === 'service_id' ? value : order.service_id)
            ) || { price: 0 };

            return {
              ...order,
              price: field === 'service_id' ? price : order.price,
              [field]:
                field !== 'recurringOn'
                  ? value
                  : order.recurringOn
                      .filter(({ isDestroy }) => !isDestroy)
                      .map((recurring, idx) => {
                        if (idx === recurringIdx) {
                          return {
                            ...recurring,
                            service_date: value._d,
                          };
                        }
                        return recurring;
                      }),
            };
          }
          return order;
        }),
      };
    });
  }

  function handleChangeMeetings(idx, value) {
    !anyChange && setAnyChange(true);
    setInvoiceData((prev) => ({
      ...prev,
      order_services: prev.order_services.map((order, orderIdx) => {
        if (orderIdx === idx) {
          let activeRecurringOrder = order.recurringOn.filter(
            ({ isDestroy = false }) => !isDestroy
          );
          const activeLength = activeRecurringOrder.length;
          if (activeLength < value) {
            activeRecurringOrder = Array.from({ length: value }, (_, i) => {
              const recurringOrder = activeRecurringOrder[i];
              if (!recurringOrder?.order_id) {
                return { service_date: moment().format('YYYY/MM/DD') };
              }
              return { ...recurringOrder, isDestroy: false };
            });
          } else if (activeLength > value) {
            activeRecurringOrder = Array.from(
              { length: activeLength },
              (_, i) => {
                const recurringOrder = activeRecurringOrder[i];
                if (i + 1 > value) {
                  return { ...recurringOrder, isDestroy: true };
                }

                return recurringOrder;
              }
            );
          }

          return {
            ...order,
            meetings: value,
            recurringOn: activeRecurringOrder,
          };
        }
        return order;
      }),
    }));
  }

  async function handleSubmitChange() {
    try {
      const order_services_attributes = dataPreparation(
        invoiceData.order_services
      );
      await InvoiceAPI.editInvoice(location.id, {
        order: {
          discount: 0,
          order_services_attributes,
        },
      });

      setIsEdit(false);
      fetchInvoiceData(location.id);
    } catch (err) {
      handleErr(err?.response?.message || 'Failed to submit edit');
    }
  }

  function handleAddNewService() {
    const template = {
      service_date: moment(),
      service_id: '',
      quantity: 1,
      price: 0,
      meetings: 1,
      recurringOn: [{ service_date: moment() }],
    };
    setInvoiceData((prev) => ({
      ...prev,
      order_services: [...prev.order_services, template],
    }));
  }

  function handleDeleteServices(idx) {
    setInvoiceData((prev) => {
      return {
        ...prev,
        order_services: prev.order_services.map((order, orderIdx) => {
          if (orderIdx === idx) {
            return { ...order, isDestroy: true };
          }
          return order;
        }),
      };
    });
  }

  function dataPreparation(payload) {
    return payload
      .map((order) => {
        const {
          service_id,
          quantity = 1,
          price = 0,
          recurringOn = [],
          isDestroy = false,
        } = order;

        if (isDestroy) {
          return recurringOn
            .filter(({ order_id = null }) => order_id)
            .map(({ order_id: id }) => ({
              id,
              _destroy: true,
            }));
        }

        return recurringOn
          .filter(
            ({ order_id = null, isDestroy = false }) => order_id || !isDestroy
          )
          .map((recurring, idx) => {
            const {
              order_id: id = null,
              info_description = null,
              service_date = moment().format('YYYY/MM/DD'),
              isDestroy: recurringDestroy = false,
            } = recurring;
            if (id && recurringDestroy) {
              return {
                id,
                _destroy: true,
              };
            }

            const orderService = {
              service_date,
              service_id,
              quantity,
              price: idx === 0 ? price : 0,
              info_description,
            };

            if (id) {
              return { ...orderService, id };
            }

            return orderService;
          });
      })
      .flat();
  }

  function handleOpenNotes({ order_id = '', info_description = '' }) {
    setShowModal(true);
    setNotesFormField((prev) => ({
      ...prev,
      id: order_id,
      info_description,
    }));
  }

  function handleOnChangeNotes(ev) {
    const { value = '' } = ev.target;
    setNotesFormField((prev) => ({ ...prev, info_description: value }));
  }

  async function handleSubmitNotes() {
    try {
      const payload = {
        order: {
          order_services_attributes: [notesFormField],
        },
      };
      await InvoiceAPI.editInvoice(location.id, payload);
      setShowModal(false);
      fetchInvoiceData(location.id);
    } catch (error) {
      handleErr(error);
    }
  }

  function renderServiceList() {
    const { order_services = [] } = invoiceData;
    return order_services.map((service, serviceIdx) => {
      return FIELDS.map(({ title, key }, idx) => {
        const value =
          key === 'amount' ? +service.price * service.quantity : service[key];
        return (
          <Row key={key}>
            {serviceIdx > 0 && idx === 0 && <Divider />}
            <Row key={idx}>
              <Col xs={10}>
                <Text strong>{title}</Text>
              </Col>
              {key === 'recurringOn' ? (
                <Col xs={22} offset={2}>
                  {value.map(
                    (
                      { order_id, service_date, finish, info_description },
                      orderIdx
                    ) => {
                      const label = `Meet ${orderIdx + 1}`;
                      return (
                        <Row
                          key={label}
                          className={`pd-margin-bottom-sm ${
                            orderIdx === 0 && 'pd-margin-top-sm'
                          }`}>
                          <Col xs={6}>{label}</Col>
                          <Col xs={10}>
                            {moment(service_date).format('MM/DD/YYYY')}
                          </Col>
                          <Col xs={4}>
                            <PdAntdSwitch
                              checked={finish}
                              onChange={(checked) =>
                                handleChangeProgress(order_id, checked)
                              }
                            />
                          </Col>
                          <Col xs={4}>
                            <Icon
                              theme="twoTone"
                              twoToneColor={info_description ? 'green' : 'red'}
                              type="profile"
                              onClick={() =>
                                handleOpenNotes({
                                  order_id,
                                  info_description,
                                })
                              }
                            />
                          </Col>
                        </Row>
                      );
                    }
                  )}
                </Col>
              ) : (
                <Col xs={14} className="pd-margin-bottom-md">
                  {key === 'service' ? (
                    <Text>{value.title}</Text>
                  ) : (
                    <Text>
                      {['price', 'amount'].includes(key) && '$ '} {value}
                    </Text>
                  )}
                </Col>
              )}
            </Row>
          </Row>
        );
      });
    });
  }

  function renderServiceForm() {
    const { order_services } = invoiceData;
    const activeOrder = order_services.filter(({ _destroy }) => !_destroy);
    let firstIndex = 1e6;
    return order_services.map((service, idx) => {
      if (service.isDestroy) return;
      firstIndex = firstIndex > idx ? idx : firstIndex;
      const {
        meetings = 1,
        service_id = 0,
        quantity = 0,
        price = 0,
        recurringOn = [],
      } = service;
      return (
        <Row key={`row-${idx}`}>
          {idx !== firstIndex && <Divider />}
          {/* service name */}
          <Row className="pd-margin-bottom-md">
            <Col>
              <Row type="flex" justify="space-between">
                <Col xs={12}>
                  <Text strong style={{ marginBottom: '16px' }}>
                    Service Name
                  </Text>
                </Col>
                {activeOrder.length > 1 && (
                  <Col xs={12} className="pd-align-right">
                    <Icon
                      type="delete"
                      theme="twoTone"
                      twoToneColor="red"
                      onClick={() => handleDeleteServices(idx)}
                    />
                  </Col>
                )}
              </Row>
            </Col>
            <Col>
              <Select
                className="pd-margin-top-sm"
                defaultValue={service_id}
                onSelect={(value) =>
                  handleChangeService(value, 'service_id', idx)
                }
                placeholder="Service Name"
                showSearch
                style={{ width: '100%' }}>
                {services.map((option) => {
                  const { title = '', id = 1 } = option ?? {};
                  return (
                    <Option key={id} value={id}>
                      {title}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>

          {/* quantity, price, amount */}
          <Row className="pd-margin-bottom-md" gutter={10}>
            <Col xs={8}>
              <Row>
                <Col>
                  <Text strong style={{ marginBottom: '16px' }}>
                    Quantity
                  </Text>
                </Col>
                <Col>
                  <InputNumber
                    min={1}
                    onChange={(value) =>
                      handleChangeService(+value, 'quantity', idx)
                    }
                    value={quantity}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={8}>
              <Row>
                <Col>
                  <Text strong style={{ marginBottom: '16px' }}>
                    Price
                  </Text>
                </Col>
                <Col>
                  <InputNumber
                    min={0}
                    onChange={(value) =>
                      handleChangeService(+value, 'price', idx)
                    }
                    prefix="$"
                    value={price}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={8}>
              <Row>
                <Col>
                  <Text strong style={{ marginBottom: '16px' }}>
                    Amount
                  </Text>
                </Col>
                <Col>
                  <InputNumber value={quantity * price} disabled prefix="$" />
                </Col>
              </Row>
            </Col>
          </Row>

          {/* meetings */}
          <Row className="pd-margin-bottom-md" gutter={10}>
            <Col>
              <Text strong style={{ marginBottom: '16px' }}>
                Meetings
              </Text>
            </Col>
            <Col>
              <InputNumber
                min={1}
                onChange={(value) => handleChangeMeetings(idx, value)}
                value={meetings}
              />
            </Col>
          </Row>

          {/* service date */}
          <Row className="pd-margin-bottom-md">
            <Col>
              <Text strong style={{ marginBottom: '16px' }}>
                Service Date
              </Text>
            </Col>
            <Col>
              {recurringOn
                .filter(({ isDestroy }) => !isDestroy)
                .map(({ service_date = moment() }, recurringIdx) => {
                  const label = `Meet ${recurringIdx + 1}`;
                  return (
                    <Row
                      key={`service-date-${recurringIdx}`}
                      type="flex"
                      align="middle"
                      className="pd-margin-bottom-sm">
                      <Col xs={5} offset={1}>
                        {label}
                      </Col>
                      <Col xs={18}>
                        <DatePicker
                          style={{ width: '100%' }}
                          value={moment(service_date)}
                          onChange={(value) =>
                            handleChangeService(
                              value,
                              'recurringOn',
                              idx,
                              recurringIdx
                            )
                          }
                        />
                      </Col>
                    </Row>
                  );
                })}
            </Col>
          </Row>
        </Row>
      );
    });
  }

  async function fetchInvoiceData(id) {
    try {
      const { data } = await InvoiceAPI.getInvoiceDetail(id);
      const { data: invoice_data = {} } = data;
      const {
        customer,
        invoice_number,
        order_services,
        ordered_at,
        payment_status,
        status,
      } = invoice_data;

      const parsedOrderServices = parseOrderService(order_services);

      setInvoiceData({
        'WO Number': invoice_number,
        'Customer Name': customer.name,
        Email: customer.email,
        'WO Date': moment(ordered_at).format('MM/DD/YYYY'),
        Status: properCaseConverter(status, '_'),
        'Payment Status': capitalizeFirstLetter(payment_status),
        order_services: parsedOrderServices,
      });
    } catch (err) {
      message.error(err?.response?.messages || 'Failed to get invoice data');
    }
  }

  function parseOrderService(data = []) {
    data.sort((a, b) => a.id - b.id);
    let parsedOrderService = [];
    data.forEach((order) => {
      const {
        id,
        service_date,
        price,
        quantity,
        service,
        finish,
        info_description,
      } = order;
      const recurringOrder = {
        order_id: id,
        service_date,
        info_description,
        finish,
      };

      if (price === '0.0') {
        const parentIdx = parsedOrderService.findIndex(
          ({ service_id }) => service_id === service.id
        );
        const parentOrder = parsedOrderService[parentIdx];
        parsedOrderService[parentIdx] = {
          ...parentOrder,
          meetings: parentOrder.meetings + 1,
          recurringOn: [...parentOrder.recurringOn, recurringOrder],
        };
        return;
      }

      parsedOrderService = [
        ...parsedOrderService,
        {
          meetings: 1,
          price,
          quantity,
          service_id: service.id,
          id,
          service,
          recurringOn: [recurringOrder],
        },
      ];
    });

    return parsedOrderService;
  }

  async function fetchServices() {
    try {
      const { data } = await ServiceAPI.getServiceList({ q: '' });

      setServices(data?.data);
    } catch (err) {
      handleErr(err.response.message || 'Failed to get service list');
    }
  }

  useEffect(() => {
    location.id ? fetchInvoiceData(location.id) : history.push('/work-orders');
    fetchServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout className="pd-mobile-detail-order">
      <Row xs={24} justify="center" type="flex">
        <Col className="pd-margin-top-md" xs={{ span: 24 }}>
          <Title className="pd-align-center" level={3}>
            Detail Order
          </Title>
        </Col>
      </Row>
      <div className="container">
        {Object.keys(invoiceData).map((key, idx) => {
          if (key === 'order_services') return;
          return (
            <Row key={`field-${idx}`}>
              <Col xs={10}>
                <Text strong>{key}</Text>
              </Col>
              <Col xs={10} className="pd-margin-bottom-md">
                <Text>{invoiceData[key]}</Text>
              </Col>
              <Col xs={4}>
                {!isEdit && !idx && (
                  <PdButton block onClick={handleEdit}>
                    Edit
                  </PdButton>
                )}
              </Col>
            </Row>
          );
        })}
      </div>

      {/* services */}
      <Row xs={24} justify="center" type="flex">
        <Col xs={24} className="pd-align-center pd-margin-top-md">
          <Text strong>Detail Service</Text>
        </Col>
      </Row>
      {/* <DashboardContainer></DashboardContainer> */}
      <div className="container">
        {isEdit ? renderServiceForm() : renderServiceList()}
      </div>

      {isEdit && (
        <PdButton
          type="link"
          className="pd-margin-top-md"
          onClick={handleAddNewService}>
          + Add More Service
        </PdButton>
      )}

      <Row className="button-container" justify="center" type="flex" gutter={8}>
        <Col xs={8} className="pd-margin-bottom-md">
          <PdButton block ghost onClick={handleBackInvoice}>
            Back
          </PdButton>
        </Col>
        {isEdit && (
          <Col xs={8}>
            <PdButton block onClick={handleSubmitChange}>
              Submit
            </PdButton>
          </Col>
        )}
      </Row>

      {/* Modal Section */}
      {showModal && (
        <PdModal
          modalTitle="Notes"
          visible={showModal}
          onCancel={setShowModal.bind(this, false)}>
          <TextArea
            className="pd-margin-bottom-md"
            onChange={handleOnChangeNotes}
            value={notesFormField?.info_description ?? ''}
          />
          <Row type="flex" justify="center">
            <PdButton type="primary" onClick={handleSubmitNotes}>
              Submit
            </PdButton>
          </Row>
        </PdModal>
      )}
    </Layout>
  );
}

export default { OrderDetail };
