import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Menu, Typography } from 'antd';

import AuthenticationApi from 'api/authentication';
import { AuthenticationContext } from 'contexts/authentication';
import { ADMIN_PERMISSION, SIDEBAR_MENU_DATA_SOURCE } from 'utils';

import './style.scss';

const { SubMenu } = Menu;
const { Title } = Typography;

const menuOptionsList = SIDEBAR_MENU_DATA_SOURCE.map((menu) => {
  const { children = [], name = '', permission = '' } = menu;
  if (name === 'Orders Management') {
    const filteredChildren = children.filter(
      ({ permission: childrenPermission = '' }) =>
        ADMIN_PERMISSION.includes(childrenPermission)
    );
    return !!filteredChildren.length && { ...menu, children: filteredChildren };
  }
  if (!permission || ADMIN_PERMISSION.includes(permission)) return menu;
}); // return unfiltered array (still has falsy element)

const menuOptions = menuOptionsList.filter((menu) => menu);

export function MobileSidebar({ closeSidebar }) {
  const [openMenu, setOpenMenu] = useState([]);
  const [subMenuKeys, setSubMenuKeys] = useState([]);

  const { authenticatedUserProfile: currentAdmin = {} } = useContext(
    AuthenticationContext
  );

  async function handleLogout() {
    const cms_token = localStorage.getItem('cms_token');

    if (cms_token) {
      AuthenticationApi.logout(cms_token);
      localStorage.removeItem('cms_token');
      localStorage.removeItem('current_admin');
      localStorage.removeItem('admin_name');
      localStorage.removeItem('admin_icon_setting');
      localStorage.removeItem('admin_permission');
    }
  }

  function handleOnOpenChange(openKeys) {
    const temp = [...openKeys];
    const lastKey = temp.pop();
    const clickedSubMenu = subMenuKeys.filter((key) => lastKey === key);
    setOpenMenu(clickedSubMenu);
  }

  function renderMenu(opt, parent = '') {
    const { name = '', link = '', type = 'Menu', children = [] } = opt;

    if (type === 'Menu') {
      return (
        <Menu.Item key={`${parent}${name}`} onClick={closeSidebar}>
          <Link to={link}>{name}</Link>
        </Menu.Item>
      );
    } else {
      return (
        <SubMenu key={name} title={<span>{name}</span>}>
          {children.map((opt) => renderMenu(opt, `${name}-`))}
        </SubMenu>
      );
    }
  }

  useEffect(() => {
    SIDEBAR_MENU_DATA_SOURCE.map(({ type = '', name = '' }) => {
      if (type === 'SubMenu') setSubMenuKeys((prev) => [...prev, name]);
    });
  }, []);

  return (
    <>
      <div className="pd-mobile-sidebar">
        <div className="wrapper">
          <Icon type="close" onClick={closeSidebar} />
          <div className="menu">
            <Title level={4}>{currentAdmin.name}</Title>
            <Menu
              mode="inline"
              openKeys={openMenu}
              onOpenChange={handleOnOpenChange}>
              {menuOptions.map(renderMenu)}
            </Menu>
          </div>
        </div>
        <div className="bottom-menu">
          <Title level={4} onClick={handleLogout}>
            <Link to={'/login'}>Logout</Link>
          </Title>
        </div>
      </div>
      <div className="pd-mobile-sidebar-overlay"></div>
    </>
  );
}

export default MobileSidebar;
