import React from 'react';
import { Table } from 'antd';

import './style.scss';

function PdAntdTable({ ...props }) {
  return <Table className="pd-antd-table" {...props} />;
}

export default PdAntdTable;
