import React, { useState } from 'react';
import { Col, Form, Popover, Row } from 'antd';
import { SketchPicker } from 'react-color';

import './style.scss';

/**
 *
 * @typedef {Object} props
 * @param {String} props.formLabel Label for the color picker
 * @param {String} props.value initial color value from BE (must be in hex format)
 * @param {Function} props.onColorChange function that return object color when the picker change it value
 * @example
 * <PdColorPicker formLabel="Title Here" onColorChange={(color)=>{}}>
 * Color Object contain hexcode, rgb code, hsla, etc.
 * For more detail log the color inside `onColorChange` function
 */
function PdColorPicker({
  formLabel = '',
  onColorChange = () => {},
  value = '#ff6347',
}) {
  const [isShowPicker, setIsShowPicker] = useState(false);

  function handleColorChange(color) {
    onColorChange(color);
  }

  function handleTogglePicker() {
    setIsShowPicker(!isShowPicker);
  }

  return (
    <Form.Item label={formLabel}>
      <Popover
        content={<SketchPicker color={value} onChange={handleColorChange} />}
        visible={isShowPicker}
        onVisibleChange={handleTogglePicker}
        trigger="click">
        <Row
          className="color-picker-container"
          type="flex"
          align="middle"
          onClick={handleTogglePicker}>
          <Col lg={3}>
            <div
              className="color-picker-display"
              style={{ backgroundColor: value }}></div>
          </Col>
          <Col lg={{ span: 16, offset: 1 }} className="color-picker-label">
            {value}
          </Col>
        </Row>
      </Popover>
    </Form.Item>
  );
}

export default PdColorPicker;
