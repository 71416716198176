import React, { useEffect, useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { get } from 'lodash';
import moment from 'moment';
import { Col, Layout, Row, Table, Typography } from 'antd';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdMobileTable from 'components/pd-mobile-table';
import DetailModal from './detail-modal';

import CustomerAPI from 'api/customer';

import { capitalizeFirstLetter } from 'utils';
import { numberWithCommas } from 'utils/numberFormater';

import './style.scss';

const { Text, Title } = Typography;

export function AllCustomerBalanceDetail({ history, location }) {
  const [dataSource, setDataSource] = useState([]);
  const [detailModalId, setDetailModalId] = useState('');
  const [showDetailModal, setShowDetailModal] = useState(false);

  const componentRef = useRef();
  const handlePDFPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { id: dataId, name } = location?.data || {};

  const columns = [
    {
      title: 'Transaction Date',
      dataIndex: 'transaction_date',
      key: 'transaction_date',
      defaultSortOrder: 'descend',
      sorter: (a, b) =>
        moment(a.transaction_date).valueOf() -
        moment(b.transaction_date).valueOf(),
    },
    {
      title: 'Type',
      dataIndex: 'id',
      key: 'transaction_type',
      render: ColumnType,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: ColumnAmount,
    },
    {
      title: 'Total Current Balance',
      dataIndex: 'current_balance',
      key: 'current_balance',
      render: ColumnAmount,
    },
  ];

  function ColumnType(id) {
    const selectedBalance = dataSource.find((balance) => balance.id === id);
    const {
      transaction_type: type = '',
      payment = {},
      order = {},
    } = selectedBalance;
    if (['payment', 'rebalance'].includes(type)) {
      return (
        <Text>
          {capitalizeFirstLetter(type)} ({' '}
          {type === 'payment'
            ? payment?.order?.invoice_number
            : order?.invoice_number}{' '}
          )
        </Text>
      );
    }

    return (
      <a onClick={handleOnClickType.bind(this, order)}>
        <Text underline>{order?.invoice_number}</Text>
      </a>
    );
  }

  function ColumnAmount(text) {
    return `$ ${numberWithCommas(text)}`;
  }

  function handleOnClickType({ id = '' }) {
    setDetailModalId(id);
    setShowDetailModal(true);
  }

  function handleBack() {
    history.goBack();
  }

  function handleCloseModal() {
    setDetailModalId('');
    setShowDetailModal(false);
  }

  async function fetchDataSource(id) {
    const { data } = await CustomerAPI.getCustomerBalance({ id });
    const balanceListTemp = get(data, 'data', []);
    const balanceList = balanceListTemp.map((balance) => ({
      ...balance,
      transaction_date: moment(balance.transaction_date).format('MM/DD/YYYY'),
    }));

    setDataSource(balanceList);
  }

  const ComponentToPrint = (props) => (
    <Layout ref={componentRef} {...props}>
      <DashboardContainer>
        <Row align="middle" type="flex" className="customer-container">
          <Col lg={4} className="title">
            Customer Name
          </Col>
          <Col lg={6} className="title">
            &nbsp;&nbsp; : {name}
          </Col>
        </Row>

        <Table
          className="pd-margin-top-lg"
          columns={columns}
          dataSource={dataSource}
          pagination={false}
        />
      </DashboardContainer>
    </Layout>
  );

  useEffect(() => {
    if (!dataId) history.push('/all-customer-balance');
    fetchDataSource(dataId);
    // eslint-disable-next-line
  }, []);

  return (
    <Layout className="pd-mobile-all-customer-balance-detail">
      {/* Section for Print PDF */}
      <ComponentToPrint className="pd-component-to-print" />

      <Row justify="center" type="flex" className="customer-container">
        <Col className="pd-margin-top-md pd-margin-bottom-md" xs={24}>
          <Title className="pd-align-center" level={3}>
            Detail Customer Balance
          </Title>
        </Col>
        <Col className="title">{name}</Col>
      </Row>
      <Row align="middle" className="pd-margin-bottom-md">
        <Col xs={24}>
          <PdMobileTable dataSource={dataSource} columns={columns} />
        </Col>
      </Row>
      <Row
        className="button-container"
        align="middle"
        justify="center"
        type="flex">
        <Col xs={8}>
          <PdButton block ghost onClick={handleBack}>
            Back
          </PdButton>
        </Col>
        <Col xs={{ span: 8, offset: 1 }}>
          <PdButton block onClick={handlePDFPrint}>
            PDF Print
          </PdButton>
        </Col>
      </Row>
      <DetailModal
        visible={showDetailModal}
        onCancel={handleCloseModal}
        orderId={detailModalId}
      />
    </Layout>
  );
}

export default { AllCustomerBalanceDetail };
