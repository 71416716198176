import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, DatePicker, Icon, Layout, Row, Typography } from 'antd';
import { ResponsiveLine } from '@nivo/line';
import { get } from 'lodash';
import moment from 'moment';

import DashboardContainer from 'components/dashboard-container';
import PdAntdSwitch from 'components/pd-antd-switch';
import PdButton from 'components/pd-button';
import PdModal from 'components/pd-modal';

import AdminApi from 'api/admin';
import RevenueAPI from 'api/revenue';

import { ADMIN_PERMISSION, handleErr, HOME_ICON_DATA_SOURCE } from 'utils';

import './style.scss';

const { RangePicker } = DatePicker;
const { Title } = Typography;

const HOME_ICON_LIST = HOME_ICON_DATA_SOURCE.filter(({ permission = '' }) =>
  ADMIN_PERMISSION.includes(permission)
);

const logosListDefaultValue = HOME_ICON_LIST.filter(
  (icon, idx) => idx < 5 && icon
);

export function Home() {
  const [dataRevenue, setDataRevenue] = useState([
    {
      id: 'revenue',
      data: [],
    },
  ]);
  const [logosList, setLogosList] = useState(logosListDefaultValue);
  const [rangeRevenue, setRangeRevenue] = useState({
    start_date: moment().subtract(3, 'months').format('YYYY/MM/01'),
    end_date: moment().format('YYYY/MM/01'),
  });
  const [showCurrentRevenue, setShowCurrentRevenue] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [showModal, setShowModal] = useState(false);

  function handleDownloadChart() {
    // write down here
  }

  function handleOnChangeSwitch(checked) {
    setShowCurrentRevenue(checked);
  }

  const PdModalContent = () => (
    <Row
      gutter={[96, 48]}
      type="flex"
      className="section"
      align="middle"
      justify="start">
      {HOME_ICON_LIST.map(({ alt = '', id = '', imgSrc = '' }) => (
        <Col
          xs={5}
          className="pd-add-icon"
          key={id}
          onClick={handleAddToLogoList.bind(this, id)}>
          <img src={imgSrc} alt={alt} height={80} width={60} />
        </Col>
      ))}
    </Row>
  );

  function handleOnClickAdd() {
    setShowModal(true);
  }

  async function handleAddToLogoList(iconId) {
    const findResult = HOME_ICON_LIST.find(({ id }) => id === iconId);
    const { id: findResultId = '' } = findResult ?? {};
    try {
      const logoListIds = logosList.map(({ id }) => id);
      const payload = {
        admin: {
          icon_setting: [...logoListIds, findResultId],
        },
      };
      await AdminApi.updatePersonalAdmin(payload);
      if (findResultId) setLogosList((prev) => [...prev, findResult]);
      setShowModal(false);
    } catch (error) {
      handleErr(error);
    }
  }

  async function handleDeleteIcon(deleteId) {
    const filterResult = logosList.filter(({ id }) => id !== deleteId);
    const filterIds = filterResult.map(({ id }) => id);
    try {
      const payload = {
        admin: {
          icon_setting: filterIds,
        },
      };
      await AdminApi.updatePersonalAdmin(payload);
      setLogosList(filterResult);
      setShowModal(false);
    } catch (error) {
      handleErr(error);
    }
  }

  async function fetchLogos() {
    try {
      const { data } = await AdminApi.getPersonalAdmin();
      const listOfIconIds = get(data, ['data', 'icon_setting'], []);
      const listOfIcons = HOME_ICON_LIST.filter(({ id }) =>
        listOfIconIds.includes(id)
      );
      if (listOfIcons.length !== 0) setLogosList(listOfIcons);
    } catch (error) {
      handleErr(error);
    }
  }

  async function fetchDataRevenue({ start_date = '', end_date = '' }) {
    try {
      const { data } = await RevenueAPI.getRevenue({ start_date, end_date });
      const dataRevenue = data?.data?.breakdown.map(
        ({ date = '', total_revenue = '' }) => {
          return {
            x: date,
            y: total_revenue,
          };
        }
      );
      setDataRevenue([
        {
          id: 'revenue',
          data: dataRevenue,
        },
      ]);
    } catch (error) {
      handleErr(error);
    }
  }

  function handleChangeRange(value) {
    const [start_date, end_date] = value;
    setRangeRevenue((prev) => {
      return {
        ...prev,
        start_date: start_date.format('YYYY/MM/DD'),
        end_date: end_date.format('YYYY/MM/DD'),
      };
    });
  }

  useEffect(() => {
    fetchLogos();
    fetchDataRevenue(rangeRevenue);
  }, [rangeRevenue]);

  return (
    <Layout className="pd-mobile-home">
      <DashboardContainer>
        <Row
          className="pd-margin-bottom-lg"
          type="flex"
          align="end"
          justify="end">
          <Col
            className="pd-add-icon pd-align-right"
            xs={3}
            onClick={handleOnClickAdd}>
            <img src={`img/logo/Group 35.svg`} height={50} width={60} />
          </Col>
          <Col
            className="pd-delete-trigger pd-align-right"
            xs={4}
            onClick={setShowDeleteIcon.bind(this, !showDeleteIcon)}>
            <Icon type="delete" theme="filled" />
          </Col>
        </Row>
        {/* Section that display icon */}
        <Row
          gutter={[36, 48]}
          type="flex"
          className="section"
          align="middle"
          justify="center">
          {logosList.map(({ alt = '', id = '', imgSrc = '', link = '/' }) => (
            <Col xs={5} key={id} className="icon-container">
              <Link to={link}>
                <img src={imgSrc} alt={alt} height={80} width={60} />
              </Link>
              {showDeleteIcon && (
                <Col className="pd-delete-icon pd-align-right">
                  <Icon
                    theme="twoTone"
                    twoToneColor="red"
                    type="minus-square"
                    onClick={handleDeleteIcon.bind(this, id)}
                  />
                </Col>
              )}
            </Col>
          ))}
        </Row>

        {/* Section that display switch button */}
        <Row type="flex" className="section" align="middle" justify="center">
          <Col xs={12} className="pd-align-center">
            <PdAntdSwitch
              checkedChildren="Hide Current Revenue"
              unCheckedChildren="Show Current Revenue"
              onChange={handleOnChangeSwitch}
            />
          </Col>
        </Row>

        {/* Section that display responsive-line */}
        {showCurrentRevenue && (
          <Row className="section" align="middle">
            <Col xs={24}>
              <Title level={3} className="section-title">
                CURRENT REVENUE
              </Title>
            </Col>
            <Col xs={14}>
              <RangePicker onChange={handleChangeRange} />
            </Col>
            <Col xs={{ span: 9, offset: 1 }}>
              <PdButton block onClick={handleDownloadChart}>
                Download Chart
              </PdButton>
            </Col>
            <Col xs={24} className="section-chart">
              <ResponsiveLine
                data={dataRevenue}
                margin={{ top: 50, right: 25, bottom: 50, left: 50 }}
                xScale={{ type: 'point' }}
                yScale={{
                  type: 'linear',
                  min: 'auto',
                  max: 'auto',
                  stacked: true,
                  reverse: false,
                }}
                axisBottom={{
                  orient: 'bottom',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Date',
                  legendOffset: 36,
                  legendPosition: 'middle',
                }}
                axisLeft={{
                  orient: 'left',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 5,
                  legend: '$',
                  legendOffset: -36,
                  legendPosition: 'middle',
                }}
                yFormat=" >-.2f"
                curve="cardinal"
                axisTop={null}
                axisRight={null}
                enableGridX={false}
                colors={'#74c476'}
                pointSize={10}
                pointColor="#74c476"
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                enableCrosshair={false}
                useMesh={true}
                legends={[]}
              />
            </Col>
          </Row>
        )}
        {showModal && (
          <PdModal
            modalTitle="Pick One to Add"
            onCancel={setShowModal.bind(this, false)}
            visible={showModal}>
            <PdModalContent />
          </PdModal>
        )}
      </DashboardContainer>
    </Layout>
  );
}

export default { Home };
