export const ADMIN_PERMISSION =
  localStorage.getItem('admin_permission') ?? ''.split(',');

export const FORM_TITLE_LIST = {
  create: 'Add New ',
  edit: 'Edit ',
};

export const FORM_BUTTON_LIST = {
  create: 'Submit',
  edit: 'Edit',
};

export const FORM_ADMIN_FIELD = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
  ROLE: 'role_id',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'password_confirmation',
  STATUS: 'status',
};

export const FORM_ADMIN_ROLE_LIST = [
  {
    value: '1',
    label: 'Super Admin',
  },
  {
    value: '2',
    label: 'Admin',
  },
];

export const FORM_USER_MANAGEMENT_STATUS_BUTTON = {
  active: 'Deactivate',
  inactive: 'Activate',
};

export const FORM_USER_MANAGEMENT_ACTION_BUTTON = {
  active: 'Edit',
  inactive: 'Delete',
};

export const FORM_ROLE_FIELD = {
  TITLE: 'title',
  PERMISSION: 'page_permission',
};

export const FORM_ROLE_FORMATTER = {
  calendar: 'Calendar',
  customer_balance: 'Customer Balance',
  customer_management: 'Customer Management',
  customers: 'Customers',
  invoice: 'Invoice',
  order_invoices: 'Order Invoices',
  proposal: 'Proposal',
  report: 'Report',
  roles: 'Roles',
  service: 'Service',
  services: 'Services',
  setting: 'Setting',
  user_management: 'User Management',
  wo_management: 'WO Management',
};

export const FORM_ROLE_CHECKBOX_LIST = [
  {
    title: 'Admins',
    content: 'admins',
  },
  {
    title: 'Roles',
    content: 'roles',
  },
  {
    title: 'Services',
    content: 'services',
  },
  {
    title: 'Customers',
    content: 'customers',
  },
  {
    title: 'Order Invoices',
    content: 'order_invoices',
  },
];

export const FORM_RECURRING_DAY_OPTIONS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const HOME_ICON_DATA_SOURCE = [
  {
    alt: 'customer-logo',
    id: 1,
    imgSrc: 'img/logo/customer.svg',
    link: '/customer-setup',
    permission: 'customer_management',
  },
  {
    alt: 'invoice-logo',
    id: 2,
    imgSrc: 'img/logo/invoice.svg',
    link: '/invoice',
    permission: 'invoice',
  },
  {
    alt: 'proposal-logo',
    id: 3,
    imgSrc: 'img/logo/proposal.svg',
    link: '/proposal',
    permission: 'proposal',
  },
  {
    alt: 'service-logo',
    id: 4,
    imgSrc: 'img/logo/service.svg',
    link: '/service',
    permission: 'service',
  },
  {
    alt: 'customer-balance-logo',
    id: 5,
    imgSrc: 'img/logo/customer_balance.svg',
    link: '/customer-balance',
    permission: 'customer_balance',
  },
  {
    alt: 'admins-logo',
    id: 6,
    imgSrc: 'img/logo/admin.svg',
    link: '/admin',
    permission: 'user_management',
  },
  {
    alt: 'work-orders-logo',
    id: 7,
    imgSrc: 'img/logo/work_order.svg',
    link: '/work-orders',
    permission: 'wo_management',
  },
  {
    alt: 'customer-report-logo',
    id: 8,
    imgSrc: 'img/logo/customer_report.svg',
    link: '/all-customer',
    permission: 'report',
  },
  {
    alt: 'calendar-logo',
    id: 9,
    imgSrc: 'img/logo/calendar.svg',
    link: '/calendar',
    permission: 'calendar',
  },
];

export const SIDEBAR_MENU_DATA_SOURCE = [
  {
    type: 'Menu',
    name: 'Dashboard',
    link: '/',
  },
  {
    type: 'Menu',
    name: 'Calendar',
    link: '/calendar',
    permission: 'calendar',
  },
  {
    type: 'Menu',
    name: 'Customer Setup',
    link: '/customer-setup',
    permission: 'customer_management',
  },
  {
    type: 'SubMenu',
    name: 'Orders Management',
    children: [
      {
        type: 'Menu',
        name: 'Work Orders',
        link: '/work-orders',
        permission: 'wo_management',
      },
      {
        type: 'Menu',
        name: 'Customer Balance',
        link: '/customer-balance',
        permission: 'customer_balance',
      },
      {
        type: 'Menu',
        name: 'Proposal',
        link: '/proposal',
        permission: 'proposal',
      },
    ],
  },
  {
    type: 'SubMenu',
    name: 'Reports',
    permission: 'report',
    children: [
      { type: 'Menu', name: 'All Customer', link: '/all-customer' },
      {
        type: 'Menu',
        name: 'All Customer Balance',
        link: '/all-customer-balance',
      },
      {
        type: 'Menu',
        name: 'Work Orders Information',
        link: '/work-orders-information',
      },
    ],
  },
  {
    type: 'Menu',
    name: 'Invoices',
    link: '/invoice',
    permission: 'invoice',
  },
  {
    type: 'Menu',
    name: 'Services',
    link: '/service',
    permission: 'service',
  },
  {
    type: 'SubMenu',
    name: 'User Management',
    permission: 'user_management',
    children: [
      { type: 'Menu', name: 'Admins', link: '/admin' },
      { type: 'Menu', name: 'Roles', link: '/role' },
    ],
  },
  {
    type: 'Menu',
    name: 'Terms and Policy',
    link: '/terms-and-policy',
  },
  {
    type: 'Menu',
    name: 'Settings',
    link: '/setting',
    permission: 'setting',
  },
];

export const DATE_FORMAT = 'MM/DD/YYYY';

export const ORDER_STATUS = [
  {
    key: 'all',
    text: 'All',
  },
  {
    key: 'on_progress',
    text: 'On Progress',
  },
  {
    key: 'completed',
    text: 'Completed',
  },
];

export const FORM_FIELD_SETTING = {
  BACKGROUND_COLOR: 'background_color',
  BUTTON_COLOR: 'button_color',
  COMPANY_NAME: 'company_name',
  COMPANY_EMAIL: 'email',
  INVOICE_EMAIL: 'invoice_email',
  PROPOSAL_EMAIL: 'proposal_email',
  WO_EMAIL: 'wo_email',
  FIRST_LINE_ADDRESS: 'address',
  LOGO: 'company_logo',
  SECOND_LINE_ADDRESS: 'second_line_address',
  TITLE_COLOR: 'title_color',
  PHONE_NUMBER: 'phone_number',
};

export const BASIC_PLAN_WARN_COPYWRITE = 'Upgrade your subscription plan';

export const UNLIMTED_PLAN_TERM = [
  'unlimited plan',
  'unlimited_plan',
  'unlimited',
];

export const PATH_PAGE = {
  '/': 'Dashboard',
  '/calendar': 'Calendar',
  '/customer-setup': 'Customer Setup',
  '/customer-setup/create': 'Create Customer',
  '/customer-setup/edit': 'Edit Customer',
  '/work-orders': 'Work Orders',
  '/work-orders/create': 'Create Work Orders',
  '/work-orders/detail': 'Detail Work Orders',
  '/customer-balance': 'Customer Balance',
  '/customer-balance/detail': 'Detail Customer Balance',
  '/proposal': 'Proposal',
  '/proposal/create': 'Create Proposal',
  '/proposal/edit': 'Edit Proposal',
  '/proposal/detail': 'Detail Proposal',
  '/all-customer': 'Report All Customer',
  '/all-customer/detail': 'Detail Customer',
  '/all-customer-balance': 'Report All Customer Balance',
  '/all-customer-balance/detail': 'Detail Customer Balance',
  '/work-orders-information': 'Report Work Orders',
  '/work-orders-information/detail': 'Detail Work Order',
  '/invoice': 'Invoice',
  '/invoice/detail': 'Detail Invoice',
  '/service': 'Service',
  '/service/create': 'Create Service',
  '/service/edit': 'Edit Service',
  '/admin': 'Admin',
  '/admin/create': 'Create Admin',
  '/admin/edit': 'Edit Admin',
  '/role': 'Role',
  '/role/create': 'Create Role',
  '/role/edit': 'Edit Role',
  '/terms-and-policy': 'Term and Policy',
  '/setting': 'Setting',
};

export const CUSTOMER_EMAIL_COPY_WRITE =
  'The proposal will be sent to this email address';
