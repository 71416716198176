import React, { useContext, useState } from 'react';
import { Icon, Form, Input, Layout, message, Typography, Divider } from 'antd';
import { get } from 'lodash';
import { Link } from 'react-router-dom';

import PdButton from 'components/pd-button';
import CompanyLogo from 'assets/images/logo.png';

import { AuthenticationContext } from 'contexts/authentication';

import './style.scss';

const { Content } = Layout;
const { Text } = Typography;

export function LoginPage({ form }) {
  const [loadingButton, seLoadingButton] = useState(false);
  const { getFieldDecorator } = form;

  const { login } = useContext(AuthenticationContext);

  async function handleLogin(payload) {
    try {
      seLoadingButton(true);
      await login({
        ...payload,
        email: payload.email.toLowerCase(),
      });
    } catch (err) {
      message.error(
        get(
          err,
          'response.data.message',
          "Can't connect to server, please check connection"
        )
      );
    } finally {
      seLoadingButton(false);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        handleLogin(values);
      }
    });
  }

  return (
    <Content className="pd-login">
      <img src={CompanyLogo} alt="logo" width="400" />
      <Form onSubmit={handleSubmit} className="login-form">
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input your email!' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </Form.Item>
        <Form.Item>
          <PdButton
            className="login-form-button"
            loading={loadingButton}
            htmlType="submit">
            Log in
          </PdButton>
        </Form.Item>
      </Form>
      <Link to="/forgot-password">Forgot Password</Link>
      <Divider className="pd-login-divider" />
      <Text>
        Not a subscriber?{' '}
        <Link
          to={{ pathname: 'https://www.greencutscrm.com/plans-pricing' }}
          target="_blank">
          See a plan now
        </Link>
      </Text>
    </Content>
  );
}

export const LoginPageForm = Form.create({ name: 'login' })(LoginPage);

export default { LoginPage };
