import React from 'react';
import { Tabs } from 'antd';

import './style.scss';

function PdTabs({ children, ...props }) {
  return (
    <Tabs className={'pd-tabs'} {...props}>
      {children}
    </Tabs>
  );
}

export default PdTabs;
