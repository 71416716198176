import React, { createContext, useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { get } from 'lodash';

import { PageSpinner } from 'components/page-spinner';

import SettingApi from 'api/settings';

import { ADMIN_PERMISSION, handleErr } from 'utils';

export const UserContext = createContext();

const initialState = {
  userSettings: {},
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_USER_SETTINGS':
      return {
        ...state,
        userSettings: action.settings,
      };
    default:
      return state;
  }
}

function UserContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(false);

  function setUserSettings(settings) {
    dispatch({
      type: 'SET_USER_SETTINGS',
      settings,
    });
  }

  async function fetchUserSettings() {
    setLoading(true);
    try {
      const { data } = await SettingApi.getSettingsValue();
      const settings = get(data, 'data', {});
      dispatch({
        type: 'SET_USER_SETTINGS',
        settings,
      });
    } catch (error) {
      handleErr(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (
      ADMIN_PERMISSION.includes('setting') &&
      localStorage.getItem('cms_token')
    ) {
      fetchUserSettings();
    }
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <UserContext.Provider
      value={{
        dispatch,
        fetchUserSettings,
        setUserSettings,
        state,
      }}>
      {children}
    </UserContext.Provider>
  );
}

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserContextProvider;
