import React, { useEffect, useState } from 'react';
import {
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  InputNumber,
  Layout,
  message,
  Row,
  Select,
  Modal,
} from 'antd';
import { get } from 'lodash';
import moment from 'moment';

import DashboardContainer from 'components/dashboard-container';
import PdAntdSwitch from 'components/pd-antd-switch';
import PdButton from 'components/pd-button';
import PdForm from 'components/pd-form';
import PdModal from 'components/pd-modal';
import PdTable, { PdTableBodyRow, PdTableHeader } from 'components/pd-table';

import { calculateAmount, capitalizeFirstLetter, handleErr } from 'utils';
import { priceFormatter, priceParser } from 'utils/inputNumberUtils';
import { numberDate, numberWithCommas } from 'utils/numberFormater';

import InvoiceAPI from 'api/invoice';
import ServiceAPI from 'api/service';

import './style.scss';

const { TextArea } = Input;
const { Option } = Select;
const { confirm } = Modal;

const quarterHorizontalLayout = {
  labelCol: { span: 3, offset: 16 },
  wrapperCol: { span: 4, offset: 1 },
};

export function WorkOrdersDetail({ history, location }) {
  const [discountEdit, setDiscountEdit] = useState(0);
  const [edit, setEdit] = useState(false);
  const [invoiceData, setInvoiceData] = useState({});
  const [notesFormField, setNotesFormField] = useState({});
  const [serviceList, setServiceList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [anyChange, setAnyChange] = useState(false);

  const {
    discount = 0,
    id: invoiceId = '',
    invoice_number = '-',
    order_services = [],
    payment_status = '',
    total_price = 0,
  } = invoiceData ?? {};
  const {
    billing_address = {},
    email = '-',
    fax_number = '-',
    last_invoice_date = '-',
    name = '-',
    phone_number = '-',
    regular_service = 0,
  } = invoiceData.customer ?? {};
  const {
    address = '-',
    city = '-',
    country = '-',
    postal_code = '-',
    state = {},
  } = billing_address || {};
  const { name: stateName = '-' } = state || {};

  const { id: locationID = '' } = location ?? {};

  const {
    address: property_address = '-',
    city: property_city = '-',
    country: property_country = '-',
    postal_code: property_postal_code = '-',
    state: property_state = {},
  } = invoiceData.address ?? {};

  const { name: propertyStateName = '-' } = property_state || {};

  const tableHeadersEdit = [
    { title: 'Service', width: 5 },
    { title: 'Quantity', width: 3 },
    { title: '$ per Unit', width: 3 },
    { title: 'Amount', width: 3 },
    { title: 'Meetings', width: 3 },
    { title: 'Service Date', width: 7 },
  ];

  const tableHeaders = [
    { title: 'Service', width: 5 },
    { title: 'Quantity', width: 3 },
    { title: '$ per Unit', width: 4 },
    { title: 'Amount', width: 4 },
    { title: 'Service Date', width: 5 },
    { title: 'Progress', width: 2 },
    { title: '', width: 1 },
  ];

  function handleChangeService(value, key) {
    !anyChange && setAnyChange(true);
    const { price } = serviceList.find(({ id }) => id === value);
    setInvoiceData((prev) => ({
      ...prev,
      order_services: prev.order_services
        .filter(({ isDestroy = false }) => !isDestroy)
        .map((order, idx) => {
          if (key === idx) {
            return {
              ...order,
              service_id: value,
              price,
            };
          }
          return order;
        }),
    }));
  }

  function handleChangeDiscount(e) {
    !anyChange && setAnyChange(true);
    setDiscountEdit(Number(get(e, 'target.value', 0)));
  }

  function handleChangeDate(orderServiceIdx, orderIdx, date) {
    !anyChange && setAnyChange(true);
    setInvoiceData((prev) => ({
      ...prev,
      order_services: prev.order_services.map((order, idx) => {
        if (orderServiceIdx === idx) {
          return {
            ...order,
            recurringOn: order.recurringOn.map((recurring, recurringIdx) => {
              if (recurringIdx === orderIdx) {
                return {
                  ...recurring,
                  service_date: date.format('YYYY/MM/DD'),
                };
              }
              return recurring;
            }),
          };
        }
        return order;
      }),
    }));
  }

  async function fetchInvoiceData(id) {
    try {
      const { data } = await InvoiceAPI.getInvoiceDetail(id);
      const { data: invoiceData } = data;
      const parsedData = parseOrderService(invoiceData);
      setInvoiceData({
        ...invoiceData,
        order_services: parsedData,
      });
    } catch (err) {
      message.error(get(err, 'response.message', 'Failed to get invoice data'));
    }
  }

  function parseOrderService(data) {
    const { order_services = [] } = data;
    order_services.sort((a, b) => a.id - b.id);
    let parsedOrderService = [];
    order_services.forEach((order) => {
      const {
        id,
        service_date,
        price,
        quantity,
        service,
        finish,
        info_description,
      } = order;
      const recurringOrder = {
        order_id: id,
        service_date,
        info_description,
        finish,
      };

      if (price === '0.0') {
        const parentIdx = parsedOrderService.findIndex(
          ({ service_id }) => service_id === service.id
        );
        const parentOrder = parsedOrderService[parentIdx];
        parsedOrderService[parentIdx] = {
          ...parentOrder,
          meetings: parentOrder.meetings + 1,
          recurringOn: [...parentOrder.recurringOn, recurringOrder],
        };
        return;
      }

      parsedOrderService = [
        ...parsedOrderService,
        {
          meetings: 1,
          price,
          quantity,
          service_id: service.id,
          id,
          service,
          recurringOn: [recurringOrder],
        },
      ];
    });

    return parsedOrderService;
  }

  async function fetchServiceList() {
    try {
      const { data } = await ServiceAPI.getServiceList({ q: '' });

      setServiceList(data?.data);
    } catch (err) {
      message.error(get(err, 'response.message', 'Failed to get service list'));
    }
  }

  function handleSearch(input, option) {
    const { children: text } = option?.props;
    return text.toLowerCase().includes(input.toLowerCase());
  }

  function handleBackInvoice() {
    if (edit && anyChange) {
      confirm({
        title: 'Are you sure?',
        content: 'Your changes will be lost',
        cancelButtonProps: { ghost: true },
        onOk: () => {
          history.goBack();
        },
      });
    } else {
      history.goBack();
    }
  }

  function handleChangeEdit() {
    setEdit(true);
    fetchServiceList();
  }

  function handleChangePrice(orderIdx, price) {
    !anyChange && setAnyChange(true);
    setInvoiceData((prev) => ({
      ...prev,
      order_services: order_services.map((order, idx) => {
        if (orderIdx === idx) {
          return {
            ...order,
            price,
          };
        }
        return order;
      }),
    }));
  }

  function handleChangeQuantity(orderIdx, quantity) {
    !anyChange && setAnyChange(true);
    setInvoiceData((prev) => ({
      ...prev,
      order_services: order_services.map((order, idx) => {
        if (orderIdx === idx) {
          return {
            ...order,
            quantity,
          };
        }
        return order;
      }),
    }));
  }

  function handleAddNewService() {
    setInvoiceData((prev) => ({
      ...prev,
      order_services: [...prev.order_services, { recurringOn: [{}] }],
    }));
  }

  function handleDeleteRow(idx) {
    setInvoiceData((prev) => ({
      ...prev,
      order_services: prev.order_services.map((order, order_idx) => {
        if (order_idx === idx) {
          return { ...order, isDestroy: true };
        }
        return order;
      }),
    }));
  }

  function handleChangeMeetings(orderIdx, value) {
    !anyChange && setAnyChange(true);
    setInvoiceData((prev) => ({
      ...prev,
      order_services: order_services.map((order, idx) => {
        if (orderIdx === idx) {
          let activeRecurringOrder = order.recurringOn.filter(
            ({ isDestroy = false }) => !isDestroy
          );
          const activeLength = activeRecurringOrder.length;
          if (activeLength < value) {
            activeRecurringOrder = Array.from({ length: value }, (_, i) => {
              const recurringOrder = activeRecurringOrder[i];
              if (!recurringOrder?.order_id) {
                return { service_date: moment().format('YYYY/MM/DD') };
              }
              return { ...recurringOrder, isDestroy: false };
            });
          } else if (activeLength > value) {
            activeRecurringOrder = Array.from(
              { length: activeLength },
              (_, i) => {
                const recurringOrder = activeRecurringOrder[i];
                if (i + 1 > value) {
                  return { ...recurringOrder, isDestroy: true };
                }

                return recurringOrder;
              }
            );
          }

          return {
            ...order,
            meetings: value,
            recurringOn: activeRecurringOrder,
          };
        }
        return order;
      }),
    }));
  }

  function handleTotalAmount() {
    let totalQty = order_services
      .filter(({ isDestroy }) => !isDestroy)
      .reduce(
        (acc, { price = 0, quantity = 0 }) => acc + Number(price) * quantity,
        0
      );

    const calc = totalQty - totalQty * (discountEdit / 100);
    setTotalAmount(calc);
  }

  function handleOpenNotes([rowId = '', notes = '']) {
    setShowModal(true);
    setNotesFormField((prev) => ({
      ...prev,
      id: rowId,
      info_description: notes,
    }));
  }

  function handleOnChangeNotes(e) {
    const value = get(e, ['target', 'value'], '');
    setNotesFormField((prev) => ({ ...prev, info_description: value }));
  }

  async function handleSubmitNotes() {
    try {
      const payload = {
        order: {
          order_services_attributes: [notesFormField],
        },
      };
      await InvoiceAPI.editInvoice(locationID, payload);
      setShowModal(false);
      fetchInvoiceData(locationID);
    } catch (error) {
      handleErr(error);
    }
  }

  async function handleOnChangeProgress(rowId, checked) {
    try {
      const order_services_attributes = { id: rowId, finish: checked };
      const payload = { order: { order_services_attributes } };
      await InvoiceAPI.updateServiceProgress(invoiceId, payload);
      fetchInvoiceData(locationID);
    } catch (error) {
      handleErr(error);
    }
  }

  async function handleSubmitForm(e) {
    e.preventDefault();

    const order_services_attributes = parseServicePayload(
      invoiceData.order_services
    );

    const payload = {
      discount: discountEdit,
      order_services_attributes,
    };

    try {
      const { data } = await InvoiceAPI.editInvoice(invoiceData.id, {
        order: payload,
      });
      const { data: udpatedData } = data;
      const parsedData = parseOrderService(udpatedData);
      setInvoiceData({
        ...udpatedData,
        order_services: parsedData,
      });

      setEdit(false);
    } catch (err) {
      message.error(err?.response?.message || 'Failed to submit edit');
    }
  }

  async function handleDeleteOrder() {
    confirm({
      title: 'Are you sure you want to delete this order?',
      content: "You can't undo this action",
      cancelButtonProps: { ghost: true },
      onOk: async () => {
        try {
          await InvoiceAPI.cancelInvoice(invoiceData.id);
          history.goBack();
        } catch (error) {
          handleErr(error);
        }
      },
    });
  }

  function parseServicePayload(payload) {
    return payload
      .map((order) => {
        const {
          service_id,
          quantity = 1,
          price,
          recurringOn,
          isDestroy = false,
        } = order;

        if (isDestroy) {
          return recurringOn
            .filter(({ order_id = null }) => order_id)
            .map(({ order_id: id }) => ({
              id,
              _destroy: true,
            }));
        }

        return recurringOn
          .filter(
            ({ order_id = null, isDestroy = false }) => order_id || !isDestroy
          )
          .map((recurring, idx) => {
            const {
              order_id: id = null,
              info_description = null,
              service_date = moment().format('YYYY/MM/DD'),
              isDestroy: recurringDestroy = false,
            } = recurring;
            if (id && recurringDestroy) {
              return {
                id,
                _destroy: true,
              };
            }

            const orderService = {
              service_date,
              service_id,
              quantity,
              price: idx === 0 ? price : 0,
              info_description,
            };

            if (id) {
              return { ...orderService, id };
            }

            return orderService;
          });
      })
      .flat();
  }

  function PdTableBodyRowService(props) {
    const { price = 0, quantity = 0, title = '', recurringOn = [] } = props;
    return (
      <PdTableBodyRow type="flex" align="start">
        <Col lg={5}>{title}</Col>
        <Col lg={3}>{quantity}</Col>
        <Col lg={4}>{`$ ${numberWithCommas(price)}`}</Col>
        <Col lg={4}>{`$ ${numberWithCommas(price * quantity)}`}</Col>
        <Col lg={8}>
          {recurringOn.map(
            ({ order_id, service_date, info_description, finish }, idx) => {
              return (
                <Row key={order_id} className="pd-margin-bottom-md">
                  <Col lg={15}>
                    Meet {idx + 1} - {numberDate(service_date)}
                  </Col>
                  <Col lg={6}>
                    <PdAntdSwitch
                      checked={finish}
                      onChange={handleOnChangeProgress.bind(this, order_id)}
                    />
                  </Col>
                  <Col lg={3}>
                    <Icon
                      theme="twoTone"
                      twoToneColor={info_description ? 'green' : 'red'}
                      type="profile"
                      onClick={handleOpenNotes.bind(this, [
                        order_id,
                        info_description,
                      ])}
                    />
                  </Col>
                </Row>
              );
            }
          )}
        </Col>
      </PdTableBodyRow>
    );
  }

  function renderService() {
    if (edit) {
      return (
        <PdTable className="detail-container">
          <PdTableHeader listHeader={edit ? tableHeadersEdit : tableHeaders} />
          <PdForm id="service-form" onSubmit={handleSubmitForm}>
            {order_services.map((order, idx) => {
              if (order.isDestroy) return;
              const { quantity, price, service_id, recurringOn, meetings } =
                order;

              return (
                <PdTableBodyRow
                  className="pd-table-body-work-orders"
                  key={`order-${idx}`}
                  type="flex"
                  align="start">
                  <Col lg={5}>
                    <Form.Item>
                      <Select
                        showSearch
                        placeholder="Service Name"
                        onSelect={(value) => handleChangeService(value, idx)}
                        filterOption={handleSearch}
                        defaultValue={service_id}>
                        {serviceList.map((service) => {
                          const { title = '', id = 1 } = service ?? {};
                          return (
                            <Option key={id} value={id}>
                              {title}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={3}>
                    <Form.Item>
                      <InputNumber
                        min={1}
                        placeholder={0}
                        value={quantity}
                        onChange={handleChangeQuantity.bind(this, idx)}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={3}>
                    <Form.Item>
                      <InputNumber
                        onChange={handleChangePrice.bind(this, idx)}
                        value={price ?? 0}
                        formatter={priceFormatter}
                        parser={priceParser}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={3}>
                    <Form.Item>
                      <InputNumber
                        disabled
                        value={calculateAmount(price, quantity)}
                        formatter={priceFormatter}
                        parser={priceParser}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={3}>
                    <Form.Item>
                      <InputNumber
                        min={1}
                        placeholder={1}
                        value={meetings}
                        onChange={handleChangeMeetings.bind(this, idx)}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6}>
                    {recurringOn
                      .filter(({ isDestroy = false }) => !isDestroy)
                      .map(
                        (
                          {
                            order_id = '',
                            service_date = moment().format('YYYY/MM/DD'),
                          },
                          orderIdx
                        ) => {
                          return (
                            <Form.Item
                              key={order_id}
                              label={`Meet - ${orderIdx + 1}`}
                              labelCol={{ span: 6 }}
                              wrapperCol={{ span: 18 }}
                              className="pd-margin-bottom-sm">
                              <DatePicker
                                className="w-full"
                                format="MM/DD/YYYY"
                                onChange={handleChangeDate.bind(
                                  this,
                                  idx,
                                  orderIdx
                                )}
                                value={moment(service_date)}
                              />
                            </Form.Item>
                          );
                        }
                      )}
                  </Col>
                  {order_services.filter(({ isDestroy = false }) => !isDestroy)
                    .length > 1 && (
                    <Col lg={1}>
                      <Form.Item>
                        <Icon
                          type="delete"
                          theme="twoTone"
                          twoToneColor="red"
                          onClick={handleDeleteRow.bind(this, idx)}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </PdTableBodyRow>
              );
            })}
            <Form.Item>
              <PdButton type="link" icon="plus" onClick={handleAddNewService}>
                Add More Service
              </PdButton>
            </Form.Item>

            <Form.Item label="Discount" {...quarterHorizontalLayout}>
              <Input
                onChange={handleChangeDiscount}
                suffix="%"
                value={discountEdit}
              />
            </Form.Item>

            <Form.Item label="Total Amount" {...quarterHorizontalLayout}>
              <Input prefix="$" value={totalAmount} disabled />
            </Form.Item>
          </PdForm>
        </PdTable>
      );
    }

    return (
      <>
        <PdTable className="detail-container">
          <PdTableHeader listHeader={tableHeaders} />
          {order_services.map((orderService) => {
            const {
              id = '-',
              price = 0,
              quantity = 0,
              service = {},
              recurringOn = [],
            } = orderService ?? {};
            const { title = '-' } = service ?? {};
            return (
              <PdTableBodyRowService
                recurringOn={recurringOn}
                key={id}
                price={price}
                quantity={quantity}
                title={title}
              />
            );
          })}
        </PdTable>

        <Row
          align="middle"
          justify="end"
          type="flex"
          className="detail-container">
          <Col className="detail-container-label" lg={3}>
            Discount
          </Col>
          <Col lg={{ span: 4, offset: 1 }}>{`${numberWithCommas(
            discount
          )} %`}</Col>
        </Row>

        <Row
          align="middle"
          justify="end"
          type="flex"
          className="detail-container">
          <Col className="detail-container-label" lg={3}>
            Total Amount
          </Col>
          <Col lg={{ span: 4, offset: 1 }}>{`$ ${numberWithCommas(
            total_price
          )}`}</Col>
        </Row>
      </>
    );
  }

  useEffect(() => {
    !locationID ? history.push('/work-orders') : fetchInvoiceData(locationID);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleTotalAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceData, discountEdit]);

  useEffect(() => {
    setDiscountEdit(invoiceData?.discount);
  }, [invoiceData]);

  return (
    <Layout className="pd-cms-invoice-detail">
      <DashboardContainer>
        <Row>
          <Col lg={19}>
            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" lg={5}>
                Work Order Number
              </Col>
              <Col lg={10}>: {invoice_number}</Col>
            </Row>
            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" lg={5}>
                Work Order Date
              </Col>
              <Col lg={10}>: {numberDate(last_invoice_date)}</Col>
            </Row>
            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" lg={5}>
                Customer Name
              </Col>
              <Col lg={10}>: {name}</Col>
            </Row>
            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" lg={5}>
                Billing Address
              </Col>
              <Col lg={10}>: {address}</Col>
            </Row>
            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" lg={5}>
                City
              </Col>
              <Col lg={3}>: {capitalizeFirstLetter(city)}</Col>
              <Col className="detail-container-label" lg={4}>
                State / Province
              </Col>
              <Col lg={4}>: {stateName}</Col>
              <Col lg={3} className="detail-container-label">
                Country
              </Col>
              <Col lg={4}>: {country}</Col>
            </Row>
            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" lg={5}>
                Postal Code
              </Col>
              <Col lg={10}>: {postal_code}</Col>
            </Row>
            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" lg={5}>
                Property Address
              </Col>
              <Col lg={10}>: {property_address}</Col>
            </Row>
            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" lg={5}>
                City
              </Col>
              <Col lg={3}>: {capitalizeFirstLetter(property_city)}</Col>
              <Col className="detail-container-label" lg={4}>
                State / Province
              </Col>
              <Col lg={4}>: {propertyStateName}</Col>
              <Col lg={3} className="detail-container-label">
                Country
              </Col>
              <Col lg={4}>: {property_country}</Col>
            </Row>
            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" lg={5}>
                Postal Code
              </Col>
              <Col lg={10}>: {property_postal_code}</Col>
            </Row>
            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" lg={5}>
                Email
              </Col>
              <Col lg={10}>: {email}</Col>
            </Row>
            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" lg={5}>
                Phone number
              </Col>
              <Col lg={10}>: {phone_number}</Col>
            </Row>
            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" lg={5}>
                Fax
              </Col>
              <Col lg={10}>: {fax_number}</Col>
            </Row>
            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" lg={5}>
                Regular Service Date
              </Col>
              <Col lg={10}>: $ {numberWithCommas(regular_service)}</Col>
            </Row>
            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" lg={5}>
                Payment Status
              </Col>
              <Col lg={10}>: {capitalizeFirstLetter(payment_status)}</Col>
            </Row>
          </Col>

          {/* Action Section */}
          <Col lg={5}>
            {!edit && (
              <Row gutter={[0, 16]}>
                <Col>
                  <PdButton
                    block
                    ghost
                    onClick={handleChangeEdit}>
                    Edit
                  </PdButton>
                </Col>
                <Col>
                  <PdButton
                    block
                    ghost
                    type="danger"
                    onClick={handleDeleteOrder}>
                    Delete
                  </PdButton>
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        {/* Table Section */}
        {renderService()}

        {/* Button Section */}
        <Row
          align="middle"
          justify="center"
          type="flex"
          className="detail-container-action"
          gutter={10}>
          <Col lg={6}>
            <PdButton block ghost type="primary" onClick={handleBackInvoice}>
              Back
            </PdButton>
          </Col>
          {edit && (
            <Col lg={6}>
              <PdButton
                block
                type="primary"
                htmlType="submit"
                form="service-form">
                Submit
              </PdButton>
            </Col>
          )}
        </Row>

        {/* Modal Section */}
        {showModal && (
          <PdModal
            modalTitle="Notes"
            visible={showModal}
            onCancel={setShowModal.bind(this, false)}>
            <Row
              align="middle"
              justify="center"
              type="flex"
              className="detail-container-action">
              <Col lg={24}>
                <TextArea
                  onChange={handleOnChangeNotes}
                  value={get(notesFormField, 'info_description', '')}
                />
              </Col>
              <Col lg={6} className="pd-margin-top-md">
                <PdButton block type="primary" onClick={handleSubmitNotes}>
                  Submit
                </PdButton>
              </Col>
            </Row>
          </PdModal>
        )}
      </DashboardContainer>
    </Layout>
  );
}

export default { WorkOrdersDetail };
