import api from './index';
import { omitBy } from 'lodash';
import { isNil } from 'utils';

export default {
  createAdmin(payload) {
    return api.post(`/admins`, payload);
  },
  listAdmin({ q }) {
    const params = {
      q,
      limit: 1000,
    };
    const url = '/admins';
    return api.get(url, { params: omitBy(params, isNil) });
  },
  updateAdmin(id, payload) {
    return api.put(`/admins/${id}`, payload);
  },
  changeAdminPassword(payload) {
    return api.post(`/admins/change_password`, payload);
  },
  activateAdmin(id) {
    return api.put(`/admins/${id}/reactivate`);
  },
  deactivateAdmin(id) {
    return api.delete(`/admins/${id}`);
  },
  deleteAdmin(id) {
    return api.delete(`/admins/${id}/delete`);
  },
  getPersonalAdmin() {
    return api.get('admins/me');
  },
  updatePersonalAdmin(payload) {
    return api.put('admins/me', payload);
  },
  resetPassword(payload) {
    return api.post('admins/reset_password', payload);
  },
};
