import React, { useEffect, useState } from 'react';
import { Menu, Layout, Typography, message } from 'antd';
import { Link } from 'react-router-dom';

import AuthenticationApi from 'api/authentication';
import SettingAPI from 'api/settings';

import { ADMIN_PERMISSION, SIDEBAR_MENU_DATA_SOURCE } from 'utils';

import './style.scss';

const { Sider } = Layout;
const { SubMenu } = Menu;
const { Title } = Typography;

const menuOptionsList = SIDEBAR_MENU_DATA_SOURCE.map((menu) => {
  const { children = [], name = '', permission = '' } = menu;
  if (name === 'Orders Management') {
    const filteredChildren = children.filter(
      ({ permission: childrenPermission = '' }) =>
        ADMIN_PERMISSION.includes(childrenPermission)
    );
    return !!filteredChildren.length && { ...menu, children: filteredChildren };
  }
  if (!permission || ADMIN_PERMISSION.includes(permission)) return menu;
}); // return unfiltered array (still has falsy element)

const menuOptions = menuOptionsList.filter((menu) => menu);

function renderMenu(opt, parent = '') {
  const { name = '', link = '', type = 'Menu', children = [] } = opt;

  if (type === 'Menu') {
    return (
      <Menu.Item key={`${parent}${name}`}>
        <Link to={link}>{name}</Link>
      </Menu.Item>
    );
  } else {
    return (
      <SubMenu key={name} title={<span>{name}</span>}>
        {children.map((opt) => renderMenu(opt, `${name}-`))}
      </SubMenu>
    );
  }
}

export function Sidebar() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [openMenu, setOpenMenu] = useState([]);
  const [setting, setSetting] = useState({
    company_name: 'Lopez Gardening',
  });
  const [subMenuKeys, setSubMenuKeys] = useState([]);

  async function handleLogout() {
    const cms_token = localStorage.getItem('cms_token');

    if (cms_token) {
      AuthenticationApi.logout(cms_token);
      localStorage.removeItem('cms_token');
      localStorage.removeItem('current_admin');
      localStorage.removeItem('admin_name');
      localStorage.removeItem('admin_icon_setting');
      localStorage.removeItem('admin_permission');
    }
  }

  async function getSetting() {
    try {
      const { data } = await SettingAPI.getSettingsValue();
      const { company_name } = data?.data || {};
      setSetting({ company_name });
    } catch (err) {
      message.error(err?.response?.message || 'Failed to get setting value');
    }
  }

  function handleOnCollapse(collapsed) {
    setIsCollapsed(collapsed);
  }

  function handleOnOpenChange(openKeys) {
    const temp = [...openKeys];
    const lastKey = temp.pop();
    const clickedSubMenu = subMenuKeys.filter((key) => lastKey === key);
    setOpenMenu(clickedSubMenu);
  }

  useEffect(() => {
    menuOptions.map(({ type = '', name = '' }) => {
      if (type === 'SubMenu') setSubMenuKeys((prev) => [...prev, name]);
    });

    if (ADMIN_PERMISSION.includes('setting')) {
      getSetting();
    }
  }, []);

  return (
    <Sider
      collapsed={isCollapsed}
      collapsedWidth={0}
      collapsible
      onCollapse={handleOnCollapse}
      width={300}
      className={!isCollapsed && 'pd-cms-sidebar'}>
      <div className="menu">
        <Title level={4} className="pd-margin-top-lg">
          {setting.company_name}
        </Title>
        <div className="wrapper">
          <Menu
            mode="inline"
            openKeys={openMenu}
            onOpenChange={handleOnOpenChange}>
            {menuOptions.map((opt) => renderMenu(opt))}
          </Menu>
        </div>
      </div>
      <div className="bottom-menu">
        <Title level={4} onClick={handleLogout}>
          <Link to={'/login'}>Logout</Link>
        </Title>
      </div>
    </Sider>
  );
}

export default { Sidebar };
