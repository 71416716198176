import React, { useEffect, useState } from 'react';
import {
  Col,
  Form,
  Icon,
  Input,
  InputNumber,
  Layout,
  message,
  Row,
  Select,
  Typography,
} from 'antd';
import { get, has, set } from 'lodash';

import CustomerService from 'api/customer';
import StateService from 'api/state';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdForm from 'components/pd-form';

import { priceFormatter, priceParser } from 'utils/inputNumberUtils';

import './style.scss';

const { Title } = Typography;

const FORM_FIELD = {
  ID: 'id',
  NAME: 'name',
  EMAIL: 'email',
  PHONE: 'phone_number',
  FAX: 'fax_number',
  REGULAR_SERVICE: 'regular_service',
  STATE_CODE: 'state_code',
  ADDRESS: 'address',
  CITY: 'city',
  POSTAL_CODE: 'postal_code',
};

const { Option } = Select;

function ReportCustomerEdit({ form, history, location }) {
  const [customerData, setCustomerData] = useState({});
  const [billingAddress, setBillingAddress] = useState({});
  const [listAddress, setListAddress] = useState({ 0: {} });
  const [listState, setListState] = useState([]);

  const { getFieldDecorator } = form;

  const horizontalLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  const halfHorizontalLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  async function fetchStatesData() {
    const { data } = await StateService.getStates();
    setListState(get(data, 'data', []));
  }

  async function fetchCustomerData(id) {
    try {
      const { data } = await CustomerService.getCustomerDetail(id);
      setCustomerData(get(data, 'data', {}));
      setBillingAddress(get(data, 'data.billing_address', {}));

      // convert property addresses
      const propertyAddresses = get(data, 'data.property_addresses', []);
      propertyAddresses.forEach((address, idx) => {
        setListAddress((prevState) => ({
          ...prevState,
          [idx]: address,
        }));
      });
    } catch (error) {
      message.error(
        get(error, 'response.message', 'Failed to get customer data')
      );
    }
  }

  function handleAddNewAddress() {
    setListAddress((prevState) => ({
      ...prevState,
      [Object.keys(listAddress).length]: {},
    }));
  }

  function handleCancel() {
    history.push('/all-customer');
  }

  function handleChangeAddress({ idx, key, value }) {
    let selectedAddress = listAddress[idx];
    selectedAddress[key] = value;

    setListAddress((prevState) => ({
      ...prevState,
      [idx]: selectedAddress,
    }));
  }

  function handleChangeBillingAddress({ key, value }) {
    setBillingAddress((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }

  function handleChangeBillingState(optionObject) {
    const selectedAddress = JSON.parse(optionObject.key);
    setBillingAddress((prevState) => ({
      ...prevState,
      ['state']: selectedAddress,
    }));
  }

  function handleChangeState(idx, optionObject) {
    const selectedAddress = JSON.parse(optionObject.key);
    const addressObject = listAddress[idx];
    set(addressObject, 'state', selectedAddress);

    setListAddress((prevState) => ({
      ...prevState,
      [idx]: addressObject,
    }));
  }

  function handleShowRecurringModal() {
    // TODO: Implement Recurring Modal
  }

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        const listPropertyAddress = [];
        let updatedListAddress = listAddress;
        Object.keys(updatedListAddress).forEach((key) => {
          set(
            updatedListAddress[key],
            'state_code',
            get(updatedListAddress[key], 'state.code', '')
          );
          listPropertyAddress.push(updatedListAddress[key]);
        });

        const payload = {
          name: get(values, 'name', ''),
          email: get(values, 'email', ''),
          phone_number: get(values, 'phone_number', ''),
          fax_number: get(values, 'fax_number', ''),
          regular_service: get(values, 'regular_service', 0),
          billing_address_attributes: billingAddress,
          property_addresses_attributes: listPropertyAddress,
        };

        try {
          await CustomerService.updateCustomer({
            id: get(location, 'data.id', ''),
            customer: payload,
          });
          message.success('Customer has been updated');
          history.push({ pathname: '/all-customer' });
        } catch (error) {
          message.error(
            get(error, 'response.message', 'Failed to Submit Form Data')
          );
        }
      }
    });
  }

  useEffect(() => {
    if (!has(location, 'data.id')) {
      history.push({ pathname: '/all-customer' });
    }
    fetchStatesData();
    fetchCustomerData(get(location, 'data.id', ''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout className="pd-mobile-form-customer">
      <DashboardContainer>
        <Col xs={24}>
          <Title
            className="pd-align-center pd-margin-top-xs pd-margin-bottom-md"
            level={3}>
            Edit Customer
          </Title>
        </Col>
        <PdForm onSubmit={handleSubmit}>
          <Form.Item label="Customer ID" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.ID, {
              initialValue: get(customerData, 'id', ''),
            })(<Input placeholder="Autofill" disabled />)}
          </Form.Item>
          <Form.Item label="Customer Name" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.NAME, {
              initialValue: get(customerData, 'name', ''),
              rules: [
                {
                  required: true,
                  message: 'Please input Customer Name!',
                },
              ],
            })(<Input placeholder="Enter Customer Name" />)}
          </Form.Item>

          <Form.Item label="Billing Address" {...horizontalLayout}>
            <Input
              value={get(billingAddress, 'address', '')}
              placeholder="Enter Customer Billing Address"
              onChange={(e) =>
                handleChangeBillingAddress({
                  key: FORM_FIELD.ADDRESS,
                  value: get(e, 'target.value', ''),
                })
              }
            />
          </Form.Item>
          <Form.Item label="City" {...horizontalLayout}>
            {/** ?Need check value input should using FORM_FIELD.CITY or 'city' */}
            <Input
              value={get(billingAddress, 'city', '')}
              placeholder="Enter Customer City"
              onChange={(e) =>
                handleChangeBillingAddress({
                  key: FORM_FIELD.CITY,
                  value: get(e, 'target.value', ''),
                })
              }
            />
          </Form.Item>
          <Row>
            <Col xs={12}>
              <Form.Item label="State / Province" {...halfHorizontalLayout}>
                <Select
                  value={get(billingAddress, 'state.name', '')}
                  showSearch
                  placeholder="Choose State / Province"
                  onSelect={(val, optionObject) =>
                    handleChangeBillingState(optionObject)
                  }>
                  {listState?.map((state) => (
                    <Option key={JSON.stringify(state)} value={state.name}>
                      {get(state, 'name', '')}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 11, offset: 1 }}>
              <Form.Item label="Country" {...halfHorizontalLayout}>
                <Input
                  value={get(billingAddress, 'country', '')}
                  placeholder="Autofill"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label="Postal Code" {...halfHorizontalLayout}>
                <Input
                  value={get(billingAddress, 'postal_code', '')}
                  placeholder="Enter Postal Code"
                  onChange={(e) =>
                    handleChangeBillingAddress({
                      key: FORM_FIELD.POSTAL_CODE,
                      value: get(e, 'target.value', ''),
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          {/** PROPERTY ADDRESS SECTION */}

          {Object.keys(listAddress).map((key, idx) => (
            <React.Fragment key={idx}>
              <Form.Item label="Property Address" {...horizontalLayout}>
                <Input
                  value={get(listAddress[key], FORM_FIELD.ADDRESS, '')}
                  placeholder="Enter Customer Property Address"
                  onChange={(e) =>
                    handleChangeAddress({
                      idx,
                      key: FORM_FIELD.ADDRESS,
                      value: get(e, 'target.value', ''),
                    })
                  }
                />
              </Form.Item>

              <Form.Item label="City" {...horizontalLayout}>
                <Input
                  value={get(listAddress[key], FORM_FIELD.CITY, '')}
                  placeholder="Enter Property City"
                  onChange={(e) =>
                    handleChangeAddress({
                      idx,
                      key: FORM_FIELD.CITY,
                      value: get(e, 'target.value', ''),
                    })
                  }
                />
              </Form.Item>

              <Row>
                <Col xs={12}>
                  <Form.Item label="State / Province" {...halfHorizontalLayout}>
                    <Select
                      value={get(listAddress[key], 'state.name', '')}
                      showSearch
                      placeholder="Choose State / Province"
                      onSelect={(value, optionObject) =>
                        handleChangeState(idx, optionObject)
                      }>
                      {listState?.map((state) => (
                        <Option key={JSON.stringify(state)} value={state.name}>
                          {get(state, 'name', '')}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 11, offset: 1 }} onClick={handleAddNewAddress}>
                  <Form.Item label="Country" {...halfHorizontalLayout}>
                    <Input
                      placeholder="Autofill"
                      disabled
                      value={get(listAddress[key], 'state.country', '')}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item label="Postal Code" {...halfHorizontalLayout}>
                    <Input
                      value={get(listAddress[key], 'postal_code', '')}
                      placeholder="Enter Postal Code"
                      onChange={(e) =>
                        handleChangeAddress({
                          idx,
                          key: FORM_FIELD.POSTAL_CODE,
                          value: get(e, 'target.value', ''),
                        })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </React.Fragment>
          ))}

          {/** PROPERTY ADDRESS SECTION/ */}

          <Form.Item className="add-property-container">
            <Row>
              <Col xs={{ span: 24 }} onClick={handleAddNewAddress}>
                <Icon className="pd-margin-right-sm" type="plus-circle" />
                Add Another Property
              </Col>
            </Row>
          </Form.Item>

          <Form.Item label="Phone Number" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.PHONE, {
              initialValue: get(customerData, 'phone_number', ''),
              rules: [
                {
                  required: true,
                  message: 'Please input Customer Phone!',
                },
              ],
            })(<Input placeholder="Enter Phone Number" />)}
          </Form.Item>
          <Form.Item label="Fax" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.FAX, {
              initialValue: get(customerData, 'fax_number', ''),
            })(<Input placeholder="Enter Fax" />)}
          </Form.Item>
          <Form.Item label="Email Address" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.EMAIL, {
              initialValue: get(customerData, 'email', ''),
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid Email Address!',
                },
                {
                  required: true,
                  message: 'Please input Customer Email Address!',
                },
              ],
            })(<Input placeholder="Enter Customer Email Address" />)}
          </Form.Item>
          <Form.Item label="Regular Service" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.REGULAR_SERVICE, {
              initialValue: get(customerData, 'regular_service', 0),
              rules: [
                {
                  required: true,
                  message: 'Please input Customer Regular Service!',
                },
              ],
            })(<InputNumber formatter={priceFormatter} parser={priceParser} />)}
          </Form.Item>

          <Row
            type="flex"
            justify="center"
            align="middle"
            className="recurring-container">
            <Col lg={22}>
              <Col type="flex" justify="center" align="middle">
                <Row lg={19}>
                  <Title level={4}>Recurring Services</Title>
                </Row>
                <Row lg={5}>
                  <PdButton block onClick={handleShowRecurringModal}>
                    Add Recurring Service
                  </PdButton>
                </Row>
              </Col>
            </Col>
          </Row>

          <Form.Item className="btn-container">
            <Row justify="center" type="flex">
              <Col xs={8}>
                <PdButton block onClick={handleCancel}>
                  Cancel
                </PdButton>
              </Col>
              <Col xs={{ span: 8, offset: 2 }}>
                <PdButton block onClick={handleSubmit}>
                  Edit
                </PdButton>
              </Col>
            </Row>
          </Form.Item>
        </PdForm>
      </DashboardContainer>
    </Layout>
  );
}

export const ReportCustomerPageForm = Form.create({
  name: 'report-customer-edit',
})(ReportCustomerEdit);
