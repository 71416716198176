import React, { useRef, useState } from 'react';
import { identity } from 'lodash';
import { CSVLink } from 'react-csv';

import PdButton from 'components/pd-button';

import { handleErr } from 'utils';

export function PdDownloadCSV({ fetchData = identity, filename = '' }) {
  const [report, setReport] = useState('');
  const csvElement = useRef(null);

  async function handleDownloadCSV() {
    try {
      const { data } = await fetchData();

      setReport(data?.data);
      csvElement.current.link.click();
    } catch (err) {
      handleErr(err);
    }
  }

  return (
    <>
      <PdButton block onClick={handleDownloadCSV}>
        Download .csv
      </PdButton>
      <CSVLink
        data={report}
        filename={filename}
        target="_blank"
        ref={csvElement}
      />
    </>
  );
}
