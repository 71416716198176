import React from 'react';
import { Modal, Typography } from 'antd';

import './style.scss';

const { Title } = Typography;

function PdModal({
  modalTitle = '',
  modalClassName = 'pd-modal',
  children,
  ...props
}) {
  return (
    <Modal className={modalClassName} footer={null} {...props}>
      {modalTitle !== '' && (
        <Title level={2} className={`${modalClassName}-title`}>
          {modalTitle}
        </Title>
      )}
      {children}
    </Modal>
  );
}

export default PdModal;
