import React, { useEffect, useState } from 'react';
import { Col, Input, Layout, Row, message } from 'antd';
import { get } from 'lodash';

import { PageSpinner } from 'components/page-spinner';
import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdAntdTable from 'components/pd-antd-table';

import { numberWithCommas } from 'utils/numberFormater';

import CustomerAPI from 'api/customer';

import './style.scss';

const { Search } = Input;

const itemPerPage = 10;

export function CustomerBalance({ history }) {
  const [cursor, setCursor] = useState(1);
  const [customerList, setCustomerList] = useState([]);
  const [customerCount, setCustomerCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const columns = [
    {
      title: 'Customer',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Outstanding Balance',
      dataIndex: 'current_balance',
      key: 'current_balance',
      render: ColumnAmount,
    },
    {
      title: '',
      key: 'id',
      render: ColumnAction,
      width: '15%',
    },
  ];

  function ColumnAmount(text) {
    return `$ ${numberWithCommas(text)}`;
  }

  async function getCustomerData({ q = '', cursor = 1, limit = 100 }) {
    setIsLoading(true);
    try {
      const { data: response } = await CustomerAPI.getCustomerList({
        q,
        cursor,
        limit,
      });
      const { data = [], metadata = {} } = response ?? {};

      setCustomerList(data);
      setCustomerCount(metadata?.total_items);
    } catch (err) {
      message.error(
        get(err, 'response.message', 'Failed to get customer data')
      );
    } finally {
      setIsLoading(false);
    }
  }

  function ColumnAction(rowData = {}) {
    return (
      <Row>
        <Col lg={24}>
          <PdButton
            block
            type="primary"
            onClick={handleBalanceDetail.bind(
              this,
              get(rowData, 'id', undefined),
              get(rowData, 'name', '')
            )}>
            Detail
          </PdButton>
        </Col>
      </Row>
    );
  }

  function handleBalanceDetail(id = undefined, name = '') {
    history.push({
      pathname: '/customer-balance/detail',
      id,
      name,
    });
  }

  function handleSearchCustomerName(e) {
    setSearchQuery(get(e, 'target.value', ''));
  }

  useEffect(() => {
    getCustomerData({ q: searchQuery, cursor, limit: itemPerPage });
    // eslint-disable-next-line
  }, [searchQuery, cursor]);

  return (
    <Layout className="pd-cms-customer-balance">
      {/* Section that display chart */}
      <DashboardContainer>
        <Row align="middle" type="flex">
          <Col lg={10}>
            <Search
              placeholder="Search by Customer Name"
              onChange={handleSearchCustomerName}
            />
          </Col>
        </Row>
      </DashboardContainer>
      <DashboardContainer>
        {/* Section that display chart */}
        {isLoading && <PageSpinner />}
        <Row align="middle">
          <Col lg={24}>
            <PdAntdTable
              dataSource={customerList}
              rowKeys="id"
              columns={columns}
              pagination={{
                onChange: (page) => setCursor(page),
                pageSize: itemPerPage,
                total: customerCount,
              }}
            />
          </Col>
        </Row>
      </DashboardContainer>
    </Layout>
  );
}

export default { CustomerBalance };
