import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import {
  Cascader,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Typography,
  Modal,
} from 'antd';
import { get } from 'lodash';

import AdminApi from 'api/admin';
import RoleApi from 'api/role.js';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdForm from 'components/pd-form';

import {
  handleErr,
  FORM_ADMIN_FIELD,
  FORM_BUTTON_LIST,
  FORM_TITLE_LIST,
} from 'utils';

import './style.scss';

const { Title } = Typography;
const { confirm } = Modal;

function AdminCreateEdit({ form, history, location }) {
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [roles, setRoles] = useState([]);
  const [anyChange, setAnyChange] = useState(false);

  async function fetchRoles() {
    try {
      const { data } = await RoleApi.listRole({});
      const temp = get(data, 'data', []);
      const roles = temp.map(({ id = '', title = '' }) => ({
        value: id,
        label: title,
      }));
      setRoles(roles);
    } catch (err) {
      handleErr(err);
    }
  }

  useEffect(() => {
    fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { page } = useParams();
  const { getFieldDecorator, validateFields } = form;
  const detailAdmin = location.state || {};

  function handleSubmit(e) {
    e.preventDefault();
    validateFields(async (err, values) => {
      try {
        if (!err) {
          const payload = {
            admin: {
              ...values,
              // change array value to string, because by default => cascader value is array
              role_id: values.role_id[0],
              email: values.email.toLowerCase(),
            },
          };
          switch (page) {
            case 'create':
              await AdminApi.createAdmin(payload);
              break;
            case 'edit':
              await AdminApi.updateAdmin(get(detailAdmin, 'id', ''), payload);
              break;
          }
          history.push('/admin');
        }
      } catch (error) {
        handleErr(error);
      }
    });
  }

  function handleConfirmBlur(e) {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  }

  function validateToConfirmPassword(rule, value, callback) {
    if (value && confirmDirty) {
      form.validateFields([FORM_ADMIN_FIELD.CONFIRM_PASSWORD], { force: true });
    }
    callback();
  }

  function validateToPassword(rule, value, callback) {
    if (value && value !== form.getFieldValue(FORM_ADMIN_FIELD.PASSWORD)) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  }

  function handleCancel() {
    if (anyChange) {
      confirm({
        title: 'Are you sure?',
        content: 'Your changes will be lost',
        cancelButtonProps: { ghost: true },
        onOk: () => {
          history.goBack();
        },
      });
    } else {
      history.goBack();
    }
  }

  return (
    <Layout className="pd-mobile-form-admin">
      <DashboardContainer>
        <Col xs={24}>
          <Title className="pd-align-center pd-margin-top-xs" level={3}>
            {FORM_TITLE_LIST[page] + 'Admin'}
          </Title>
        </Col>
        <PdForm
          onSubmit={handleSubmit}
          onChange={() => !anyChange && setAnyChange(true)}>
          <Form.Item label="First Name">
            {getFieldDecorator(FORM_ADMIN_FIELD.FIRST_NAME, {
              rules: [
                {
                  required: true,
                  message: 'Please input your First Name!',
                },
              ],
              initialValue: get(detailAdmin, 'first_name', ''),
            })(<Input placeholder="Enter First Name" />)}
          </Form.Item>
          <Form.Item label="Last Name">
            {getFieldDecorator(FORM_ADMIN_FIELD.LAST_NAME, {
              rules: [
                {
                  required: true,
                  message: 'Please input your Last Name!',
                },
              ],
              initialValue: get(detailAdmin, 'last_name', ''),
            })(<Input placeholder="Enter Last Name" />)}
          </Form.Item>
          <Form.Item label="E-mail">
            {getFieldDecorator(FORM_ADMIN_FIELD.EMAIL, {
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ],
              initialValue: get(detailAdmin, 'email', ''),
            })(<Input placeholder="Enter First Name" />)}
          </Form.Item>
          <Form.Item label="Role">
            {getFieldDecorator(FORM_ADMIN_FIELD.ROLE, {
              rules: [
                {
                  required: true,
                  message: 'Please input your Role!',
                },
              ],
              initialValue: [detailAdmin?.role?.id || ''], // must be in array with string format to display the cascade label
            })(<Cascader options={roles} placeholder="Select Role" />)}
          </Form.Item>

          <Form.Item label="Password" hasFeedback>
            {getFieldDecorator(FORM_ADMIN_FIELD.PASSWORD, {
              rules: [
                {
                  validator: validateToConfirmPassword,
                },
              ],
            })(<Input.Password placeholder="Enter Password" />)}
          </Form.Item>
          <Form.Item label="Confirm Password" hasFeedback>
            {getFieldDecorator(FORM_ADMIN_FIELD.CONFIRM_PASSWORD, {
              rules: [
                {
                  validator: validateToPassword,
                },
              ],
            })(
              <Input.Password
                placeholder="Enter Confirm Password"
                onBlur={handleConfirmBlur}
              />
            )}
          </Form.Item>
          <Row justify="center" type="flex" className="pd-margin-top-lg">
            <Col xs={8} className="cancel-btn-container">
              <PdButton block ghost onClick={handleCancel}>
                Cancel
              </PdButton>
            </Col>
            <Col xs={{ span: 8, offset: 2 }}>
              <PdButton block onClick={handleSubmit}>
                {FORM_BUTTON_LIST[page]}
              </PdButton>
            </Col>
          </Row>
        </PdForm>
      </DashboardContainer>
    </Layout>
  );
}

export const AdminPageForm = Form.create({ name: 'admin' })(AdminCreateEdit);
