import React, { useState, useEffect, useContext } from 'react';
import { Col, Icon, Layout, Row, Typography, Modal, message } from 'antd';
import Search from 'antd/lib/input/Search';
import { get } from 'lodash';
import queryString from 'querystring';

import AdminApi from 'api/admin';

import { PageSpinner } from 'components/page-spinner';
import PdAntdSwitch from 'components/pd-antd-switch';
import PdMobileTable from 'components/pd-mobile-table';
import { AuthenticationContext } from 'contexts/authentication';

import PdButton from 'components/pd-button';

import { handleErr } from 'utils';

import './style.scss';

const { Title } = Typography;
const { confirm } = Modal;

export function Admin({ history, location }) {
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cursor, setCursor] = useState(1);

  const { authenticatedUserProfile } = useContext(AuthenticationContext);
  const { isUnlimitedPlan = false } = authenticatedUserProfile;

  const parsed = queryString.parse(location.search);

  function handleAddAdmin() {
    // write code here
    history.push({
      pathname: '/admin/create',
    });
  }

  function handleEditAdmin(detailData) {
    // condition to revert back status to string from switch component
    if (detailData.status.props.checked) {
      detailData.status = 'active';
    } else {
      detailData.status = 'inactive';
    }
    history.push('admin/edit', detailData);
  }

  function handleDeleteAdmin(id) {
    confirm({
      title: 'Are you sure you want to delete this admin?',
      content: "You can't undo this action",
      cancelButtonProps: { ghost: true },
      onOk: async () => {
        try {
          await AdminApi.deleteAdmin(id);

          message.success('Successfully delete an admin');
          getListAdmin();
        } catch (err) {
          handleErr(err);
        }
      },
    });
  }

  function handleSearch(q) {
    history.push({
      pathname: '/admin',
      search: queryString.stringify(
        q && {
          q,
        }
      ),
    });
  }

  async function getListAdmin() {
    setIsLoading(true);
    try {
      const payload = {
        q: parsed['?q'],
      };
      const { data } = await AdminApi.listAdmin(payload);
      const adminList = get(data, 'data', '');
      // map for change status to switch button
      adminList.map((admin) => {
        const { status, id } = admin;
        admin.status = renderStatusSwitch(id, status);
      });
      setDataSource(adminList);
    } catch (error) {
      handleErr(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleOnChangeSwitch([id, status]) {
    setIsLoading(true);
    try {
      switch (status) {
        case 'active':
          await AdminApi.deactivateAdmin(id);
          break;
        case 'inactive':
          await AdminApi.activateAdmin(id);
          break;
        default:
          break;
      }
      history.push({
        pathname: '/admin',
      });
    } catch (error) {
      handleErr(error);
    } finally {
      setIsLoading(false);
    }
  }

  const renderStatusSwitch = (id, status) => {
    const isChecked = {
      active: true,
      inactive: false,
    };
    return (
      <PdAntdSwitch
        checked={isChecked[status]}
        onChange={handleOnChangeSwitch.bind(this, [id, status])}
      />
    );
  };

  const ColumnRole = (text) => {
    return <div className="text-role">{text}</div>;
  };

  const ColumnAction = (rowData = {}) => {
    const { status, id } = rowData;

    return (
      <Row>
        <Col xs={{ span: 24, offset: 2 }} lg={0}>
          {status.props.checked ? (
            <Icon
              onClick={handleEditAdmin.bind(this, rowData)}
              theme="twoTone"
              twoToneColor="gray"
              type="edit"
            />
          ) : (
            <Icon
              onClick={handleDeleteAdmin.bind(this, id)}
              theme="twoTone"
              twoToneColor="red"
              type="delete"
            />
          )}
        </Col>
      </Row>
    );
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'first_name',
      key: 'first_name',
      align: 'center',
      width: '25%',
      sorter: (a, b) => (a.name > b.name ? -1 : 1),
    },
    {
      title: 'Role',
      dataIndex: 'role.title',
      key: 'role.title',
      render: ColumnRole,
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: ColumnRole,
      align: 'center',
    },
    {
      title: '',
      key: 'id',
      render: ColumnAction,
      width: '15%',
      align: 'center',
    },
  ];

  useEffect(() => {
    getListAdmin();
    // eslint-disable-next-line
  }, [location.key]);

  return (
    <Layout className="pd-mobile-admin">
      {/* Section that display chart */}
      <Row xs={24} justify="center" type="flex">
        <Col className="pd-margin-top-md pd-margin-bottom-sm" xs={24}>
          <Title className="pd-align-center" level={3}>
            Admins
          </Title>
        </Col>
        <Col xs={13}>
          <Search placeholder="Search Name" onSearch={handleSearch} />
        </Col>
        <Col xs={{ span: 8, offset: 1 }}>
          <PdButton block onClick={handleAddAdmin} disabled={!isUnlimitedPlan}>
            Add New
          </PdButton>
        </Col>
        {/* Section that display chart */}
        <Col xs={24} className="pd-margin-top-lg">
          {isLoading ? (
            <PageSpinner />
          ) : (
            <PdMobileTable
              dataSource={dataSource}
              columns={columns}
              pagination={{ current: cursor, onChange: setCursor, pageSize: 5 }}
            />
          )}
        </Col>
      </Row>
    </Layout>
  );
}

export default { Admin };
