import React, { useEffect, useState } from 'react';
import { Col, Icon, Input, message, Row } from 'antd';
import { get, identity } from 'lodash';

import PdAntdSwitch from 'components/pd-antd-switch';
import PdButton from 'components/pd-button';
import PdModal from 'components/pd-modal';
import PdTable, { PdTableBodyRow, PdTableHeader } from 'components/pd-table';

import { capitalizeFirstLetter, handleErr } from 'utils';
import { numberDate } from 'utils/numberFormater';

import InvoiceAPI from 'api/invoice';

import './style.scss';

const { TextArea } = Input;

export function CalendarWorkOrderDetail({
  onClickBack = identity,
  orderId = '',
}) {
  const [invoiceData, setInvoiceData] = useState({});
  const [notesFormField, setNotesFormField] = useState({});
  const [showModal, setShowModal] = useState(false);

  const {
    id: invoiceId = '',
    invoice_number = '-',
    order_services = [],
  } = invoiceData ?? {};
  const {
    billing_address = {},
    email = '-',
    fax_number = '-',
    last_invoice_date = '-',
    name = '-',
    phone_number = '-',
  } = invoiceData.customer ?? {};
  const {
    address = '-',
    city = '-',
    country = '-',
    postal_code = '-',
    state = {},
  } = billing_address || {};
  const { name: stateName = '-' } = state || {};
  const {
    address: property_address = '-',
    city: property_city = '-',
    country: property_country = '-',
    postal_code: property_postal_code = '-',
    state: property_state = {},
  } = invoiceData.address ?? {};

  const { name: propertyStateName = '-' } = property_state || {};

  const tableHeaders = [
    { title: 'Service', width: 7 },
    { title: 'Service Date', width: 7 },
    { title: 'Qty', width: 4 },
    { title: 'Progress', width: 3 },
    { title: '', width: 3 },
  ];

  async function fetchInvoiceData(id) {
    try {
      const { data } = await InvoiceAPI.getInvoiceDetail(id);
      setInvoiceData(get(data, 'data', []));
    } catch (err) {
      message.error(get(err, 'response.message', 'Failed to get invoice data'));
    }
  }

  function handleOpenNotes([rowId = '', notes = '']) {
    setShowModal(true);
    setNotesFormField((prev) => ({
      ...prev,
      id: rowId,
      info_description: notes,
    }));
  }

  function handleOnChangeNotes(e) {
    const value = get(e, ['target', 'value'], '');
    setNotesFormField((prev) => ({ ...prev, info_description: value }));
  }

  async function handleOnChangeProgress(rowId, checked) {
    try {
      const order_services_attributes = { id: rowId, finish: checked };
      const payload = { order: { order_services_attributes } };
      await InvoiceAPI.updateServiceProgress(invoiceId, payload);
      fetchInvoiceData(orderId);
    } catch (error) {
      handleErr(error);
    }
  }

  async function handleSubmitNotes() {
    try {
      const payload = {
        order: {
          order_services_attributes: [notesFormField],
        },
      };
      await InvoiceAPI.editInvoice(orderId, payload);
      setShowModal(false);
      fetchInvoiceData(orderId);
    } catch (error) {
      handleErr(error);
    }
  }

  function PdTableBodyRowService(props) {
    const {
      finish = '',
      notes = '',
      quantity = 0,
      rowId = '',
      service_date = '',
      title = '',
    } = props;
    return (
      <PdTableBodyRow>
        <Col xs={7}>{title}</Col>
        <Col xs={7}>{numberDate(service_date)}</Col>
        <Col xs={4}>{quantity}</Col>
        <Col xs={3}>
          <PdAntdSwitch
            checked={finish}
            onChange={handleOnChangeProgress.bind(this, rowId)}
          />
        </Col>
        <Col xs={3} className="pd-align-center">
          <Icon
            theme="twoTone"
            twoToneColor={notes ? 'green' : 'red'}
            type="profile"
            onClick={handleOpenNotes.bind(this, [rowId, notes])}
          />
        </Col>
      </PdTableBodyRow>
    );
  }

  function renderService() {
    return (
      <PdTable className="detail-container">
        <PdTableHeader listHeader={tableHeaders} />
        {order_services.map((orderService) => {
          const {
            finish = false,
            id = '-',
            info_description = '',
            quantity = 0,
            service = {},
            service_date = '-',
          } = orderService ?? {};
          const { title = '-', price = 0 } = service ?? {};
          return (
            <PdTableBodyRowService
              finish={finish}
              key={id}
              notes={info_description}
              price={price}
              quantity={quantity}
              rowId={id}
              service_date={service_date}
              title={title}
            />
          );
        })}
      </PdTable>
    );
  }

  useEffect(() => {
    fetchInvoiceData(orderId);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pd-cms-invoice-detail">
      <Row>
        <Row align="middle" type="flex" className="detail-container">
          <Col className="detail-container-label" xs={12}>
            Work Order Number
          </Col>
          <Col xs={12}>: {invoice_number}</Col>
        </Row>
        <Row align="middle" type="flex" className="detail-container">
          <Col className="detail-container-label" xs={12}>
            Work Order Date
          </Col>
          <Col xs={12}>: {numberDate(last_invoice_date)}</Col>
        </Row>
        <Row align="middle" type="flex" className="detail-container">
          <Col className="detail-container-label" xs={12}>
            Customer Name
          </Col>
          <Col xs={12}>: {name}</Col>
        </Row>
        <Row align="middle" type="flex" className="detail-container">
          <Col className="detail-container-label" xs={12}>
            Billing Address
          </Col>
          <Col xs={12}>: {address}</Col>
        </Row>
        <Row align="middle" type="flex" className="detail-container">
          <Col className="detail-container-label" xs={12}>
            City
          </Col>
          <Col xs={12}>: {capitalizeFirstLetter(city)}</Col>
          <Col className="detail-container-label" xs={12}>
            State / Province
          </Col>
          <Col xs={12}>: {stateName}</Col>
          <Col xs={12} className="detail-container-label">
            Country
          </Col>
          <Col xs={12}>: {country}</Col>
        </Row>
        <Row align="middle" type="flex" className="detail-container">
          <Col className="detail-container-label" xs={12}>
            Postal Code
          </Col>
          <Col xs={12}>: {postal_code}</Col>
        </Row>
        <Row align="middle" type="flex" className="detail-container">
          <Col className="detail-container-label" xs={12}>
            Property Address
          </Col>
          <Col xs={12}>: {property_address}</Col>
        </Row>
        <Row align="middle" type="flex" className="detail-container">
          <Col className="detail-container-label" xs={12}>
            City
          </Col>
          <Col xs={123}>: {capitalizeFirstLetter(property_city)}</Col>
          <Col className="detail-container-label" xs={12}>
            State / Province
          </Col>
          <Col xs={12}>: {propertyStateName}</Col>
          <Col xs={12} className="detail-container-label">
            Country
          </Col>
          <Col xs={12}>: {property_country}</Col>
        </Row>
        <Row align="middle" type="flex" className="detail-container">
          <Col className="detail-container-label" xs={12}>
            Postal Code
          </Col>
          <Col xs={12}>: {property_postal_code}</Col>
        </Row>
        <Row align="middle" type="flex" className="detail-container">
          <Col className="detail-container-label" xs={12}>
            Email
          </Col>
          <Col xs={12}>: {email}</Col>
        </Row>
        <Row align="middle" type="flex" className="detail-container">
          <Col className="detail-container-label" xs={12}>
            Phone number
          </Col>
          <Col xs={12}>: {phone_number}</Col>
        </Row>
        <Row align="middle" type="flex" className="detail-container">
          <Col className="detail-container-label" xs={12}>
            Fax
          </Col>
          <Col xs={12}>: {fax_number}</Col>
        </Row>
      </Row>

      {/* Table Section */}
      {renderService()}

      {/* Button Section */}
      <Row
        align="middle"
        justify="center"
        type="flex"
        className="detail-container-action"
        gutter={10}>
        <Col xs={12}>
          <PdButton block ghost type="primary" onClick={onClickBack}>
            Back
          </PdButton>
        </Col>
      </Row>

      {/* Modal Section */}
      {showModal && (
        <PdModal
          modalTitle="Notes"
          visible={showModal}
          onCancel={setShowModal.bind(this, false)}>
          <Row
            align="middle"
            justify="center"
            type="flex"
            className="detail-container-action">
            <Col xs={24}>
              <TextArea
                onChange={handleOnChangeNotes}
                value={get(notesFormField, 'info_description', '')}
                rows={8}
              />
            </Col>
            <Col xs={6} className="pd-margin-top-md">
              <PdButton block type="primary" onClick={handleSubmitNotes}>
                Submit
              </PdButton>
            </Col>
          </Row>
        </PdModal>
      )}
    </div>
  );
}

export default { CalendarWorkOrderDetail };
