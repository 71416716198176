export const FIELDS = [
  {
    title: 'Service Name',
    key: 'service',
  },
  {
    title: 'Quantity',
    key: 'quantity',
  },
  {
    title: '$ per Unit',
    key: 'price',
  },
  {
    title: 'Amount',
    key: 'amount',
  },
  {
    title: 'Service Dates',
    key: 'recurringOn',
  },
];
