import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Cascader, Col, Form, Input, Layout, Row, Modal } from 'antd';
import { get } from 'lodash';

import AdminApi from 'api/admin';
import RoleApi from 'api/role.js';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdForm from 'components/pd-form';

import { handleErr, FORM_ADMIN_FIELD } from 'utils';

import './style.scss';

const { confirm } = Modal;

function AdminCreateEdit({ form, history, location }) {
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [roles, setRoles] = useState([]);
  const [anyChange, setAnyChange] = useState(false);

  const { page } = useParams();
  const { getFieldDecorator, validateFields } = form;
  const detailAdmin = location.state || {};
  const roleId = get(detailAdmin, ['role', 'id'], '');

  function handleSubmit(e) {
    e.preventDefault();
    validateFields(async (err, values) => {
      try {
        if (!err) {
          const payload = {
            admin: {
              ...values,
              // change array value to string, because by default => cascader value is array
              role_id: values.role_id[0],
              email: values.email.toLowerCase(),
            },
          };
          switch (page) {
            case 'create':
              await AdminApi.createAdmin(payload);
              break;
            case 'edit':
              await AdminApi.updateAdmin(get(detailAdmin, 'id', ''), payload);
              break;
          }
          history.push('/admin');
        }
      } catch (error) {
        handleErr(error);
      }
    });
  }

  function handleConfirmBlur(e) {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  }

  function validateToConfirmPassword(rule, value, callback) {
    if (value && confirmDirty) {
      form.validateFields([FORM_ADMIN_FIELD.CONFIRM_PASSWORD], { force: true });
    }
    callback();
  }

  function validateToPassword(rule, value, callback) {
    if (value && value !== form.getFieldValue(FORM_ADMIN_FIELD.PASSWORD)) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  }

  function handleBack() {
    if (anyChange) {
      confirm({
        title: 'Are you sure?',
        content: 'Your changes will be lost',
        cancelButtonProps: { ghost: true },
        onOk: () => {
          history.goBack();
        },
      });
    } else {
      history.goBack();
    }
  }

  async function fetchRoles() {
    try {
      const { data } = await RoleApi.listRole({});
      const temp = get(data, 'data', []);
      const roles = temp.map(({ id = '', title = '' }) => ({
        value: id,
        label: title,
      }));
      setRoles(roles);
    } catch (err) {
      handleErr(err);
    }
  }

  useEffect(() => {
    fetchRoles();
    // eslint-disable-next-line
  }, []);

  return (
    <Layout className="pd-cms-form-admin">
      <DashboardContainer>
        <PdForm
          onSubmit={handleSubmit}
          onChange={() => !anyChange && setAnyChange(true)}>
          <Form.Item label="First Name">
            {getFieldDecorator(FORM_ADMIN_FIELD.FIRST_NAME, {
              rules: [
                {
                  required: true,
                  message: 'Please input your First Name!',
                },
              ],
              initialValue: get(detailAdmin, 'first_name', ''),
            })(<Input placeholder="Enter First Name" />)}
          </Form.Item>
          <Form.Item label="Last Name">
            {getFieldDecorator(FORM_ADMIN_FIELD.LAST_NAME, {
              rules: [
                {
                  required: true,
                  message: 'Please input your Last Name!',
                },
              ],
              initialValue: get(detailAdmin, 'last_name', ''),
            })(<Input placeholder="Enter Last Name" />)}
          </Form.Item>
          <Form.Item label="E-mail">
            {getFieldDecorator(FORM_ADMIN_FIELD.EMAIL, {
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ],
              initialValue: get(detailAdmin, 'email', ''),
            })(<Input placeholder="Enter First Name" />)}
          </Form.Item>
          <Form.Item label="Role">
            {getFieldDecorator(FORM_ADMIN_FIELD.ROLE, {
              rules: [
                {
                  required: true,
                  message: 'Please input your Role!',
                },
              ],
              initialValue: [roleId], // must be in array
            })(<Cascader options={roles} placeholder="Select Role" />)}
          </Form.Item>
          <Form.Item
            label={page === 'create' ? 'Password' : 'Change Password'}
            hasFeedback>
            {getFieldDecorator(FORM_ADMIN_FIELD.PASSWORD, {
              rules: [
                {
                  required: page === 'create',
                  message: 'Please input your Password!',
                },
                {
                  validator: validateToConfirmPassword,
                },
              ],
            })(<Input.Password placeholder="Enter Password" />)}
          </Form.Item>
          <Form.Item label="Confirm Password" hasFeedback>
            {getFieldDecorator(FORM_ADMIN_FIELD.CONFIRM_PASSWORD, {
              rules: [
                {
                  required: page === 'create',
                  message: 'Please input your Confirm Password!',
                },
                {
                  validator: validateToPassword,
                },
              ],
            })(
              <Input.Password
                placeholder="Enter Confirm Password"
                onBlur={handleConfirmBlur}
              />
            )}
          </Form.Item>
          <Form.Item className="btn-container">
            <Row align="middle" justify="center" type="flex">
              <Col lg={6}>
                <PdButton block ghost type="primary" onClick={handleBack}>
                  Cancel
                </PdButton>
              </Col>
              <Col lg={{ span: 6, offset: 1 }}>
                <PdButton block htmlType="submit">
                  Submit
                </PdButton>
              </Col>
            </Row>
          </Form.Item>
        </PdForm>
      </DashboardContainer>
    </Layout>
  );
}

export const AdminPageForm = Form.create({ name: 'admin' })(AdminCreateEdit);
