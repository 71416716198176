import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  Checkbox,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Typography,
  Modal,
} from 'antd';
import { get } from 'lodash';

import PagesApi from 'api/pages';
import RoleApi from 'api/role';

import { PageSpinner } from 'components/page-spinner';
import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdForm from 'components/pd-form';

import {
  handleErr,
  FORM_BUTTON_LIST,
  FORM_ROLE_FORMATTER,
  FORM_ROLE_FIELD,
  FORM_TITLE_LIST,
} from 'utils';

import './style.scss';

const { Title } = Typography;
const { confirm } = Modal;

function RoleCreateEdit({ form, history, location }) {
  const [isLoading, setIsLoading] = useState([]);
  const [roles, setRoles] = useState([]);
  const [anyChange, setAnyChange] = useState(false);

  const { page } = useParams();
  const { getFieldDecorator, validateFields } = form;
  const detailRole = location.state || {};

  async function getRoles() {
    setIsLoading(true);
    try {
      const { data: response } = await PagesApi.getPageList();
      const { data = {} } = response ?? {};

      setRoles(data);
    } catch (error) {
      handleErr(error);
    } finally {
      setIsLoading(false);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    validateFields(async (err, values) => {
      try {
        if (!err) {
          const payload = {
            role: {
              ...values,
            },
          };
          switch (page) {
            case 'create':
              await RoleApi.createRole(payload);
              break;
            case 'edit':
              await RoleApi.updateRole(get(detailRole, 'id', ''), payload);
              break;
          }
          history.push('/role');
        }
      } catch (error) {
        handleErr(error);
      }
    });
  }

  function handleCancel() {
    if (anyChange) {
      confirm({
        title: 'Are you sure?',
        content: 'Your changes will be lost',
        cancelButtonProps: { ghost: true },
        onOk: () => {
          history.goBack();
        },
      });
    } else {
      history.goBack();
    }
  }

  useEffect(() => {
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout className="pd-mobile-form-role">
      <DashboardContainer>
        <Col xs={24}>
          <Title className="pd-align-center pd-margin-top-xs" level={3}>
            {FORM_TITLE_LIST[page] + 'Role'}
          </Title>
        </Col>
        <PdForm
          onSubmit={handleSubmit}
          onChange={() => !anyChange && setAnyChange(true)}>
          <Form.Item label="Title">
            {getFieldDecorator(FORM_ROLE_FIELD.TITLE, {
              rules: [
                {
                  required: true,
                  message: 'Please input Role Name!',
                },
              ],
              initialValue: get(detailRole, 'title', ''),
            })(<Input placeholder="Write Role Name Here" />)}
          </Form.Item>
          <Form.Item label="Page Permission">
            {getFieldDecorator(FORM_ROLE_FIELD.PERMISSION, {
              initialValue: get(detailRole, 'permission', ''),
            })(
              <Checkbox.Group className="w-full">
                <Row>
                  {isLoading ? (
                    <PageSpinner />
                  ) : (
                    roles?.map(({ page }, idx) => (
                      <Col lg={24} key={idx}>
                        <Checkbox value={page}>
                          {FORM_ROLE_FORMATTER[page]}
                        </Checkbox>
                      </Col>
                    ))
                  )}
                </Row>
              </Checkbox.Group>
            )}
          </Form.Item>
          <Row justify="center" type="flex" className="pd-margin-top-lg">
            <Col xs={8} className="cancel-btn-container">
              <PdButton block ghost onClick={handleCancel}>
                Cancel
              </PdButton>
            </Col>
            <Col xs={{ span: 8, offset: 2 }}>
              <PdButton block onClick={handleSubmit}>
                {FORM_BUTTON_LIST[page]}
              </PdButton>
            </Col>
          </Row>
        </PdForm>
      </DashboardContainer>
    </Layout>
  );
}

export const RolePageForm = Form.create({ name: 'role' })(RoleCreateEdit);
