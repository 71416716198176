import React, { useEffect, useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Col, Layout, message, Row } from 'antd';
import { get, has } from 'lodash';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';

import CustomerService from 'api/customer';

import { numberWithCommas } from 'utils/numberFormater';
import './style.scss';

export function ReportCustomerDetail({ history, location }) {
  const [customerData, setCustomerData] = useState({});

  const componentRef = useRef();
  const handlePDFPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function handleBack() {
    history.goBack();
  }

  async function fetchUserData(id) {
    try {
      const { data } = await CustomerService.getCustomerDetail(id);
      setCustomerData(get(data, 'data', {}));
    } catch (error) {
      message.error(
        get(error, 'response.message', 'Failed to get customer data')
      );
    }
  }

  const ComponentToPrint = (props) => (
    <div {...props}>
      <Layout
        className="pd-cms-customer-detail pd-component-to-print-content"
        ref={componentRef}>
        <DashboardContainer>
          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              Customer ID
            </Col>
            <Col lg={10}> {get(customerData, 'id', '-')}</Col>
          </Row>
          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              Customer Name
            </Col>
            <Col lg={10}>{get(customerData, 'name', '-')}</Col>
          </Row>

          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              Billing Address
            </Col>
            <Col lg={10}>
              {get(customerData, 'billing_address.address', '-')}
            </Col>
          </Row>
          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              City
            </Col>
            <Col lg={10}>{get(customerData, 'billing_address.city', '-')}</Col>
          </Row>
          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              State / Province
            </Col>
            <Col lg={7}>
              {get(customerData, 'billing_address.state.name', '')}
            </Col>
            <Col lg={5} className="detail-container-label">
              Country
            </Col>
            <Col lg={7}>
              {get(customerData, 'billing_address.state.country', '-')}
            </Col>
          </Row>
          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              Postal Code
            </Col>
            <Col lg={10}>
              {get(customerData, 'billing_address.postal_code', '-')}
            </Col>
          </Row>

          {get(customerData, 'property_addresses', []).map((propertyData) => (
            <>
              <Row align="middle" type="flex" className="detail-container">
                <Col className="detail-container-label" lg={5}>
                  Property Address
                </Col>
                <Col lg={10}>{get(propertyData, 'address', '-')}</Col>
              </Row>
              <Row align="middle" type="flex" className="detail-container">
                <Col className="detail-container-label" lg={5}>
                  City
                </Col>
                <Col lg={10}>{get(propertyData, 'city', '-')}</Col>
              </Row>
              <Row align="middle" type="flex" className="detail-container">
                <Col className="detail-container-label" lg={5}>
                  State / Province
                </Col>
                <Col lg={7}>{get(propertyData, 'state.name', '')}</Col>
                <Col lg={5} className="detail-container-label">
                  Country
                </Col>
                <Col lg={7}>{get(propertyData, 'state.country', '-')}</Col>
              </Row>
              <Row align="middle" type="flex" className="detail-container">
                <Col className="detail-container-label" lg={5}>
                  Postal Code
                </Col>
                <Col lg={10}>{get(propertyData, 'postal_code', '-')}</Col>
              </Row>
            </>
          ))}

          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              Phone number
            </Col>
            <Col lg={10}>{get(customerData, 'phone_number', '-')}</Col>
          </Row>
          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              Fax
            </Col>
            <Col lg={10}>{get(customerData, 'fax_number', '-')}</Col>
          </Row>
          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              Email Address
            </Col>
            <Col lg={10}>{get(customerData, 'email', '-')}</Col>
          </Row>
          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              Regular Service
            </Col>
            <Col lg={10}>{`$${numberWithCommas(
              get(customerData, 'regular_service', 0)
            )}`}</Col>
          </Row>
        </DashboardContainer>
      </Layout>
    </div>
  );

  useEffect(() => {
    if (!has(location, 'id')) {
      history.push({ pathname: '/all-customer' });
    }
    fetchUserData(get(location, 'id', ''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pd-cms-customer-detail">
      {/* Section for Print PDF Component */}
      <ComponentToPrint className="pd-component-to-print" />

      {/* Main Section */}
      <Layout>
        <DashboardContainer>
          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              Customer ID
            </Col>
            <Col lg={10}>{get(customerData, 'id', '-')}</Col>
          </Row>
          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              Customer Name
            </Col>
            <Col lg={10}>{get(customerData, 'name', '-')}</Col>
          </Row>

          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              Billing Address
            </Col>
            <Col lg={10}>
              {get(customerData, 'billing_address.address', '-')}
            </Col>
          </Row>
          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              City
            </Col>
            <Col lg={10}>{get(customerData, 'billing_address.city', '-')}</Col>
          </Row>
          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              State / Province
            </Col>
            <Col lg={7}>
              {get(customerData, 'billing_address.state.name', '')}
            </Col>
            <Col lg={5} className="detail-container-label">
              Country
            </Col>
            <Col lg={7}>
              {get(customerData, 'billing_address.state.country', '-')}
            </Col>
          </Row>
          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              Postal Code
            </Col>
            <Col lg={10}>
              {get(customerData, 'billing_address.postal_code', '-')}
            </Col>
          </Row>

          {get(customerData, 'property_addresses', []).map((propertyData) => (
            <>
              <Row align="middle" type="flex" className="detail-container">
                <Col className="detail-container-label" lg={5}>
                  Property Address
                </Col>
                <Col lg={10}>{get(propertyData, 'address', '-')}</Col>
              </Row>
              <Row align="middle" type="flex" className="detail-container">
                <Col className="detail-container-label" lg={5}>
                  City
                </Col>
                <Col lg={10}>{get(propertyData, 'city', '-')}</Col>
              </Row>
              <Row align="middle" type="flex" className="detail-container">
                <Col className="detail-container-label" lg={5}>
                  State / Province
                </Col>
                <Col lg={7}>{get(propertyData, 'state.name', '')}</Col>
                <Col lg={5} className="detail-container-label">
                  Country
                </Col>
                <Col lg={7}>{get(propertyData, 'state.country', '-')}</Col>
              </Row>
              <Row align="middle" type="flex" className="detail-container">
                <Col className="detail-container-label" lg={5}>
                  Postal Code
                </Col>
                <Col lg={10}>{get(propertyData, 'postal_code', '-')}</Col>
              </Row>
            </>
          ))}

          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              Phone number
            </Col>
            <Col lg={10}>{get(customerData, 'phone_number', '-')}</Col>
          </Row>
          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              Fax
            </Col>
            <Col lg={10}>{get(customerData, 'fax_number', '-')}</Col>
          </Row>
          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              Email Address
            </Col>
            <Col lg={10}>{get(customerData, 'email', '-')}</Col>
          </Row>
          <Row align="middle" type="flex" className="detail-container">
            <Col className="detail-container-label" lg={5}>
              Regular Service
            </Col>
            <Col lg={10}>{`$${numberWithCommas(
              get(customerData, 'regular_service', 0)
            )}`}</Col>
          </Row>
          <Row
            align="middle"
            justify="center"
            type="flex"
            className="detail-container-action">
            <Col lg={6}>
              <PdButton block ghost type="primary" onClick={handleBack}>
                Back
              </PdButton>
            </Col>
            <Col lg={{ span: 6, offset: 1 }}>
              <PdButton block onClick={handlePDFPrint}>
                PDF Print
              </PdButton>
            </Col>
          </Row>
        </DashboardContainer>
      </Layout>
    </div>
  );
}

export default { ReportCustomerDetail };
