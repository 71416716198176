import React, { useEffect, useState } from 'react';
import { Col, Icon, Input, Layout, message, Row, Select } from 'antd';
import { get } from 'lodash';
import moment from 'moment';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdAntdTable from 'components/pd-antd-table';

import { numberDate, numberWithCommas } from 'utils/numberFormater';
import { ORDER_STATUS } from 'utils';

import InvoiceAPI from 'api/invoice';

import './style.scss';

const { Search } = Input;
const { Option } = Select;

export function WorkOrders({ history }) {
  const [customerList, setCustomerList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [status, setStatus] = useState('');

  const columns = [
    {
      title: 'Work Order No.',
      dataIndex: 'id',
      key: 'id',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Customer',
      dataIndex: 'customer.name',
      key: 'customer.name',
      sorter: (a, b) => a.customer.name.localeCompare(b.customer.name),
    },
    {
      title: 'Work Order Date',
      dataIndex: 'ordered_at',
      key: 'ordered_at',
      render: ColumnDate,
      defaultSortOrder: 'descend',
      sorter: (a, b) =>
        moment(a.ordered_at).valueOf() - moment(b.ordered_at).valueOf(),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: ColumnStatusText,
    },
    {
      title: 'Progress',
      dataIndex: 'progress',
      key: 'progress',
    },
    {
      title: 'Amount',
      dataIndex: 'total_price',
      key: 'total_price',
      render: ColumnPrice,
    },
    {
      align: 'center',
      dataIndex: 'paid',
      key: 'paid',
      render: ColumnStatus,
      title: 'Payment Status',
    },
    {
      title: '',
      key: 'key',
      render: ColumnAction,
      width: '15%',
    },
  ];

  const dataSource = customerList || [];

  function ColumnAction(data = {}) {
    return (
      <Row>
        <Col lg={24}>
          <PdButton
            block
            type="primary"
            onClick={handleWorkOrdersDetail.bind(this, get(data, 'id', ''))}>
            Detail
          </PdButton>
        </Col>
      </Row>
    );
  }

  function ColumnDate(date) {
    return <>{`${numberDate(date)}`}</>;
  }

  function ColumnStatus(status) {
    return (
      <Icon
        type="check-circle"
        theme="twoTone"
        twoToneColor={status ? 'green' : 'red'}
      />
    );
  }

  function ColumnStatusText(status) {
    const temp = status?.split('_').join(' ');
    return <>{temp.charAt(0).toUpperCase() + temp.slice(1)}</>;
  }

  function ColumnPrice(price) {
    return <>{`$${numberWithCommas(price)}`}</>;
  }

  async function fetchCustomerList({
    limit = 6,
    cursor = 1,
    q = '',
    status = '',
  }) {
    try {
      const { data } = await InvoiceAPI.getAllInvoiceInfo({
        limit,
        cursor,
        q,
        status,
      });

      const customerData = get(data, 'data', []);

      setCustomerList(customerData);
    } catch (err) {
      message.error(
        get(err, 'response.message', 'Failed to get customer data')
      );
    }
  }

  function handleCreateInvoice() {
    // write code here
    history.push({
      pathname: '/work-orders/create',
    });
  }

  function handleSearchCustomer(e) {
    setSearchQuery(get(e, 'target.value', ''));
  }

  function handleWorkOrdersDetail(id = '') {
    history.push({
      pathname: '/work-orders/detail',
      id,
    });
  }

  function handleSelectStatus(value) {
    setStatus(value === 'all' ? '' : value);
  }

  useEffect(() => {
    fetchCustomerList({
      limit: 250,
      cursor: 1,
      q: searchQuery,
      status,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, status]);

  return (
    <Layout className="pd-cms-order">
      {/* Section that display chart */}
      <DashboardContainer>
        <Row align="middle" type="flex" justify="space-between">
          <Col lg={21}>
            <Row gutter={10}>
              <Col lg={10}>
                <Search
                  placeholder="Search by Customer Name"
                  onChange={handleSearchCustomer}
                />
              </Col>
              <Col lg={4}>
                <Select
                  defaultValue="all"
                  placeholder="Status"
                  className="w-full"
                  onSelect={handleSelectStatus}>
                  {ORDER_STATUS.map(({ key, text }) => (
                    <Option key={key} value={key}>
                      {text}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col lg={3}>
            <PdButton block onClick={handleCreateInvoice}>
              Create New
            </PdButton>
          </Col>
        </Row>
      </DashboardContainer>
      <DashboardContainer>
        {/* Section that display chart */}
        <Row align="middle">
          <Col lg={24}>
            <PdAntdTable
              dataSource={dataSource}
              rowKey={dataSource.id}
              columns={columns}
            />
          </Col>
        </Row>
      </DashboardContainer>
    </Layout>
  );
}

export default { WorkOrders };
