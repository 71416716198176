import React, { useEffect, useState } from 'react';
import {
  Col,
  Icon,
  Input,
  Layout,
  Row,
  Select,
  Typography,
  message,
  Modal,
} from 'antd';
import { get } from 'lodash';
import moment from 'moment';

import PdButton from 'components/pd-button';
import PdMobileTable from 'components/pd-mobile-table';
import PdCheckbox from 'components/pd-checkbox';

import ProposalAPI from 'api/proposal';

import { handleErr } from 'utils';
import { numberDate } from 'utils/numberFormater';
import './style.scss';
import PdArchieveIcon from 'components/pd-archieve-icon';

const { Option } = Select;
const { Search } = Input;
const { Title } = Typography;
const { confirm } = Modal;

export function Proposal({ history }) {
  const [archiveQuery, setArchiveQuery] = useState([]);
  const [cursor, setCursor] = useState(1);
  const [loading, setLoading] = useState({});
  const [proposalList, setProposalList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [status, setStatus] = useState(undefined);

  const dataSource = proposalList ?? {};

  const columns = [
    {
      title: 'No.',
      dataIndex: 'id',
      key: 'id',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Customer',
      dataIndex: 'customer.name',
      key: 'customer.name',
      sorter: (a, b) => a.customer.name.localeCompare(b.customer.name),
    },
    {
      title: 'Proposal Date',
      dataIndex: 'ordered_at',
      key: 'ordered_at',
      render: ColumnDate,
      sorter: (a, b) =>
        moment(a.ordered_at).valueOf() - moment(b.ordered_at).valueOf(),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: ColumnStatus,
    },
    {
      title: '',
      render: ColumnAction,
      align: 'center',
    },
  ];

  const statusFilter = [
    { value: 'created', text: 'Created' },
    { value: 'sent', text: 'Sent' },
    { value: 'approved', text: 'Approved' },
    { value: 'rejected', text: 'Rejected' },
  ];

  function ColumnAction({ id = undefined, archive = 'false' }) {
    const isCurrentRow = loading?.id === id;
    const archiveLoading = isCurrentRow && loading?.type === 'archive';
    const deleteLoading = isCurrentRow && loading?.type === 'delete';
    return (
      <Row justify="center" type="flex">
        <Col onClick={handleProposalDetail.bind(this, id)}>
          <Icon type="search" />
        </Col>
        <Col>
          <PdArchieveIcon
            isNotArchieve={!archive}
            onClick={() => handleProposalArchive(id)}
            loading={archiveLoading}
            className="pd-margin-right-sm"
          />
        </Col>
        <Col>
          <Icon
            type={deleteLoading ? 'sync' : 'delete'}
            onClick={() => handleDeleteProposal(id)}
            spin={deleteLoading}
          />
        </Col>
      </Row>
    );
  }

  function ColumnStatus(value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  function ColumnDate(date) {
    return numberDate(date);
  }

  function handleChangeStatus(status) {
    setStatus(status);
  }

  function handleCreateProposal() {
    history.push({
      pathname: '/proposal/create',
    });
  }

  function handleProposalDetail(id) {
    history.push({
      pathname: '/proposal/detail',
      id,
    });
  }

  function handleSearchCustomerName(e) {
    setSearchQuery(get(e, 'target.value', ''));
    setCursor(1);
  }

  async function handleProposalArchive(id) {
    try {
      setLoading({ type: 'archive', id });
      await ProposalAPI.archiveProposal(id);
      fetchProposalData({
        cursor,
        q: searchQuery,
        status,
        archive: !!archiveQuery.length,
      });
    } catch (err) {
      handleErr(err);
    } finally {
      setLoading({});
    }
  }

  function handleDeleteProposal(id) {
    confirm({
      title: 'Are you sure you want to delete this proposal?',
      content: "You can't undo this action",
      cancelButtonProps: { ghost: true },
      onOk: async () => {
        try {
          setLoading({ type: 'delete', id });
          await ProposalAPI.deleteProposal(id);
          fetchProposalData({
            cursor,
            q: searchQuery,
            status,
            archive: !!archiveQuery.length,
          });
          message.success('Proposal has been removed');
        } catch (err) {
          handleErr(err);
        } finally {
          setLoading({});
        }
      },
    });
  }

  async function fetchProposalData({
    limit = 250,
    cursor = 1,
    q = '',
    status = '',
    archive = false,
  }) {
    try {
      const { data } = await ProposalAPI.getProposalList({
        limit,
        cursor,
        q,
        status,
        archive,
      });

      const proposalData = get(data, 'data', []).map((proposal) => ({
        ...proposal,
        key: proposal.id,
      }));

      setProposalList(proposalData);
    } catch (err) {
      handleErr(err);
    }
  }

  useEffect(() => {
    fetchProposalData({
      cursor,
      q: searchQuery,
      status,
      archive: !!archiveQuery.length,
    });
  }, [cursor, status, searchQuery, archiveQuery]);

  return (
    <Layout className="pd-mobile-proposal">
      {/* Section that display chart */}
      <Row xs={24} justify="center" type="flex">
        <Col className="pd-margin-top-md pd-margin-bottom-sm" xs={{ span: 24 }}>
          <Title className="pd-align-center" level={3}>
            Proposal
          </Title>
        </Col>
        <Col xs={22}>
          <Search
            placeholder="Search Customer Name"
            onChange={handleSearchCustomerName}
          />
        </Col>
        <Col xs={22} className="pd-margin-top-sm">
          <Row xs={24} gutter={10}>
            <Col xs={8}>
              <Select
                className="w-full"
                placeholder="Status"
                defaultValue={undefined}
                onSelect={handleChangeStatus}>
                {statusFilter.map((status) => (
                  <Option key={status.value} value={status.value}>
                    {status.text}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xs={8}>
              <PdCheckbox
                data={['Archive']}
                value={archiveQuery}
                onChange={setArchiveQuery}
                fullWidth
              />
            </Col>
            <Col xs={8}>
              <PdButton
                onClick={handleCreateProposal}
                style={{ width: '100%' }}>
                Create New
              </PdButton>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} className="pd-margin-top-lg">
          <PdMobileTable
            dataSource={dataSource}
            columns={columns}
            pagination={{ pageSize: 5 }}
          />
        </Col>
      </Row>
    </Layout>
  );
}

export default { Proposal };
