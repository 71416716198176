import React, { useRef, useState } from 'react';
import { Row, Carousel, Input, Icon, Form } from 'antd';
import { identity } from 'lodash';

import PdModal from 'components/pd-modal';
import PdButton from 'components/pd-button';

import './style.scss';

function PreviewEmail({
  currentRecipient = '',
  isDesktop = false,
  onCancel = identity,
  onSendEmail = identity,
  previewUrl = '',
  visible = false,
  form = {},
  modalClassName = 'pd-modal',
}) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [emailFields, setEmailFields] = useState([0]);
  const ref = useRef(null);

  const handleChangeSlide = (index) => {
    setCurrentSlide(index);
  };

  const addEmailField = () => {
    setEmailFields((prev) => [...prev, prev[prev.length - 1] + 1]);
  };

  const removeEmailField = (key) => {
    if (emailFields.length === 1) {
      return;
    }

    setEmailFields((prev) => prev.filter((k) => k !== key));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const emails = Object.values(values?.emails).slice(1);
        onSendEmail(emails);
      }
    });
  };

  return (
    <PdModal
      modalClassName={modalClassName}
      modalTitle={currentSlide === 0 ? 'Preview Email' : 'Email Recipient'}
      visible={visible}
      onCancel={onCancel}
      width={isDesktop && '80%'}
      style={{ top: 30 }}>
      <Carousel
        ref={ref}
        dots={false}
        infinite={false}
        beforeChange={(_, index) => handleChangeSlide(index)}>
        <iframe
          src={previewUrl}
          width="100%"
          height="350"
          title="description"></iframe>
        <Form className="preview-email-form">
          {emailFields.map((key) => (
            <Form.Item key={key} className="field">
              {form.getFieldDecorator(`emails[${key}]`, {
                initialValue: !key ? currentRecipient : '',
                validateTrigger: ['onBlur'],
                rules: [
                  {
                    required: true,
                    type: 'email',
                    message: 'Please input proper email or delete this field.',
                  },
                ],
              })(<Input placeholder="input email" disabled={!key} />)}
              {!!key && emailFields.length > 1 && (
                <Icon
                  className="delete-button"
                  type="minus-circle-o"
                  onClick={() => removeEmailField(key)}
                />
              )}
            </Form.Item>
          ))}
          <Form.Item className="field pd-margin-top-md">
            <PdButton type="dashed" block onClick={addEmailField}>
              <Icon type="plus" /> Add email
            </PdButton>
          </Form.Item>
        </Form>
      </Carousel>
      <Row type="flex" justify="space-between">
        <PdButton
          ghost
          disabled={!currentSlide}
          onClick={() => ref.current.prev()}>
          Back
        </PdButton>
        {currentSlide !== 0 ? (
          <PdButton onClick={handleSubmit}>Send Mail</PdButton>
        ) : (
          <PdButton onClick={() => ref.current.next()}>Next</PdButton>
        )}
      </Row>
    </PdModal>
  );
}

const PdPreviewEmail = Form.create({
  name: 'pd-preview-email',
})(PreviewEmail);

export default PdPreviewEmail;
