import React, { useState, useEffect } from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import moment from 'moment';

import PdButton from 'components/pd-button';
import PdMobileTable from 'components/pd-mobile-table';

import CustomerAPI from 'api/customer';
import InvoiceAPI from 'api/invoice';

import { numberWithCommas } from 'utils/numberFormater';
import { capitalizeFirstLetter, DATE_FORMAT, handleErr } from 'utils';

import PaymentModal from './payment-modal';
import DetailModal from './detail-modal';
import './style.scss';

const { Title, Text } = Typography;

export function CustomerBalanceDetail({ location, history }) {
  const [balanceList, setBalanceList] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const { id = 0, name = '' } = location ?? {};

  const columns = [
    {
      title: 'Transaction Date',
      dataIndex: 'transaction_date',
      key: 'transaction_date',
      defaultSortOrder: 'descend',
      sorter: (a, b) =>
        moment(a.transaction_date).valueOf() -
        moment(b.transaction_date).valueOf(),
    },
    {
      title: 'Type',
      dataIndex: 'id',
      key: 'transaction_type',
      render: ColumnType,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: ColumnAmount,
    },
    {
      title: 'Total Current Balance',
      dataIndex: 'current_balance',
      key: 'current_balance',
      render: ColumnAmount,
    },
  ];

  function ColumnType(id) {
    const selectedBalance = balanceList.find((balance) => balance.id === id);
    const {
      transaction_type: type = '',
      payment = {},
      order = {},
    } = selectedBalance;
    if (['payment', 'rebalance'].includes(type)) {
      return (
        <Text>
          {capitalizeFirstLetter(type)} ({' '}
          {type === 'payment'
            ? payment?.order?.invoice_number
            : order?.invoice_number}{' '}
          )
        </Text>
      );
    }

    return (
      <a onClick={handleOnRowClick.bind(this, order)}>
        <Text underline>{order?.invoice_number}</Text>
      </a>
    );
  }

  function ColumnAmount(text) {
    return `$ ${numberWithCommas(text)}`;
  }

  function handlePayment() {
    setShowPaymentModal(true);
  }

  async function handlePaymentSubmit(payload) {
    try {
      await CustomerAPI.createCustomerPayment(id, payload);
      setShowPaymentModal(false);
      history.push('/customer-balance');
    } catch (error) {
      handleErr(error);
    }
  }

  function handleCloseModal() {
    setShowPaymentModal(false);
    setShowDetailModal(false);
  }

  function handleBack() {
    history.push({ pathname: '/customer-balance' });
  }

  async function handleOnRowClick({ id = '' }) {
    try {
      const { data } = await InvoiceAPI.getInvoiceDetail(id);

      // Manipulate Order Service Date
      let { order_services = [] } = data?.data;
      order_services = order_services.map((service) => {
        const { service_date = '' } = service;
        const formattedDate = moment(service_date).format(DATE_FORMAT);
        return { ...service, service_date: formattedDate };
      });
      const result = { ...data?.data, order_services };

      setInvoiceData(result);
      setShowDetailModal(true);
    } catch (error) {
      handleErr(error);
    }
  }

  async function fetchCustomerBalanceData() {
    try {
      const { data } = await CustomerAPI.getCustomerBalance({ id });

      const balanceData = data.data?.map((balance) => ({
        ...balance,
        transaction_date: moment(balance.transaction_date).format('MM/DD/YYYY'),
      }));

      setBalanceList(balanceData);
    } catch (err) {
      handleErr(err);
    }
  }

  useEffect(() => {
    !id && handleBack();
    fetchCustomerBalanceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout className="pd-mobile-customer-balance-detail">
      <Row justify="center" type="flex" className="customer-container">
        <Col className="pd-margin-top-md pd-margin-bottom-md" xs={{ span: 24 }}>
          <Title className="pd-align-center" level={3}>
            Detail Customer Balance
          </Title>
        </Col>
        <Col xs={13} className="title">
          Customer name : {name}
        </Col>
        <Col xs={{ span: 7, offset: 2 }}>
          <PdButton block onClick={handlePayment}>
            Payment
          </PdButton>
        </Col>
      </Row>

      <Row align="middle" className="pd-margin-bottom-lg">
        <Col xs={24}>
          <PdMobileTable dataSource={balanceList} columns={columns} />
        </Col>
      </Row>

      <Row align="middle" justify="center" type="flex">
        <Col xs={10}>
          <PdButton block ghost onClick={handleBack}>
            Back
          </PdButton>
        </Col>
      </Row>
      <PaymentModal
        visible={showPaymentModal}
        onCancel={handleCloseModal}
        handleSubmit={handlePaymentSubmit}
      />
      <DetailModal
        invoiceData={invoiceData}
        onCancel={handleCloseModal}
        visible={showDetailModal}
      />
    </Layout>
  );
}

export default { CustomerBalanceDetail };
