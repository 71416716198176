import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { get, identity } from 'lodash';
import moment from 'moment';

import PdAntdTable from 'components/pd-antd-table';
import PdModal from 'components/pd-modal';

import InvoiceAPI from 'api/invoice';

import { numberWithCommas } from 'utils/numberFormater';

import './style.scss';

function DetailModal({ visible = false, onCancel = identity, orderId = '' }) {
  const [dataSource, setDataSource] = useState([]);
  const [totalAmount, setTotalAmount] = useState('');

  const columns = [
    {
      title: 'Service Date',
      dataIndex: 'service_date',
      key: 'service_date',
    },
    {
      title: 'Service',
      dataIndex: 'service.title',
      key: 'service.title',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: '$ per unit',
      dataIndex: 'price',
      key: 'price',
      render: ColumnAmount,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: ColumnAmount,
    },
  ];

  function ColumnAmount(text) {
    return `$ ${numberWithCommas(text)}`;
  }

  async function fetchDataSource(id) {
    const { data } = await InvoiceAPI.getInvoiceDetail(id);
    // set totalAmount
    const totalAmount = get(data, ['data', 'total_price'], '');
    setTotalAmount(totalAmount);

    // set dataSource
    const orderServiceListTemp = get(data, ['data', 'order_services'], []);
    const orderServiceList = orderServiceListTemp.map((service) => {
      const { price = 0, quantity = 0, service_date = '' } = service;
      const amount = quantity * price;
      const formattedDate = moment(service_date).format('MM/DD/YYYY');
      return service_date
        ? { ...service, amount, service_date: formattedDate }
        : { ...service, amount };
    });
    setDataSource(orderServiceList);
  }

  useEffect(() => {
    if (orderId) fetchDataSource(orderId);
    // eslint-disable-next-line
  }, [orderId]);

  return (
    <PdModal
      className="pd-modal-detail"
      modalTitle="Detail Work Order"
      width={900}
      visible={visible}
      onCancel={onCancel}>
      <Row
        align="middle"
        className="detail-container"
        justify="center"
        type="flex">
        <Col lg={23} className="detail-container-label">
          Work Order No: {orderId}
        </Col>
        <Col lg={23} className="detail-container-table">
          <PdAntdTable
            dataSource={dataSource}
            columns={columns}
            pagination={false}
          />
        </Col>
        <Col
          lg={{ span: 4, offset: 23 - 7 }}
          className="detail-container-label">
          Total Amount:
        </Col>
        <Col lg={{ span: 3 }} className="detail-container-label">
          {`$${numberWithCommas(totalAmount)}`}
        </Col>
      </Row>
    </PdModal>
  );
}

export default DetailModal;
