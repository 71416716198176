import React from 'react';
import { Row, Col } from 'antd';

import './style.scss';

export function Footer() {
  return (
    // <footer className="pd-footer">
    // </footer>
    <Row
      className="pd-footer"
      type="flex"
      justify="center"
      style={{ width: '100%' }}>
      <Col>Powered by greencuts.crm</Col>
    </Row>
  );
}

export default { Footer };
