import React from 'react';
import { Icon } from 'antd';
import { identity } from 'lodash-es';

const Unarchieved = () => (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 5.28571V10.4286M9 10.4286L6.33333 7.85714M9 10.4286L11.6667 7.85714M1 11.2857V2.71429C1 1.76751 1.79594 1 2.77778 1H8.11111L9.88889 2.71429H15.2222C16.2041 2.71429 17 3.4818 17 4.42857V11.2857C17 12.2325 16.2041 13 15.2222 13H2.77778C1.79594 13 1 12.2325 1 11.2857Z"
      stroke="#595959"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Archieved = () => (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 1C1.89543 1 1 1.89543 1 3V11C1 12.1046 1.89543 13 3 13H15C16.1046 13 17 12.1046 17 11V5C17 3.89543 16.1046 3 15 3H10L8 1H3ZM10 6C10 5.44772 9.5523 5 9 5C8.44772 5 8 5.44772 8 6V7.5858L7.70711 7.2929C7.31658 6.90237 6.68342 6.90237 6.29289 7.2929C5.90237 7.6834 5.90237 8.3166 6.29289 8.7071L8.29289 10.7071C8.68342 11.0976 9.3166 11.0976 9.7071 10.7071L11.7071 8.7071C12.0976 8.3166 12.0976 7.6834 11.7071 7.2929C11.3166 6.90237 10.6834 6.90237 10.2929 7.2929L10 7.5858V6Z"
      fill="#595959"
    />
  </svg>
);

export default function PdArchieveIcon({
  loading = false,
  onClick = identity,
  isNotArchieve = false,
  className = '',
}) {
  return (
    <Icon
      component={isNotArchieve ? Unarchieved : Archieved}
      onClick={onClick}
      spin={loading}
      className={className}
    />
  );
}
