import React from 'react';
import { Col, Layout, Row } from 'antd';

import DashboardContainer from 'components/dashboard-container';

import './style.scss';

export function TermPolicy() {
  const termAndPolicy = [
    {
      title: 'Privacy Policy',
      link: '/terms-and-policy/policy',
    },
    {
      title: 'Terms and Conditions',
      link: '/terms-and-policy/terms',
    },
  ];

  return (
    <Layout className="pd-cms-term">
      <DashboardContainer>
        {termAndPolicy.map((term, idx) => (
          <Row key={idx} align="middle">
            <Col xs={24}>
              <h1>{term.title}</h1>
            </Col>
            <Col xs={24}>
              Eu sunt velit aute anim. Lorem sint do laborum consectetur culpa
              officia excepteur. Reprehenderit eu eiusmod tempor consectetur
              cupidatat labore fugiat ut est sit elit culpa. Officia ad ea qui
              ullamco. Ad quis excepteur officia ad mollit Lorem proident anim
              amet laboris proident dolore cupidatat id. Adipisicing occaecat
              consequat elit dolor qui anim consectetur ullamco esse elit dolore
              incididunt. Reprehenderit proident Lorem eiusmod laborum
              exercitation. Nulla proident magna anim mollit nisi aliqua in
              irure eu eiusmod. Duis proident anim culpa pariatur voluptate.
              Laborum proident Lorem ad irure. Proident enim ullamco pariatur
              sit magna deserunt sit.
            </Col>
          </Row>
        ))}
      </DashboardContainer>
    </Layout>
  );
}

export default { TermPolicy };
