/**
 * This formatter is use to add $ as prefix and , for each thousand
 */
export function priceFormatter(value) {
  return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * This parser is as price parser
 */
export function priceParser(value) {
  return value.replace(/\$\s?|(,*)/g, '');
}

/**
 * This formatter is use to add % as suffix
 */
export function appendPercentage(value) {
  return `${value}%`;
}

/**
 * This parser is as discount parser
 */
export function parsePercentage(value) {
  return value.replace('%', '');
}

/**
 *  use this function inside InputNumber formatter
 * @param {Number} value - value from InputNumber (only works on InputNumber antd)
 * @example numberOnlyFormat('abc') //
 *
 */
export function numberOnlyFormat(value) {
  if (+value === 0) return value;
  return +value ? value : value.replace(value, '');
}
