// TODO: Implement Download .csv Feature

import React, { useEffect, useState } from 'react';
import { Col, Icon, Input, Layout, Row, Typography } from 'antd';
import { get } from 'lodash';

import CustomerService from 'api/customer';

import { PdDownloadCSV } from 'components/pd-download-csv';
import PdMobileTable from 'components/pd-mobile-table';

import './style.scss';

const { Search } = Input;
const { Title } = Typography;

export function ReportAllCustomer({ history }) {
  const [customerList, setCustomerList] = useState([]);
  const [cursor, setCursor] = useState(1);
  const [query, setQuery] = useState('');

  const columns = [
    {
      title: 'Customer',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: '20%',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Phone',
      dataIndex: 'phone_number',
      key: 'phone_number',
      align: 'center',
      width: '25%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
      width: '17%',
    },
    {
      title: '',
      key: 'id',
      render: ColumnAction,
      align: 'center',
      width: '5%',
    },
  ];

  function ColumnAction(rowData = {}) {
    return (
      <Row justify="center" type="flex">
        <Col xs={24} onClick={handleDetailCustomer.bind(this, rowData)}>
          <Icon type="search" />
        </Col>
      </Row>
    );
  }

  function handleDetailCustomer(rowData) {
    history.push({
      pathname: `/all-customer/detail`,
      id: get(rowData, 'id', ''),
    });
  }

  function handleSearchCustomerName(e) {
    setQuery(get(e, 'target.value', ''));
    setCursor(1);
  }

  async function fetchCustomerList({ cursor = 1, q = '' }) {
    const { data } = await CustomerService.getCustomerList({
      cursor,
      q,
      status: 'active',
    });
    // append key for each data
    const customersData = get(data, 'data', []);
    customersData.forEach((customer) => {
      customer['key'] = customer.id;
    });
    setCustomerList(customersData);
  }

  useEffect(() => {
    fetchCustomerList({ cursor, q: query });
  }, [cursor, query]);

  return (
    <Layout className="pd-mobile-all-customer">
      {/* Section that display chart */}
      <Row xs={24} justify="center" type="flex">
        <Col className="pd-margin-top-md pd-margin-bottom-sm" xs={{ span: 24 }}>
          <Title className="pd-align-center" level={3}>
            All Customer
          </Title>
        </Col>
        <Col xs={13}>
          <Search
            placeholder="Search by customer name"
            onChange={handleSearchCustomerName}
          />
        </Col>
        <Col xs={{ span: 8, offset: 1 }}>
          <PdDownloadCSV
            fetchData={CustomerService.getAllCustomerReport}
            filename="customer-report.csv"
          />
        </Col>
        <Col xs={24} className="pd-margin-top-lg">
          <PdMobileTable
            dataSource={customerList}
            columns={columns}
            pagination={{ pageSize: 6 }}
          />
        </Col>
      </Row>
    </Layout>
  );
}

export default { ReportAllCustomer };
