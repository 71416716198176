import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Layout, message, Row, Modal } from 'antd';
import { get } from 'lodash';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdForm from 'components/pd-form';
import PdInputNumber from 'components/pd-input-number';

import ServiceAPI from 'api/service';

import { priceFormatter, priceParser } from 'utils/inputNumberUtils';

const FORM_FIELD = {
  SERVICE_NAME: 'title',
  PRICE: 'price',
  DESCRIPTION: 'description',
};

const { TextArea } = Input;
const { confirm } = Modal;

function ServiceCreateEdit({ form, history, location }) {
  const [serviceData, setServiceData] = useState({});
  const [anyChange, setAnyChange] = useState(false);
  const { id = '', pathname = '' } = location;

  const { getFieldDecorator } = form;

  async function handleCreateSubmit(values) {
    try {
      await ServiceAPI.createService({
        service: values,
      });
      message.success('Service created successfully');
      history.push({ pathname: '/service' });
    } catch (error) {
      message.error(
        get(error, 'response.data.message', 'Failed to create service')
      );
    }
  }

  async function handleEditSubmit(values) {
    try {
      await ServiceAPI.updateService({
        id: id,
        service: values,
      });
      message.success('Service updated successfully');
      history.push({ pathname: '/service' });
    } catch (error) {
      message.error(
        get(error, 'response.data.message', 'Failed to update service')
      );
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        id ? handleEditSubmit(values) : handleCreateSubmit(values);
      }
    });
  }

  async function fetchServiceData(id) {
    try {
      const { data } = await ServiceAPI.getServiceDetail(id);
      setServiceData(get(data, 'data', {}));
    } catch (error) {
      message.error(
        get(error, 'response.data.message', 'Failed to get service data')
      );
    }
  }

  function handleGoBack() {
    if (anyChange) {
      confirm({
        title: 'Are you sure?',
        content: 'Your changes will be lost',
        cancelButtonProps: { ghost: true },
        onOk: () => {
          history.goBack();
        },
      });
    } else {
      history.goBack();
    }
  }

  useEffect(() => {
    if (pathname.includes('edit')) {
      if (id) {
        fetchServiceData(id);
      } else {
        history.push({
          pathname: '/service',
        });
      }
    }
  }, [history, id, pathname]);

  return (
    <Layout className="pd-cms-form-service">
      <DashboardContainer>
        <PdForm
          onSubmit={handleSubmit}
          onReset={handleGoBack}
          onChange={() => !anyChange && setAnyChange(true)}>
          <Form.Item label="Service Name">
            {getFieldDecorator(FORM_FIELD.SERVICE_NAME, {
              initialValue: get(serviceData, 'title', ''),
              rules: [
                {
                  required: true,
                  message: 'Please input Service Name!',
                },
              ],
            })(<Input placeholder="Enter Service Name" />)}
          </Form.Item>
          <Form.Item label="Price / unit">
            {getFieldDecorator(FORM_FIELD.PRICE, {
              initialValue: get(serviceData, 'price', ''),
              rules: [
                {
                  required: true,
                  message: 'Please input your Last Name!',
                },
              ],
            })(
              <PdInputNumber
                defaultValue={0}
                min={0}
                formatter={priceFormatter}
                parser={priceParser}
              />
            )}
          </Form.Item>
          <Form.Item label="Description">
            {getFieldDecorator(FORM_FIELD.DESCRIPTION, {
              initialValue: get(serviceData, 'description', ''),
            })(<TextArea rows={4} placeholder="Enter Service Description" />)}
          </Form.Item>

          <Form.Item className="btn-container">
            <Row gutter={20} align="middle" justify="center" type="flex">
              <Col lg={4}>
                <PdButton ghost block htmlType="reset">
                  Cancel
                </PdButton>
              </Col>
              <Col lg={4}>
                <PdButton block htmlType="submit">
                  Submit
                </PdButton>
              </Col>
            </Row>
          </Form.Item>
        </PdForm>
      </DashboardContainer>
    </Layout>
  );
}

export const ServicePageForm = Form.create({ name: 'service' })(
  ServiceCreateEdit
);
