import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col } from 'antd';

import Logo from 'components/logo';
import { AuthenticationContext } from 'contexts/authentication';

import './style.scss';
import { PATH_PAGE } from 'utils/constants';

export function Header() {
  const { authenticatedUserProfile: currentAdmin = {} } = useContext(
    AuthenticationContext
  );
  const location = useLocation();

  return (
    <>
      <header className="pd-header">
        <Row type="flex" align="middle">
          <Col xs={0} lg={4}>
            <Logo size="small" />
          </Col>
          <Col xs={12} lg={14}>
            <h3 className="pd-header-title">
              {PATH_PAGE[location?.pathname] || '-'}
            </h3>
          </Col>
          <Col xs={12} lg={6}>
            <div className="pd-header-right">{currentAdmin.name}</div>
          </Col>
        </Row>
      </header>
    </>
  );
}

export default { Header };
