import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Layout,
  message,
  Row,
  Select,
  Typography,
  Modal,
} from 'antd';
import { get, has, set } from 'lodash';
import moment from 'moment';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdForm from 'components/pd-form';
import PdTable, { PdTableBodyRow, PdTableHeader } from 'components/pd-table';
import { PdRecurringFormModal } from 'components/pd-recurring-form-modal';

import CustomerService from 'api/customer';
import InvoiceApi from 'api/invoice';
import StateService from 'api/state';

import { FORM_RECURRING_DAY_OPTIONS } from 'utils';
import { priceFormatter, priceParser } from 'utils/inputNumberUtils';
import { oneNumberDecimalFormat } from 'utils/numberFormater';
import { CUSTOMER_EMAIL_COPY_WRITE } from 'utils/constants';

import './style.scss';

const { confirm } = Modal;

const FORM_FIELD = {
  ADDRESS: 'address',
  CITY: 'city',
  EMAIL: 'email',
  FAX: 'fax_number',
  ID: 'id',
  NAME: 'name',
  PHONE: 'phone_number',
  POSTAL_CODE: 'postal_code',
  REGULAR_SERVICE: 'regular_service',
  STATE_CODE: 'state_code',
  START_DATE: 'start_date',
  NOTES: 'notes',
  SECONDARY_EMAIL: 'secondary_email',
};

const tableHeaders = [
  { title: 'Service', width: 5 },
  { title: 'Quantity', width: 5 },
  { title: '$ per Unit', width: 5 },
  { title: 'Amount', width: 5 },
  { title: 'Meetings', width: 4 },
];

const { Option } = Select;
const { Text, Title } = Typography;
const { TextArea } = Input;

function CustomerEdit({ form, history, location }) {
  const [addressList, setAddressList] = useState([]);
  const [billingAddress, setBillingAddress] = useState({});
  const [customerData, setCustomerData] = useState({});
  const [isBillingPropertyEqual, setIsBillingPropertyEqual] = useState(true);
  const [listAddress, setListAddress] = useState({ 0: {} });
  const [listState, setListState] = useState([]);
  const [recurringAddContent, setRecurringAddContent] = useState([]);
  // recurringAddId for generate id when click Add Recurring
  const [recurringAddId, setRecurringAddId] = useState(0);
  const [recurringContent, setRecurringContent] = useState([]);
  const [recurringDeleteId, setRecurringDeleteId] = useState([]);
  const [recurringEditContent, setRecurringEditContent] = useState([]);
  const [recurringEditTempData, setRecurringEditTempData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [anyChange, setAnyChange] = useState(false);

  const { getFieldDecorator } = form;

  const horizontalLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  const halfHorizontalLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const customerId = get(location, ['data', 'id'], '');

  const renderDays = (checkedDays) => {
    return (
      <Row className="pd-margin-top-sm">
        <Col lg={24}>
          {FORM_RECURRING_DAY_OPTIONS.map((day, idx) => (
            <Checkbox
              checked={checkedDays.includes(day.toLowerCase())}
              disabled
              key={idx}>
              {day[0]}
            </Checkbox>
          ))}
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    if (!has(location, 'data.id')) {
      history.push({ pathname: '/customer-setup' });
    } else {
      fetchStatesData();
      fetchUserData(customerId);
      fetchAddresses(customerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        const listPropertyAddress = [];
        let updatedListAddress = listAddress;
        Object.keys(updatedListAddress).forEach((key) => {
          set(
            updatedListAddress[key],
            'state_code',
            get(updatedListAddress[key], 'state.code', '')
          );
          listPropertyAddress.push(updatedListAddress[key]);
        });

        const payload = {
          billing_address_attributes: {
            ...billingAddress,
            state_code: billingAddress.state.code,
          },
          email: get(values, 'email', ''),
          fax_number: get(values, 'fax_number', ''),
          name: get(values, 'name', ''),
          phone_number: get(values, 'phone_number', ''),
          property_addresses_attributes: listPropertyAddress,
          regular_service: get(values, 'regular_service', 0),
          start_date: values?.start_date?._d ?? moment()._d,
          notes: values?.notes ?? '',
          secondary_email: values?.secondary_email,
        };

        /* ADD RECURRING PAYLOAD */
        // adjust payload for order recurring
        const addRecurringAttrTemp = recurringAddContent.map((addContent) => {
          const {
            address = {},
            days = [],
            discount = 0,
            frequency = 4,
            order_services = [],
            start_date = '',
          } = addContent;
          const { id: address_id = '' } = address;

          // adjust order_services_attributes
          const order_services_attributes = order_services.map(
            ({
              quantity = 0,
              price = 0,
              service = {},
              meetings = 1,
              info_description = null,
            }) => {
              const { id: service_id = '' } = service;
              const returnValue = {
                price,
                quantity,
                service_id,
                meetings,
                info_description,
              };

              if (info_description) {
                return { ...returnValue, info_description };
              }

              return returnValue;
            }
          );

          // remodeling order recurring payload
          const convertedObject = {
            address_id,
            days,
            discount,
            frequency,
            order_services_attributes,
            start_date,
          };

          return convertedObject;
        });
        const addRecurringAttr = addRecurringAttrTemp.filter(
          (addTempContent) => addTempContent
        ); // to make value is not [undefined], but [] instead

        /* EDIT RECURRING PAYLOAD */
        // adjust payload for order recurring
        const editRecurringAttrTemp = recurringEditContent.map(
          (editContent) => {
            const {
              address = {},
              days = [],
              discount = 0,
              frequency = 4,
              id = '',
              order_services = [],
              start_date = '',
            } = editContent;
            const { id: address_id = '' } = address;

            // adjust order_services_attributes
            const order_services_attributes = order_services.map(
              ({
                id = '',
                price = 0,
                quantity = 0,
                service = {},
                meetings = 1,
                info_description = null,
              }) => {
                const { id: service_id = '' } = service;
                let result = {
                  price,
                  quantity,
                  service_id,
                  meetings,
                };

                if (info_description) {
                  result = { ...result, info_description };
                }

                return id ? { ...result, id } : result;
              }
            );

            // remodeling order recurring payload
            const convertedObject = {
              address_id,
              days,
              discount,
              frequency,
              id,
              order_services_attributes,
              start_date,
            };

            if (typeof id !== 'string') return convertedObject;
          }
        );
        const editRecurringAttr = editRecurringAttrTemp.filter(
          (editTempContent) => editTempContent
        ); // to make value is not [undefined], but [] instead

        /* DELETE RECURRING PAYLOAD */
        const deleteRecurringAttrTemp = recurringDeleteId.map((deleteId) => {
          if (deleteId && typeof deleteId !== 'string') {
            return {
              id: deleteId,
              _destroy: true,
            };
          }
        });
        const deleteRecurringAttr = deleteRecurringAttrTemp.filter(
          (deleteTempContent) => deleteTempContent
        ); // to make value is not [undefined], but [] instead

        /*  COMBINE ADD, EDIT, AND DELETE ATTR */
        const order_recurring_invoices_attributes = [
          ...addRecurringAttr,
          ...editRecurringAttr,
          ...deleteRecurringAttr,
        ];

        try {
          await CustomerService.updateCustomer({
            id: customerId,
            customer: { ...payload, order_recurring_invoices_attributes },
          });

          message.success('Customer has been updated');

          if (location?.function) {
            location.function();
            return;
          }

          history.push({ pathname: '/customer-setup' });
        } catch (error) {
          message.error(
            get(error, 'response.message', 'Failed to Submit Form Data')
          );
        }
      }
    });
  }

  async function fetchStatesData() {
    const { data } = await StateService.getStates();
    setListState(get(data, 'data', []));
  }

  async function fetchUserData(id) {
    try {
      const { data } = await CustomerService.getCustomerDetail(id);
      setCustomerData(get(data, 'data', {}));
      setBillingAddress(get(data, 'data.billing_address', {}));

      // convert property addresses
      const propertyAddresses = get(data, 'data.property_addresses', []);
      propertyAddresses.forEach((address, idx) => {
        setListAddress((prevState) => ({
          ...prevState,
          [idx]: address,
        }));
      });

      // convert order recurring invoices
      const recurringTemp = get(data, ['data', 'order_recurring_invoices'], []);

      const orderRecurringInvoices = recurringTemp.map((recurring) => {
        const rawStartDate = get(recurring, 'start_date', Date.now());
        return {
          ...recurring,
          start_date: moment(rawStartDate).format('MM/DD/YYYY'),
        };
      });

      setRecurringContent(orderRecurringInvoices);
    } catch (error) {
      message.error(
        get(error, 'response.message', 'Failed to get customer data')
      );
    }
  }

  async function fetchAddresses(id) {
    const { data } = await InvoiceApi.getCustomerAddressList(id);
    const list = get(data, 'data', []);
    setAddressList(list);
  }

  function handleAddNewAddress() {
    setListAddress((prevState) => ({
      ...prevState,
      [Object.keys(listAddress).length]: {},
    }));
  }

  function handleBack() {
    if (anyChange) {
      confirm({
        title: 'Are you sure?',
        content: 'Your changes will be lost',
        cancelButtonProps: { ghost: true },
        onOk: () => {
          history.goBack();
        },
      });
    } else {
      history.goBack();
    }
  }

  function handleChangeState(idx, optionObject) {
    const selectedAddress = JSON.parse(optionObject.key);
    const addressObject = listAddress[idx];
    set(addressObject, 'state', selectedAddress);

    setListAddress((prevState) => ({
      ...prevState,
      [idx]: addressObject,
    }));
  }

  function handleChangeAddress({ idx, key, value }) {
    let selectedAddress = listAddress[idx];
    selectedAddress[key] = value;

    setListAddress((prevState) => ({
      ...prevState,
      [idx]: selectedAddress,
    }));
  }

  function handleChangeBillingAddress({ key, value }) {
    setBillingAddress((prevState) => ({
      ...prevState,
      [key]: value,
    }));

    if (isBillingPropertyEqual) {
      handleChangeAddress({ idx: 0, key, value });
    }
  }

  function handleChangeBillingState(optionObject) {
    const selectedAddress = JSON.parse(optionObject.key);
    setBillingAddress((prevState) => ({
      ...prevState,
      ['state']: selectedAddress,
      ['country']: get(selectedAddress, 'country', ''),
    }));

    if (isBillingPropertyEqual) {
      handleChangeState(0, optionObject);
    }
  }

  function handleChangeAddressCondition(ev) {
    setIsBillingPropertyEqual(ev.target.checked);

    if (ev.target.checked) {
      setListAddress((prev) => {
        return {
          ...prev,
          0: billingAddress,
        };
      });
    }
  }

  function handleAddReccuring() {
    setRecurringEditTempData({});
    setShowModal(true);
  }

  function handleModalSubmit(modalData) {
    const id = `add-data${recurringAddId}`;
    setRecurringContent((prev) => [...prev, { ...modalData, id }]);
    setRecurringAddContent((prev) => [...prev, { ...modalData, id }]);
    setRecurringAddId((prev) => prev + 1);
  }

  function handleEditRecurring(editId) {
    const result = recurringContent.find(({ id }) => id === editId);
    setRecurringEditTempData({ ...result, isEdit: true });
    setShowModal(true);
  }

  function handleDeleteRecurring(deleteId) {
    // manage deleteRecurringContent
    recurringContent.map(({ id }) => {
      if (id === deleteId) setRecurringDeleteId((prev) => [...prev, id]);
    });

    // filter in recurringContent
    setRecurringContent((prev) => {
      const recurringContentTemp = [...prev];
      return recurringContentTemp.filter(({ id }) => id !== deleteId);
    });

    // filter in addRecurringContent
    setRecurringAddContent((prev) => {
      const recurringAddContentTemp = [...prev];
      return recurringAddContentTemp.filter(({ id }) => id !== deleteId);
    });

    // filter in editRecurringContent
    setRecurringEditContent((prev) => {
      const recurringEditContentTemp = [...prev];
      return recurringEditContentTemp.filter(({ id }) => id !== deleteId);
    });

    message.success('Successfully delete the recurring');
  }

  function handleChangeRecurringEditContent(editId, editData) {
    // adjust content in main content
    setRecurringContent((prev) => {
      const recurringContentTemp = [...prev];
      return recurringContentTemp.map((content) => {
        const id = get(content, 'id', '');
        if (id === editId) return editData;
        return content;
      });
    });

    // adjust content in add content
    setRecurringAddContent((prev) => {
      const recurringAddContentTemp = [...prev];
      return recurringAddContentTemp.map((content) => {
        const id = get(content, 'id', '');
        if (id === editId) return editData;
        return content;
      });
    });

    // adjust content in edit
    setRecurringEditContent((prev) => [...prev, editData]);
  }

  return (
    <Layout className="pd-cms-form-customer">
      <DashboardContainer>
        <PdForm
          onSubmit={handleSubmit}
          onChange={() => !anyChange && setAnyChange(true)}>
          <Form.Item label="Customer ID" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.ID, {
              initialValue: get(customerData, 'id', ''),
            })(<Input placeholder="Autofill" disabled />)}
          </Form.Item>
          <Form.Item label="Customer Name" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.NAME, {
              initialValue: get(customerData, 'name', ''),
              rules: [
                {
                  required: true,
                  message: 'Please input Customer Name!',
                },
              ],
            })(<Input placeholder="Enter Customer Name" />)}
          </Form.Item>

          <Form.Item label="Billing Address" {...horizontalLayout}>
            <Input
              value={get(billingAddress, 'address', '')}
              placeholder="Enter Customer Billing Address"
              onChange={(e) =>
                handleChangeBillingAddress({
                  key: FORM_FIELD.ADDRESS,
                  value: get(e, 'target.value', ''),
                })
              }
            />
          </Form.Item>
          <Form.Item label="City" {...horizontalLayout}>
            <Input
              value={get(billingAddress, FORM_FIELD.CITY, '')}
              placeholder="Enter Customer City"
              onChange={(e) =>
                handleChangeBillingAddress({
                  key: FORM_FIELD.CITY,
                  value: get(e, 'target.value', ''),
                })
              }
            />
          </Form.Item>

          <Row>
            <Col lg={12}>
              <Form.Item label="State / Province" {...halfHorizontalLayout}>
                <Select
                  value={get(billingAddress, 'state.name', '')}
                  showSearch
                  placeholder="Choose State / Province"
                  onSelect={(value, optionObject) =>
                    handleChangeBillingState(optionObject)
                  }>
                  {listState.map((state) => (
                    <Option key={JSON.stringify(state)} value={state.name}>
                      {get(state, 'name', '')}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={{ span: 11, offset: 1 }}>
              <Form.Item label="Country" {...halfHorizontalLayout}>
                <Input
                  placeholder="Autofill"
                  disabled
                  value={get(billingAddress, 'country', '')}
                />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item label="Postal Code" {...halfHorizontalLayout}>
                <Input
                  placeholder="Enter Postal Code"
                  value={get(billingAddress, 'postal_code', '')}
                  onChange={(e) =>
                    handleChangeBillingAddress({
                      key: FORM_FIELD.POSTAL_CODE,
                      value: get(e, 'target.value', ''),
                    })
                  }
                />
                <Checkbox
                  defaultChecked={isBillingPropertyEqual}
                  onChange={handleChangeAddressCondition}>
                  Property Address same with Billing Address
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          {/* address Section */}
          {Object.keys(listAddress).map((key, idx) => (
            <>
              <Form.Item label="Property Address" {...horizontalLayout}>
                <Input
                  value={get(listAddress[key], FORM_FIELD.ADDRESS, '')}
                  placeholder="Enter Customer Property Address"
                  onChange={(e) =>
                    handleChangeAddress({
                      idx: idx,
                      key: FORM_FIELD.ADDRESS,
                      value: get(e, 'target.value', ''),
                    })
                  }
                  disabled={idx === 0 && isBillingPropertyEqual}
                />
              </Form.Item>
              <Form.Item label="City" {...horizontalLayout}>
                <Input
                  value={get(listAddress[key], FORM_FIELD.CITY, '')}
                  placeholder="Enter Property City"
                  onChange={(e) =>
                    handleChangeAddress({
                      idx: idx,
                      key: FORM_FIELD.CITY,
                      value: get(e, 'target.value', ''),
                    })
                  }
                  disabled={idx === 0 && isBillingPropertyEqual}
                />
              </Form.Item>
              <Row>
                <Col lg={12}>
                  <Form.Item label="State / Province" {...halfHorizontalLayout}>
                    <Select
                      value={get(listAddress[key], 'state.name', '')}
                      showSearch
                      placeholder="Choose State / Province"
                      onSelect={(value, optionObject) =>
                        handleChangeState(idx, optionObject)
                      }
                      disabled={idx === 0 && isBillingPropertyEqual}>
                      {listState.map((state) => (
                        <Option key={JSON.stringify(state)} value={state.name}>
                          {get(state, 'name', '')}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg={{ span: 11, offset: 1 }}>
                  <Form.Item label="Country" {...halfHorizontalLayout}>
                    <Input
                      placeholder="Autofill"
                      disabled
                      value={get(listAddress[key], 'state.country', '')}
                    />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item label="Postal Code" {...halfHorizontalLayout}>
                    <Input
                      value={get(listAddress[key], 'postal_code', '')}
                      placeholder="Enter Postal Code"
                      onChange={(e) =>
                        handleChangeAddress({
                          idx: idx,
                          key: FORM_FIELD.POSTAL_CODE,
                          value: get(e, 'target.value', ''),
                        })
                      }
                      disabled={idx === 0 && isBillingPropertyEqual}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          ))}

          <Form.Item className="btn-container">
            <Row>
              <Col lg={{ span: 4, offset: 4 }}>
                <PdButton block onClick={handleAddNewAddress}>
                  Add Property
                </PdButton>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item label="Phone Number" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.PHONE, {
              initialValue: get(customerData, 'phone_number', ''),
              rules: [
                {
                  required: true,
                  message: 'Please input Customer Phone!',
                },
              ],
            })(<Input placeholder="Enter Phone Number" />)}
          </Form.Item>
          <Form.Item label="Fax" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.FAX, {
              initialValue: get(customerData, 'fax_number', ''),
            })(<Input placeholder="Enter Fax" />)}
          </Form.Item>
          <Form.Item label="Email Address" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.EMAIL, {
              initialValue: get(customerData, 'email', ''),
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid Email Address!',
                },
                {
                  required: true,
                  message: 'Please input Customer Email Address!',
                },
              ],
            })(
              <>
                <Input placeholder="Enter Customer Email Address" />
                <Text>{CUSTOMER_EMAIL_COPY_WRITE}</Text>
              </>
            )}
          </Form.Item>
          <Form.Item label="Secondary Email" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.SECONDARY_EMAIL, {
              initialValue: get(customerData, 'secondary_email', ''),
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid Email Address!',
                },
              ],
            })(<Input placeholder="Enter Customer Secondary Email Address" />)}
          </Form.Item>
          <Form.Item label="Regular Service" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.REGULAR_SERVICE, {
              initialValue: get(customerData, 'regular_service', 0),
            })(<InputNumber formatter={priceFormatter} parser={priceParser} />)}
          </Form.Item>
          <Form.Item label="Start Date" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.START_DATE, {
              initialValue: moment(customerData?.start_date) ?? '',
            })(
              <DatePicker format="MM/DD/YYYY" placeholder="Select Start Date" />
            )}
          </Form.Item>
          <Form.Item label="Notes" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.NOTES, {
              initialValue: get(customerData, 'notes', ''),
            })(<TextArea />)}
          </Form.Item>

          {false && (
            <Row
              type="flex"
              justify="center"
              align="middle"
              gutter={[8, 24]}
              className="recurring-container">
              <Col lg={22}>
                <Row type="flex" justify="center" align="middle">
                  <Col lg={16}>
                    <Title level={3}>Recurring Work Order</Title>
                  </Col>
                  <Col lg={8}>
                    <PdButton block onClick={handleAddReccuring}>
                      Add Recurring Work Order
                    </PdButton>
                  </Col>
                </Row>

                {/* Content */}
                {recurringContent.map(
                  (
                    {
                      address = {},
                      days = [],
                      discount = '',
                      id = '',
                      order_services = [],
                      start_date = '',
                      total_price = '',
                    },
                    idx
                  ) => (
                    <div
                      className="recurring-content pd-margin-top-md"
                      key={idx}>
                      <Row
                        align="middle"
                        className="btn-delete-float-right"
                        justify="end"
                        type="flex">
                        <Col>
                          <PdButton
                            block
                            className="w-full pd-button"
                            onClick={handleDeleteRecurring.bind(this, id)}>
                            Delete
                          </PdButton>
                        </Col>
                      </Row>
                      <Row className="content">
                        <Col className="content-label" lg={7}>
                          Start Date
                        </Col>
                        <Col lg={9}>
                          <Text>: {start_date}</Text>
                        </Col>
                        <Col lg={{ span: 6, offset: 1 }}></Col>
                      </Row>

                      <Row className="content">
                        <Col className="content-label" lg={7}>
                          Address
                        </Col>
                        <Col lg={17}>
                          <Text>: {get(address, 'address', '')}</Text>
                        </Col>
                      </Row>

                      <Row className="content pd-margin-bottom-md">
                        <Col className="content-label" lg={7}>
                          Days
                        </Col>
                        <Col lg={17}>{renderDays(days)}</Col>
                      </Row>

                      {/* Table Section */}
                      <PdTable className="detail-container">
                        <PdTableHeader listHeader={tableHeaders} />
                        {order_services.map(
                          (
                            {
                              price = 0,
                              quantity = 0,
                              service = {},
                              meetings = 1,
                              info_description = null,
                            },
                            idx
                          ) => (
                            <PdTableBodyRow key={idx} align="start">
                              <Col lg={5}>
                                <Text
                                  style={{
                                    display: 'block',
                                    fontWeight: 'bold',
                                  }}>
                                  {get(service, 'title', '')}
                                </Text>
                                <Text>Notes : {info_description || '-'}</Text>
                              </Col>
                              <Col lg={5}>
                                <Text>{quantity}</Text>
                              </Col>
                              <Col lg={5}>
                                <Text>$ {price}</Text>
                              </Col>
                              <Col lg={5}>
                                <Text>$ {price * quantity}</Text>
                              </Col>
                              <Col lg={4}>
                                <Text>{meetings}</Text>
                              </Col>
                            </PdTableBodyRow>
                          )
                        )}
                      </PdTable>

                      <Row
                        align="middle"
                        className="content pd-margin-top-md"
                        justify="end"
                        type="flex">
                        <Col className="content-label" lg={6}>
                          Discount
                        </Col>
                        <Col lg={{ span: 4, offset: 1 }}>
                          <Text>{discount} %</Text>
                        </Col>
                      </Row>

                      <Row
                        align="middle"
                        className="content"
                        justify="end"
                        type="flex">
                        <Col className="content-label" lg={6}>
                          Total Amount
                        </Col>
                        <Col lg={{ span: 4, offset: 1 }}>
                          <Text>$ {oneNumberDecimalFormat(total_price)}</Text>
                        </Col>
                      </Row>

                      <Row
                        align="middle"
                        className="pd-margin-top-md"
                        justify="center"
                        type="flex">
                        <Col lg={10}>
                          <PdButton
                            block
                            onClick={handleEditRecurring.bind(this, id)}>
                            Edit
                          </PdButton>
                        </Col>
                      </Row>
                    </div>
                  )
                )}
              </Col>
            </Row>
          )}

          <Form.Item className="btn-container">
            <Row
              align="middle"
              justify="center"
              type="flex"
              className="detail-container-action pd-margin-top-md">
              <Col lg={6}>
                <PdButton block ghost type="primary" onClick={handleBack}>
                  Cancel
                </PdButton>
              </Col>
              <Col lg={{ span: 6, offset: 1 }}>
                <PdButton block htmlType="submit">
                  Submit
                </PdButton>
              </Col>
            </Row>
          </Form.Item>
        </PdForm>
      </DashboardContainer>

      {/* Modal Recurring */}
      {showModal && (
        <PdRecurringFormModal
          addressList={addressList}
          data={recurringEditTempData}
          onCancel={setShowModal.bind(this, false)}
          onChangeRecurringEditContent={handleChangeRecurringEditContent}
          onSubmit={handleModalSubmit}
          visible={showModal}
        />
      )}
    </Layout>
  );
}

export const CustomerEditPageForm = Form.create({ name: 'customer-edit' })(
  CustomerEdit
);
