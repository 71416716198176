import React, { useState, useEffect } from 'react';
import {
  Col,
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  Layout,
  message,
  Row,
  Select,
  Typography,
  Modal,
} from 'antd';
import { get } from 'lodash';
import moment from 'moment';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdForm from 'components/pd-form';

import {
  appendPercentage,
  numberOnlyFormat,
  parsePercentage,
  priceFormatter,
  priceParser,
} from 'utils/inputNumberUtils';

import ServiceAPI from 'api/service';
import CustomerAPI from 'api/customer';
import ProposalAPI from 'api/proposal';

import AddCustModal from './add-cust-modal';
import './style.scss';
import { handleErr } from 'utils';

const { Option } = Select;
const { TextArea } = Input;
const { Text, Title } = Typography;
const { confirm } = Modal;

const FORM_FIELD = {
  ORDERED_AT: 'ordered_at',
  BODY_PROPOSAL: 'body_proposal',
  DISCOUNT: 'discount',
};

function ProposalCreate({ form, history }) {
  const [customerList, setCustomerList] = useState([]);
  const [customer, setCustomer] = useState({
    address_id: undefined,
    email: 'Autofill',
    customer_id: undefined,
  });
  const [discount, setDiscount] = useState(0);
  const [metaField, setMetaField] = useState(1);
  const [serviceField, setServiceField] = useState({ 0: {} });
  const [serviceList, setServiceList] = useState([]);
  const [showAddCustModal, setShowAddCustModal] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [anyChange, setAnyChange] = useState(false);

  const customersData = customerList ?? {};

  const { getFieldDecorator } = form;

  function handleBackProposal() {
    if (anyChange) {
      confirm({
        title: 'Are you sure?',
        content: 'Your changes will be lost',
        cancelButtonProps: { ghost: true },
        onOk: () => {
          history.goBack();
        },
      });
    } else {
      history.goBack();
    }
  }

  function handleAddNewService() {
    setServiceField((prevState) => ({
      ...prevState,
      [metaField]: {},
    }));
    setMetaField((metaField) => metaField + 1);
  }

  async function handleChangeCustomer({ key: customerID }) {
    !anyChange && setAnyChange(true);
    try {
      const data = customerList.find(({ id }) => id === Number(customerID));
      const { email = '', id = 0, billing_address = {} } = data;

      return setCustomer({
        address_id: billing_address.id,
        customer_id: id,
        email,
      });
    } catch (err) {
      message.error(
        get(err, 'response.message', 'Failed to get customer detail')
      );
    }
  }

  function handleChangeDiscount(value) {
    setDiscount(value);
  }

  function handleChangeService(serviceID, serviceFieldIdx) {
    !anyChange && setAnyChange(true);
    try {
      const data = serviceList.find(({ id }) => id === Number(serviceID));
      const { id = undefined, price = 0 } = data ?? {};
      return setServiceField((prevState) => {
        const serviceObject = {
          ...prevState[serviceFieldIdx],
          service_id: id,
          price: Number(price),
        };
        return {
          ...prevState,
          [serviceFieldIdx]: serviceObject,
        };
      });
    } catch (err) {
      message.error(
        get(err, 'response.message', 'Failed to get service detail')
      );
    }
  }

  function handleChangeDate(e, serviceFieldIdx) {
    !anyChange && setAnyChange(true);
    const { _d = '' } = e ?? {};
    return setServiceField((prevState) => {
      const serviceObject = { ...prevState[serviceFieldIdx], service_date: _d };
      return { ...prevState, [serviceFieldIdx]: serviceObject };
    });
  }

  function handleChangeQuantity(e, serviceFieldIdx) {
    !anyChange && setAnyChange(true);
    const { qty = 0 } = e ?? {};
    return setServiceField((prevState) => {
      const serviceObject = { ...prevState[serviceFieldIdx], quantity: qty };
      return { ...prevState, [serviceFieldIdx]: serviceObject };
    });
  }

  function handleChangeMeetings(value, serviceFieldIdx) {
    !anyChange && setAnyChange(true);
    return setServiceField((prevState) => {
      const serviceObject = { ...prevState[serviceFieldIdx], meetings: value };
      return { ...prevState, [serviceFieldIdx]: serviceObject };
    });
  }

  function handleChangePrice(e, serviceFieldIdx) {
    const { price = 0 } = e ?? {};
    return setServiceField((prevState) => {
      const serviceObject = {
        ...prevState[serviceFieldIdx],
        price: Number(price),
      };
      return { ...prevState, [serviceFieldIdx]: serviceObject };
    });
  }

  function handleAmount(idx) {
    const { price = 0, quantity = 0 } = serviceField[idx];
    return price * quantity;
  }

  function handleTotalAmount() {
    let totalQty = Object.values(serviceField).reduce(
      (acc, { price = 0, quantity = 0 }) => acc + Number(price) * quantity,
      0
    );

    const calc = totalQty - totalQty * (discount / 100);
    setTotalAmount(calc);
  }

  function handleTwoFormatter(type, value) {
    switch (type) {
      case 'currency':
        return priceFormatter(numberOnlyFormat(value));
      case 'percentage':
        return appendPercentage(numberOnlyFormat(value));
    }
  }

  function handleDeleteRow(idx) {
    setServiceField((prevState) => {
      let filtered = {};

      for (let key in prevState) {
        if (key !== String(idx)) {
          filtered[key] = { ...prevState[key] };
        }
      }

      return filtered;
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        const order_services_attributes = Object.values(serviceField).map(
          (order) => {
            if (!order.service_date) {
              return {
                ...order,
                service_date: moment(),
              };
            }
            return order;
          }
        );

        const payload = {
          customer_id: customer.customer_id,
          ordered_at: get(values, 'ordered_at._d', ''),
          discount,
          address_id: customer.address_id,
          body_proposal: get(values, 'body_proposal', ''),
          order_services_attributes,
        };

        try {
          await ProposalAPI.createProposal({
            order: payload,
          });
          history.push({ pathname: '/proposal' });
        } catch (err) {
          message.error(
            get(err, 'response.message', 'Failed to create proposal')
          );
        }
      }
    });
  }

  async function handleAddCust(payload) {
    try {
      await CustomerAPI.createCustomer({
        customer: payload,
      });
      setShowAddCustModal(false);
      fetchCustomerList();
    } catch (error) {
      handleErr(error);
    }
  }

  async function fetchCustomerList() {
    try {
      const { data } = await CustomerAPI.getCustomerList();

      const customerData = get(data, 'data', []);

      setCustomerList(customerData);
    } catch (err) {
      message.error(
        get(err, 'response.message', 'Failed to get customer list')
      );
    }
  }

  async function fetchServiceList() {
    try {
      const { data } = await ServiceAPI.getServiceList({ q: '' });

      const serviceData = get(data, 'data', []);

      setServiceList(serviceData);
    } catch (err) {
      get(err, 'response.message', 'Failed to get service list');
    }
  }

  useEffect(() => {
    fetchCustomerList();
    fetchServiceList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleTotalAmount();
    // eslint-disable-next-line
  }, [serviceField, discount]);

  return (
    <Layout className="pd-mobile-create-form-proposal">
      <DashboardContainer>
        <Row>
          <Col>
            <Title
              className="pd-align-center pd-margin-top-2xs pd-margin-bottom-md"
              level={3}>
              Create New Proposal
            </Title>
          </Col>
        </Row>
        <PdForm
          onSubmit={handleSubmit}
          onChange={() => !anyChange && setAnyChange(true)}>
          <Row className="form-item" align="middle" type="flex">
            <Col className="form-item-label" xs={24}>
              Proposal Number
            </Col>
            <Col xs={24}>
              <Input placeholder="Auto generate" disabled />
            </Col>
          </Row>
          <Row className="form-item" align="middle" type="flex">
            <Col className="form-item-label" xs={24}>
              Customer Name
            </Col>
            <Col xs={24}>
              <Row gutter={10}>
                <Col span={16}>
                  <Select
                    className="form-item-block"
                    showSearch
                    placeholder="Enter Customer Name"
                    onSelect={(val, customerID) =>
                      handleChangeCustomer(customerID)
                    }>
                    {customersData.map((customer) => {
                      const { name = '', id = 1 } = customer ?? {};
                      return (
                        <Option key={id} value={name}>
                          {name}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
                <Col span={8}>
                  <PdButton
                    block
                    onClick={() => setShowAddCustModal(true)}
                    type="primary">
                    Add Cust
                  </PdButton>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="form-item" align="middle" type="flex">
            <Col className="form-item-label" xs={24}>
              Email
            </Col>
            <Col className="form-item-label-muted" xs={24}>
              <Input value={customer.email} disabled />
            </Col>
          </Row>
          <Row className="form-item" align="middle" type="flex">
            <Col className="form-item-label" xs={24}>
              Proposal Date
            </Col>
            <Col xs={24}>
              {getFieldDecorator(FORM_FIELD.ORDERED_AT, {
                initialValue: moment(),
              })(
                <DatePicker
                  placeholder="Select Proposal Date"
                  format="MM/DD/YYYY"
                />
              )}
            </Col>
          </Row>
          <Row className="form-item">
            <Col className="form-item-label" xs={24}>
              Body Proposal
            </Col>
            <Col xs={24}>
              {getFieldDecorator(FORM_FIELD.BODY_PROPOSAL)(
                <TextArea rows={5}></TextArea>
              )}
            </Col>
          </Row>

          <Row
            xs={24}
            justify="center"
            type="flex"
            className="pd-margin-top-lg">
            <Col xs={24} className="pd-align-center">
              <Text strong>Detail Service</Text>
            </Col>
          </Row>

          {/* Detail Service */}
          {Object.keys(serviceField).map((key) => (
            <div key={key}>
              <Divider />
              {Object.keys(serviceField).length > 1 && (
                <Row type="flex" justify="end">
                  <Icon
                    type="delete"
                    theme="twoTone"
                    twoToneColor="red"
                    onClick={handleDeleteRow.bind(this, key)}
                  />
                </Row>
              )}
              <Row className="form-item" align="middle" type="flex">
                <Col className="form-item-label" xs={24}>
                  Service
                </Col>
                <Col xs={24}>
                  <Select
                    showSearch
                    placeholder="Service Name"
                    onSelect={(val) => handleChangeService(val, key)}>
                    {serviceList
                      .filter(({ status }) => status === 'active')
                      .map((service) => {
                        const { title = '', id = 1 } = service ?? {};
                        return (
                          <Option key={id} value={id}>
                            {title}
                          </Option>
                        );
                      })}
                  </Select>
                </Col>
              </Row>
              <Row className="form-item" align="middle" type="flex">
                <Col className="form-item-label" xs={24}>
                  Service Date
                </Col>
                <Col xs={24}>
                  <DatePicker
                    defaultValue={moment()}
                    format="MM/DD/YYYY"
                    onChange={(e) => handleChangeDate(e, key)}
                  />
                </Col>
              </Row>
              <Row className="form-item" align="middle" type="flex">
                <Col xs={12}>
                  <Col className="form-item-label" xs={24}>
                    Quantity
                  </Col>
                  <Col xs={24}>
                    <InputNumber
                      min={1}
                      placeholder={0}
                      onChange={(e) => handleChangeQuantity({ qty: e }, key)}
                      formatter={numberOnlyFormat}
                    />
                  </Col>
                </Col>
                <Col xs={{ span: 11, offset: 1 }}>
                  <Col className="form-item-label" xs={24}>
                    $ per Unit
                  </Col>
                  <Col xs={24}>
                    <InputNumber
                      onChange={(e) => handleChangePrice({ price: e }, key)}
                      value={serviceField[key].price ?? 0}
                      formatter={handleTwoFormatter.bind(this, 'currency')}
                      parser={priceParser}
                    />
                  </Col>
                </Col>
              </Row>
              <Row className="form-item" align="middle" type="flex">
                <Col className="form-item-label" xs={24}>
                  Amount
                </Col>
                <Col xs={24}>
                  <InputNumber
                    disabled
                    value={handleAmount(key)}
                    formatter={priceFormatter}
                    parser={priceParser}
                  />
                </Col>
              </Row>
              <Row className="form-item" align="middle" type="flex">
                <Col className="form-item-label" xs={24}>
                  Meetings
                </Col>
                <Col xs={24}>
                  <InputNumber
                    min={1}
                    formatter={priceFormatter}
                    parser={priceParser}
                    onChange={(value) => handleChangeMeetings(value, key)}
                  />
                </Col>
              </Row>
            </div>
          ))}

          <Row>
            <Col
              className="add-property-container"
              xs={24}
              onClick={handleAddNewService}>
              <Icon className="pd-margin-right-sm" type="plus-circle" />
              Add Another Property
            </Col>
          </Row>

          <Row className="form-item" align="middle" type="flex">
            <Col className="form-item-label" xs={9}>
              Discount
            </Col>
            <Col xs={{ span: 14, offset: 1 }}>
              <InputNumber
                formatter={handleTwoFormatter.bind(this, 'percentage')}
                parser={parsePercentage}
                onChange={handleChangeDiscount}
              />
            </Col>
          </Row>
          <Row className="form-item" align="middle" type="flex">
            <Col className="form-item-label" xs={9}>
              Total Amount
            </Col>
            <Col xs={{ span: 14, offset: 1 }}>
              <Input prefix="$" value={totalAmount} disabled />
            </Col>
          </Row>

          <Row
            align="middle"
            justify="center"
            type="flex"
            className="submit-container">
            <Col xs={8}>
              <PdButton block ghost onClick={handleBackProposal}>
                Back
              </PdButton>
            </Col>
            <Col xs={{ span: 8, offset: 1 }}>
              <PdButton block onClick={handleSubmit}>
                Submit
              </PdButton>
            </Col>
          </Row>
        </PdForm>
      </DashboardContainer>

      <AddCustModal
        visible={showAddCustModal}
        onCancel={() => setShowAddCustModal(false)}
        onSubmit={handleAddCust}
      />
    </Layout>
  );
}

export const ProposalCreateForm = Form.create({ name: 'proposal' })(
  ProposalCreate
);
