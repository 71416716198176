import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Layout,
  message,
  Row,
  Select,
  Typography,
} from 'antd';
import { get, has, set } from 'lodash';
import moment from 'moment';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdForm from 'components/pd-form';
import PdTable, { PdTableBodyRow, PdTableHeader } from 'components/pd-table';
import { PdRecurringFormModal } from 'components/pd-recurring-form-modal';

import CustomerService from 'api/customer';
import InvoiceApi from 'api/invoice';
import StateService from 'api/state';

import { FORM_RECURRING_DAY_OPTIONS } from 'utils';
import { priceFormatter, priceParser } from 'utils/inputNumberUtils';
import { oneNumberDecimalFormat } from 'utils/numberFormater';

import './style.scss';

const FORM_FIELD = {
  ADDRESS: 'address',
  CITY: 'city',
  EMAIL: 'email',
  FAX: 'fax_number',
  ID: 'id',
  NAME: 'name',
  PHONE: 'phone_number',
  POSTAL_CODE: 'postal_code',
  REGULAR_SERVICE: 'regular_service',
  STATE_CODE: 'state_code',
};

const tableHeaders = [
  { title: 'Service', width: 6 },
  { title: 'Quantity', width: 6 },
  { title: '$ per Unit', width: 6 },
  { title: 'Amount', width: 6 },
];

const { Option } = Select;
const { Text, Title } = Typography;

function ReportCustomerEdit({ form, history, location }) {
  const [addressList, setAddressList] = useState([]);
  const [billingAddress, setBillingAddress] = useState({});
  const [customerData, setCustomerData] = useState({});
  const [listAddress, setListAddress] = useState({ 0: {} });
  const [listState, setListState] = useState([]);
  const [recurringAddContent, setRecurringAddContent] = useState([]);
  // recurringAddId for generate id when click Add Recurring
  const [recurringAddId, setRecurringAddId] = useState(0);
  const [recurringContent, setRecurringContent] = useState([]);
  const [recurringDeleteId, setRecurringDeleteId] = useState([]);
  const [recurringEditContent, setRecurringEditContent] = useState([]);
  const [recurringEditTempData, setRecurringEditTempData] = useState({});
  const [showModal, setShowModal] = useState(false);

  const { getFieldDecorator } = form;

  const horizontalLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  const halfHorizontalLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const customerId = get(location, ['data', 'id'], '');

  const renderDays = (checkedDays) => {
    return (
      <Row className="pd-margin-top-sm">
        <Col lg={24}>
          {FORM_RECURRING_DAY_OPTIONS.map((day, idx) => (
            <Checkbox
              checked={checkedDays.includes(day.toLowerCase())}
              disabled
              key={idx}>
              {day[0]}
            </Checkbox>
          ))}
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    if (!has(location, 'data.id')) {
      history.push({ pathname: '/all-customer' });
    } else {
      fetchStatesData();
      fetchUserData(customerId);
      fetchAddresses(customerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        const listPropertyAddress = [];
        let updatedListAddress = listAddress;
        Object.keys(updatedListAddress).forEach((key) => {
          set(
            updatedListAddress[key],
            'state_code',
            get(updatedListAddress[key], 'state.code', '')
          );
          listPropertyAddress.push(updatedListAddress[key]);
        });

        const payload = {
          billing_address_attributes: billingAddress,
          email: get(values, 'email', ''),
          fax_number: get(values, 'fax_number', ''),
          name: get(values, 'name', ''),
          phone_number: get(values, 'phone_number', ''),
          property_addresses_attributes: listPropertyAddress,
          regular_service: get(values, 'regular_service', 0),
        };

        /* ADD RECURRING PAYLOAD */
        // adjust payload for order recurring
        const addRecurringAttrTemp = recurringAddContent.map((addContent) => {
          const {
            address = {},
            days = [],
            discount = 0,
            frequency = 0,
            order_services = [],
            start_date = '',
          } = addContent;
          const { id: address_id = '' } = address;

          // adjust order_services_attributes
          const order_services_attributes = order_services.map(
            ({ quantity = 0, price = 0, service = {} }) => {
              const { id: service_id = '' } = service;
              return {
                price,
                quantity,
                service_id,
              };
            }
          );

          // remodeling order recurring payload
          const convertedObject = {
            address_id,
            days,
            discount,
            frequency,
            order_services_attributes,
            start_date,
          };

          return convertedObject;
        });
        const addRecurringAttr = addRecurringAttrTemp.filter(
          (addTempContent) => addTempContent
        ); // to make value is not [undefined], but [] instead

        /* EDIT RECURRING PAYLOAD */
        // adjust payload for order recurring
        const editRecurringAttrTemp = recurringEditContent.map(
          (editContent) => {
            const {
              address = {},
              days = [],
              discount = 0,
              frequency = 0,
              id = '',
              order_services = [],
              start_date = '',
            } = editContent;
            const { id: address_id = '' } = address;

            // adjust order_services_attributes
            const order_services_attributes = order_services.map(
              ({ id = '', price = 0, quantity = 0, service = {} }) => {
                const { id: service_id = '' } = service;
                const result = {
                  price,
                  quantity,
                  service_id,
                };
                return id ? { ...result, id } : result;
              }
            );

            // remodeling order recurring payload
            const convertedObject = {
              address_id,
              days,
              discount,
              frequency,
              id,
              order_services_attributes,
              start_date,
            };

            if (typeof id !== 'string') return convertedObject;
          }
        );
        const editRecurringAttr = editRecurringAttrTemp.filter(
          (editTempContent) => editTempContent
        ); // to make value is not [undefined], but [] instead

        /* DELETE RECURRING PAYLOAD */
        const deleteRecurringAttrTemp = recurringDeleteId.map((deleteId) => {
          if (deleteId && typeof deleteId !== 'string') {
            return {
              id: deleteId,
              _destroy: true,
            };
          }
        });
        const deleteRecurringAttr = deleteRecurringAttrTemp.filter(
          (deleteTempContent) => deleteTempContent
        ); // to make value is not [undefined], but [] instead

        /*  COMBINE ADD, EDIT, AND DELETE ATTR */
        const order_recurring_invoices_attributes = [
          ...addRecurringAttr,
          ...editRecurringAttr,
          ...deleteRecurringAttr,
        ];

        try {
          await CustomerService.updateCustomer({
            id: customerId,
            customer: { ...payload, order_recurring_invoices_attributes },
          });

          message.success('Customer has been updated');
          history.push({ pathname: '/all-customer' });
        } catch (error) {
          message.error(
            get(error, 'response.message', 'Failed to Submit Form Data')
          );
        }
      }
    });
  }

  async function fetchStatesData() {
    const { data } = await StateService.getStates();
    setListState(get(data, 'data', []));
  }

  async function fetchUserData(id) {
    try {
      const { data } = await CustomerService.getCustomerDetail(id);
      setCustomerData(get(data, 'data', {}));
      setBillingAddress(get(data, 'data.billing_address', {}));

      // convert property addresses
      const propertyAddresses = get(data, 'data.property_addresses', []);
      propertyAddresses.forEach((address, idx) => {
        setListAddress((prevState) => ({
          ...prevState,
          [idx]: address,
        }));
      });

      // convert order recurring invoices
      const recurringTemp = get(data, ['data', 'order_recurring_invoices'], '');

      const orderRecurringInvoices = recurringTemp.map((recurring) => {
        const rawStartDate = get(recurring, 'start_date', Date.now());
        return {
          ...recurring,
          start_date: moment(rawStartDate).format('MM/DD/YYYY'),
        };
      });
      setRecurringContent(orderRecurringInvoices);
    } catch (error) {
      message.error(
        get(error, 'response.message', 'Failed to get customer data')
      );
    }
  }

  async function fetchAddresses(id) {
    const { data } = await InvoiceApi.getCustomerAddressList(id);
    const list = get(data, 'data', []);
    setAddressList(list);
  }

  function handleAddNewAddress() {
    setListAddress((prevState) => ({
      ...prevState,
      [Object.keys(listAddress).length]: {},
    }));
  }

  function handleBack() {
    history.goBack();
  }

  function handleChangeState(idx, optionObject) {
    const selectedAddress = JSON.parse(optionObject.key);
    const addressObject = listAddress[idx];
    set(addressObject, 'state', selectedAddress);

    setListAddress((prevState) => ({
      ...prevState,
      [idx]: addressObject,
    }));
  }

  function handleChangeAddress({ idx, key, value }) {
    let selectedAddress = listAddress[idx];
    selectedAddress[key] = value;

    setListAddress((prevState) => ({
      ...prevState,
      [idx]: selectedAddress,
    }));
  }

  function handleChangeBillingAddress({ key, value }) {
    setBillingAddress((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }

  function handleChangeBillingState(optionObject) {
    const selectedAddress = JSON.parse(optionObject.key);
    setBillingAddress((prevState) => ({
      ...prevState,
      ['state']: selectedAddress,
    }));
  }

  function handleAddReccuring() {
    setRecurringEditTempData({});
    setShowModal(true);
  }

  function handleModalSubmit(modalData) {
    const id = `add-data${recurringAddId}`;
    setRecurringContent((prev) => [...prev, { ...modalData, id }]);
    setRecurringAddContent((prev) => [...prev, { ...modalData, id }]);
    setRecurringAddId((prev) => prev + 1);
  }

  function handleEditRecurring(editId) {
    const result = recurringContent.find(({ id }) => id === editId);
    setRecurringEditTempData({ ...result, isEdit: true });
    setShowModal(true);
  }

  function handleDeleteRecurring(deleteId) {
    // manage deleteRecurringContent
    recurringContent.map(({ id }) => {
      if (id === deleteId) setRecurringDeleteId((prev) => [...prev, id]);
    });

    // filter in recurringContent
    setRecurringContent((prev) => {
      const recurringContentTemp = [...prev];
      return recurringContentTemp.filter(({ id }) => id !== deleteId);
    });

    // filter in addRecurringContent
    setRecurringAddContent((prev) => {
      const recurringAddContentTemp = [...prev];
      return recurringAddContentTemp.filter(({ id }) => id !== deleteId);
    });

    // filter in editRecurringContent
    setRecurringEditContent((prev) => {
      const recurringEditContentTemp = [...prev];
      return recurringEditContentTemp.filter(({ id }) => id !== deleteId);
    });

    message.success('Successfully delete the recurring');
  }

  function handleChangeRecurringEditContent(editId, editData) {
    // adjust content in main content
    setRecurringContent((prev) => {
      const recurringContentTemp = [...prev];
      return recurringContentTemp.map((content) => {
        const id = get(content, 'id', '');
        if (id === editId) return editData;
        return content;
      });
    });

    // adjust content in add content
    setRecurringAddContent((prev) => {
      const recurringAddContentTemp = [...prev];
      return recurringAddContentTemp.map((content) => {
        const id = get(content, 'id', '');
        if (id === editId) return editData;
        return content;
      });
    });

    // adjust content in edit
    setRecurringEditContent((prev) => [...prev, editData]);
  }

  return (
    <Layout className="pd-cms-form-customer">
      <DashboardContainer>
        <PdForm onSubmit={handleSubmit}>
          <Form.Item label="Customer ID" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.ID, {
              initialValue: get(customerData, 'id', ''),
            })(<Input placeholder="Autofill" disabled />)}
          </Form.Item>
          <Form.Item label="Customer Name" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.NAME, {
              initialValue: get(customerData, 'name', ''),
              rules: [
                {
                  required: true,
                  message: 'Please input Customer Name!',
                },
              ],
            })(<Input placeholder="Enter Customer Name" />)}
          </Form.Item>

          <Form.Item label="Billing Address" {...horizontalLayout}>
            <Input
              value={get(billingAddress, 'address', '')}
              placeholder="Enter Customer Billing Address"
              onChange={(e) =>
                handleChangeBillingAddress({
                  key: FORM_FIELD.ADDRESS,
                  value: get(e, 'target.value', ''),
                })
              }
            />
          </Form.Item>
          <Form.Item label="City" {...horizontalLayout}>
            <Input
              value={get(billingAddress, FORM_FIELD.CITY, '')}
              placeholder="Enter Customer City"
              onChange={(e) =>
                handleChangeBillingAddress({
                  key: FORM_FIELD.CITY,
                  value: get(e, 'target.value', ''),
                })
              }
            />
          </Form.Item>

          <Row>
            <Col lg={12}>
              <Form.Item label="State / Province" {...halfHorizontalLayout}>
                <Select
                  value={get(billingAddress, 'state.name', '')}
                  showSearch
                  placeholder="Choose State / Province"
                  onSelect={(value, optionObject) =>
                    handleChangeBillingState(optionObject)
                  }>
                  {listState.map((state) => (
                    <Option key={JSON.stringify(state)} value={state.name}>
                      {get(state, 'name', '')}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={{ span: 11, offset: 1 }}>
              <Form.Item label="Country" {...halfHorizontalLayout}>
                <Input
                  placeholder="Autofill"
                  disabled
                  value={get(billingAddress, 'country', '')}
                />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item label="Postal Code" {...halfHorizontalLayout}>
                <Input
                  placeholder="Enter Postal Code"
                  value={get(billingAddress, 'postal_code', '')}
                  onChange={(e) =>
                    handleChangeBillingAddress({
                      key: FORM_FIELD.POSTAL_CODE,
                      value: get(e, 'target.value', ''),
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          {/* address Section */}
          {Object.keys(listAddress).map((key, idx) => (
            <>
              <Form.Item label="Property Address" {...horizontalLayout}>
                <Input
                  value={get(listAddress[key], FORM_FIELD.ADDRESS, '')}
                  placeholder="Enter Customer Property Address"
                  onChange={(e) =>
                    handleChangeAddress({
                      idx: idx,
                      key: FORM_FIELD.ADDRESS,
                      value: get(e, 'target.value', ''),
                    })
                  }
                />
              </Form.Item>
              <Form.Item label="City" {...horizontalLayout}>
                <Input
                  value={get(listAddress[key], FORM_FIELD.CITY, '')}
                  placeholder="Enter Property City"
                  onChange={(e) =>
                    handleChangeAddress({
                      idx: idx,
                      key: FORM_FIELD.CITY,
                      value: get(e, 'target.value', ''),
                    })
                  }
                />
              </Form.Item>
              <Row>
                <Col lg={12}>
                  <Form.Item label="State / Province" {...halfHorizontalLayout}>
                    <Select
                      value={get(listAddress[key], 'state.name', '')}
                      showSearch
                      placeholder="Choose State / Province"
                      onSelect={(value, optionObject) =>
                        handleChangeState(idx, optionObject)
                      }>
                      {listState.map((state) => (
                        <Option key={JSON.stringify(state)} value={state.name}>
                          {get(state, 'name', '')}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg={{ span: 11, offset: 1 }}>
                  <Form.Item label="Country" {...halfHorizontalLayout}>
                    <Input
                      placeholder="Autofill"
                      disabled
                      value={get(listAddress[key], 'state.country', '')}
                    />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item label="Postal Code" {...halfHorizontalLayout}>
                    <Input
                      value={get(listAddress[key], 'postal_code', '')}
                      placeholder="Enter Postal Code"
                      onChange={(e) =>
                        handleChangeAddress({
                          idx: idx,
                          key: FORM_FIELD.POSTAL_CODE,
                          value: get(e, 'target.value', ''),
                        })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          ))}

          <Form.Item className="btn-container">
            <Row>
              <Col lg={{ span: 4, offset: 4 }}>
                <PdButton block onClick={handleAddNewAddress}>
                  Add Property
                </PdButton>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item label="Phone Number" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.PHONE, {
              initialValue: get(customerData, 'phone_number', ''),
              rules: [
                {
                  required: true,
                  message: 'Please input Customer Phone!',
                },
              ],
            })(<Input placeholder="Enter Phone Number" />)}
          </Form.Item>
          <Form.Item label="Fax" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.FAX, {
              initialValue: get(customerData, 'fax_number', ''),
            })(<Input placeholder="Enter Fax" />)}
          </Form.Item>
          <Form.Item label="Email Address" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.EMAIL, {
              initialValue: get(customerData, 'email', ''),
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid Email Address!',
                },
                {
                  required: true,
                  message: 'Please input Customer Email Address!',
                },
              ],
            })(<Input placeholder="Enter Customer Email Address" />)}
          </Form.Item>
          <Form.Item label="Regular Service" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.REGULAR_SERVICE, {
              initialValue: get(customerData, 'regular_service', 0),
            })(<InputNumber formatter={priceFormatter} parser={priceParser} />)}
          </Form.Item>

          <Row
            type="flex"
            justify="center"
            align="middle"
            gutter={[8, 24]}
            className="recurring-container">
            <Col lg={22}>
              <Row type="flex" justify="center" align="middle">
                <Col lg={16}>
                  <Title level={3}>Recurring Services</Title>
                </Col>
                <Col lg={8}>
                  <PdButton block onClick={handleAddReccuring}>
                    Add Recurring Service
                  </PdButton>
                </Col>
              </Row>

              {/* Content */}
              {recurringContent.map(
                (
                  {
                    address = {},
                    days = [],
                    discount = '',
                    frequency = 0,
                    id = '',
                    order_services = [],
                    start_date = '',
                    total_price = '',
                  },
                  idx
                ) => (
                  <div className="recurring-content pd-margin-top-md" key={idx}>
                    <Row
                      align="middle"
                      className="btn-delete-float-right"
                      justify="end"
                      type="flex">
                      <Col>
                        <PdButton
                          block
                          className="w-full"
                          onClick={handleDeleteRecurring.bind(this, id)}>
                          Delete
                        </PdButton>
                      </Col>
                    </Row>
                    <Row className="content">
                      <Col className="content-label" lg={5}>
                        Start Date
                      </Col>
                      <Col lg={{ span: 9, offset: 2 }}>
                        <Text>: {start_date}</Text>
                      </Col>
                      <Col lg={{ span: 6, offset: 1 }}></Col>
                    </Row>

                    <Row className="content">
                      <Col className="content-label" lg={6}>
                        Weekly Frequency
                      </Col>
                      <Col lg={{ span: 9, offset: 1 }}>
                        <Text>: {frequency}</Text>
                      </Col>
                    </Row>

                    <Row className="content">
                      <Col className="content-label" lg={5}>
                        Address
                      </Col>
                      <Col lg={{ span: 17, offset: 2 }}>
                        <Text>: {get(address, 'address', '')}</Text>
                      </Col>
                    </Row>

                    <Row className="content pd-margin-bottom-md">
                      <Col className="content-label" lg={5}>
                        Days
                      </Col>
                      <Col lg={{ span: 17, offset: 2 }}>{renderDays(days)}</Col>
                    </Row>

                    {/* Table Section */}
                    <PdTable className="detail-container">
                      <PdTableHeader listHeader={tableHeaders} />
                      {order_services.map(
                        ({ price = 0, quantity = 0, service = {} }, idx) => (
                          <PdTableBodyRow key={idx}>
                            <Col lg={6}>
                              <Text>{get(service, 'title', '')}</Text>
                            </Col>
                            <Col lg={6}>
                              <Text>{quantity}</Text>
                            </Col>
                            <Col lg={6}>
                              <Text>$ {price}</Text>
                            </Col>
                            <Col lg={6}>
                              <Text>$ {price * quantity}</Text>
                            </Col>
                          </PdTableBodyRow>
                        )
                      )}
                    </PdTable>

                    <Row
                      align="middle"
                      className="content pd-margin-top-md"
                      justify="end"
                      type="flex">
                      <Col className="content-label" lg={6}>
                        Discount
                      </Col>
                      <Col lg={{ span: 4, offset: 1 }}>
                        <Text>{discount} %</Text>
                      </Col>
                    </Row>

                    <Row
                      align="middle"
                      className="content"
                      justify="end"
                      type="flex">
                      <Col className="content-label" lg={6}>
                        Total Amount
                      </Col>
                      <Col lg={{ span: 4, offset: 1 }}>
                        <Text>$ {oneNumberDecimalFormat(total_price)}</Text>
                      </Col>
                    </Row>

                    <Row
                      align="middle"
                      className="pd-margin-top-md"
                      justify="center"
                      type="flex">
                      <Col lg={10}>
                        <PdButton
                          block
                          onClick={handleEditRecurring.bind(this, id)}>
                          Edit
                        </PdButton>
                      </Col>
                    </Row>
                  </div>
                )
              )}
            </Col>
          </Row>

          <Form.Item className="btn-container">
            <Row
              align="middle"
              justify="center"
              type="flex"
              className="detail-container-action pd-margin-top-md">
              <Col lg={6}>
                <PdButton block ghost type="primary" onClick={handleBack}>
                  Cancel
                </PdButton>
              </Col>
              <Col lg={{ span: 6, offset: 1 }}>
                <PdButton block htmlType="submit">
                  Submit
                </PdButton>
              </Col>
            </Row>
          </Form.Item>
        </PdForm>
      </DashboardContainer>

      {/* Modal Recurring */}
      {showModal && (
        <PdRecurringFormModal
          addressList={addressList}
          data={recurringEditTempData}
          onCancel={setShowModal.bind(this, false)}
          onChangeRecurringEditContent={handleChangeRecurringEditContent}
          onSubmit={handleModalSubmit}
          visible={showModal}
        />
      )}
    </Layout>
  );
}

export const ReportCustomerPageForm = Form.create({
  name: 'report-customer-edit',
})(ReportCustomerEdit);
