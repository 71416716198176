import React, { useEffect, useState, useContext } from 'react';
import { Redirect } from 'react-router';
import { Col, Layout, message, Row, Select, Typography, Modal } from 'antd';
import moment from 'moment';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdModal from 'components/pd-modal';
import PdPreviewEmail from 'components/pd-preview-email';
import { AuthenticationContext } from 'contexts/authentication';

import { handleErr } from 'utils';
import { numberDate, numberWithCommas } from 'utils/numberFormater';

import ProposalAPI from 'api/proposal';

import './style.scss';

const { Option } = Select;
const { Text, Title } = Typography;
const { confirm } = Modal;

export function ProposalDetail({ history, location }) {
  const [selectedProperty, setSelectedProperty] = useState('');
  const [propertyAddress, setPropertyAddress] = useState([]);
  const [proposalData, setProposalData] = useState({});
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const [isPreviewEmail, setIsPreviewEmail] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('initialState');

  const { authenticatedUserProfile } = useContext(AuthenticationContext);
  const { isUnlimitedPlan = false } = authenticatedUserProfile;

  const { id: proposalDataId = '' } = location ?? {};
  const {
    body_proposal = '',
    customer = {},
    discount = 0,
    id = undefined,
    order_services = [],
    ordered_at = '',
    status = '',
    total_price = 0,
  } = proposalData ?? {};
  const { name = '', email = '' } = customer ?? {};

  const statusList = [
    { value: 'sent', text: 'Sent' },
    { value: 'approved', text: 'Approved' },
    { value: 'rejected', text: 'Rejected' },
  ];

  const PdStatusDropdown = () => (
    <Select onChange={handleOnChangeStatus} defaultValue={status}>
      {statusList.map(({ value, text }, idx) => (
        <Option key={idx} value={value}>
          {text}
        </Option>
      ))}
    </Select>
  );

  async function handleOnChangeStatus(statusValue) {
    try {
      await ProposalAPI.updateProposal(id, { order: { status: statusValue } });
      fetchProposalData(id);
    } catch (error) {
      handleErr(error);
    }
  }

  function handleEditInvoice(id = undefined) {
    history.push({
      pathname: '/proposal/edit',
      id,
    });
  }

  async function fetchProposalData(id) {
    try {
      const { data: proposalData } = await ProposalAPI.getProposalDetail(id);
      const { data = {} } = proposalData ?? {};

      setProposalData(data);
      getPDFUrl({ isComeFromFetch: true });
    } catch (err) {
      handleErr(err);
    }
  }

  async function fetchPropertyAddress(id) {
    try {
      const { data = {} } = await ProposalAPI.getPropertyAddress(id);
      const { data: dataProperty = [] } = data;

      setPropertyAddress(dataProperty);
    } catch (error) {
      handleErr(error);
    }
  }

  async function getPDFUrl({ isComeFromFetch = false } = {}) {
    try {
      if (!isComeFromFetch && status !== 'rejected')
        fetchProposalData(proposalDataId);
      const { data } = await ProposalAPI.getProposalPDF(proposalDataId);
      setPdfUrl(data?.pdf_link);
    } catch (err) {
      handleErr(err);
    }
  }

  function handleBackProposal() {
    history.push({
      pathname: '/proposal',
    });
  }

  async function handleSendMail() {
    try {
      await ProposalAPI.sendMail(id);
      fetchProposalData(id);
      setIsPreviewEmail(false);
      message.success('Successfully send email to the customer');
    } catch (error) {
      handleErr(error);
    }
  }

  async function handlePDFPrint() {
    try {
      if (pdfUrl === '') {
        await getPDFUrl();
      }

      window.open(pdfUrl, '_blank');
    } catch (err) {
      handleErr(err);
    }
  }

  async function handleAction(id, type) {
    try {
      if (type === 'reject') {
        await handleOnChangeStatus('rejected');
        handleBackProposal();
      } else {
        await handleOnChangeStatus('approved');
        history.push('/work-orders');
      }
    } catch (err) {
      handleErr(err);
    }
  }

  async function handleApprove() {
    try {
      if (!customer?.billing_address?.id) {
        message.info(
          'Please complete your customer data before generate work order',
          5
        );
        history.push({
          pathname: '/customer-setup/edit',
          data: customer,
          function: () => {
            history.push({
              pathname: '/proposal/detail',
              id,
            });
          },
        });
        return;
      }

      if (!proposalData?.address?.id) {
        await fetchPropertyAddress(customer.id);
        setShowPropertyModal(true);
        return;
      }

      await ProposalAPI.approveProposal(id);
      history.push('/work-orders');
    } catch (error) {
      handleErr(error);
    }
  }

  async function handleUpdatePropertyAddress() {
    try {
      await ProposalAPI.updateProposal(id, {
        order: { address_id: selectedProperty },
      });
      fetchProposalData(id);
      setShowPropertyModal(false);
    } catch (error) {
      handleErr(error);
    }
  }

  function handleDeleteProposal(id) {
    confirm({
      title: 'Are you sure you want to delete this proposal?',
      content: "You can't undo this action",
      cancelButtonProps: { ghost: true },
      onOk: async () => {
        try {
          await ProposalAPI.deleteProposal(id);
          history.goBack();
        } catch (err) {
          message.error(err?.response?.message || 'Failed to delete proposals');
        }
      },
    });
  }

  useEffect(() => {
    fetchProposalData(proposalDataId);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!proposalDataId) {
    return <Redirect to="/proposal" />;
  }

  return (
    <Layout className="pd-mobile-detail-order">
      <Row xs={24} justify="center" type="flex">
        <Col className="pd-margin-top-md" xs={24}>
          <Title className="pd-align-center" level={3}>
            Detail Proposal
          </Title>
        </Col>
      </Row>
      <DashboardContainer>
        <Row xs={24}>
          <Col xs={17}>
            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" xs={10}>
                Proposal No.
              </Col>
              <Col xs={14}>: {id}</Col>
            </Row>

            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" xs={10}>
                Customer Name
              </Col>
              <Col xs={14}>: {name}</Col>
            </Row>
            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" xs={10}>
                Email
              </Col>
              <Col xs={14}>: {email}</Col>
            </Row>
            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" xs={10}>
                Proposal Date
              </Col>
              <Col xs={14}>: {numberDate(ordered_at)}</Col>
            </Row>
            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" xs={10}>
                Description
              </Col>
              <Col xs={14}>: {body_proposal}</Col>
            </Row>
            <Row align="middle" type="flex">
              <Col className="detail-container-label" xs={10}>
                Status
              </Col>
              <Col xs={14}>
                {status === 'created' ? (
                  status.charAt(0).toUpperCase() + status.slice(1)
                ) : (
                  <PdStatusDropdown />
                )}
              </Col>
            </Row>
          </Col>
          <Col xs={7}>
            <PdButton
              block
              ghost
              type="primary"
              onClick={handleEditInvoice.bind(this, id)}>
              Edit
            </PdButton>
            <PdButton
              className="pd-margin-top-sm"
              block
              ghost
              type="danger"
              onClick={() => handleDeleteProposal(id)}>
              Delete
            </PdButton>
            {status === 'sent' && (
              <>
                <PdButton
                  className="pd-margin-top-sm"
                  block
                  type="primary"
                  onClick={handleApprove}>
                  Generate WO
                </PdButton>
                <PdButton
                  className="pd-margin-top-sm"
                  block
                  type="primary"
                  onClick={handleAction.bind(this, id, 'reject')}>
                  Reject
                </PdButton>
              </>
            )}
          </Col>
        </Row>
      </DashboardContainer>
      <Row xs={24} justify="center" type="flex">
        <Col xs={24} className="pd-align-center">
          <Text strong>Detail Service</Text>
        </Col>
      </Row>
      {order_services.map(
        ({
          id = '',
          price = 0,
          quantity = 0,
          service = {},
          service_date = Date.now(),
          meetings = 1,
        }) => {
          const { title = '' } = service;
          return (
            <DashboardContainer key={id}>
              <Row>
                <Col xs={12}>
                  <Text strong>Service</Text>
                </Col>
                <Col xs={12} className="detail-container">
                  <Text>{title}</Text>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Text strong>Service Date</Text>
                </Col>
                <Col xs={12} className="detail-container">
                  <Text>{moment(service_date).format('MM/DD/YYYY')}</Text>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Text strong>Quantity</Text>
                </Col>
                <Col xs={12} className="detail-container">
                  <Text>{quantity}</Text>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Text strong>$ per Unit</Text>
                </Col>
                <Col xs={12} className="detail-container">
                  <Text>{price}</Text>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Text strong>Amount</Text>
                </Col>
                <Col xs={12} className="detail-container">
                  <Text>${numberWithCommas(price * quantity)}</Text>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Text strong>Meetings</Text>
                </Col>
                <Col xs={12}>
                  <Text>{meetings}</Text>
                </Col>
              </Row>
            </DashboardContainer>
          );
        }
      )}

      <Row className="pd-margin-top-sm pd-margin-bottom-md">
        <Row className="pd-margin-bottom-md">
          <Col xs={{ span: 10, offset: 2 }}>
            <Text strong>Discount</Text>
          </Col>
          <Col xs={12}>
            <Text>{discount}%</Text>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 10, offset: 2 }}>
            <Text strong>Total Amount</Text>
          </Col>
          <Col xs={12}>
            <Text>${numberWithCommas(total_price)}</Text>
          </Col>
        </Row>
      </Row>
      <Row className="button-container" justify="center" type="flex">
        {status === 'approved' && (
          <Col xs={17} className="pd-margin-bottom-md">
            <PdButton block onClick={handleAction.bind(this, id, 'approve')}>
              Generate Work Order
            </PdButton>
          </Col>
        )}
        <Col xs={17} className="pd-margin-bottom-md">
          <PdButton
            block
            type="primary"
            onClick={() => setIsPreviewEmail(true)}
            disabled={!isUnlimitedPlan}>
            Send Mail to Customer
          </PdButton>
        </Col>
        <Col xs={8}>
          <PdButton block ghost type="primary" onClick={handleBackProposal}>
            Back
          </PdButton>
        </Col>
        <Col xs={{ span: 8, offset: 1 }} className="pd-margin-bottom-md">
          <PdButton block onClick={handlePDFPrint}>
            PDF Print
          </PdButton>
        </Col>
      </Row>

      <PdModal
        modalTitle="Property Address"
        onCancel={() => setShowPropertyModal(false)}
        visible={showPropertyModal}>
        <Row type="flex" justify="center" align="middle">
          <Col span={8}>
            <Text>Address</Text>
          </Col>
          <Col span={15}>
            <Select
              onChange={setSelectedProperty}
              value={selectedProperty}
              style={{ width: '100%' }}>
              {propertyAddress.map(({ id, address }) => (
                <Option key={id} value={id}>
                  {address}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row type="flex" justify="center" className="pd-margin-top-md">
          <Col span={6}>
            <PdButton
              block
              type="primary"
              onClick={handleUpdatePropertyAddress}>
              Submit
            </PdButton>
          </Col>
        </Row>
      </PdModal>

      {/* modal preview email */}
      <PdPreviewEmail
        modalClassName="pd-modal-mobile"
        visible={isPreviewEmail}
        onCancel={() => setIsPreviewEmail(false)}
        previewUrl={pdfUrl}
        onSendEmail={handleSendMail}
        currentRecipient={email}
      />
    </Layout>
  );
}

export default { ProposalDetail };
