import api from './index';

export default {
  getSettingsValue() {
    return api.get('/settings');
  },
  updateSettingsValue(payload) {
    return api.put('/settings', payload);
  },
};
