import React, { useState, useEffect } from 'react';
import { Col, Icon, Layout, Row, Typography, message, Select } from 'antd';
import Search from 'antd/lib/input/Search';
import moment from 'moment';

import PdButton from 'components/pd-button';
import PdMobileTable from 'components/pd-mobile-table';

import InvoiceAPI from 'api/invoice';

import { numberWithCommas } from 'utils/numberFormater';
import { ORDER_STATUS } from 'utils';

import './style.scss';
import { DATE_FORMAT } from 'utils';

const { Title } = Typography;
const { Option } = Select;

const itemPerPage = 5;

export function Orders({ history }) {
  const [customerList, setCustomerList] = useState([]);
  const [status, setStatus] = useState('');
  const [query, setQuery] = useState('');

  const columns = [
    {
      title: 'WO No.',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Customer',
      dataIndex: 'customer.name',
      key: 'name',
      align: 'center',
      sorter: (a, b) => (a.customer.name < b.customer.name ? -1 : 1),
    },
    {
      title: 'WO Date',
      dataIndex: 'ordered_at',
      key: 'ordered_at',
      align: 'center',
      sorter: (a, b) => moment(a.ordered_at) - moment(b.invoice_date),
    },
    {
      title: 'Amount',
      dataIndex: 'total_price',
      key: 'total_price',
      render: ColumnAmount,
      align: 'center',
    },
    {
      title: 'Payment Status',
      dataIndex: 'paid',
      key: 'paid',
      render: ColumnStatus,
      align: 'center',
    },
    {
      title: '',
      key: 'action',
      render: ColumnAction,
    },
  ];

  function ColumnAmount(text) {
    return `$ ${numberWithCommas(text)}`;
  }

  function ColumnAction(rowData = {}) {
    return (
      <Row justify="center" type="flex">
        <Col xs={24} onClick={handleDetailOrder.bind(this, rowData)}>
          <Icon type="search" />
        </Col>
      </Row>
    );
  }

  function ColumnStatus(status) {
    return (
      <Icon
        type="check-circle"
        theme="twoTone"
        twoToneColor={status ? 'green' : 'red'}
      />
    );
  }

  function handleDetailOrder(rowData = {}) {
    // write code here
    history.push({
      pathname: `/work-orders/detail`,
      id: rowData.id,
    });
  }

  function handleCreateInvoice() {
    history.push({
      pathname: `/work-orders/create`,
    });
  }

  function handleSearch(ev) {
    const { value = '' } = ev.target;
    setQuery(value);
  }

  async function fetchCustomerList({
    limit = 6,
    cursor = 1,
    q = '',
    status = '',
  }) {
    try {
      const { data } = await InvoiceAPI.getAllInvoiceInfo({
        limit,
        cursor,
        q,
        status,
      });
      let { data: customerData = {} } = data;

      customerData = customerData.map((customer) => {
        return {
          ...customer,
          ordered_at: moment(customer.ordered_at).format(DATE_FORMAT),
        };
      });

      setCustomerList(customerData);
    } catch (err) {
      message.error(err.response.message || 'Failed to get customer data');
    }
  }

  function handleSelectStatus(value) {
    setStatus(value === 'all' ? '' : value);
  }

  useEffect(() => {
    fetchCustomerList({ limit: 1000, q: query, status });
  }, [query, status]);

  return (
    <Layout className="pd-mobile-orders">
      <Title className="pd-align-center pd-margin-top-md" level={3}>
        Orders
      </Title>
      <Row gutter={10}>
        <Col xs={14} offset={1} className="pd-margin-bottom-sm">
          <Search
            placeholder="Search by customer name"
            onChange={handleSearch}
          />
        </Col>
        <Col xs={8}>
          <Select
            defaultValue="all"
            placeholder="Status"
            className="w-full"
            onSelect={handleSelectStatus}>
            {ORDER_STATUS.map(({ key, text }) => (
              <Option key={key} value={key}>
                {text}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row className="pd-margin-bottom-lg">
        <Col xs={8} offset={1}>
          <PdButton block onClick={handleCreateInvoice}>
            Create New
          </PdButton>
        </Col>
      </Row>
      <PdMobileTable
        dataSource={customerList}
        columns={columns}
        rowKey={customerList.id}
        pagination={{
          pageSize: itemPerPage,
        }}
      />
    </Layout>
  );
}

export default { Orders };
