import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  Col,
  Form,
  Input,
  Layout,
  message,
  Row,
  Typography,
  Modal,
} from 'antd';
import { get } from 'lodash';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdForm from 'components/pd-form';
import PdInputNumber from 'components/pd-input-number';

import ServiceAPI from 'api/service';

import { FORM_TITLE_LIST, FORM_BUTTON_LIST } from 'utils';

import './style.scss';

const FORM_FIELD = {
  SERVICE_NAME: 'title',
  PRICE: 'price',
  DESCRIPTION: 'description',
};

const { TextArea } = Input;
const { Title } = Typography;
const { confirm } = Modal;

function ServiceCreateEdit({ form, history, location }) {
  const [serviceData, setServiceData] = useState({});
  const [anyChange, setAnyChange] = useState(false);
  const { id = '', pathname = '' } = location;

  const { getFieldDecorator } = form;
  const { page } = useParams();

  async function handleCreateSubmit(values) {
    try {
      await ServiceAPI.createService({
        service: values,
      });
      message.success('Service created successfully');
      history.push({ pathname: '/service' });
    } catch (err) {
      message.error(
        get(err, 'response.data.message', 'Failed to create service')
      );
    }
  }

  async function handleEditSubmit(values) {
    try {
      await ServiceAPI.updateService({
        id,
        service: values,
      });
      message.success('Service updated successfully');
      history.push({ pathname: '/service' });
    } catch (err) {
      message.error(
        get(err, 'response.data.message', 'Failed to update service')
      );
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        id ? handleEditSubmit(values) : handleCreateSubmit(values);
      }
    });
  }

  function handleCancel() {
    if (anyChange) {
      confirm({
        title: 'Are you sure?',
        content: 'Your changes will be lost',
        cancelButtonProps: { ghost: true },
        onOk: () => {
          history.goBack();
        },
      });
    } else {
      history.goBack();
    }
  }

  async function fetchServiceData(id) {
    try {
      const { data } = await ServiceAPI.getServiceDetail(id);
      setServiceData(get(data, 'data', {}));
    } catch (err) {
      message.error(get(err, 'response.data.message', 'Failed to get service'));
    }
  }

  function priceFormatter(value) {
    return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  function priceParser(value) {
    return value.replace(/\$\s?|(,*)/g, '');
  }

  useEffect(() => {
    if (pathname.includes('edit')) {
      if (id) {
        fetchServiceData(id);
      } else {
        history.push({
          pathname: '/service',
        });
      }
    }
  }, [history, id, pathname]);

  return (
    <Layout className="pd-mobile-form-service">
      <DashboardContainer>
        <Col xs={24}>
          <Title className="pd-align-center pd-margin-top-xs" level={3}>
            {FORM_TITLE_LIST[page] + 'Services'}
          </Title>
        </Col>
        <PdForm
          onSubmit={handleSubmit}
          onChange={() => !anyChange && setAnyChange(true)}>
          <Form.Item label="Service Name">
            {getFieldDecorator(FORM_FIELD.SERVICE_NAME, {
              initialValue: get(serviceData, 'title', ''),
              rules: [
                {
                  required: true,
                  message: 'Please input Service Name!',
                },
              ],
            })(<Input placeholder="Enter Service Name" />)}
          </Form.Item>
          <Form.Item label="Price / unit">
            {getFieldDecorator(FORM_FIELD.PRICE, {
              initialValue: get(serviceData, 'price', ''),
              rules: [
                {
                  required: true,
                  message: 'Please input your Last Name!',
                },
              ],
            })(
              <PdInputNumber
                defaultValue={0}
                min={0}
                formatter={priceFormatter}
                parser={priceParser}
              />
            )}
          </Form.Item>
          <Form.Item label="Description">
            {getFieldDecorator(FORM_FIELD.DESCRIPTION, {
              initialValue: get(serviceData, 'description', ''),
            })(<TextArea rows={4} placeholder="Enter Service Description" />)}
          </Form.Item>

          <Form.Item className="btn-container">
            <Row justify="center" type="flex">
              <Col xs={8}>
                <PdButton block onClick={handleCancel}>
                  Cancel
                </PdButton>
              </Col>
              <Col xs={{ span: 8, offset: 2 }}>
                <PdButton block htmlType="submit">
                  {FORM_BUTTON_LIST[page]}
                </PdButton>
              </Col>
            </Row>
          </Form.Item>
        </PdForm>
      </DashboardContainer>
    </Layout>
  );
}

export const ServicePageForm = Form.create({ name: 'service' })(
  ServiceCreateEdit
);
