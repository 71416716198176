import React from 'react';
import { DatePicker } from 'antd';

import './style.scss';

function PdDatePicker({ ...props }) {
  return <DatePicker className="pd-date-picker" {...props} />;
}

export default PdDatePicker;
