// TODO: Handle PDF PRINT Feature

import React, { useEffect, useState } from 'react';
import { Col, Layout, message, Row } from 'antd';
import { get } from 'lodash';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdTable, { PdTableBodyRow, PdTableHeader } from 'components/pd-table';

import { numberDate, numberWithCommas } from 'utils/numberFormater';

import InvoiceAPI from 'api/invoice';

import { handleErr } from 'utils';

import './style.scss';

export function InvoiceInformationDetail({ history, location }) {
  const [invoiceData, setInvoiceData] = useState({});
  const {
    invoice_number = '-',
    total_price = 0,
    order_services = [],
  } = invoiceData ?? {};
  const {
    billing_address = {},
    email = '-',
    fax_number = '-',
    last_invoice_date = '-',
    name = '-',
    phone_number = '-',
    regular_service = 0,
  } = invoiceData.customer ?? {};
  const {
    address = '-',
    city = '-',
    country = '-',
    postal_code = '-',
    state = {},
  } = billing_address || {};
  const { name: stateName = '-' } = state || {};

  const { id: locationID = '' } = location ?? {};

  const tableHeaders = [
    { title: 'Service', width: 7 },
    { title: 'Service Date', width: 5 },
    { title: 'Quantity', width: 4 },
    { title: '$ per Unit', width: 4 },
    { title: 'Amount', width: 4 },
  ];

  async function fetchInvoiceData(id) {
    try {
      const { data } = await InvoiceAPI.getInvoiceDetail(id);
      setInvoiceData(get(data, 'data', []));
    } catch (err) {
      message.error(get(err, 'response.message', 'Failed to get invoice data'));
    }
  }

  function handleBackInvoice() {
    history.push({
      pathname: '/work-orders-information',
    });
  }

  async function handlePDFPrint() {
    try {
      const { data } = (await InvoiceAPI.getInvoicePDF(locationID)) || {};

      window.open(data?.pdf_link, '_blank');
    } catch (err) {
      message.error(
        err?.response?.message || 'Failed to get PDF print of the work order'
      );
    }
  }

  async function handleSendMail() {
    try {
      await InvoiceAPI.sendMail(locationID);
      message.success('Successfully send email to the customer');
    } catch (error) {
      handleErr(error);
    }
  }

  function PdTableBodyRowService(props) {
    const { title, service_date, quantity, price } = props;
    return (
      <PdTableBodyRow>
        <Col lg={7}>{title}</Col>
        <Col lg={5}>{numberDate(service_date)}</Col>
        <Col lg={4}>{quantity}</Col>
        <Col lg={4}>{`$ ${numberWithCommas(price)}`}</Col>
        <Col lg={4}>{`$ ${numberWithCommas(price * quantity)}`}</Col>
      </PdTableBodyRow>
    );
  }

  useEffect(() => {
    !locationID
      ? history.push('/work-orders-information')
      : fetchInvoiceData(locationID);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout className="pd-cms-invoice-detail">
      <DashboardContainer>
        <Row align="middle" type="flex" className="detail-container">
          <Col className="detail-container-label" lg={5}>
            Work Order Number
          </Col>
          <Col lg={10}>: {invoice_number}</Col>
        </Row>
        <Row align="middle" type="flex" className="detail-container">
          <Col className="detail-container-label" lg={5}>
            Work Order Date
          </Col>
          <Col lg={10}>: {numberDate(last_invoice_date)}</Col>
        </Row>
        <Row align="middle" type="flex" className="detail-container">
          <Col className="detail-container-label" lg={5}>
            Customer Name
          </Col>
          <Col lg={10}>: {name}</Col>
        </Row>
        <Row align="middle" type="flex" className="detail-container">
          <Col className="detail-container-label" lg={5}>
            Billing Address
          </Col>
          <Col lg={10}>: {address}</Col>
        </Row>
        <Row align="middle" type="flex" className="detail-container">
          <Col className="detail-container-label" lg={5}>
            City
          </Col>
          <Col lg={3}>: {city}</Col>
          <Col className="detail-container-label" lg={4}>
            State / Province
          </Col>
          <Col lg={4}>: {stateName}</Col>
          <Col lg={3} className="detail-container-label">
            Country
          </Col>
          <Col lg={4}>: {country}</Col>
        </Row>
        <Row align="middle" type="flex" className="detail-container">
          <Col className="detail-container-label" lg={5}>
            Postal Code
          </Col>
          <Col lg={10}>: {postal_code}</Col>
        </Row>
        <Row align="middle" type="flex" className="detail-container">
          <Col className="detail-container-label" lg={5}>
            Email
          </Col>
          <Col lg={10}>: {email}</Col>
        </Row>
        <Row align="middle" type="flex" className="detail-container">
          <Col className="detail-container-label" lg={5}>
            Phone number
          </Col>
          <Col lg={10}>: {phone_number}</Col>
        </Row>
        <Row align="middle" type="flex" className="detail-container">
          <Col className="detail-container-label" lg={5}>
            Fax
          </Col>
          <Col lg={10}>: {fax_number}</Col>
        </Row>
        <Row align="middle" type="flex" className="detail-container">
          <Col className="detail-container-label" lg={5}>
            Regular service_date
          </Col>
          <Col lg={10}>: $ {numberWithCommas(regular_service)}</Col>
        </Row>

        {/* Table Section */}
        <PdTable className="detail-container">
          <PdTableHeader listHeader={tableHeaders} />
          {order_services.map((orderService) => {
            const {
              id = '-',
              quantity = 0,
              service = {},
              service_date = '-',
            } = orderService ?? {};
            const { title = '-', price = 0 } = service ?? {};
            return (
              <PdTableBodyRowService
                key={id}
                service_date={service_date}
                quantity={quantity}
                title={title}
                price={price}
              />
            );
          })}
        </PdTable>

        <Row
          align="middle"
          justify="end"
          type="flex"
          className="detail-container">
          <Col className="detail-container-label" lg={{ span: 3 }}>
            Total Amount
          </Col>
          <Col lg={{ span: 4, offset: 1 }}>{`$ ${numberWithCommas(
            total_price
          )}`}</Col>
        </Row>

        <Row
          align="middle"
          justify="center"
          type="flex"
          className="detail-container-action">
          <Col lg={4}>
            <PdButton block ghost type="primary" onClick={handleBackInvoice}>
              Back
            </PdButton>
          </Col>

          <Col lg={{ span: 7, offset: 1 }}>
            <PdButton block onClick={handleSendMail}>
              Send Mail to Customer
            </PdButton>
          </Col>
          <Col lg={{ span: 4, offset: 1 }}>
            <PdButton block onClick={handlePDFPrint}>
              PDF Print
            </PdButton>
          </Col>
        </Row>
      </DashboardContainer>
    </Layout>
  );
}

export default { InvoiceInformationDetail };
