import api from './index';
import { omitBy } from 'lodash';
import { isNil } from 'utils';

export default {
  getBillingInvoiceList({
    cursor = 1,
    limit = 25000,
    q = '',
    period = '',
    status = '',
  }) {
    const params = {
      cursor,
      limit,
      q,
      period,
      status,
    };
    const url = '/billing_invoices';
    return api.get(url, {
      params: omitBy(params, isNil),
    });
  },
  getBillingInvoiceDetail(id) {
    return api.get(`/billing_invoices/${id}`);
  },
  sendMail(id) {
    return api.post(`/billing_invoices/${id}/send_email`);
  },
  updateMessage(id, message = {}) {
    return api.put(`/billing_invoices/${id}`, message);
  },
  resyncDetail(id) {
    return api.post(`/billing_invoices/${id}/resync_invoice`);
  },
  createOneTimeService(payload) {
    return api.post(`/billing_invoices/`, { billing_invoice: payload });
  },
};
