import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Col, Row, Select } from 'antd';

import PdButton from 'components/pd-button';
import PdModal from 'components/pd-modal';
import PdInputNumber from 'components/pd-input-number';
import PdDatePicker from 'components/pd-datepicker';

import CustomerApi from 'api/customer';

import { priceFormatter, priceParser } from 'utils/inputNumberUtils';
import './style.scss';
import { DATE_FORMAT, handleErr } from 'utils';
import moment from 'moment';
import { identity } from 'lodash';

const { Option } = Select;

function PaymentModal({ handleSubmit = identity, ...props }) {
  const [maxAmount, setMaxAmount] = useState(0);
  const [options, setOptions] = useState([]);
  const [payload, setPayload] = useState({
    order_invoice_id: 0,
    payment_date: moment()._d,
    amount: 0,
  });

  const location = useLocation();

  const modalConfig = {
    modalTitle: 'Payment',
    modalClassName: 'pd-modal-mobile',
  };

  function handleChange(value, type) {
    setPayload((prev) => {
      let newState = { ...prev, [type]: value };

      if (type === 'order_invoice_id') {
        const { amount = 0 } = options.find((option) => option.value === value);
        newState = { ...newState, amount: +amount };
        setMaxAmount(+amount);
      }

      return newState;
    });
  }

  function handleClickSubmit() {
    handleSubmit({ payment: payload });
  }

  async function fetchOrderPayment() {
    try {
      const { data } = await CustomerApi.getCustomerOrderForPayment(
        location.id
      );
      let { data: paymentList = [] } = data;
      paymentList = paymentList.map(
        ({ id = '', invoice_number = '', payment_remaining = 0 }) => ({
          title: invoice_number,
          value: id,
          amount: payment_remaining,
        })
      );
      setOptions(paymentList);
    } catch (error) {
      handleErr(error);
    }
  }

  useEffect(() => {
    fetchOrderPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PdModal className="pd-modal-payment" {...modalConfig} {...props}>
      <Row
        align="middle"
        className="payment-container"
        justify="center"
        type="flex">
        <Col xs={24} className="payment-label">
          Transaction Date
        </Col>
        <Col xs={24}>
          <PdDatePicker
            format={DATE_FORMAT}
            defaultValue={moment()}
            onChange={(value) => handleChange(value._d, 'payment_date')}
          />
        </Col>
      </Row>
      <Row
        align="middle"
        className="payment-container"
        justify="center"
        type="flex">
        <Col xs={24} className="payment-label">
          Work Orders Type
        </Col>
        <Col xs={24}>
          <Select
            className="w-full"
            onChange={(value) => handleChange(value, 'order_invoice_id')}
            placeholder="Select type">
            {options.map(({ title = '', value = '' }, idx) => (
              <Option key={idx} value={value}>
                {title}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row
        align="middle"
        className="payment-container"
        justify="center"
        type="flex">
        <Col xs={24} className="payment-label">
          Amount
        </Col>
        <Col xs={24}>
          <PdInputNumber
            value={payload.amount}
            min={0}
            max={maxAmount}
            formatter={priceFormatter}
            parser={priceParser}
            onChange={(value) => handleChange(value, 'amount')}
          />
        </Col>
      </Row>
      <Row
        align="middle"
        className="payment-container"
        justify="center"
        type="flex">
        <Col xs={10}>
          <PdButton block onClick={handleClickSubmit}>
            Submit
          </PdButton>
        </Col>
      </Row>
    </PdModal>
  );
}

export default PaymentModal;
