import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { UserContext } from 'contexts/user';

import { ADMIN_PERMISSION } from 'utils';

function AuthenticatedGuardRoute(props) {
  const { component: WrapperComponent, path, ...rest } = props;

  const {
    state: { userSettings },
  } = useContext(UserContext);

  const {
    background_color = '',
    button_color = '',
    title_font_color = '',
  } = userSettings;

  function setInitialColor() {
    document.documentElement.style.setProperty(
      '--backgroundTheme',
      background_color
    );
    document.documentElement.style.setProperty('--buttonTheme', button_color);
    document.documentElement.style.setProperty(
      '--titleTheme',
      title_font_color
    );
  }

  useEffect(() => {
    if (ADMIN_PERMISSION.includes('setting')) {
      setInitialColor();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Route
      exact
      path={path}
      {...rest}
      render={(props) => {
        return localStorage.getItem('cms_token') ? (
          <WrapperComponent {...props} />
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
}

AuthenticatedGuardRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  path: PropTypes.string.isRequired,
};

export default AuthenticatedGuardRoute;
