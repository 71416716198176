import React, { useEffect, useState } from 'react';
import { Col, Layout, Row, Select, Modal, message } from 'antd';
import Search from 'antd/lib/input/Search';
import { get } from 'lodash';

import ServiceAPI from 'api/service';

import { PdDownloadCSV } from 'components/pd-download-csv';
import { PdCsvUploader } from 'components/pd-csv-uploader';
import DashboardContainer from 'components/dashboard-container';
import PdAntdTable from 'components/pd-antd-table';
import PdButton from 'components/pd-button';

import { numberWithCommas } from 'utils/numberFormater';
import {
  handleErr,
  FORM_USER_MANAGEMENT_STATUS_BUTTON,
  FORM_USER_MANAGEMENT_ACTION_BUTTON,
} from 'utils';

import './style.scss';

const { confirm } = Modal;
const { Option } = Select;

const itemPerPage = 6;

export function Service({ history }) {
  const [cursor, setCursor] = useState(1);
  const [filter, setFilter] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [serviceList, setServiceList] = useState([]);

  const csvRequiredFieldName = ['service_name'];

  const columns = [
    {
      title: 'Service Name',
      dataIndex: 'title',
      key: 'title',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: 'Price/unit',
      dataIndex: 'price',
      key: 'price',
      render: ColumnPrice,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: ColumnRole,
    },
    {
      title: '',
      key: 'id',
      render: ColumnAction,
      width: '30%',
    },
  ];

  const statusFilter = [
    { value: '', text: 'All' },
    { value: 'active', text: 'Active' },
    { value: 'inactive', text: 'Inactive' },
  ];

  function ColumnRole(text) {
    return <div className="text-role">{text}</div>;
  }

  function handleButtonAction(status = '', id = '') {
    if (status === 'active') {
      handleEditService(id);
    } else {
      handleDeleteService(id);
    }
  }

  function ColumnAction(data = {}) {
    const { id, status } = data;
    return (
      <Row>
        <Col lg={{ span: 10, offset: 2 }}>
          <PdButton
            block
            ghost
            type="primary"
            onClick={toggleStatus.bind(this, id, status)}>
            {FORM_USER_MANAGEMENT_STATUS_BUTTON[data.status]}
          </PdButton>
        </Col>
        <Col lg={{ span: 10, offset: 2 }}>
          <PdButton
            block
            onClick={handleButtonAction.bind(this, status, id)}
            type="primary">
            {FORM_USER_MANAGEMENT_ACTION_BUTTON[status]}
          </PdButton>
        </Col>
      </Row>
    );
  }

  function ColumnPrice(price) {
    return <>{`$${numberWithCommas(price)}`}</>;
  }

  function handleAddService() {
    history.push({
      pathname: '/service/create',
    });
  }

  function handleSearchService(e) {
    setSearchQuery(get(e, 'target.value', ''));
  }

  function handleEditService(id = '') {
    history.push({
      pathname: '/service/edit',
      id,
    });
  }

  function handleDeleteService(id) {
    confirm({
      title: 'Do you want to delete this service',
      content: "You can't undo this action",
      cancelButtonProps: { ghost: true },
      onOk: async () => {
        try {
          await ServiceAPI.deleteService(id);

          message.success('Successfully delete a service');
          fetchServiceList({
            cursor,
            q: searchQuery,
          });
        } catch (err) {
          handleErr(err);
        }
      },
    });
  }

  async function fetchServiceList({
    limit = itemPerPage,
    cursor = 1,
    q = '',
    status = filter,
  }) {
    const { data } = await ServiceAPI.getServiceList({
      limit,
      cursor,
      q,
      ...(status ? { status } : {}),
    });
    const serviceData = get(data, 'data', []);
    serviceData.forEach((service) => {
      service['key'] = get(service, 'id', '');
    });
    setServiceList(serviceData);
  }

  async function toggleStatus(id, status) {
    try {
      if (status === 'active') {
        await ServiceAPI.deactiveService(id);
      } else {
        await ServiceAPI.reactiveService(id);
      }

      fetchServiceList({
        cursor,
        q: searchQuery,
      });
    } catch (err) {
      message.error(err?.response?.message, 'Failed to change status');
    }
  }

  useEffect(() => {
    fetchServiceList({
      cursor: cursor,
      q: searchQuery,
      status: filter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor, filter, searchQuery]);

  return (
    <Layout className="pd-cms-admin">
      {/* Section that display chart */}
      <DashboardContainer>
        <Row align="middle" type="flex" gutter={10}>
          <Col lg={8}>
            <Search
              onChange={handleSearchService}
              placeholder="Search Service"
            />
          </Col>
          <Col lg={3}>
            <Select className="w-full" defaultValue={''} onChange={setFilter}>
              {statusFilter.map((status) => (
                <Option key={status.value} value={status.value}>
                  {status.text}
                </Option>
              ))}
            </Select>
          </Col>
          <Col lg={{ span: 3, offset: 4 }}>
            <PdCsvUploader
              afterUpload={fetchServiceList.bind(this, {
                cursor: cursor,
                q: searchQuery,
                status: filter,
              })}
              requiredFieldName={csvRequiredFieldName}
              type="service"
            />
          </Col>
          <Col lg={3}>
            <PdDownloadCSV
              fetchData={ServiceAPI.getServiceReport}
              filename="service-report.csv"
            />
          </Col>
          <Col lg={3}>
            <PdButton block onClick={handleAddService}>
              Add New
            </PdButton>
          </Col>
        </Row>
      </DashboardContainer>
      <DashboardContainer>
        <Row align="middle">
          <Col lg={24}>
            <PdAntdTable
              columns={columns}
              dataSource={serviceList}
              pagination={{
                onChange: setCursor,
                pageSize: itemPerPage,
                total: 15,
              }}
            />
          </Col>
        </Row>
      </DashboardContainer>
    </Layout>
  );
}

export default { Service };
