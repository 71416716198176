import { message } from 'antd';
import { get } from 'lodash';

export {
  ADMIN_PERMISSION,
  FORM_ADMIN_FIELD,
  FORM_ADMIN_ROLE_LIST,
  FORM_BUTTON_LIST,
  FORM_RECURRING_DAY_OPTIONS,
  FORM_ROLE_CHECKBOX_LIST,
  FORM_ROLE_FIELD,
  FORM_ROLE_FORMATTER,
  FORM_TITLE_LIST,
  FORM_USER_MANAGEMENT_ACTION_BUTTON,
  FORM_USER_MANAGEMENT_STATUS_BUTTON,
  HOME_ICON_DATA_SOURCE,
  SIDEBAR_MENU_DATA_SOURCE,
  DATE_FORMAT,
  ORDER_STATUS,
  FORM_FIELD_SETTING,
  BASIC_PLAN_WARN_COPYWRITE,
} from './constants';

export const handleErr = (error) => {
  return message.error(
    get(
      error,
      'response.data.message',
      'Cannot connect to server, please check connection'
    )
  );
};

export function isNil(key) {
  return key === undefined || key === null || key === '';
}

export const calculateAmount = (price, quantity) => price * quantity;

/**
 *  use this function inside InputNumber formatter
 * @param {Number} value - value from InputNumber (only works on InputNumber antd)
 * @example <InputNumber formatter={numberOnlyFormat} />
 *
 */
export function numberOnlyFormat(value) {
  if (+value === 0) return value;
  return +value ? value : value.replace(value, '');
}

export function capitalizeFirstLetter(text) {
  if (text) {
    return text[0].toUpperCase() + text.substring(1);
  }
}

export function properCaseConverter(text = '', wordConnector = '') {
  const textArr = text.split(wordConnector);
  return textArr.map((word) => capitalizeFirstLetter(word)).join(' ');
}
