import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { Col, Layout, Row, message, Typography } from 'antd';
import { get } from 'lodash';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';

import InvoiceAPI from 'api/invoice';

import { handleErr } from 'utils';
import { numberDate, numberWithCommas } from 'utils/numberFormater';

import './style.scss';

const { Text, Title } = Typography;

export function InvoiceInformationDetail({ history, location }) {
  const [invoiceData, setInvoiceData] = useState({});
  const {
    invoice_number = '-',
    total_price = 0,
    order_services = [],
  } = invoiceData ?? {};
  const {
    billing_address = {},
    email = '-',
    fax_number = '-',
    last_invoice_date = '-',
    name = '-',
    phone_number = '-',
    regular_service = 0,
  } = invoiceData.customer ?? {};
  const {
    address = '-',
    city = '-',
    country = '-',
    postal_code = '-',
    state = {},
  } = billing_address ?? {};
  const { name: stateName = '-' } = state ?? {};
  const { id: locationID = '' } = location ?? {};

  async function fetchInvoiceData(id) {
    try {
      const { data } = await InvoiceAPI.getInvoiceDetail(id);
      setInvoiceData(get(data, 'data', []));
    } catch (err) {
      message.error(get(err, 'response.message', 'Failed to get invoice data'));
    }
  }

  async function handleSendMail() {
    try {
      await InvoiceAPI.sendMail(locationID);
      message.success('Successfully send email to the customer');
    } catch (error) {
      handleErr(error);
    }
  }

  function handleBackInvoice() {
    history.push('/work-orders-information');
  }

  async function handlePDFPrint() {
    try {
      const { data = {} } = await InvoiceAPI.getInvoicePDF(locationID);

      window.open(data?.pdf_link, '_blank');
    } catch (err) {
      handleErr(err);
    }
  }

  const DETAIL_INVOICE_FIELD = [
    {
      title: 'Work Order Number',
      content: invoice_number,
    },
    {
      title: 'Work Order Date',
      content: numberDate(last_invoice_date),
    },
    {
      title: 'Customer Name',
      content: name,
    },
    {
      title: 'Billing Address',
      content: address,
    },
    {
      title: 'City',
      content: city,
    },
    {
      title: 'State/Province',
      content: stateName,
    },
    {
      title: 'Country',
      content: country,
    },
    {
      title: 'Postal Code',
      content: postal_code,
    },
    {
      title: 'Email',
      content: email,
    },
    {
      title: 'Phone Number',
      content: phone_number,
    },
    {
      title: 'Fax',
      content: fax_number,
    },
    {
      title: 'Regular Service',
      content: `$ ${numberWithCommas(regular_service)}`,
    },
  ];

  useEffect(() => {
    fetchInvoiceData(locationID);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!locationID) {
    return <Redirect to="/work-orders-information" />;
  }

  return (
    <Layout className="pd-mobile-detail-invoice">
      <Row xs={24} justify="center" type="flex">
        <Col className="pd-margin-top-md" xs={{ span: 24 }}>
          <Title className="pd-align-center" level={3}>
            Detail Work Orders Information
          </Title>
        </Col>
      </Row>
      <DashboardContainer>
        <Row xs={24}>
          {DETAIL_INVOICE_FIELD.map(({ title, content }, idx) => (
            <Row key={idx}>
              <Col xs={12}>
                <Text strong>{title}</Text>
              </Col>
              <Col xs={12} className="pd-margin-bottom-md">
                <Text>{content}</Text>
              </Col>
            </Row>
          ))}
        </Row>
      </DashboardContainer>
      <Row xs={24} justify="center" type="flex">
        <Col xs={24} className="pd-align-center">
          <Text strong>Detail Service</Text>
        </Col>
      </Row>
      {order_services.map((orderService) => {
        const {
          id = '',
          service_date = '',
          quantity = 0,
          service = {},
        } = orderService ?? {};
        const { title = '', price = 0 } = service ?? {};
        return (
          <DashboardContainer key={id}>
            <Row xs={24}>
              <Row>
                <Col xs={12}>
                  <Text strong>Service</Text>
                </Col>
                <Col xs={12} className="pd-margin-bottom-md">
                  <Text>{title}</Text>
                </Col>
              </Row>
            </Row>
            <Row xs={24}>
              <Row>
                <Col xs={12}>
                  <Text strong>Service Date</Text>
                </Col>
                <Col xs={12} className="pd-margin-bottom-md">
                  <Text>{numberDate(service_date)}</Text>
                </Col>
              </Row>
            </Row>
            <Row xs={24}>
              <Row>
                <Col xs={12}>
                  <Text strong>Quantity</Text>
                </Col>
                <Col xs={12} className="pd-margin-bottom-md">
                  <Text>{quantity}</Text>
                </Col>
              </Row>
            </Row>
            <Row xs={24}>
              <Row>
                <Col xs={12}>
                  <Text strong>$ per Unit</Text>
                </Col>
                <Col xs={12} className="pd-margin-bottom-md">
                  <Text>{`$ ${numberWithCommas(price)}`}</Text>
                </Col>
              </Row>
            </Row>
            <Row xs={24}>
              <Row>
                <Col xs={12}>
                  <Text strong>Amount</Text>
                </Col>
                <Col xs={12} className="pd-margin-bottom-md">
                  <Text>{`$ ${numberWithCommas(price * quantity)}`}</Text>
                </Col>
              </Row>
            </Row>
          </DashboardContainer>
        );
      })}

      <Row xs={24}>
        <Col xs={{ span: 10, offset: 2 }}>
          <Text strong>Total Amount</Text>
        </Col>
        <Col xs={6} className="pd-margin-bottom-md">
          <Text>$ {numberWithCommas(total_price)}</Text>
        </Col>
      </Row>
      <Row className="button-container" justify="center" type="flex">
        <Col xs={17} className="pd-margin-bottom-md">
          <PdButton block type="primary" onClick={handleSendMail}>
            Send Mail to Customer
          </PdButton>
        </Col>
        <Col xs={8}>
          <PdButton block ghost type="primary" onClick={handleBackInvoice}>
            Back
          </PdButton>
        </Col>
        <Col xs={{ span: 8, offset: 1 }} className="pd-margin-bottom-md">
          <PdButton block onClick={handlePDFPrint}>
            PDF Print
          </PdButton>
        </Col>
      </Row>
    </Layout>
  );
}

export default { InvoiceInformationDetail };
