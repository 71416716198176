import React, { useState, useEffect } from 'react';
import {
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  InputNumber,
  Layout,
  message,
  Row,
  Select,
  Modal,
} from 'antd';
import { get, range } from 'lodash';
import moment from 'moment';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdTable, { PdTableBodyRow, PdTableHeader } from 'components/pd-table';
import PdForm from 'components/pd-form';

import { priceFormatter, priceParser } from 'utils/inputNumberUtils';

import ServiceAPI from 'api/service';
import CustomerAPI from 'api/customer';
import InvoiceAPI from 'api/invoice';

import './style.scss';

const { Option } = Select;
const { confirm } = Modal;

const FORM_FIELD = {
  ORDERED_AT: 'ordered_at',
  DISCOUNT: 'discount',
};

function WorkOrdersCreate({ form, history }) {
  const [customerList, setCustomerList] = useState([]);
  const [customer, setCustomer] = useState({
    address_id: undefined,
    email: '',
    customer_id: undefined,
    property_addresses: [],
  });
  const [discount, setDiscount] = useState(0);
  const [serviceField, setServiceField] = useState({ 0: {} });
  const [metaField, setMetaField] = useState(1);
  const [serviceList, setServiceList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [anyChange, setAnyChange] = useState(false);

  const customersData = customerList ?? {};

  const { getFieldDecorator } = form;

  const horizontalLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 20, offset: 1 },
  };

  const quarterHorizontalLayout = {
    labelCol: { span: 3, offset: 16 },
    wrapperCol: { span: 4, offset: 1 },
  };

  const tableHeaders = [
    { title: 'Service', width: 4 },
    { title: 'Quantity', width: 3 },
    { title: '$ per Unit', width: 4 },
    { title: 'Amount', width: 4 },
    { title: 'Meetings', width: 2 },
    { title: 'Service Date', width: 7 },
  ];

  function handleBackWorkOrders() {
    if (anyChange) {
      confirm({
        title: 'Are you sure?',
        content: 'Your changes will be lost',
        cancelButtonProps: { ghost: true },
        onOk: () => {
          history.goBack();
        },
      });
    } else {
      history.goBack();
    }
  }

  function handleAddNewService() {
    setServiceField((prevState) => ({
      ...prevState,
      [metaField]: {},
    }));
    setMetaField((metaField) => metaField + 1);
  }

  function handleDeleteRow(idx) {
    setServiceField((prevState) => {
      let filtered = {};

      for (let key in prevState) {
        if (key !== String(idx)) {
          filtered[key] = { ...prevState[key] };
        }
      }

      return filtered;
    });
  }

  async function handleChangeCustomer({ key: customerID }) {
    try {
      const { data } = await CustomerAPI.getCustomerDetail(customerID);
      const { email = '', id = 0, property_addresses = [] } = data?.data || {};

      return setCustomer({
        customer_id: id,
        email,
        property_addresses,
      });
    } catch (err) {
      message.error(
        get(err, 'response.message', 'Failed to get customer detail')
      );
    }
  }

  function handleChangeAddress(id) {
    setCustomer((customer) => ({ ...customer, address_id: id }));
  }

  function handleChangeDiscount(e) {
    setDiscount(Number(get(e, 'target.value', 0)));
  }

  function handleChangeService(value, key) {
    !anyChange && setAnyChange(true);
    const { price } = serviceList.find(({ id }) => id === value);
    setServiceField((prev) => ({
      ...prev,
      [key]: {
        service_id: value,
        price,
      },
    }));
  }

  function handleChangeDate(idx, meet, date) {
    !anyChange && setAnyChange(true);
    const service_date = moment(date).format('YYYY/MM/DD');
    return setServiceField((prevState) => {
      const serviceObject = {
        ...prevState[idx],
        service_dates: {
          ...prevState[idx].service_dates,
          [meet]: service_date,
        },
      };
      return { ...prevState, [idx]: serviceObject };
    });
  }

  function handleChangePrice(idx, price) {
    !anyChange && setAnyChange(true);
    return setServiceField((prevState) => {
      const serviceObject = {
        ...prevState[idx],
        price,
      };
      return { ...prevState, [idx]: serviceObject };
    });
  }

  function handleChangeQuantity(idx, quantity) {
    !anyChange && setAnyChange(true);
    return setServiceField((prevState) => {
      const serviceObject = { ...prevState[idx], quantity };
      return { ...prevState, [idx]: serviceObject };
    });
  }

  function handleChangeMeetings(idx, meetings) {
    !anyChange && setAnyChange(true);
    const { price } = serviceList.find(
      ({ id }) => id === serviceField[idx].service_id
    ) || { price: 0 };
    return setServiceField((prevState) => {
      const serviceObject = {
        ...prevState[idx],
        meetings,
        price: price,
      };
      return { ...prevState, [idx]: serviceObject };
    });
  }

  function handleAmount(idx) {
    const { price = 0, quantity = 0 } = serviceField[idx];
    return price * quantity;
  }

  function handleTotalAmount() {
    let totalQty = Object.values(serviceField).reduce(
      (acc, { price = 0, quantity = 0 }) => acc + Number(price) * quantity,
      0
    );

    const calc = totalQty - totalQty * (discount / 100);
    setTotalAmount(calc);
  }

  function handleSearch(input, option) {
    const { children: text } = option?.props;
    return text.toLowerCase().includes(input.toLowerCase());
  }

  async function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          const order_services_attributes = Object.values(serviceField)
            .map((service) => {
              const {
                service_dates = {},
                meetings = 1,
                quantity = 1,
                ...serviceAttr
              } = service;
              if (!Object.keys(service_dates).length) {
                throw new Error('Please insert the service date');
              }

              return range(meetings).map((meet) => ({
                ...serviceAttr,
                meetings: 1,
                price: meet > 0 ? 0 : serviceAttr.price,
                service_date: service_dates[meet] ?? moment()._d,
                quantity,
              }));
            })
            .flat();

          const payload = {
            customer_id: customer.customer_id,
            ordered_at: get(values, 'ordered_at._d', ''),
            discount,
            address_id: customer.address_id,
            order_services_attributes,
          };

          await InvoiceAPI.createInvoice({
            order: payload,
          });
          history.push({ pathname: '/work-orders' });
        } catch (err) {
          if (err instanceof Error) {
            message.error(err.message);
            return;
          }

          message.error(
            get(err, 'response.message', 'Failed to create invoice')
          );
        }
      }
    });
  }

  async function fetchCustomerList() {
    try {
      const { data } = await CustomerAPI.getCustomerList({ q: '' });

      const customerData = get(data, 'data', []);

      setCustomerList(customerData);
    } catch (err) {
      message.error(
        get(err, 'response.message', 'Failed to get customer list')
      );
    }
  }

  async function fetchServiceList() {
    try {
      const { data } = await ServiceAPI.getServiceList({ q: '' });

      const serviceData = get(data, 'data', []);

      setServiceList(serviceData);
    } catch (err) {
      get(err, 'response.message', 'Failed to get service list');
    }
  }

  useEffect(() => {
    fetchCustomerList();
    fetchServiceList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleTotalAmount();
    // eslint-disable-next-line
  }, [serviceField, discount]);

  return (
    <Layout className="pd-cms-form-order">
      <DashboardContainer>
        <PdForm
          onSubmit={handleSubmit}
          onChange={() => !anyChange && setAnyChange(true)}>
          <Form.Item label="Work Order Number" {...horizontalLayout}>
            <Input placeholder="Auto generate" disabled />
          </Form.Item>

          <Form.Item label="Customer Name" {...horizontalLayout}>
            <Select
              className="form-item-block"
              showSearch
              placeholder="Enter Customer Name"
              onSelect={(val, customerID) => handleChangeCustomer(customerID)}>
              {customersData.map((customer) => {
                const { name = '', id = 1 } = customer ?? {};
                return (
                  <Option key={id} value={name}>
                    {name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item label="Property Address" {...horizontalLayout}>
            <Select
              className="form-item-block"
              onSelect={handleChangeAddress}
              placeholder="Enter Property Address"
              showSearch>
              {customer.property_addresses.map((property) => {
                const { address = '', id = 1 } = property ?? {};
                return (
                  <Option key={id} value={id}>
                    {address}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item label="Email" {...horizontalLayout}>
            <Input value={customer.email} disabled />
          </Form.Item>

          <Form.Item label="Work Order Date" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.ORDERED_AT, {
              initialValue: moment(),
            })(
              <DatePicker
                format="MM/DD/YYYY"
                placeholder="Select Work Order Date"
              />
            )}
          </Form.Item>

          {/* Table Section */}
          <PdTable className="pd-table-body-work-orders">
            <PdTableHeader listHeader={tableHeaders} />

            {Object.keys(serviceField).map((key) => (
              <PdTableBodyRow key={key} align="start" type="flex">
                <Col lg={4}>
                  <Form.Item>
                    <Select
                      showSearch
                      placeholder="Service Name"
                      onSelect={(value) => handleChangeService(value, key)}
                      filterOption={handleSearch}>
                      {serviceList.map((service) => {
                        const { title = '', id = 1 } = service ?? {};
                        return (
                          <Option key={id} value={id}>
                            {title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg={3}>
                  <Form.Item>
                    <InputNumber
                      min={1}
                      placeholder={0}
                      onChange={handleChangeQuantity.bind(this, key)}
                    />
                  </Form.Item>
                </Col>
                <Col lg={4}>
                  <Form.Item>
                    <InputNumber
                      onChange={handleChangePrice.bind(this, key)}
                      value={serviceField[key].price ?? 0}
                      formatter={priceFormatter}
                      parser={priceParser}
                    />
                  </Form.Item>
                </Col>
                <Col lg={4}>
                  <Form.Item>
                    <InputNumber
                      disabled
                      value={handleAmount(key)}
                      formatter={priceFormatter}
                      parser={priceParser}
                    />
                  </Form.Item>
                </Col>
                <Col lg={2}>
                  <Form.Item>
                    <InputNumber
                      min={1}
                      placeholder={1}
                      onChange={handleChangeMeetings.bind(this, key)}
                    />
                  </Form.Item>
                </Col>
                <Col lg={6}>
                  {range(serviceField[key]?.meetings ?? 1).map((meet) => {
                    const label = `Meet - ${meet + 1}`;
                    return (
                      <Form.Item
                        key={label}
                        label={label}
                        labelAlign="right"
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 19 }}>
                        <DatePicker
                          format="MM/DD/YYYY"
                          onChange={handleChangeDate.bind(this, key, meet)}
                        />
                      </Form.Item>
                    );
                  })}
                </Col>
                {Object.keys(serviceField).length > 1 && (
                  <Col lg={1}>
                    <Form.Item>
                      <Icon
                        type="delete"
                        theme="twoTone"
                        twoToneColor="red"
                        onClick={handleDeleteRow.bind(this, key)}
                      />
                    </Form.Item>
                  </Col>
                )}
              </PdTableBodyRow>
            ))}
          </PdTable>

          <Form.Item>
            <PdButton type="link" icon="plus" onClick={handleAddNewService}>
              Add More Service
            </PdButton>
          </Form.Item>

          <Form.Item label="Discount" {...quarterHorizontalLayout}>
            <Input suffix="%" onChange={(e) => handleChangeDiscount(e)} />
          </Form.Item>

          <Form.Item label="Total Amount" {...quarterHorizontalLayout}>
            <Input prefix="$" value={totalAmount} disabled />
          </Form.Item>

          <Row
            align="middle"
            justify="center"
            type="flex"
            className="submit-container">
            <Col lg={6}>
              <PdButton
                block
                ghost
                type="primary"
                onClick={handleBackWorkOrders}>
                Back
              </PdButton>
            </Col>
            <Col lg={{ span: 6, offset: 1 }}>
              <PdButton block htmlType="submit">
                Submit
              </PdButton>
            </Col>
          </Row>
        </PdForm>
      </DashboardContainer>
    </Layout>
  );
}

export const WorkOrdersCreateForm = Form.create({ name: 'orders' })(
  WorkOrdersCreate
);
