import React, { useState, useEffect } from 'react';
import { Col, Layout, Row, Tag, Typography, Icon, Modal, message } from 'antd';
import Search from 'antd/lib/input/Search';
import { get } from 'lodash';
import queryString from 'querystring';

import RoleApi from 'api/role';

import { PageSpinner } from 'components/page-spinner';
import PdAntdSwitch from 'components/pd-antd-switch';
import PdButton from 'components/pd-button';
import PdMobileTable from 'components/pd-mobile-table';

import { handleErr, FORM_ROLE_FORMATTER } from 'utils';

import './style.scss';

const { confirm } = Modal;
const { Title } = Typography;

export function Role({ history, location }) {
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cursor, setCursor] = useState(1);

  const parsed = queryString.parse(location.search);

  const renderStatusSwitch = (id, status) => {
    const isChecked = {
      active: true,
      inactive: false,
    };
    return (
      <PdAntdSwitch
        checked={isChecked[status]}
        onChange={handleOnChangeSwitch.bind(this, [id, status])}
      />
    );
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      sorter: (a, b) => (a.name > b.name ? -1 : 1),
    },
    {
      title: 'Page Access',
      dataIndex: 'permission',
      key: 'permission',
      render: ColumnAccess,
      width: '35%',
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: ColumnRole,
      align: 'center',
    },
    {
      title: '',
      key: 'id',
      render: ColumnAction,
      align: 'center',
      width: '15%',
    },
  ];

  function ColumnAccess(access = []) {
    return (
      <>
        {access.map((acc, idx) => {
          return <Tag key={idx}>{FORM_ROLE_FORMATTER[acc]}</Tag>;
        })}
      </>
    );
  }

  function ColumnRole(text) {
    return <div className="text-role">{text}</div>;
  }

  function ColumnAction(rowData = {}) {
    const { status, id } = rowData;

    return (
      <Row>
        <Col xs={{ span: 24, offset: 2 }} lg={0}>
          {status.props.checked ? (
            <Icon
              onClick={handleEditRole.bind(this, rowData)}
              theme="twoTone"
              twoToneColor="gray"
              type="edit"
            />
          ) : (
            <Icon
              onClick={handleDeleteRole.bind(this, id)}
              theme="twoTone"
              twoToneColor="red"
              type="delete"
            />
          )}
        </Col>
      </Row>
    );
  }

  function handleAddRole() {
    // write code here
    history.push({
      pathname: '/role/create',
    });
  }

  function handleEditRole(detailData) {
    // condition to revert back status to string from switch component
    if (detailData.status.props.checked) {
      detailData.status = 'active';
    } else {
      detailData.status = 'inactive';
    }
    history.push('role/edit', detailData);
  }

  function handleDeleteRole(id) {
    confirm({
      title: 'Are you sure you want to delete this role?',
      content: "You can't undo this action",
      cancelButtonProps: { ghost: true },
      onOk: async () => {
        try {
          await RoleApi.deleteRole(id);

          message.success('Successfully delete a role');
          getListRole();
        } catch (err) {
          handleErr(err);
        }
      },
    });
  }

  async function getListRole() {
    setIsLoading(true);
    try {
      const payload = {
        q: parsed['?q'],
      };
      const { data } = await RoleApi.listRole(payload);
      const roleList = get(data, 'data', '');
      // map for change status to switch button
      roleList.map((role) => {
        const { status, id } = role;
        role.status = renderStatusSwitch(id, status);
      });
      setDataSource(roleList);
    } catch (error) {
      handleErr(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleOnChangeSwitch([id, status]) {
    setIsLoading(true);
    try {
      switch (status) {
        case 'active':
          await RoleApi.deactivateRole(id);
          break;
        case 'inactive':
          await RoleApi.activateRole(id);
          break;
        default:
          break;
      }
      history.push({
        pathname: '/role',
      });
    } catch (error) {
      handleErr(error);
    } finally {
      setIsLoading(false);
    }
  }

  function handleSearch(q) {
    history.push({
      pathname: '/role',
      search: queryString.stringify(
        q && {
          q,
        }
      ),
    });
  }

  useEffect(() => {
    getListRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  return (
    <Layout className="pd-mobile-admin">
      {/* Section that display chart */}
      <Row type="flex" justify="center">
        <Col className="pd-margin-top-md" xs={24}>
          <Title className="pd-align-center" level={3}>
            Role
          </Title>
        </Col>

        <Col xs={13}>
          {false && (
            <Search placeholder="Search Title" onSearch={handleSearch} />
          )}
        </Col>
        <Col xs={{ span: 8, offset: 1 }}>
          <PdButton block onClick={handleAddRole}>
            Add New
          </PdButton>
        </Col>
        <Col xs={24} className="pd-margin-top-lg">
          {isLoading ? (
            <PageSpinner />
          ) : (
            <PdMobileTable
              dataSource={dataSource}
              columns={columns}
              pagination={{
                current: cursor,
                onChange: setCursor,
                pageSize: 5,
              }}
            />
          )}
        </Col>
      </Row>
    </Layout>
  );
}

export default { Role };
