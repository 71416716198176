import React, { useState, useEffect } from 'react';
import {
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  InputNumber,
  Layout,
  message,
  Row,
  Select,
  Typography,
  Modal,
} from 'antd';
import { range } from 'lodash';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';

import CustomerAPI from 'api/customer';
import ServiceAPI from 'api/service';
import InvoiceAPI from 'api/invoice';

import { priceFormatter, priceParser } from 'utils/inputNumberUtils';

import './style.scss';
import moment from 'moment';

const { Option } = Select;
const { Text, Title } = Typography;
const { confirm } = Modal;

const ORDER_SERVICE_FIELD_TEMPLATE = {
  service_dates: {},
  service_id: '',
  quantity: 0,
  price: 0,
  meetings: 1,
};

function OrderCreateEdit({ history }) {
  const [customerList, setCustomerList] = useState([]);
  const [customerDetail, setCustomerDetail] = useState({});
  const [serviceList, setServiceList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [workOrders, setWorkOrders] = useState({
    customer_id: 0,
    ordered_at: moment(),
    discount: 0,
    address_id: 0,
    order_services_attributes: [ORDER_SERVICE_FIELD_TEMPLATE],
  });
  const [anyChange, setAnyChange] = useState(false);

  async function handleSubmit() {
    try {
      const order_services_attributes = Object.values(
        workOrders.order_services_attributes
      )
        .map((service) => {
          const {
            service_dates = {},
            meetings = 1,
            quantity = 1,
            ...serviceAttr
          } = service;
          if (!Object.keys(service_dates).length) {
            throw new Error('Please insert the service date');
          }

          return range(meetings).map((meet) => ({
            ...serviceAttr,
            meetings: 1,
            price: meet > 0 ? 0 : serviceAttr.price,
            service_date: service_dates[meet] ?? moment()._d,
            quantity,
          }));
        })
        .flat();
      await InvoiceAPI.createInvoice({
        order: {
          ...workOrders,
          order_services_attributes,
        },
      });
      history.push({ pathname: '/work-orders' });
    } catch (err) {
      if (err instanceof Error) {
        message.error(err.message);
        return;
      }

      message.error(err.response.message || 'Failed to create invoice');
    }
  }

  function handleSearch(input, option) {
    return (
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  }

  async function handleCustomerSelect(value) {
    !anyChange && setAnyChange(true);
    setWorkOrders((prev) => ({ ...prev, customer_id: value }));
    await fetchCustomerDetail(value);
  }

  function handlePropertySelect(value) {
    !anyChange && setAnyChange(true);
    setWorkOrders((prev) => ({ ...prev, address_id: value }));
  }

  function handleOrderDateSelect(value) {
    !anyChange && setAnyChange(true);
    setWorkOrders((prev) => ({ ...prev, ordered_at: value._id }));
  }

  function handleServiceChange(value, idx) {
    !anyChange && setAnyChange(true);
    const service = serviceList.find(({ id }) => id === value);
    const { order_services_attributes: changeServices } = workOrders;
    changeServices[idx] = {
      ...changeServices[idx],
      price: service.price,
      quantity: 0,
      meetings: 1,
      service_dates: {},
      service_id: value,
    };
    setWorkOrders((prev) => ({
      ...prev,
      order_services_attributes: changeServices,
    }));
  }

  function handleServiceFieldChange(value, idx, field, meet = 0) {
    !anyChange && setAnyChange(true);
    const { order_services_attributes: changeServices } = workOrders;

    changeServices[idx] = {
      ...changeServices[idx],
      [field]:
        field !== 'service_dates'
          ? value
          : {
              ...changeServices[idx].service_dates,
              [meet]: value._d,
            },
    };

    setWorkOrders((prev) => ({
      ...prev,
      order_services_attributes: changeServices,
    }));
  }

  function handleAddService() {
    setWorkOrders((prev) => ({
      ...prev,
      order_services_attributes: [
        ...prev.order_services_attributes,
        ORDER_SERVICE_FIELD_TEMPLATE,
      ],
    }));
  }

  function handleDiscountChange(ev) {
    !anyChange && setAnyChange(true);
    const { value = 0 } = ev.target;
    setWorkOrders((prev) => ({ ...prev, discount: value }));
  }

  function handleBackWorkOrders() {
    if (anyChange) {
      confirm({
        title: 'Are you sure?',
        content: 'Your changes will be lost',
        cancelButtonProps: { ghost: true },
        onOk: () => {
          history.goBack();
        },
      });
    } else {
      history.goBack();
    }
  }

  function handleDeleteService(idx) {
    setWorkOrders((prev) => {
      const { order_services_attributes: services = [] } = prev;
      return {
        ...prev,
        order_services_attributes: [
          ...services.slice(0, idx),
          ...services.slice(idx + 1),
        ],
      };
    });
  }

  async function fetchCustomerList() {
    try {
      const { data } = await CustomerAPI.getCustomerList({ q: '' });
      const { data: customerData = {} } = data;

      setCustomerList(customerData);
    } catch (err) {
      message.error(err.response.message || 'Failed to get customer list');
    }
  }

  async function fetchCustomerDetail(id) {
    try {
      const { data } = await CustomerAPI.getCustomerDetail(id);
      const { email = {}, property_addresses = [] } = data.data;

      setCustomerDetail({ email, property_addresses });
    } catch (err) {
      message.error(err.response.message || 'Failed to get customer detail');
    }
  }

  async function fetchServiceList() {
    try {
      const { data } = await ServiceAPI.getServiceList({ q: '' });
      const { data: serviceData = {} } = data;

      setServiceList(serviceData);
    } catch (err) {
      message.error(err.response.message || 'Failed to get service list');
    }
  }

  useEffect(() => {
    fetchCustomerList();
    fetchServiceList();
  }, []);

  useEffect(() => {
    setTotalAmount(() => {
      const { discount = 0, order_services_attributes = [] } = workOrders;
      const amountBeforeDiscount = order_services_attributes.reduce(
        (result, { price, quantity }) => result + price * quantity,
        0
      );
      return amountBeforeDiscount * (1 - discount / 100);
    });
  }, [workOrders]);

  return (
    <Layout className="pd-mobile-form-order">
      <DashboardContainer>
        <Row className="form-item" align="middle" type="flex">
          <Col xs={24}>
            <Title
              className="pd-align-center pd-margin-top-2xs pd-margin-bottom-md"
              level={3}>
              Create New Order
            </Title>
          </Col>
          <Col className="form-item-label" xs={24}>
            Work Orders Number
          </Col>
          <Col xs={24}>
            <Input placeholder="Auto generate" disabled />
          </Col>
        </Row>
        <Row className="form-item" align="middle" type="flex">
          <Col className="form-item-label" xs={24}>
            Customer Name
          </Col>
          <Col xs={24}>
            <Select
              className="form-item-block"
              filterOption={handleSearch}
              onSelect={handleCustomerSelect}
              placeholder="Enter Customer Name"
              showSearch>
              {customerList.map(({ id, name }) => {
                return (
                  <Option key={`customer-${id}`} value={id}>
                    {name}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>
        <Row className="form-item" align="middle" type="flex">
          <Col className="form-item-label" xs={24}>
            Property Address
          </Col>
          <Col xs={24}>
            <Select
              className="form-item-block"
              filterOption={handleSearch}
              onSelect={handlePropertySelect}
              placeholder="Enter Property Address"
              showSearch>
              {customerDetail.property_addresses?.map(
                ({ id = '', address = '' }) => {
                  return (
                    <Option key={`address-${id}`} value={id}>
                      {address}
                    </Option>
                  );
                }
              )}
            </Select>
          </Col>
        </Row>
        <Row className="form-item" align="middle" type="flex">
          <Col className="form-item-label" xs={24}>
            Email
          </Col>
          <Input
            placeholder="Autofill"
            disabled
            value={customerDetail.email || ''}
          />
        </Row>
        <Row className="form-item" align="middle" type="flex">
          <Col className="form-item-label" xs={24}>
            Work Order Date
          </Col>
          <Col xs={24}>
            <DatePicker
              placeholder="Select Invoice Date"
              format="MM/DD/YYYY"
              defaultValue={moment()}
              onChange={handleOrderDateSelect}
            />
          </Col>
        </Row>

        <Row xs={24} justify="center" type="flex" className="pd-margin-top-lg">
          <Col xs={24} className="pd-align-center">
            <Text strong>Services</Text>
          </Col>
        </Row>

        {/* Detail Service */}
        {workOrders.order_services_attributes.map(
          ({ service_id, service_dates, quantity, price, meetings }, idx) => {
            return (
              <div key={`service-${idx}`}>
                <Row className="form-item" align="middle" type="flex">
                  <Col className="form-item-label" xs={22}>
                    Service Name
                  </Col>
                  {workOrders.order_services_attributes.length > 1 && (
                    <Col xs={2}>
                      <Icon
                        type="delete"
                        theme="twoTone"
                        twoToneColor="red"
                        onClick={() => {
                          handleDeleteService(idx);
                        }}
                      />
                    </Col>
                  )}
                  <Col xs={24}>
                    <Select
                      showSearch
                      placeholder="Service Name"
                      onChange={(value) => handleServiceChange(value, idx)}
                      value={service_id}>
                      {serviceList
                        .filter(({ status }) => status === 'active')
                        .map(({ id, title }) => {
                          return (
                            <Option key={id} value={id}>
                              {title}
                            </Option>
                          );
                        })}
                    </Select>
                  </Col>
                </Row>
                <Row className="form-item" gutter={8}>
                  <Col xs={8}>
                    <Col className="form-item-label">Quantity</Col>
                    <Col>
                      <InputNumber
                        min={0}
                        value={quantity}
                        onChange={(value) =>
                          handleServiceFieldChange(value, idx, 'quantity')
                        }
                      />
                    </Col>
                  </Col>
                  <Col xs={8}>
                    <Col className="form-item-label">$ per Unit</Col>
                    <Col>
                      <InputNumber
                        min={0}
                        value={+price}
                        onChange={(value) =>
                          handleServiceFieldChange(value, idx, 'price')
                        }
                      />
                    </Col>
                  </Col>
                  <Col xs={8}>
                    <Col className="form-item-label">Amount</Col>
                    <Col>
                      <InputNumber
                        disabled
                        value={quantity * price}
                        formatter={priceFormatter}
                        parser={priceParser}
                      />
                    </Col>
                  </Col>
                </Row>
                <Row className="form-item" align="middle" type="flex">
                  <Col className="form-item-label" xs={24}>
                    Meetings
                  </Col>
                  <Col xs={24}>
                    <InputNumber
                      min={1}
                      value={meetings}
                      onChange={(value) =>
                        handleServiceFieldChange(value, idx, 'meetings')
                      }
                    />
                  </Col>
                </Row>
                <Row className="form-item" align="middle" type="flex">
                  <Col className="form-item-label" xs={24}>
                    Service Date
                  </Col>
                  <Col xs={24}>
                    {range(meetings ?? 1).map((meet) => {
                      const label = `Meet - ${meet + 1}`;
                      return (
                        <Row
                          key={`meet-${meet}`}
                          type="flex"
                          align="middle"
                          className="pd-margin-bottom-sm">
                          <Col xs={6}>
                            <Text className="pd-padding-left-md">{label}</Text>
                          </Col>
                          <Col xs={18}>
                            <DatePicker
                              value={moment(service_dates[meet])}
                              format={'MM/DD/YYYY'}
                              onChange={(value) =>
                                handleServiceFieldChange(
                                  value,
                                  idx,
                                  'service_dates',
                                  meet
                                )
                              }
                            />
                          </Col>
                        </Row>
                      );
                    })}
                  </Col>
                </Row>
              </div>
            );
          }
        )}

        <Row>
          <Col
            className="add-property-container"
            xs={{ span: 24 }}
            onClick={handleAddService}>
            <Icon className="pd-margin-right-sm" type="plus-circle" />
            Add More Service
          </Col>
        </Row>

        <Row className="form-item" align="middle" type="flex">
          <Col className="form-item-label" xs={9}>
            Discount
          </Col>
          <Col xs={{ span: 14, offset: 1 }}>
            <Input
              suffix="%"
              value={workOrders.discount}
              onChange={(ev) => handleDiscountChange(ev)}
            />
          </Col>
        </Row>
        <Row className="form-item" align="middle" type="flex">
          <Col className="form-item-label" xs={9}>
            Total Amount
          </Col>
          <Col xs={{ span: 14, offset: 1 }}>
            <Input prefix="$" value={totalAmount} disabled />
          </Col>
        </Row>

        <Row
          align="middle"
          gutter={10}
          justify="center"
          type="flex"
          className="submit-container">
          <Col xs={8}>
            <PdButton block ghost type="primary" onClick={handleBackWorkOrders}>
              Back
            </PdButton>
          </Col>
          <Col xs={8}>
            <PdButton block onClick={handleSubmit}>
              Submit
            </PdButton>
          </Col>
        </Row>
      </DashboardContainer>
    </Layout>
  );
}

export const OrderPageForm = Form.create({ name: 'orders' })(OrderCreateEdit);
