// *NOT FINISHED YET BECAUSE PROBLEM WITH POPULATE DATA

import React, { useState, useEffect } from 'react';
import {
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  InputNumber,
  Layout,
  message,
  Row,
  Select,
  Modal,
} from 'antd';
import moment from 'moment';
import { get } from 'lodash';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdTable, { PdTableBodyRow, PdTableHeader } from 'components/pd-table';
import PdForm from 'components/pd-form';

import {
  appendPercentage,
  numberOnlyFormat,
  parsePercentage,
  priceFormatter,
  priceParser,
} from 'utils/inputNumberUtils';
import { oneNumberDecimalFormat } from 'utils/numberFormater';

import ServiceAPI from 'api/service';
import CustomerAPI from 'api/customer';
import ProposalAPI from 'api/proposal';

import './style.scss';

const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;

const FORM_FIELD = {
  ORDERED_AT: 'ordered_at',
  BODY_PROPOSAL: 'body_proposal',
  DISCOUNT: 'discount',
};

let globalIndex = 0;

export function ProposalEdit({ form, history, location }) {
  const [customerList, setCustomerList] = useState([]);
  const [deleteServiceIds, setDeleteServiceIds] = useState([]);
  const [proposalData, setProposalData] = useState({});
  const [proposalEmail, setProposalEmail] = useState('');
  const [proposalNumber, setProposalNumber] = useState('');
  const [serviceIds, setServiceIds] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [anyChange, setAnyChange] = useState(false);

  const {
    id = undefined,
    customer = {},
    ordered_at = '',
    body_proposal = '',
    discount = 0,
  } = proposalData ?? {};
  const customersData = customerList ?? {};
  const { id: proposalID = '' } = location ?? {};
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

  const horizontalLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 20, offset: 1 },
  };

  const quarterHorizontalLayout = {
    labelCol: { span: 3, offset: 16 },
    wrapperCol: { span: 4, offset: 1 },
  };

  const tableHeaders = [
    { title: 'Service', width: 4 },
    { title: 'Service Date', width: 4 },
    { title: 'Quantity', width: 4 },
    { title: '$ per Unit', width: 4 },
    { title: 'Amount', width: 4 },
    { title: 'Meetings', width: 4 },
  ];

  function handleBackProposal() {
    if (anyChange) {
      confirm({
        title: 'Are you sure?',
        content: 'Your changes will be lost',
        cancelButtonProps: { ghost: true },
        onOk: () => {
          cleanUpAndBack();
        },
      });
    } else {
      cleanUpAndBack();
    }
  }

  function cleanUpAndBack() {
    globalIndex = 0;
    setFieldsValue({
      keys: [],
    });
    history.goBack();
  }

  function handleTwoFormatter(type, value) {
    switch (type) {
      case 'currency':
        return priceFormatter(numberOnlyFormat(value));
      case 'percentage':
        return appendPercentage(numberOnlyFormat(value));
    }
  }

  function handleOnSelect(key, value) {
    !anyChange && setAnyChange(true);
    const selectedService = serviceList.find(({ id }) => id === value);
    const servicePrice = get(selectedService, 'price', 0);
    setFieldsValue({
      [`price[${key}]`]: servicePrice,
    });

    // calculate Amount based on quantity and price field
    const quantity = getFieldValue(`quantity[${key}]`);
    const price = getFieldValue(`price[${key}]`);
    const amount = quantity && price ? quantity * price : 0;
    setFieldsValue({
      [`amount[${key}]`]: amount,
    });
  }

  function handleChangePrice(key, value) {
    !anyChange && setAnyChange(true);
    const quantity = getFieldValue(`quantity[${key}]`);
    const amount = quantity ? value * quantity : 0;
    setFieldsValue({
      [`amount[${key}]`]: amount,
    });
  }

  function handleChangeQuantity(key, value) {
    !anyChange && setAnyChange(true);
    const price = getFieldValue(`price[${key}]`);
    const amount = price ? value * price : 0;
    setFieldsValue({
      [`amount[${key}]`]: amount,
    });
  }

  function handleChangeMeetings(key, value) {
    !anyChange && setAnyChange(true);
    setFieldsValue({
      [`amount[${key}]`]: value,
    });
  }

  function serviceDateInitialValue(key) {
    const serviceDate = get(
      proposalData,
      ['order_services', `${key}`, 'service_date'],
      ''
    );

    if (serviceDate) {
      return moment(
        get(proposalData, ['order_services', `${key}`, 'service_date'], '')
      );
    }

    return '';
  }

  const totalAmount = () => {
    const amountList = getFieldValue('amount') || [];
    let rawTotal = 0;
    if (amountList[0] || amountList[0] === 0)
      rawTotal = amountList.reduce(
        (prevValue, currentValue) => prevValue + currentValue
      );

    const discount = getFieldValue('discount') || 0;
    rawTotal *= (100 - +discount) / 100;

    return oneNumberDecimalFormat(rawTotal);
  };

  const countAmount = (key) => {
    const quantity = getFieldValue(`quantity[${key}]`);
    const price = getFieldValue(`price[${key}]`);
    return quantity && price ? quantity * price : 0;
  };

  /* TABLE SECTION MULTI FORM SETUP (globalIndex declaration is included) */

  function generateServiceTableBody() {
    getFieldDecorator('keys', { initialValue: [] });
    const keys = getFieldValue('keys');
    return keys.map((key) => (
      <PdTableBodyRow key={key}>
        <Col lg={4}>
          {getFieldDecorator(`service[${key}]`, {
            rules: [
              {
                message: 'Please select the service',
                required: true,
              },
            ],
            initialValue: get(
              proposalData,
              ['order_services', `${key}`, 'service', 'id'],
              ''
            ),
          })(
            <Select
              className="w-full"
              onSelect={handleOnSelect.bind(this, key)}
              placeholder="Service Name"
              showSearch>
              {serviceList.map(({ id, title }, idx) => (
                <Option key={idx} value={id}>
                  {title}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col lg={4}>
          {getFieldDecorator(`service_date[${key}]`, {
            initialValue: serviceDateInitialValue(key),
          })(<DatePicker format="MM/DD/YYYY" />)}
        </Col>
        <Col lg={4}>
          {getFieldDecorator(`quantity[${key}]`, {
            initialValue: get(
              proposalData,
              ['order_services', `${key}`, 'quantity'],
              0
            ),
          })(
            <InputNumber
              min={0}
              formatter={numberOnlyFormat}
              onChange={handleChangeQuantity.bind(this, key)}
            />
          )}
        </Col>
        <Col lg={4}>
          {getFieldDecorator(`price[${key}]`, {
            initialValue: get(
              proposalData,
              ['order_services', `${key}`, 'price'],
              0
            ),
          })(
            <InputNumber
              formatter={handleTwoFormatter.bind(this, 'currency')}
              onChange={handleChangePrice.bind(this, key)}
              parser={priceParser}
            />
          )}
        </Col>
        <Col lg={4}>
          {getFieldDecorator(`amount[${key}]`, {
            initialValue: countAmount(key),
          })(
            <InputNumber
              disabled
              formatter={priceFormatter}
              parser={priceParser}
            />
          )}
        </Col>
        <Col lg={3}>
          {getFieldDecorator(`meetings[${key}]`, {
            initialValue: get(
              proposalData,
              ['order_services', `${key}`, 'meetings'],
              1
            ),
          })(
            <InputNumber
              min={1}
              formatter={numberOnlyFormat}
              onChange={handleChangeMeetings.bind(this, key)}
            />
          )}
        </Col>
        <Col lg={1}>
          <Icon
            type="delete"
            theme="twoTone"
            twoToneColor="red"
            onClick={handleDeleteTableRow.bind(this, key)}
          />
        </Col>
      </PdTableBodyRow>
    ));
  }

  /* END OF SETUP */

  async function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        const { body_proposal: oldBodyProposal } = values ?? {};

        // adjust for table multiform
        const {
          discount = 0,
          keys = [],
          price = [],
          quantity = [],
          service = [],
          service_date = [],
          meetings = [],
        } = values;

        const order_services_attributes = keys.map((key) => {
          const serviceId = serviceIds[key];
          const result = {
            price: price[key],
            quantity: quantity[key],
            service_date: moment(service_date[key]).format('YYYY/MM/DD'),
            service_id: service[key],
            meetings: meetings[key],
          };
          return serviceId ? { ...result, id: serviceId } : { ...result };
        });

        // add delete payload to order_services_attributes
        deleteServiceIds.map((deleteId) =>
          order_services_attributes.push({ id: deleteId, _destroy: true })
        );

        const payload = {
          address_id: customer.address_id ?? customer.billing_address.id,
          body_proposal: oldBodyProposal ?? body_proposal,
          discount,
          order_services_attributes,
        };

        try {
          await ProposalAPI.updateProposal(id, {
            order: payload,
          });
          history.push({ pathname: '/proposal' });

          // RESET TABLE SECTION
          globalIndex = 0;
          setFieldsValue({
            keys: [],
          });
        } catch (err) {
          message.error(
            get(err, 'response.message', 'Failed to create proposal')
          );
        }
      }
    });
  }

  async function fetchProposalData(id) {
    try {
      const { data: proposalData } = await ProposalAPI.getProposalDetail(id);
      const { data = {} } = proposalData ?? {};
      const { id: proposalId = '', customer = {} } = data;
      const { email = '' } = customer;

      // console.log(data, '<<<< PROPOSAL DAT');

      // GENERATE SERVICES FIELD
      if (get(data, 'order_services', '')) {
        const servicesAttribbutes = get(data, 'order_services', []);
        const serviceIdsData = servicesAttribbutes.map(({ id }) => {
          handleAddTableRow();
          return id;
        });
        setServiceIds(serviceIdsData);
      } else {
        handleAddTableRow(); // add 1 tableBodyRow Input for defaultValue
      }

      // MODIFY DATE
      const proposal_ordered_at = get(data, 'ordered_at', '');
      const newDate = proposal_ordered_at ? moment(proposal_ordered_at) : '';
      const result = { ...data, ordered_at: newDate };

      setProposalNumber(proposalId);
      setProposalEmail(email);
      setProposalData(result);
    } catch (err) {
      message.error(get(err, 'response.message', 'Failed to get invoice data'));
    }
  }

  async function fetchCustomerList() {
    try {
      const { data } = await CustomerAPI.getCustomerList({ q: '' });
      const customerData = get(data, 'data', []);
      setCustomerList(customerData);
    } catch (err) {
      message.error(
        get(err, 'response.message', 'Failed to get customer list')
      );
    }
  }

  async function fetchServiceList() {
    try {
      const { data } = await ServiceAPI.getServiceList({ q: '' });
      const serviceData = get(data, 'data', []);
      setServiceList(serviceData);
    } catch (err) {
      get(err, 'response.message', 'Failed to get service list');
    }
  }

  function handleAddTableRow() {
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(globalIndex++);
    setFieldsValue({
      keys: nextKeys,
    });
  }

  function handleDeleteTableRow(rowKey) {
    const keys = form.getFieldValue('keys');

    // hit BE API delete service later if the table row has id
    const trackedId = serviceIds.find((id, idx) => idx === rowKey);
    const deleteServiceIdsLength = deleteServiceIds.length;
    const serviceIdsLength = serviceIds.length;
    if (trackedId && deleteServiceIdsLength < serviceIdsLength) {
      setDeleteServiceIds((prev) => [...prev, trackedId]);
    }

    const newKeys = keys.filter((key) => key !== rowKey);
    setFieldsValue({
      keys: newKeys,
    });
  }

  useEffect(() => {
    !proposalID ? history.push('/proposal') : fetchProposalData(proposalID);
    fetchCustomerList();
    fetchServiceList();
    // eslint-disable-next-line
  }, []);

  return (
    <Layout className="pd-cms-proposal-detail">
      <DashboardContainer>
        <PdForm
          onSubmit={handleSubmit}
          onChange={() => !anyChange && setAnyChange(true)}>
          <Form.Item label="Proposal Number" {...horizontalLayout}>
            <Input placeholder={proposalNumber} disabled />
          </Form.Item>

          <Form.Item label="Customer Name" {...horizontalLayout}>
            {getFieldDecorator('customerName', {
              initialValue: get(customer, 'name', ''),
            })(
              <Select className="form-item-block" showSearch disabled>
                {customersData.map((customer) => {
                  const { name = '', id = 1 } = customer ?? {};
                  return (
                    <Option key={id} value={name}>
                      {name}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Email" {...horizontalLayout}>
            <Input
              value={proposalEmail}
              placeholder={get(customer, 'email', '')}
              disabled
            />
          </Form.Item>

          <Form.Item label="Proposal Date" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.ORDERED_AT, {
              initialValue: ordered_at,
            })(
              <DatePicker
                format="MM/DD/YYYY"
                placeholder="Select Date"
                disabled
              />
            )}
          </Form.Item>

          <Form.Item label="Description" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.BODY_PROPOSAL, {
              initialValue: body_proposal,
            })(<TextArea rows={5}></TextArea>)}
          </Form.Item>

          <PdTable className="pd-table-body-work-orders">
            <PdTableHeader listHeader={tableHeaders} />
            {generateServiceTableBody()}
          </PdTable>

          <Form.Item>
            <PdButton type="link" icon="plus" onClick={handleAddTableRow}>
              Add More Service
            </PdButton>
          </Form.Item>

          <Form.Item label="Discount" {...quarterHorizontalLayout}>
            {getFieldDecorator('discount', {
              initialValue: discount,
            })(
              <InputNumber
                formatter={handleTwoFormatter.bind(this, 'percentage')}
                parser={parsePercentage}
              />
            )}
          </Form.Item>

          <Form.Item label="Total Amount" {...quarterHorizontalLayout}>
            <Input prefix="$" value={totalAmount()} disabled />
          </Form.Item>

          <Row
            align="middle"
            justify="center"
            type="flex"
            className="submit-container">
            <Col lg={6}>
              <PdButton block ghost type="primary" onClick={handleBackProposal}>
                Back
              </PdButton>
            </Col>
            <Col lg={{ span: 6, offset: 1 }}>
              <PdButton block htmlType="submit">
                Submit
              </PdButton>
            </Col>
          </Row>
        </PdForm>
      </DashboardContainer>
    </Layout>
  );
}

export const ProposalEditPageForm = Form.create({ name: 'proposal-edit' })(
  ProposalEdit
);
