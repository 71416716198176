import React, { useEffect, useState } from 'react';
import {
  Col,
  Icon,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Table,
  Typography,
} from 'antd';
import { get } from 'lodash';

import ServiceAPI from 'api/service';

import PdAntdSwitch from 'components/pd-antd-switch';
import PdButton from 'components/pd-button';
import { PdCsvUploader } from 'components/pd-csv-uploader';
import { PdDownloadCSV } from 'components/pd-download-csv';

import { numberWithCommas } from 'utils/numberFormater';
import { handleErr } from 'utils';

import './style.scss';

const { confirm } = Modal;
const { Option } = Select;
const { Search } = Input;
const { Title } = Typography;

const itemPerPage = 6;

export function Service({ history }) {
  const [cursor, setCursor] = useState(1);
  const [filter, setFilter] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [serviceList, setServiceList] = useState([]);

  const csvRequiredFieldName = ['service_name'];

  const statusFilter = [
    { value: '', text: 'All' },
    { value: 'active', text: 'Active' },
    { value: 'inactive', text: 'Inactive' },
  ];

  function handleAddService() {
    history.push({
      pathname: '/service/create',
    });
  }

  function handleEditService(id = '') {
    history.push({
      pathname: '/service/edit',
      id,
    });
  }

  function handleDeleteService(id) {
    confirm({
      title: 'Do you want to delete this service',
      content: "You can't undo this action",
      cancelButtonProps: { ghost: true },
      onOk: async () => {
        try {
          await ServiceAPI.deleteService(id);

          message.success('Successfully delete a service');
          fetchServiceList({
            cursor,
            q: searchQuery,
          });
        } catch (err) {
          handleErr(err);
        }
      },
    });
  }

  const ColumnStatus = (status, { id }) => {
    return <div className="text-role">{renderStatusSwitch(id, status)}</div>;
  };

  const ColumnAction = (data = {}) => {
    const { status, id } = data;

    return (
      <Row>
        <Col xs={{ span: 24, offset: 2 }} lg={0}>
          {status === 'active' ? (
            <Icon
              onClick={handleEditService.bind(this, id)}
              theme="twoTone"
              twoToneColor="gray"
              type="edit"
            />
          ) : (
            <Icon
              onClick={handleDeleteService.bind(this, id)}
              theme="twoTone"
              twoToneColor="red"
              type="delete"
            />
          )}
        </Col>
      </Row>
    );
  };

  const ColumnPrice = (price) => {
    return <>{`$${numberWithCommas(price)}`}</>;
  };

  const TitleFormat = (title) => (
    <h3 className="pd-margin-bottom-2xs">
      <b>{title}</b>
    </h3>
  );

  const dataSource = serviceList || [];

  const columns = [
    {
      title: TitleFormat('Service Name'),
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: TitleFormat('Price/unit'),
      dataIndex: 'price',
      key: 'price',
      render: ColumnPrice,
      align: 'center',
    },
    {
      title: TitleFormat('Status'),
      dataIndex: 'status',
      key: 'status',
      render: ColumnStatus,
      align: 'center',
    },
    {
      title: '',
      key: 'id',
      render: ColumnAction,
    },
  ];

  const renderStatusSwitch = (id, status) => {
    const isChecked = {
      active: true,
      inactive: false,
    };

    return (
      <PdAntdSwitch
        checked={isChecked[status]}
        onChange={handleOnChangeSwitch.bind(this, [id, status])}
      />
    );
  };

  function handleSearchService(e) {
    setSearchQuery(get(e, 'target.value', ''));
  }

  async function fetchServiceList({
    limit = itemPerPage,
    cursor = 1,
    q = '',
    status = filter,
  }) {
    try {
      const { data } = await ServiceAPI.getServiceList({
        limit,
        cursor,
        q,
        ...(status ? { status } : {}),
      });

      const serviceData = get(data, 'data', []);

      serviceData.map((service) => {
        service.key = get(service, 'id', '');
        service.name = get(service, 'title', '');
      });
      setServiceList(serviceData);
    } catch (err) {
      handleErr(err);
    }
  }

  async function handleOnChangeSwitch([id, status]) {
    try {
      if (status === 'active') {
        await ServiceAPI.deactiveService(id);
      } else {
        await ServiceAPI.reactiveService(id);
      }

      fetchServiceList({
        cursor,
        q: searchQuery,
      });
    } catch (err) {
      handleErr(err);
    }
  }

  useEffect(() => {
    fetchServiceList({
      cursor: cursor,
      q: searchQuery,
      status: filter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor, filter, searchQuery]);

  return (
    <Layout className="pd-mobile-service">
      {/* Section that display chart */}
      <Row type="flex" justify="center" gutter={[10, 10]}>
        <Col className="pd-margin-top-md" xs={{ span: 24 }}>
          <Title className="pd-align-center" level={3}>
            Services
          </Title>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col xs={{ span: 15, offset: 1 }}>
          <Search placeholder="Search Service" onChange={handleSearchService} />
        </Col>
        <Col xs={{ span: 7 }}>
          <Select
            defaultValue={''}
            onChange={setFilter}
            style={{ width: '100%' }}>
            {statusFilter.map((status) => (
              <Option key={status.value} value={status.value}>
                {status.text}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row gutter={10} style={{ marginTop: '10px' }}>
        <Col xs={{ span: 6, offset: 1 }}>
          <PdButton block onClick={handleAddService}>
            Add New
          </PdButton>
        </Col>
        <Col xs={{ span: 8 }}>
          <PdCsvUploader
            afterUpload={fetchServiceList.bind(this, {
              cursor: cursor,
              q: searchQuery,
              status: filter,
            })}
            requiredFieldName={csvRequiredFieldName}
            type="service"
          />
        </Col>
        <Col xs={{ span: 8 }}>
          <PdDownloadCSV
            fetchData={ServiceAPI.getServiceReport}
            filename="service-report.csv"
          />
        </Col>
        <Col xs={24} className="pd-margin-top-lg">
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={{
              onChange: setCursor,
              pageSize: itemPerPage,
              total: 15,
            }}
          />
        </Col>
      </Row>
    </Layout>
  );
}

export default { Service };
