import React from 'react';
import { Col, Row } from 'antd';

import PdButton from 'components/pd-button';
import PdModal from 'components/pd-modal';
import PdInputNumber from 'components/pd-input-number';
import PdDatePicker from 'components/pd-datepicker';

import { priceFormatter, priceParser } from 'utils/inputNumberUtils';
import './style.scss';

function PaymentModal({ handleSubmit = () => {}, ...props }) {
  return (
    <PdModal className="pd-modal-payment" modalTitle="Payment" {...props}>
      <Row
        align="middle"
        className="payment-container"
        justify="center"
        type="flex">
        <Col lg={10} className="payment-label">
          Transaction Date
        </Col>
        <Col lg={10}>
          <PdDatePicker />
        </Col>
      </Row>
      <Row
        align="middle"
        className="payment-container"
        justify="center"
        type="flex">
        <Col lg={10} className="payment-label">
          Amount
        </Col>
        <Col lg={10}>
          <PdInputNumber
            defaultValue={0}
            min={0}
            formatter={priceFormatter}
            parser={priceParser}
          />
        </Col>
      </Row>
      <Row
        align="middle"
        className="payment-container"
        justify="center"
        type="flex">
        <Col lg={10}>
          <PdButton block onClick={handleSubmit}>
            Submit
          </PdButton>
        </Col>
      </Row>
    </PdModal>
  );
}

export default PaymentModal;
