import React from 'react';
import { InputNumber } from 'antd';

import './style.scss';

function PdInputNumber({ ...props }) {
  return <InputNumber {...props} />;
}

export default PdInputNumber;
