import React, { useEffect, useState } from 'react';
import {
  Col,
  Form,
  Input,
  InputNumber,
  Layout,
  message,
  Row,
  Select,
  Checkbox,
  DatePicker,
  Modal,
  Typography,
} from 'antd';
import { get } from 'lodash';
import moment from 'moment';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdForm from 'components/pd-form';

import { priceFormatter, priceParser } from 'utils/inputNumberUtils';
import { CUSTOMER_EMAIL_COPY_WRITE } from 'utils/constants';

import StateService from 'api/state';
import CustomerService from 'api/customer';

import './style.scss';

const { confirm } = Modal;
const { Text } = Typography;

const FORM_FIELD = {
  ID: 'id',
  NAME: 'name',
  EMAIL: 'email',
  PHONE: 'phone_number',
  FAX: 'fax_number',
  REGULAR_SERVICE: 'regular_service',
  STATE_CODE: 'state_code',
  ADDRESS: 'address',
  CITY: 'city',
  POSTAL_CODE: 'postal_code',
  START_DATE: 'start_date',
  NOTES: 'notes',
  SECONDARY_EMAIL: 'secondary_email',
};

const { Option } = Select;
const { TextArea } = Input;

function CustomerCreate({ form, history }) {
  const [listState, setListState] = useState([]);
  const [listAddress, setListAddress] = useState({ 0: {} });
  const [billingAddress, setBillingAddress] = useState({});
  const [isBillingPropertyEqual, setIsBillingPropertyEqual] = useState(true);
  const [anyChange, setAnyChange] = useState(false);
  const { getFieldDecorator } = form;

  const horizontalLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  const halfHorizontalLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  useEffect(() => {
    fetchStatesData();
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        const listPropertyAddress = [];
        Object.keys(listAddress).map((key) =>
          listPropertyAddress.push(listAddress[key])
        );

        const payload = {
          name: get(values, 'name', ''),
          email: get(values, 'email', ''),
          phone_number: get(values, 'phone_number', ''),
          fax_number: get(values, 'fax_number', ''),
          regular_service: get(values, 'regular_service', 0),
          start_date: values?.start_date?._d ?? moment()._d,
          notes: values?.notes ?? '',
          billing_address_attributes: billingAddress,
          property_addresses_attributes: listPropertyAddress,
          secondary_email: values?.secondary_email,
        };

        try {
          await CustomerService.createCustomer({
            customer: payload,
          });
          history.push({ pathname: '/customer-setup' });
        } catch (error) {
          message.error(
            get(error, 'response.message', 'Failed to Submit Form Data')
          );
        }
      }
    });
  }

  async function fetchStatesData() {
    const { data } = await StateService.getStates();
    setListState(get(data, 'data', []));
  }

  function handleAddNewAddress() {
    setListAddress((prevState) => ({
      ...prevState,
      [Object.keys(listAddress).length]: {},
    }));
  }

  function handleBack() {
    if (anyChange) {
      confirm({
        title: 'Are you sure?',
        content: 'Your changes will be lost',
        cancelButtonProps: { ghost: true },
        onOk: () => {
          history.goBack();
        },
      });
    } else {
      history.goBack();
    }
  }

  function handleChangeState(idx, optionObject) {
    const selectedAddress = JSON.parse(optionObject.key);
    const addressObject = listAddress[idx];
    addressObject['state_code'] = get(selectedAddress, 'code', '');
    addressObject['country'] = get(selectedAddress, 'country', '');

    setListAddress((prevState) => ({
      ...prevState,
      [idx]: addressObject,
    }));
  }

  function handleChangeAddress({ idx, key, value }) {
    let selectedAddress = listAddress[idx];
    selectedAddress[key] = value;

    setListAddress((prevState) => ({
      ...prevState,
      [idx]: selectedAddress,
    }));
  }

  function handleChangeBillingAddress({ key, value }) {
    setBillingAddress((prevState) => ({
      ...prevState,
      [key]: value,
    }));

    if (isBillingPropertyEqual) {
      handleChangeAddress({ idx: 0, key, value });
    }
  }

  function handleChangeAddressCondition(ev) {
    setIsBillingPropertyEqual(ev.target.checked);

    if (ev.target.checked) {
      setListAddress((prev) => {
        return {
          ...prev,
          0: billingAddress,
        };
      });
    }
  }

  function handleChangeBillingState(optionObject) {
    const selectedAddress = JSON.parse(optionObject.key);
    setBillingAddress((prevState) => ({
      ...prevState,
      ['state_code']: get(selectedAddress, 'code', ''),
      ['country']: get(selectedAddress, 'country', ''),
    }));

    if (isBillingPropertyEqual) {
      handleChangeState(0, optionObject);
    }
  }

  return (
    <Layout className="pd-cms-form-customer">
      <DashboardContainer>
        <PdForm
          onSubmit={handleSubmit}
          onChange={() => !anyChange && setAnyChange(true)}>
          <Form.Item label="Customer ID" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.ID)(
              <Input placeholder="Autofill" disabled />
            )}
          </Form.Item>
          <Form.Item label="Customer Name" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.NAME, {
              rules: [
                {
                  required: true,
                  message: 'Please input Customer Name!',
                },
              ],
            })(<Input placeholder="Enter Customer Name" />)}
          </Form.Item>

          <Form.Item label="Billing Address" {...horizontalLayout}>
            <Input
              placeholder="Enter Customer Billing Address"
              onChange={(e) =>
                handleChangeBillingAddress({
                  key: FORM_FIELD.ADDRESS,
                  value: get(e, 'target.value', ''),
                })
              }
            />
          </Form.Item>
          <Form.Item label="City" {...horizontalLayout}>
            <Input
              placeholder="Enter Customer City"
              onChange={(e) =>
                handleChangeBillingAddress({
                  key: FORM_FIELD.CITY,
                  value: get(e, 'target.value', ''),
                })
              }
            />
          </Form.Item>

          <Row>
            <Col lg={12}>
              <Form.Item label="State / Province" {...halfHorizontalLayout}>
                <Select
                  showSearch
                  placeholder="Choose State / Province"
                  onSelect={(value, optionObject) =>
                    handleChangeBillingState(optionObject)
                  }>
                  {listState.map((state) => (
                    <Option key={JSON.stringify(state)} value={state.name}>
                      {get(state, 'name', '')}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={{ span: 11, offset: 1 }}>
              <Form.Item label="Country" {...halfHorizontalLayout}>
                <Input
                  placeholder="Autofill"
                  disabled
                  value={get(billingAddress, 'country', '')}
                />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item label="Postal Code" {...halfHorizontalLayout}>
                <Input
                  placeholder="Enter Postal Code"
                  onChange={(e) =>
                    handleChangeBillingAddress({
                      key: FORM_FIELD.POSTAL_CODE,
                      value: get(e, 'target.value', ''),
                    })
                  }
                />
                <Checkbox
                  defaultChecked={isBillingPropertyEqual}
                  onChange={handleChangeAddressCondition}>
                  Property Address same with Billing Address
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          {/* address Section */}
          {Object.keys(listAddress).map((key, idx) => (
            <>
              <Form.Item label="Property Address" {...horizontalLayout}>
                <Input
                  value={listAddress[idx][FORM_FIELD.ADDRESS]}
                  placeholder="Enter Customer Property Address"
                  onChange={(e) =>
                    handleChangeAddress({
                      idx: idx,
                      key: FORM_FIELD.ADDRESS,
                      value: get(e, 'target.value', ''),
                    })
                  }
                  disabled={idx === 0 && isBillingPropertyEqual}
                />
              </Form.Item>
              <Form.Item label="City" {...horizontalLayout}>
                <Input
                  value={listAddress[idx][FORM_FIELD.CITY]}
                  placeholder="Enter Property City"
                  onChange={(e) =>
                    handleChangeAddress({
                      idx: idx,
                      key: FORM_FIELD.CITY,
                      value: get(e, 'target.value', ''),
                    })
                  }
                  disabled={idx === 0 && isBillingPropertyEqual}
                />
              </Form.Item>
              <Row>
                <Col lg={12}>
                  <Form.Item label="State / Province" {...halfHorizontalLayout}>
                    <Select
                      value={listAddress[idx][FORM_FIELD.STATE_CODE]}
                      showSearch
                      placeholder="Choose State / Province"
                      onSelect={(value, optionObject) =>
                        handleChangeState(idx, optionObject)
                      }
                      disabled={idx === 0 && isBillingPropertyEqual}>
                      {listState.map((state) => (
                        <Option key={JSON.stringify(state)} value={state.code}>
                          {get(state, 'name', '')}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg={{ span: 11, offset: 1 }}>
                  <Form.Item label="Country" {...halfHorizontalLayout}>
                    <Input
                      placeholder="Autofill"
                      disabled
                      value={listAddress[idx]['country']}
                    />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item label="Postal Code" {...halfHorizontalLayout}>
                    <Input
                      value={listAddress[idx][FORM_FIELD.POSTAL_CODE]}
                      placeholder="Enter Postal Code"
                      onChange={(e) =>
                        handleChangeAddress({
                          idx: idx,
                          key: FORM_FIELD.POSTAL_CODE,
                          value: get(e, 'target.value', ''),
                        })
                      }
                      disabled={idx === 0 && isBillingPropertyEqual}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          ))}

          <Form.Item className="btn-container">
            <Row>
              <Col lg={{ span: 4, offset: 4 }}>
                <PdButton block onClick={handleAddNewAddress}>
                  Add Property
                </PdButton>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item label="Phone Number" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.PHONE, {
              rules: [
                {
                  required: true,
                  message: 'Please input Customer Phone!',
                },
              ],
            })(<Input placeholder="Enter Phone Number" />)}
          </Form.Item>
          <Form.Item label="Fax" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.FAX)(
              <Input placeholder="Enter Fax" />
            )}
          </Form.Item>
          <Form.Item label="Email Address" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.EMAIL, {
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid Email Address!',
                },
                {
                  required: true,
                  message: 'Please input Customer Email Address!',
                },
              ],
            })(
              <div>
                <Input placeholder="Enter Customer Email Address" />
                <Text>{CUSTOMER_EMAIL_COPY_WRITE}</Text>
              </div>
            )}
          </Form.Item>
          <Form.Item label="Secondary Email" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.SECONDARY_EMAIL, {
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid Email Address!',
                },
              ],
            })(<Input placeholder="Enter Customer Secondary Email Address" />)}
          </Form.Item>
          <Form.Item label="Regular Service" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.REGULAR_SERVICE)(
              <InputNumber
                defaultValue={0}
                formatter={priceFormatter}
                parser={priceParser}
              />
            )}
          </Form.Item>
          <Form.Item label="Start Date" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.START_DATE)(
              <DatePicker format="MM/DD/YYYY" placeholder="Select Start Date" />
            )}
          </Form.Item>
          <Form.Item label="Notes" {...horizontalLayout}>
            {getFieldDecorator(FORM_FIELD.NOTES)(<TextArea />)}
          </Form.Item>

          <Form.Item className="btn-container">
            <Row
              align="middle"
              justify="center"
              type="flex"
              className="detail-container-action">
              <Col lg={6}>
                <PdButton block ghost type="primary" onClick={handleBack}>
                  Back
                </PdButton>
              </Col>
              <Col lg={{ span: 6, offset: 1 }}>
                <PdButton block htmlType="submit">
                  Submit
                </PdButton>
              </Col>
            </Row>
          </Form.Item>
        </PdForm>
      </DashboardContainer>
    </Layout>
  );
}

export const CustomerCreatePageForm = Form.create({ name: 'customer-create' })(
  CustomerCreate
);
