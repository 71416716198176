import React, { useEffect, useState } from 'react';
import { /*DatePicker, */ Col, Input, Layout, Row } from 'antd';
import { get } from 'lodash';
import moment from 'moment';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdAntdTable from 'components/pd-antd-table';
import { PdDownloadCSV } from 'components/pd-download-csv';

import CustomerAPI from 'api/customer';

import { numberWithCommas } from 'utils/numberFormater';

import './style.scss';

// const { MonthPicker } = DatePicker;
const { Search } = Input;

export function AllCustomer({ history }) {
  const [dataSource, setDataSource] = useState([]);
  const [query, setQuery] = useState('');

  const columns = [
    {
      title: 'Last Invoice Date',
      dataIndex: 'last_invoice_date',
      key: 'last_invoice_date',
      defaultSortOrder: 'descend',
      sorter: (a, b) =>
        moment(a.last_invoice_date).valueOf() -
        moment(b.last_invoice_date).valueOf(),
    },
    {
      title: 'Customer',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Outstanding Balance',
      dataIndex: 'current_balance',
      key: 'current_balance',
      render: ColumnAmount,
    },
    {
      title: '',
      key: 'id',
      render: ColumnAction,
      width: '15%',
    },
  ];

  function ColumnAmount(text) {
    return `$ ${numberWithCommas(text)}`;
  }

  function ColumnAction(rowData = {}) {
    const { id, name } = rowData || {};

    return (
      <Row>
        <Col lg={24}>
          <PdButton
            block
            type="primary"
            onClick={handleBalanceDetail.bind(this, id, name)}>
            Detail
          </PdButton>
        </Col>
      </Row>
    );
  }

  function handleSearchCustomerName(e) {
    setQuery(e?.target?.value || '');
  }

  function handleBalanceDetail(id, name) {
    // write code here
    history.push({
      pathname: '/all-customer-balance/detail',
      data: { id: id, name },
    });
  }

  async function fetchDataSource(q) {
    const { data } = await CustomerAPI.getCustomerList({ q });
    const customerListTemp = get(data, 'data', []);
    const customerList = customerListTemp.map((customer) => {
      const { last_invoice_date = '' } = customer;
      const formattedDate = moment(last_invoice_date).format('MM/DD/YYYY');
      return last_invoice_date
        ? { ...customer, last_invoice_date: formattedDate }
        : { ...customer };
    });

    setDataSource(customerList);
  }

  useEffect(() => {
    fetchDataSource(query);
    // eslint-disable-next-line
  }, [query]);

  return (
    <Layout className="pd-cms-customer-balance">
      <DashboardContainer>
        <Row align="middle" type="flex">
          <Col lg={10}>
            <Search
              placeholder="Search by Customer Name"
              onChange={handleSearchCustomerName}
            />
            {/* <MonthPicker placeholder="Select month" /> */}
          </Col>
          <Col lg={{ span: 3, offset: 11 }}>
            <PdDownloadCSV
              fetchData={CustomerAPI.getAllCustomerBalanceReport}
              filename="customer-balance-report.csv"
            />
          </Col>
        </Row>
      </DashboardContainer>
      <DashboardContainer>
        <Row align="middle">
          <Col lg={24}>
            <PdAntdTable dataSource={dataSource} columns={columns} />
          </Col>
        </Row>
      </DashboardContainer>
    </Layout>
  );
}

export default { AllCustomer };
