export const dataSource = [
  {
    id: 1,
    key: 1,
    invoice_number: '20-Inv-1',
    customer_name: 'CDN Gamma',
    period: 'Feb 01',
  },
  {
    id: 2,
    key: 2,
    invoice_number: '20-Inv-2',
    customer_name: 'CDN Zero',
    period: 'Feb 02',
  },
  {
    id: 3,
    key: 3,
    invoice_number: '20-Inv-3',
    customer_name: 'CDN Sigma',
    period: 'Feb 03',
  },
];

export const INVOICE_STATUS = [
  {
    key: 'all',
    text: 'All',
  },
  {
    key: 'created',
    text: 'Created',
  },
  {
    key: 'sent',
    text: 'Sent',
  },
];
