import React from 'react';
import { Col, Row } from 'antd';
import { get } from 'lodash';

import './style.scss';

function PdTable({ children }) {
  return <div className="pd-table">{children}</div>;
}

/**
 *
 * @typedef {Object} props
 * @param {Array.<{title: String, width: Number}>} props.listHeader - Array of table header containing title and width (optional)
 * @param {(left | center | right)} props.align - Align of header content
 * @example [{title: 'Title', width: [1-24]}]
 */
export const PdTableHeader = ({ listHeader = [], align = 'left' }) => {
  return (
    <Row className="pd-table-header" align="middle" type="flex">
      {listHeader.map((header, idx) => (
        <Col
          align={align}
          className="pd-table-header-label"
          key={idx}
          xs={get(header, 'width', 24 / listHeader.length)}>
          {get(header, 'title', '')}
        </Col>
      ))}
    </Row>
  );
};

/**
 *  Children must be wrap with Col tag
 *  @example
 * <PdTableBody>
 *  <Col>content here</Col>
 * </PdTableBody>
 */
export const PdTableBodyRow = ({ children, className = '', ...props }) => {
  return (
    <Row
      className={`pd-table-body ${className}`}
      align="middle"
      type="flex"
      {...props}>
      {children}
    </Row>
  );
};

export default PdTable;
