import React, { useState } from 'react';
import { Icon, message, Upload } from 'antd';

import UploadApi from 'api/upload';
import { handleErr } from 'utils';

/**
 *
 *  @param {String} alt - img alt property
 *  @param {String} imgClassName - img className property 
 *  @param {String} imgUrl - img src property 
 *  @param {String} name - Upload.Dragger name property 
 *  @param {String} purpose - beforeUpload payload materials
 *  @param {()=> {}} afterUpload - callback after uploading image to aws is completed

 */

const PdUploader = ({
  alt = '',
  imgClassName = '',
  imgUrl = '',
  name = '',
  purpose = 'company_logo',
  afterUpload = () => {},
}) => {
  const [uploading, setUploading] = useState(false);
  async function handleBeforeUpload(file) {
    try {
      setUploading(true);
      const ext = file.type.split('/')[1];
      const {
        data: { data: uploadUrl },
      } = await UploadApi.getUploadFileUrl({
        file_extension: ext,
        file_purpose: purpose,
      });
      await UploadApi.uploadImage(file, uploadUrl.upload_url);
      message.success('Successfully uploaded');
      afterUpload(uploadUrl.secure_url);
    } catch (err) {
      handleErr(err);
    } finally {
      setUploading(false);
    }
  }
  return (
    <Upload.Dragger
      name={name}
      className="pd-uploader"
      showUploadList={false}
      beforeUpload={handleBeforeUpload}>
      {!imgUrl || uploading ? (
        <>
          <p className="ant-upload-drag-icon">
            <Icon type={uploading ? 'loading' : 'inbox'} />
          </p>
          <p className="ant-upload-text">
            Click or drag a picture here to upload
          </p>
        </>
      ) : (
        <img
          alt={alt}
          className={imgClassName}
          src={imgUrl}
          width={150}
          height={75}
        />
      )}
    </Upload.Dragger>
  );
};

export default PdUploader;
