// TODO: Handle download .csv

import React, { useEffect, useState } from 'react';
import { Col, Input, Layout, message, Row } from 'antd';
import { get } from 'lodash';
import moment from 'moment';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import { PdDownloadCSV } from 'components/pd-download-csv';
import PdAntdTable from 'components/pd-antd-table';

import { numberDate, numberWithCommas } from 'utils/numberFormater';

import InvoiceAPI from 'api/invoice';

const { Search } = Input;

const itemPerPage = 6;

export function InvoiceInformation({ history }) {
  const [customerList, setCustomerList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const columns = [
    {
      title: 'Work Order No.',
      dataIndex: 'id',
      key: 'id',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Customer',
      dataIndex: 'customer.name',
      key: 'customer.name',
      sorter: (a, b) => a.customer.name.localeCompare(b.customer.name),
    },
    {
      title: 'Work Order Date',
      dataIndex: 'ordered_at',
      key: 'ordered_at',
      render: ColumnDate,
      sorter: (a, b) =>
        moment(a.ordered_at).valueOf() - moment(b.ordered_at).valueOf(),
    },
    {
      title: 'Amount',
      dataIndex: 'total_price',
      key: 'total_price',
      render: ColumnPrice,
    },
    {
      title: '',
      key: 'key',
      render: ColumnAction,
      width: '15%',
    },
  ];

  const dataSource = customerList || [];

  function ColumnAction(data = {}) {
    return (
      <Row>
        <Col lg={24}>
          <PdButton
            block
            type="primary"
            onClick={handleInvoiceDetail.bind(this, get(data, 'id', ''))}>
            Detail
          </PdButton>
        </Col>
      </Row>
    );
  }

  function ColumnDate(date) {
    return `${numberDate(date)}`;
  }

  function ColumnPrice(price) {
    return <>{`$${numberWithCommas(price)}`}</>;
  }

  async function fetchCustomerList({ limit = 6, cursor = 1, q = '' }) {
    try {
      const { data } = await InvoiceAPI.getAllInvoiceInfo({
        limit,
        cursor,
        q,
      });

      const customerData = get(data, 'data', []);
      setCustomerList(customerData);
    } catch (err) {
      message.error(
        get(err, 'response.message', 'Failed to get customer data')
      );
    }
  }

  function handleInvoiceDetail(id = '') {
    history.push({
      pathname: '/work-orders-information/detail',
      id,
    });
  }

  function handleSearchCustomer(e) {
    setSearchQuery(get(e, 'target.value', ''));
  }

  function fetchDownloadCsvData() {
    const customerListData = customerList.map((customerEl) => {
      const { customer = {}, address = {} } = customerEl;
      const { name = '', billing_address = {} } = customer;
      const { address: billingAddressTitle = '' } = billing_address;
      const { address: addressTitle = '' } = address;
      return {
        ...customer,
        name,
        address: addressTitle,
        billing_address: billingAddressTitle,
      };
    });
    return { data: { data: customerListData } };
  }

  useEffect(() => {
    fetchCustomerList({
      limit: 100,
      cursor: 1,
      q: searchQuery,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <Layout className="pd-cms-customer-setup">
      {/* Section that display chart */}
      <DashboardContainer>
        <Row align="middle" type="flex">
          <Col lg={10}>
            <Search
              placeholder="Search by Customer Name"
              onChange={handleSearchCustomer}
            />
          </Col>
          <Col lg={{ span: 3, offset: 11 }}>
            <PdDownloadCSV
              fetchData={fetchDownloadCsvData}
              filename="work-orders-information.csv"
            />
          </Col>
        </Row>
      </DashboardContainer>
      <DashboardContainer>
        {/* Section that display chart */}
        <Row align="middle">
          <Col lg={24}>
            <PdAntdTable
              dataSource={dataSource}
              rowKey="id"
              columns={columns}
              pagination={{
                pageSize: itemPerPage,
              }}
            />
          </Col>
        </Row>
      </DashboardContainer>
    </Layout>
  );
}

export default { InvoiceInformation };
