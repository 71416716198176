import api from './index';
import { omitBy } from 'lodash';
import { isNil } from 'utils';

export default {
  createRole(payload) {
    return api.post(`/roles`, payload);
  },
  listRole({ q = '' }) {
    const params = {
      q,
    };
    const url = '/roles';
    return api.get(url, { params: omitBy(params, isNil) });
  },
  updateRole(id, payload) {
    return api.put(`/roles/${id}`, payload);
  },
  activateRole(id) {
    return api.put(`/roles/${id}/reactivate`);
  },
  deactivateRole(id) {
    return api.delete(`/roles/${id}`);
  },
  deleteRole(id) {
    return api.delete(`/roles/${id}/delete`);
  },
};
