import React, { useState, useEffect } from 'react';
import { Col, Layout, message, Row, Tag, Modal } from 'antd';
// import Search from 'antd/lib/input/Search';
import { get } from 'lodash';
import queryString from 'query-string';

import RoleApi from 'api/role';

import { PageSpinner } from 'components/page-spinner';
import DashboardContainer from 'components/dashboard-container';
import PdAntdTable from 'components/pd-antd-table';
import PdButton from 'components/pd-button';

import { FORM_ROLE_FORMATTER } from 'utils';

import {
  handleErr,
  FORM_USER_MANAGEMENT_ACTION_BUTTON,
  FORM_USER_MANAGEMENT_STATUS_BUTTON,
} from 'utils';

import './style.scss';

const { confirm } = Modal;

export function Role({ history, location }) {
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const parsed = queryString.parse(location.search.substring(1)); //substring(1) is to eliminate '?' as object key

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: 'Page Access',
      dataIndex: 'permission',
      key: 'permission',
      render: ColumnAccess,
      width: '35%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: ColumnRole,
    },
    {
      title: '',
      key: 'id',
      render: ColumnAction,
      width: '30%',
    },
  ];

  function ColumnAccess(access = []) {
    return (
      <>
        {access.map((acc, idx) => {
          return <Tag key={idx}>{FORM_ROLE_FORMATTER[acc]}</Tag>;
        })}
      </>
    );
  }

  function ColumnRole(text) {
    return <div className="text-role">{text}</div>;
  }

  function ColumnAction(rowData = {}) {
    return (
      <Row>
        <Col lg={{ span: 10, offset: 2 }}>
          <PdButton
            block
            ghost
            type="primary"
            onClick={handleOnClickActivationButton.bind(this, [
              rowData.id,
              rowData.status,
            ])}>
            {FORM_USER_MANAGEMENT_STATUS_BUTTON[rowData.status]}
          </PdButton>
        </Col>
        <Col lg={{ span: 10, offset: 2 }}>
          <PdButton
            block
            type="primary"
            onClick={handleActionButton.bind(this, [rowData, rowData.status])}>
            {FORM_USER_MANAGEMENT_ACTION_BUTTON[rowData.status]}
          </PdButton>
        </Col>
      </Row>
    );
  }

  function handleAddRole() {
    // write code here
    history.push({
      pathname: '/role/create',
    });
  }

  function handleEdit(detailData) {
    history.push('role/edit', detailData);
  }

  async function getListRole() {
    setIsLoading(true);
    try {
      const payload = {
        q: parsed['q'],
      };
      const { data } = await RoleApi.listRole(payload);
      setDataSource(get(data, 'data', ''));
    } catch (error) {
      handleErr(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleOnClickActivationButton([id, status]) {
    setIsLoading(true);
    try {
      switch (status) {
        case 'active':
          await RoleApi.deactivateRole(id);
          break;
        case 'inactive':
          await RoleApi.activateRole(id);
          break;
        default:
          break;
      }
      history.push({
        pathname: '/role',
      });
    } catch (error) {
      handleErr(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleActionButton([rowData, status]) {
    setIsLoading(true);
    try {
      switch (status) {
        case 'active':
          handleEdit(rowData);
          break;
        case 'inactive':
          confirm({
            title: 'Are you sure you want to delete this role?',
            content: "You can't undo this action",
            cancelButtonProps: { ghost: true },
            onOk: async () => {
              try {
                await RoleApi.deleteRole(get(rowData, 'id', ''));
                message.success('Successfully delete a role');
                history.push({
                  pathname: '/role',
                });
              } catch (err) {
                handleErr(err);
              }
            },
          });

          break;
      }
    } catch (error) {
      handleErr(error);
    } finally {
      setIsLoading(false);
    }
  }

  /* eslint-disable no-unused-vars */
  function handleSearch(q) {
    history.push({
      pathname: '/role',
      search: queryString.stringify(
        q && {
          q,
        }
      ),
    });
  }
  /* eslint-disable no-unused-vars */

  useEffect(() => {
    getListRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  return (
    <Layout className="pd-cms-admin">
      {/* Section that display chart */}
      <DashboardContainer>
        <Row align="middle" type="flex">
          <Col lg={10}>
            {/* <Search placeholder="Search Name" onSearch={handleSearch} /> */}
          </Col>
          <Col lg={{ span: 3, offset: 11 }}>
            <PdButton block onClick={handleAddRole}>
              Add New
            </PdButton>
          </Col>
        </Row>
      </DashboardContainer>
      {isLoading ? (
        <PageSpinner />
      ) : (
        <DashboardContainer>
          {/* Section that display chart */}
          <Row align="middle">
            <Col lg={24}>
              <PdAntdTable
                dataSource={dataSource}
                columns={columns}
                pagination={{ pageSize: 5 }}
              />
            </Col>
          </Row>
        </DashboardContainer>
      )}
    </Layout>
  );
}

export default { Role };
