import React, { useEffect, useState } from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import { get } from 'lodash';
import moment from 'moment';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdAntdTable from 'components/pd-antd-table';

import CustomerAPI from 'api/customer';
import InvoiceAPI from 'api/invoice';

import PaymentModal from './payment-modal';
import DetailModal from './detail-modal';

import { capitalizeFirstLetter, handleErr } from 'utils';
import { numberDate, numberWithCommas } from 'utils/numberFormater';

import './style.scss';

const { Text } = Typography;

export function CustomerBalanceDetail({ history, location }) {
  const [balanceList, setBalanceList] = useState([]);
  const [invoiceData, setInvoiceData] = useState({});
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);

  const { id = undefined, name = '' } = location ?? {};

  const dataSource = balanceList ?? [];

  const columns = [
    {
      title: 'Transaction Date',
      dataIndex: 'transaction_date',
      key: 'transaction_date',
      render: ColumnDate,
      defaultSortOrder: 'descend',
      sorter: (a, b) =>
        moment(a.transaction_date).valueOf() -
        moment(b.transaction_date).valueOf(),
    },
    {
      title: 'Type',
      dataIndex: 'id',
      key: 'transaction_type',
      render: ColumnType,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: ColumnAmount,
    },
    {
      title: 'Total Current Balance',
      dataIndex: 'current_balance',
      key: 'current_balance',
      render: ColumnAmount,
    },
  ];

  function ColumnAmount(text) {
    return `$ ${numberWithCommas(text)}`;
  }

  function ColumnDate(date) {
    return <>{`${numberDate(date)}`}</>;
  }

  function ColumnType(id) {
    const selectedBalance = balanceList.find((balance) => balance.id === id);
    const {
      transaction_type: type = '',
      payment = {},
      order = {},
    } = selectedBalance;
    if (['payment', 'rebalance'].includes(type)) {
      return (
        <Text>
          {capitalizeFirstLetter(type)} ({' '}
          {type === 'payment'
            ? payment?.order?.invoice_number
            : order?.invoice_number}{' '}
          )
        </Text>
      );
    }

    return (
      <a onClick={handleOnClickType.bind(this, order)}>
        <Text underline>{order?.invoice_number}</Text>
      </a>
    );
  }

  async function handleOnClickType({ id = '' }) {
    try {
      const {
        data: { data },
      } = await InvoiceAPI.getInvoiceDetail(id);

      // Manipulate Order Service Date
      const orderServicesTemp = get(data, 'order_services', []);
      const order_services = orderServicesTemp.map((service) => {
        const { service_date = '' } = service;
        const formattedDate = moment(service_date).format('MM/DD/YYYY');
        return { ...service, service_date: formattedDate };
      });
      const result = { ...data, order_services };

      setInvoiceData(result);
      setShowDetailModal(true);
    } catch (error) {
      handleErr(error);
    }
  }

  async function fetchCustomerBalanceData() {
    try {
      const { data } = await CustomerAPI.getCustomerBalance({ id });
      const balanceDataTemp = get(data, 'data', []);
      const balanceData = balanceDataTemp.map((balance) => ({
        ...balance,
        transaction_date: moment(balance.transaction_date).format('MM/DD/YYYY'),
      }));

      setBalanceList(balanceData);
    } catch (err) {
      handleErr(err);
    }
  }

  function handlePayment() {
    setShowPaymentModal(true);
  }

  async function handlePaymentSubmit(payload) {
    try {
      await CustomerAPI.createCustomerPayment(id, payload);
      setShowPaymentModal(false);
      history.push('/customer-balance');
    } catch (error) {
      handleErr(error);
    }
  }

  function handleCloseModal() {
    setShowPaymentModal(false);
    setShowDetailModal(false);
  }

  function handleBack() {
    history.push('/customer-balance');
  }

  useEffect(() => {
    if (!id) {
      history.push({ pathname: '/customer-balance' });
    }
    fetchCustomerBalanceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout className="pd-cms-customer-balance-detail">
      <DashboardContainer>
        <Row align="middle" type="flex" className="customer-container">
          <Col lg={4} className="title">
            Customer Name
          </Col>
          <Col lg={6} className="title">
            {name}
          </Col>
          <Col lg={{ span: 5, offset: 9 }}>
            <PdButton block onClick={handlePayment}>
              Payment
            </PdButton>
          </Col>
        </Row>

        <Row align="middle">
          <Col lg={24}>
            <PdAntdTable
              dataSource={dataSource}
              columns={columns}
              rowKeys="id"
            />
          </Col>
        </Row>

        <Row align="middle" justify="center" type="flex">
          <Col lg={{ span: 4 }}>
            <PdButton block ghost type="primary" onClick={handleBack}>
              Back
            </PdButton>
          </Col>
        </Row>
      </DashboardContainer>
      {showPaymentModal && (
        <PaymentModal
          visible={showPaymentModal}
          onCancel={handleCloseModal}
          onHandleSubmit={handlePaymentSubmit}
        />
      )}
      <DetailModal
        invoiceData={invoiceData}
        onCancel={handleCloseModal}
        visible={showDetailModal}
      />
    </Layout>
  );
}

export default { CustomerBalanceDetail };
