import api from './index';
import { omitBy } from 'lodash';
import { isNil } from 'utils';

export default {
  getOrderService({ start_date = '', end_date = '', customer_id = 0 }) {
    const params = {
      start_date,
      end_date,
      customer_id,
    };
    return api.get('/order_services', { params: omitBy(params, isNil) });
  },
};
