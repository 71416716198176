/**
 * numberWithCommas() Function is used for adding coma ',' for each thousand
 * @param {String} value String that wantted to be formatted
 * @returns String of number with formatted value
 * @example '10000' => '10,000'
 */

export function numberWithCommas(value) {
  return Number(value).toLocaleString();
}

/**
 * numberDate() Function is used for formatting date mm/dd/yyyy
 * @param {String} value Date that wanted to be formatted
 * @returns String of date with formatted value
 * @example '2021-05-03T13:21:22.094Z' => '03/05/2021'
 */

export function numberDate(value) {
  const month = new Date(value).getUTCMonth() + 1; // Month in Javascript start from 0
  const day = new Date(value).getUTCDate();
  const year = new Date(value).getUTCFullYear();

  return isNaN(month || day || year)
    ? '- / - / -'
    : `${month < 10 ? '0' + month : month}/${
        day < 10 ? '0' + day : day
      }/${year}`;
}

/**
 * oneNumberDecimalFormat() Function is used for round a number to one decimal behind comma
 * @param {Number} the number
 * @returns number
 * @example '22,5555555555' => '22,6'
 */
export function oneNumberDecimalFormat(value) {
  return Math.round(value * 10) / 10;
}
