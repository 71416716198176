import api from './index';

export default {
  createInvoice(order = {}) {
    return api.post('/order_invoices', order);
  },
  getAllInvoiceInfo({ limit = 1, cursor = 1, q = '', status = '' }) {
    let params = { limit, q, cursor };

    if (status !== '') params = { ...params, status };

    return api.get('/order_invoices', { params });
  },
  getInvoiceDetail(id) {
    return api.get(`/order_invoices/${id}`);
  },

  getCustomerAddressList(id) {
    return api.get(`/order_invoices/customers/${id}/addresses`);
  },
  getInvoicePDF(id) {
    return api.post(`/order_invoices/${id}/print_pdf`);
  },
  editInvoice(id, order = {}) {
    return api.put(`/order_invoices/${id}`, order);
  },
  payInvoices(id) {
    return api.put(`/order_invoices/${id}/pay`);
  },
  updateServiceProgress(id, order = {}) {
    return api.put(`/order_invoices/${id}/update_progress`, order);
  },
  sendMail(id) {
    return api.post(`/order_invoices/${id}/send_email`);
  },
  cancelInvoice(id) {
    return api.post(`/order_invoices/${id}/cancel`);
  }
};
