// TODO: Handle Send Mail to Customer Feature
// TODO: Handle PDF Print Feature

import React, { useEffect, useState } from 'react';
import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Layout,
  message,
  Row,
  Select,
} from 'antd';
import { get } from 'lodash';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdTable, { PdTableBodyRow, PdTableHeader } from 'components/pd-table';

import { priceFormatter, priceParser } from 'utils/inputNumberUtils';

import InvoiceAPI from 'api/invoice';

import './style.scss';

const { Option } = Select;

function InvoiceEdit({ history, location }) {
  // eslint-disable-next-line
  const [invoiceData, setInvoiceData] = useState({});
  const { id: locationID } = location ?? {};
  const tableHeaders = [
    { title: 'Service', width: 7 },
    { title: 'Service Date', width: 5 },
    { title: 'Quantity', width: 4 },
    { title: '$ per Unit', width: 4 },
    { title: 'Amount', width: 4 },
  ];

  async function fetchInvoiceData(id) {
    try {
      const { data } = await InvoiceAPI.getInvoiceDetail(id);
      setInvoiceData(get(data, 'data', []));
    } catch (err) {
      message.error(get(err, 'response.message', 'Failed to get invoice data'));
    }
  }

  function handleSubmit() {}

  function handlePrintPDF() {}

  function handleSendMail() {}

  function onChange() {}

  function onSearch() {}

  useEffect(() => {
    !locationID
      ? history.push('/work-orders-information')
      : fetchInvoiceData(locationID);
    // eslint-disable-next-line
  }, []);

  return (
    <Layout className="pd-cms-form-invoice">
      <DashboardContainer>
        <Row className="form-item" align="middle" type="flex">
          <Col className="form-item-label" lg={{ span: 5 }}>
            Invoice Number
          </Col>
          <Col lg={{ span: 13, offset: 1 }}>
            <Input placeholder="Enter Invoice Number" />
          </Col>
        </Row>
        <Row className="form-item" align="middle" type="flex">
          <Col className="form-item-label" lg={{ span: 5 }}>
            Customer Name
          </Col>
          <Col lg={{ span: 13, offset: 1 }}>
            <Select
              className="form-item-block"
              showSearch
              placeholder="Enter Customer Name"
              onChange={onChange}
              onSearch={onSearch}>
              <Option value="jack">Jack</Option>
              <Option value="lucy">Lucy</Option>
              <Option value="tom">Tom</Option>
            </Select>
          </Col>
        </Row>
        <Row className="form-item" align="middle" type="flex">
          <Col className="form-item-label" lg={{ span: 5 }}>
            Email
          </Col>
          <Col lg={{ span: 13, offset: 1 }} className="form-item-label-muted">
            Autofill
          </Col>
        </Row>
        <Row className="form-item" align="middle" type="flex">
          <Col className="form-item-label" lg={{ span: 5 }}>
            Invoice Date
          </Col>
          <Col lg={{ span: 13, offset: 1 }}>
            <DatePicker placeholder="Select Invoice Date" />
          </Col>
        </Row>

        {/* Table Section */}
        <PdTable>
          <PdTableHeader listHeader={tableHeaders} />
          <PdTableBodyRow>
            <Col lg={7}>
              <Select
                showSearch
                placeholder="Service Name"
                onChange={onChange}
                onSearch={onSearch}>
                <Option value="1">Service 1</Option>
                <Option value="2">Service 2</Option>
              </Select>
            </Col>

            <Col lg={5}>
              <DatePicker />
            </Col>
            <Col lg={4}>
              <InputNumber min={0} defaultValue={0} />
            </Col>
            <Col lg={4}>
              <InputNumber
                disabled
                defaultValue={0}
                formatter={priceFormatter}
                parser={priceParser}
              />
            </Col>
            <Col lg={4}>
              <InputNumber
                disabled
                defaultValue={0}
                formatter={priceFormatter}
                parser={priceParser}
              />
            </Col>
          </PdTableBodyRow>
          <PdTableBodyRow>
            <Col>
              <PdButton type="link" icon="plus">
                Add More Service
              </PdButton>
            </Col>
          </PdTableBodyRow>
        </PdTable>

        <Row className="form-item" align="middle" justify="end" type="flex">
          <Col className="form-item-label" lg={{ span: 3 }}>
            Discount
          </Col>
          <Col lg={{ span: 4, offset: 1 }}>
            <Input suffix="%" />
          </Col>
        </Row>
        <Row className="form-item" align="middle" justify="end" type="flex">
          <Col className="form-item-label" lg={{ span: 3 }}>
            Total Amount
          </Col>
          <Col lg={{ span: 4, offset: 1 }}>
            <Input prefix="$" />
          </Col>
        </Row>

        <Row
          align="middle"
          justify="center"
          type="flex"
          className="submit-container">
          <Col lg={5}>
            <PdButton block onClick={handleSendMail}>
              Send Mail to Customer
            </PdButton>
          </Col>
          <Col lg={{ span: 5, offset: 1 }}>
            <PdButton block onClick={handlePrintPDF}>
              PDF Print
            </PdButton>
          </Col>
          <Col lg={{ span: 5, offset: 1 }}>
            <PdButton block onClick={handleSubmit}>
              Submit
            </PdButton>
          </Col>
        </Row>
      </DashboardContainer>
    </Layout>
  );
}

export const InvoiceInformationEditPageForm = Form.create({
  name: 'invoice-edit',
})(InvoiceEdit);
