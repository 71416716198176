import React, { useEffect, useState } from 'react';
import { Col, Layout, message, Modal, Row, Typography } from 'antd';
import Search from 'antd/lib/input/Search';
import { get } from 'lodash';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import { PdCsvUploader } from 'components/pd-csv-uploader';
import PdAntdTable from 'components/pd-antd-table';

import CustomerService from 'api/customer';

import {
  handleErr,
  FORM_USER_MANAGEMENT_STATUS_BUTTON,
  FORM_USER_MANAGEMENT_ACTION_BUTTON,
} from 'utils';

import './style.scss';

const { confirm } = Modal;
const { Text } = Typography;

export function CustomerSetup({ history }) {
  const [customerList, setCustomerList] = useState([]);
  const [cursor, setCursor] = useState(1);
  const [query, setQuery] = useState('');

  const csvRequiredFieldName = [
    'customer_name',
    'billing_address',
    'state_code',
    'email',
  ];

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 70,
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Customer',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Billing Address',
      dataIndex: 'billing_address.address',
      key: 'billing_address.address',
      width: 250,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 200,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 80,
      render: ColumnRole,
    },
    {
      title: '',
      render: ColumnAction,
      width: '30%',
    },
  ];

  useEffect(() => {
    fetchCustomerList({ cursor, q: query });
  }, [cursor, query]);

  async function fetchCustomerList({ cursor = 1, q = '' }) {
    try {
      const { data } = await CustomerService.getCustomerList({
        cursor,
        q,
      });
      // append key for each data
      const customersData = get(data, 'data', []);
      customersData.forEach((customer) => {
        customer['key'] = customer.id;
      });
      setCustomerList(customersData);
    } catch (error) {
      handleErr(error);
    }
  }

  async function toggleStatus([id = '', status = '']) {
    try {
      if (status === 'active') {
        await CustomerService.deactivateCustomer(id);
      } else {
        await CustomerService.activateCustomer(id);
      }
      fetchCustomerList({ cursor, q: query });
    } catch (err) {
      handleErr(err);
    }
  }

  function handleButtonAction([rowData = {}, status = '']) {
    const { id = '' } = rowData;
    if (status === 'active') {
      handleEditCustomer(rowData);
    } else {
      handleDeleteCustomer(id);
    }
  }

  function ColumnRole(text) {
    return <Text className="text-role">{text}</Text>;
  }

  function ColumnAction(rowData = {}) {
    const { id = '', status = '' } = rowData;
    return (
      <Row>
        <Col lg={{ span: 10, offset: 2 }}>
          <PdButton
            block
            ghost
            type="primary"
            onClick={toggleStatus.bind(this, [id, status])}>
            {FORM_USER_MANAGEMENT_STATUS_BUTTON[status]}
          </PdButton>
        </Col>
        <Col lg={{ span: 10, offset: 2 }}>
          <PdButton
            block
            onClick={handleButtonAction.bind(this, [rowData, status])}
            type="primary">
            {FORM_USER_MANAGEMENT_ACTION_BUTTON[status]}
          </PdButton>
        </Col>
      </Row>
    );
  }

  function handleAddCustomer() {
    history.push({
      pathname: '/customer-setup/create',
    });
  }

  function handleEditCustomer(customerData) {
    history.push({
      pathname: '/customer-setup/edit',
      data: customerData,
    });
  }

  function handleDeleteCustomer(deleteId) {
    confirm({
      title: 'Do you want to delete this customer',
      content: "You can't undo this action",
      cancelButtonProps: { ghost: true },
      onOk: async () => {
        try {
          await CustomerService.deleteCustomer(deleteId);
          message.success('Customer successfully deleted');
          fetchCustomerList({ cursor, q: query });
        } catch (error) {
          handleErr(error);
        }
      },
    });
  }

  function handleSearchCustomerName(e) {
    setQuery(get(e, 'target.value', ''));
    setCursor(1);
  }

  return (
    <Layout className="pd-cms-customer-setup">
      <DashboardContainer>
        <Row align="middle" type="flex" gutter={10}>
          <Col lg={10}>
            <Search
              placeholder="Search by Customer Name"
              onChange={handleSearchCustomerName}
            />
          </Col>
          <Col lg={{ span: 3, offset: 8 }}>
            <PdCsvUploader
              afterUpload={fetchCustomerList.bind(this, { cursor, q: query })}
              requiredFieldName={csvRequiredFieldName}
              type="customer-setup"
            />
          </Col>
          <Col lg={3}>
            <PdButton block onClick={handleAddCustomer}>
              Add New
            </PdButton>
          </Col>
        </Row>
      </DashboardContainer>
      <DashboardContainer>
        {/* Section that display chart */}
        <Row align="middle">
          <Col lg={24}>
            <PdAntdTable
              dataSource={customerList}
              rowKeys="id"
              columns={columns}
            />
          </Col>
        </Row>
      </DashboardContainer>
    </Layout>
  );
}

export default { CustomerSetup };
