// TODO: Implement Pagination, for now has been disabled and limit up to 25000

import React, { useEffect, useState } from 'react';
import { Col, Layout, Input, message, Row, Select, Icon, Modal } from 'antd';
import moment from 'moment';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdAntdTable from 'components/pd-antd-table';
import PdCheckbox from 'components/pd-checkbox';
import PdArchieveIcon from 'components/pd-archieve-icon';

import ProposalAPI from 'api/proposal';
import { numberDate } from 'utils/numberFormater';

import './style.scss';
import { get } from 'lodash';

const { Option } = Select;
const { Search } = Input;
const { confirm } = Modal;

export function Proposal({ history }) {
  const [archiveQuery, setArchiveQuery] = useState([]);
  const [cursor, setCursor] = useState(1);
  const [loading, setLoading] = useState({});
  const [proposalList, setProposalList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [status, setStatus] = useState(undefined);

  const dataSource = proposalList ?? {};

  const columns = [
    {
      title: 'Proposal No.',
      dataIndex: 'id',
      key: 'id',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Customer',
      dataIndex: 'customer.name',
      key: 'customer.name',
      sorter: (a, b) => a.customer.name.localeCompare(b.customer.name),
    },
    {
      title: 'Proposal Date',
      dataIndex: 'ordered_at',
      key: 'ordered_at',
      render: ColumnDate,
      sorter: (a, b) =>
        moment(a.ordered_at).valueOf() - moment(b.ordered_at).valueOf(),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: ColumnStatus,
    },
    {
      title: '',
      key: 'archive',
      render: ColumnArchive,
    },
    {
      title: '',
      key: 'key',
      render: ColumnAction,
      width: '15%',
    },
  ];

  const statusFilter = [
    { value: 'created', text: 'Created' },
    { value: 'sent', text: 'Sent' },
    { value: 'approved', text: 'Approved' },
    { value: 'rejected', text: 'Rejected' },
  ];

  function ColumnAction(data = {}) {
    return (
      <Row>
        <Col lg={24}>
          <PdButton
            block
            type="primary"
            onClick={handleProposalDetail.bind(
              this,
              get(data, 'id', undefined)
            )}>
            Detail
          </PdButton>
        </Col>
      </Row>
    );
  }

  function ColumnStatus(value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  function ColumnDate(date) {
    return numberDate(date);
  }

  function ColumnArchive({ archive, id }) {
    const isCurrentRow = loading?.id === id;
    const archiveLoading = isCurrentRow && loading?.type === 'archive';
    const deleteLoading = isCurrentRow && loading?.type === 'delete';
    return (
      <>
        <PdArchieveIcon
          isNotArchieve={!archive}
          onClick={() => handleProposalArchive(id, !archive)}
          loading={archiveLoading}
          className="pd-margin-right-sm"
        />
        <Icon
          type={deleteLoading ? 'sync' : 'delete'}
          spin={deleteLoading}
          onClick={() => handleDeleteProposal(id)}
        />
      </>
    );
  }

  function handleChangeStatus(status) {
    setStatus(status);
  }

  function handleCreateProposal() {
    history.push({
      pathname: '/proposal/create',
    });
  }

  function handleProposalDetail(id = undefined) {
    history.push({
      pathname: '/proposal/detail',
      id,
    });
  }

  function handleSearchCustomerName(e) {
    setSearchQuery(get(e, 'target.value', ''));
    setCursor(1);
  }

  async function handleProposalArchive(id, isArchieveAction) {
    try {
      setLoading({ type: 'archive', id });
      await ProposalAPI.archiveProposal(id);
      fetchProposalData({
        cursor,
        q: searchQuery,
        status,
        archive: !!archiveQuery.length,
      });
      message.success(
        `Proposal has been ${isArchieveAction ? 'archieved' : 'unarchieved'}`
      );
    } catch (err) {
      message.error(err?.response?.message || 'Failed to archive proposals');
    } finally {
      setLoading(null);
    }
  }

  function handleDeleteProposal(id) {
    confirm({
      title: 'Are you sure you want to delete this proposal?',
      content: "You can't undo this action",
      cancelButtonProps: { ghost: true },
      onOk: async () => {
        try {
          setLoading({ type: 'delete', id });
          await ProposalAPI.deleteProposal(id);
          fetchProposalData({
            cursor,
            q: searchQuery,
            status,
            archive: !!archiveQuery.length,
          });
          message.success('Proposal has been removed');
        } catch (err) {
          message.error(err?.response?.message || 'Failed to delete proposals');
        } finally {
          setLoading({});
        }
      },
    });
  }

  async function fetchProposalData({
    limit = 250,
    cursor = 1,
    q = '',
    status = '',
    archive = false,
  }) {
    try {
      const { data } = await ProposalAPI.getProposalList({
        limit,
        cursor,
        q,
        status,
        archive,
      });

      const proposalData = get(data, 'data', []).map((proposal) => ({
        ...proposal,
        key: proposal.id,
      }));

      setProposalList(proposalData);
    } catch (err) {
      message.error(
        get(err, 'response.message', 'Failed to get proposals data')
      );
    }
  }

  useEffect(() => {
    fetchProposalData({
      cursor,
      q: searchQuery,
      status,
      archive: !!archiveQuery.length,
    });
  }, [cursor, status, searchQuery, archiveQuery]);

  return (
    <Layout className="pd-cms-admin">
      {/* Section that display chart */}
      <DashboardContainer>
        <Row align="middle" type="flex" gutter="10">
          <Col lg={10}>
            <Search
              placeholder="Search Customer Name"
              onChange={handleSearchCustomerName}
            />
          </Col>
          <Col lg={4}>
            <Select
              defaultValue={undefined}
              placeholder="Status"
              className="w-full"
              onSelect={handleChangeStatus}>
              {statusFilter.map((status) => (
                <Option key={status.value} value={status.value}>
                  {status.text}
                </Option>
              ))}
            </Select>
          </Col>
          <Col lg={3}>
            <PdCheckbox
              data={['Archive']}
              value={archiveQuery}
              onChange={setArchiveQuery}
              fullWidth
            />
          </Col>
          <Col lg={{ span: 3, offset: 4 }}>
            <PdButton block onClick={handleCreateProposal}>
              Create New
            </PdButton>
          </Col>
        </Row>
      </DashboardContainer>
      <DashboardContainer>
        <Row align="middle">
          <Col lg={24}>
            <PdAntdTable
              dataSource={dataSource}
              rowKeys="id"
              columns={columns}
            />
          </Col>
        </Row>
      </DashboardContainer>
    </Layout>
  );
}

export default { Proposal };
