import React, { useState, useEffect } from 'react';
import { Col, Row, Popover } from 'antd';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import moment from 'moment';
import { identity } from 'lodash';

import PdDatePicker from 'components/pd-datepicker';

import calendar from 'assets/icons/calendar.svg';

import './style.scss';

const dictionary = {
  dayGridMonth: {
    className: 'month',
    format: 'MMMM YYYY',
  },
  dayGridWeek: {
    className: 'week',
    format: '[Week] Wo [of] YYYY',
  },
};

/**
 *
 * @param {Object} props
 * @param {Object[]} props.events a FullCalendar compatible event array of object
 * @param {('dayGridWeek' | 'dayGridMonth')} props.view
 */

export function PdCalendar({
  events,
  view = 'dayGridWeek',
  onClickOrder = identity,
  onChangeDate = identity,
}) {
  const calendarRef = React.createRef();
  const [date, setDate] = useState(moment());

  useEffect(() => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.changeView(view);

    onChangeDate(emitChangeDate(moment()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  function handleChangeDate(value) {
    let calendarApi = calendarRef.current.getApi();

    if (value) {
      calendarApi.gotoDate(value.format());
      setDate(value);
    } else {
      calendarApi.gotoDate(moment().format());
      setDate(moment());
    }
    onChangeDate(emitChangeDate(value));
  }

  function emitChangeDate(value) {
    let start_date, end_date;
    if (view === 'dayGridWeek') {
      const format = 'YYYY-MM-DD';
      start_date = value.day(0).format(format);
      end_date = value.day(6).format(format);
    } else {
      const currentMonth = value.format('YYYY-MM-');
      start_date = currentMonth + '01';
      end_date = currentMonth + value.daysInMonth();
    }
    return { start_date, end_date };
  }

  function TableHeader(type) {
    if (type === 'date') {
      return (
        <Row gutter={165}>
          <Col>
            <a>Service Title</a>
          </Col>
        </Row>
      );
    }
  }

  function Content(event) {
    return (
      <div>
        <p>{event.extendedProps?.address}</p>
        <p>Notes: {event.extendedProps?.description || '-'}</p>
      </div>
    );
  }

  function Event(args) {
    const { customer } = args.event?.extendedProps || {};
    const { id } = args.event;

    return (
      <Popover
        content={Content(args.event)}
        overlayClassName={`pd-calendar-${dictionary[view].className}-event-popover`}
        title={customer}>
        <span onClick={onClickOrder.bind(this, id)}>{args.event?.title}</span>
      </Popover>
    );
  }

  function NoEvent() {
    return (
      <h3 className="pd-header-title">
        You have no service scheduled in this time.
      </h3>
    );
  }

  return (
    <div className={`pd-calendar-${dictionary[view].className}`}>
      <Row justify="center" align="middle" gutter={10}>
        <Col lg={1} sm={2} xs={4}>
          <img src={calendar} className="pd-calendar-image" />
        </Col>
        <Col lg={23} sm={22} xs={20}>
          <PdDatePicker
            format={dictionary[view].format}
            onChange={handleChangeDate}
            picker={dictionary[view].className}
            value={date}
          />
        </Col>
      </Row>
      <Row justify="center">
        <Col lg={24} sm={24} xs={24}>
          <FullCalendar
            allDaySlot={false}
            dayCellClassNames={`pd-calendar-${dictionary[view].className}-cell`}
            dayHeaderClassNames={`pd-calendar-${dictionary[view].className}-header`}
            dayHeaderContent={TableHeader.bind(
              this,
              dictionary[view].className
            )}
            dayHeaderFormat={
              view === 'dayGridWeek'
                ? { day: '2-digit', weekday: 'short' }
                : { weekday: 'short' }
            }
            eventClassNames={`pd-calendar-${dictionary[view].className}-event`}
            eventContent={Event}
            eventDisplay="auto"
            events={events}
            headerToolbar={{ start: '', center: '', end: '' }}
            noEventsClassNames="pd-calendar-date-empty"
            noEventsContent={NoEvent}
            plugins={[timeGridPlugin, dayGridPlugin]}
            ref={calendarRef}
            viewClassNames={`pd-calendar-${dictionary[view].className}`}
          />
        </Col>
      </Row>
    </div>
  );
}

export default PdCalendar;
