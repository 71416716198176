import React, { useState } from 'react';
import { Icon, Form, Input, Layout, Typography, message } from 'antd';
import { Link } from 'react-router-dom';

import PdButton from 'components/pd-button';

import AdminAPI from 'api/admin';

import { handleErr } from 'utils';

import './style.scss';

const { Content } = Layout;
const { Text, Title } = Typography;

export function ForgotPassword({ form, history }) {
  const [loadingButton, seLoadingButton] = useState(false);
  const { getFieldDecorator } = form;

  async function handleResetPassword(payload) {
    try {
      seLoadingButton(true);
      await AdminAPI.resetPassword({
        ...payload,
        email: payload.email.toLowerCase(),
      });

      await message.loading('Please check your mail to reset password', 2);

      history.push({
        pathname: '/',
      });
    } catch (err) {
      handleErr(err);
    } finally {
      seLoadingButton(false);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        handleResetPassword(values);
      }
    });
  }

  return (
    <Content className="pd-login">
      <Title level={2} className="pd-margin-bottom-lg">
        Forgot Password
      </Title>
      <Form onSubmit={handleSubmit} className="login-form">
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input your email!' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email"
            />
          )}
        </Form.Item>
        <Form.Item>
          <PdButton
            className="login-form-button"
            loading={loadingButton}
            htmlType="submit">
            Send
          </PdButton>
        </Form.Item>
      </Form>
      <Text>
        Already have an account? <Link to="/">Login now</Link>
      </Text>
    </Content>
  );
}

export const ForgotPasswordForm = Form.create({ name: 'login' })(
  ForgotPassword
);

export default { ForgotPassword };
