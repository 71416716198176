import React, { useEffect, useState, useContext } from 'react';
import { Redirect } from 'react-router';
import {
  Col,
  Input,
  Layout,
  message,
  Row,
  Typography,
  Pagination,
  Icon,
} from 'antd';
import { get, startCase } from 'lodash';
import moment from 'moment';

import DashboardContainer from 'components/dashboard-container';
import PdButton from 'components/pd-button';
import PdTable, { PdTableBodyRow, PdTableHeader } from 'components/pd-table';
import PdPreviewEmail from 'components/pd-preview-email';
import { AuthenticationContext } from 'contexts/authentication';

import BillingInvoiceApi from 'api/billing-invoice';
import CustomerAPI from 'api/customer';

import { handleErr, DATE_FORMAT } from 'utils';
import { numberDate, numberWithCommas } from 'utils/numberFormater';

import './style.scss';

const { TextArea } = Input;
const { Title } = Typography;

const itemPerPage = 5;

export function InvoiceDetail({ history, location }) {
  const [billingInvoiceDetail, setBillingInvoiceDetail] = useState({});
  const [customerBalance, setCustomerBalance] = useState([]);
  const [detailPageMeta, setDetailPageMeta] = useState({});
  const [listRange, setListRange] = useState([0, itemPerPage]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isPreviewEmail, setIsPreviewEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { authenticatedUserProfile } = useContext(AuthenticationContext);
  const { isUnlimitedPlan = false } = authenticatedUserProfile;

  const { id: locationId = '', invoiceList = [] } = location;
  const {
    customer = {},
    period = '',
    pdf_url = '',
    message: billingMessage = '',
    status,
  } = billingInvoiceDetail;
  const { current_balance = 0, name = '' } = customer;

  const tableHeaders = [
    { title: 'Date', width: 8 },
    { title: 'Description', width: 8 },
    { title: 'Amount', width: 8 },
  ];

  function handleBackInvoice() {
    history.push({
      pathname: '/invoice',
    });
  }

  function handleOnChangeMessages(e) {
    const value = get(e, ['target', 'value'], '');
    setBillingInvoiceDetail((prev) => ({
      ...prev,
      message: value,
    }));
  }

  async function handleSubmit() {
    try {
      const payload = {
        message: billingMessage,
      };
      await BillingInvoiceApi.updateMessage(locationId, payload);
      message.success('Successfully update the message');
    } catch (error) {
      handleErr(error);
    }
  }

  function handlePDFPrint() {
    window.open(pdf_url);
  }

  function handleChangePage(page) {
    const maxRange = page * itemPerPage;
    const minRange = maxRange - itemPerPage;
    setListRange([minRange, maxRange]);
  }

  async function handleSendMail() {
    try {
      await BillingInvoiceApi.sendMail(locationId);
      setIsPreviewEmail(false);
      message.success('Successfully send email to the customer');
    } catch (error) {
      handleErr(error);
    }
  }

  function PdTableBodyRowService({
    amount = 0,
    date = Date.now(),
    description = '',
  }) {
    return (
      <PdTableBodyRow>
        <Col xs={8}>{numberDate(date)}</Col>
        <Col xs={8}>{description}</Col>
        <Col xs={8}>{`$ ${numberWithCommas(amount)}`}</Col>
      </PdTableBodyRow>
    );
  }

  async function fetchBillingInvoiceDetail(id) {
    try {
      setIsLoading(true);
      await BillingInvoiceApi.resyncDetail(id);
      const { data } = await BillingInvoiceApi.getBillingInvoiceDetail(id);
      const billingInvoiceData = get(data, 'data', {});
      const { period = Date.now() } = billingInvoiceData;
      setBillingInvoiceDetail({
        ...billingInvoiceData,
        period: moment(period).format('MM/YYYY'),
      });

      // fetch balance customer
      const { data: customerData } = await CustomerAPI.getCustomerBalance({
        id: billingInvoiceData.customer.id,
      });

      const balance = customerData.data.map((item) => {
        const { id, transaction_type, amount, transaction_date } = item;
        let description =
          transaction_type === 'payment'
            ? item?.payment?.order?.invoice_number
            : item?.order?.invoice_number;
        description += ` - ${startCase(transaction_type)} `;
        return {
          amount,
          date: moment(transaction_date).format(DATE_FORMAT),
          description,
          id,
        };
      });
      setCustomerBalance(balance);

      const totalPrice = getTotalAmount(balance);
      setTotalAmount(totalPrice);
    } catch (error) {
      handleErr(error);
    } finally {
      setIsLoading(false);
    }
  }

  function getTotalAmount(payment) {
    return payment.reduce(
      (accumulator, { amount = 0 }) => accumulator + +amount,
      0
    );
  }

  function handleChangeDetailPage(type) {
    return function () {
      window.scrollTo(0, 0);
      history.replace({
        pathname: '/invoice/detail',
        id: detailPageMeta[type],
        invoiceList,
      });
    };
  }

  function getDetailPageMeta() {
    const lastIndex = invoiceList.length - 1;
    const currentIndex = invoiceList.findIndex((value) => value === locationId);
    setDetailPageMeta({
      prev: currentIndex - 1 < 0 ? '' : invoiceList[currentIndex - 1],
      next: currentIndex === lastIndex ? '' : invoiceList[currentIndex + 1],
    });
  }

  useEffect(() => {
    getDetailPageMeta();
    fetchBillingInvoiceDetail(locationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  if (!locationId) {
    return <Redirect to="/invoice" />;
  }

  return (
    <Layout className="pd-mobile-invoice-detail">
      <Row xs={24} justify="center" type="flex">
        <Col className="pd-margin-top-md" xs={{ span: 24 }}>
          <Title className="pd-align-center" level={3}>
            Detail Invoice
          </Title>
        </Col>
      </Row>
      <DashboardContainer>
        <Row
          type="flex"
          justify="space-between"
          className="pd-margin-bottom-md">
          <PdButton
            className="button-navigate"
            type="link"
            onClick={handleChangeDetailPage('prev')}
            disabled={detailPageMeta?.prev === ''}>
            <Icon type="backward" /> Previous
          </PdButton>
          <PdButton
            className="button-navigate"
            type="link"
            onClick={handleChangeDetailPage('next')}
            disabled={detailPageMeta?.next === ''}>
            Next <Icon type="forward" />
          </PdButton>
        </Row>

        <Row>
          {/* Field Section */}
          <Col lg={19}>
            <Row align="middle" type="flex" className="detail-container">
              <Col xs={24}>
                <span className="detail-container-label">Invoice Number</span> :{' '}
                {locationId}
              </Col>
            </Row>

            <Row align="middle" type="flex" className="detail-container">
              <Col xs={24}>
                <span className="detail-container-label">Customer Name</span> :{' '}
                {name}
              </Col>
            </Row>

            <Row align="middle" type="flex" className="detail-container">
              <Col xs={24}>
                <span className="detail-container-label">Period</span> :{' '}
                {period}
              </Col>
            </Row>

            <Row align="middle" type="flex" className="detail-container">
              <Col xs={24}>
                <span className="detail-container-label">Last Balance</span> :{' '}
                {`$ ${numberWithCommas(current_balance)}`}
              </Col>
            </Row>

            <Row align="middle" type="flex" className="detail-container">
              <Col xs={24}>
                <span className="detail-container-label">Status</span> :{' '}
                {startCase(status)}
              </Col>
            </Row>

            <Row align="middle" type="flex" className="detail-container">
              <Col className="detail-container-label" xs={24}>
                Messages :
              </Col>
            </Row>
            <Row align="middle" type="flex" className="detail-container">
              <Col xs={24}>
                <TextArea
                  value={billingMessage}
                  onChange={handleOnChangeMessages}
                />
              </Col>
            </Row>
          </Col>

          {/* FLoat Button  */}
          <Col lg={5}>
            <Row className="action-section">
              <Col>
                <PdButton
                  block
                  ghost
                  type="primary"
                  onClick={handlePDFPrint}
                  disabled={isLoading}>
                  PDF Print
                </PdButton>
              </Col>
              <Col>
                <PdButton
                  block
                  onClick={() => setIsPreviewEmail(true)}
                  disabled={!isUnlimitedPlan}>
                  Send Mail to Customer
                </PdButton>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Table Section */}
        <PdTable className="detail-container">
          <PdTableHeader listHeader={tableHeaders} />
          {customerBalance
            .slice(...listRange)
            .map(({ id, date, description, amount }) => {
              return (
                <PdTableBodyRowService
                  amount={amount}
                  date={date}
                  description={description}
                  key={id}
                />
              );
            })}
          <Row type="flex" justify="end">
            <Pagination
              onChange={handleChangePage}
              pageSize={itemPerPage}
              total={customerBalance.length}
            />
          </Row>
        </PdTable>

        <Row
          align="middle"
          justify="end"
          type="flex"
          className="detail-container"
          gutter={10}>
          <Col className="detail-container-label">Total Amount</Col>
          <Col xs={7} align="right">{`$ ${numberWithCommas(totalAmount)}`}</Col>
        </Row>

        <Row align="middle" justify="center" type="flex" gutter={10}>
          <Col xs={8}>
            <PdButton block ghost type="primary" onClick={handleBackInvoice}>
              Back
            </PdButton>
          </Col>
          <Col xs={8}>
            <PdButton block onClick={handleSubmit}>
              Submit
            </PdButton>
          </Col>
        </Row>
      </DashboardContainer>

      {/* modal preview email */}
      <PdPreviewEmail
        visible={isPreviewEmail}
        onCancel={() => setIsPreviewEmail(false)}
        previewUrl={pdf_url}
        onSendEmail={handleSendMail}
      />
    </Layout>
  );
}

export default { InvoiceDetail };
