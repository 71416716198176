import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { UserContext } from 'contexts/user';

import './style.scss';

/**
 * @param {Object} props
 * @param {('small'|'medium')} props.size - The allowed type of the size
 */
function Logo({ size = 'medium' }) {
  const {
    state: { userSettings },
  } = useContext(UserContext);
  const { company_logo = require('assets/images/logo.png') } = userSettings;
  return (
    <Link to="/">
      <img className={`logo-image-${size}`} src={company_logo} alt="logo" />
    </Link>
  );
}

export default Logo;
