import React from 'react';
import { Button, Tooltip } from 'antd';

import './style.scss';

/**
 * @param {object} props
 */
function PdButton({
  children,
  type = 'primary',
  className = '',
  tooltipTitle = '',
  disabled = false,
  ...props
}) {
  return (
    <Tooltip title={tooltipTitle}>
      <Button
        type={type}
        className={className ? `pd-button ${className}` : 'pd-button'}
        disabled={disabled}
        {...props}>
        {children}
      </Button>
    </Tooltip>
  );
}

export default PdButton;
